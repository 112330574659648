package com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.widget

import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.MarkFeavoriteState
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_heart_primary_color_fill
import org.jetbrains.compose.resources.painterResource

@Composable
fun ActionFavoriteIconButton(
    expertData: RequestConsultant,
    icon: Painter,
    onClick: () -> Unit,
    component: ExpertListPageComponent
) {
    var isClicked by remember { mutableStateOf<Boolean?>(false) }
    var clickedId by remember { mutableStateOf<Int?>(null) }
    val markFavoriteState by component.markedFavoriteState.collectAsState()
    val isFavorite = remember(markFavoriteState, expertData.id, isClicked) {
        ((markFavoriteState is MarkFeavoriteState.Success) && isClicked == true && clickedId == expertData.id) ||
                expertData.is_favrouite == 1
    }

    IconButton(
        onClick = {
            Logger.d { "Favorite button click ${expertData.is_favrouite}" }
            if (!isFavorite) {  // Use the updated isFavorite state
                isClicked = true
                clickedId = expertData.id
                Logger.d { "Favorite button click if part  ${expertData.is_favrouite}" }
                onClick()
            } else {
                Logger.d { "Favorite button click else part  ${expertData.is_favrouite}" }
                // Handle case when the expert is already a favorite
                clickedId = null
                isClicked = false
                onClick()
            }

        },
        modifier = Modifier.size(48.dp)
    ) {
        val iconToShow = if (isFavorite) {
            Logger.d { "Add to favorite $isFavorite" }
            painterResource(Res.drawable.ic_heart_primary_color_fill)
        } else {
            Logger.d { "Remove from Favorite $isFavorite" }
            icon
        }
        Icon(
            painter = iconToShow,
            contentDescription = null,
            tint = Color(0xFF1B98F0),
            modifier = Modifier.size(24.dp)
        )
    }
}


