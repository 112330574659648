package com.winiwayuser.feature_courses.data.response.course

import com.winiwayuser.core.data.CommonResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import com.winiwayuser.feature_courses.data.response.courses.CourseData


@Serializable
data class CourseResponse(
    @SerialName("data") val data: CourseData? = null
) : CommonResponse()