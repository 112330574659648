package com.winiwayuser.feature_common.data.request

import com.winiwayuser.feature_common.data.response.SubCategoriesModel
import kotlinx.serialization.Serializable

@Serializable
data class RequestCategory(
    val id: Int?,
    val name: String?,
    val image: String?,
    val image_url: String?,
    val subcategories: List<SubCategoriesModel>?
)
