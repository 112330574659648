package com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data

import co.touchlab.kermit.Logger
import co.touchlab.kermit.NoTagFormatter
import co.touchlab.kermit.loggerConfigInit
import co.touchlab.kermit.platformLogWriter
import com.winiwayuser.core.local.TokenProvider
import com.winiwayuser.core.local.TokenProvider.getNewToken
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.client.utils.EmptyContent.headers
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.withContext

class EditProfileApiService : KtorApi() {
    private val storeLogger = Logger(
        loggerConfigInit(platformLogWriter(NoTagFormatter)),
        "StoreApi"
    )

    companion object {
        const val POST_EDIT_PROFILE = "edit-profile"
    }

    private suspend fun updateProfileApi(request: EditProfileRequest): EditProfileModel {
        val token = TokenProvider.getNewToken()
        Logger.d("Auth toke Edit profile  -> ${TokenProvider.getNewToken()}")
        return client.post {
            url(POST_EDIT_PROFILE)
            contentType(ContentType.Application.Json)
            headers {
                append("Authorization", "Bearer $token")
            }
            setBody(request)
        }.body()
    }


    suspend fun onUpdateUserProfile(
        request: EditProfileRequest
    ): Flow<Resources<ProfileDetails>> = flow {
        emit(Resources.Loading(true))
        try {
            val productResponse = withContext(Dispatchers.Default) {
                updateProfileApi(request)
            }
            if (productResponse.status.not()) {
                emit(Resources.Error("Client request error: ${productResponse.message}"))
            } else {
                emit(Resources.Success(productResponse.data))
            }
        } catch (e: ClientRequestException) {
            emit(Resources.Error("Client request error: ${e.message}"))
        } catch (e: ServerResponseException) {
            emit(Resources.Error("Server response error: ${e.message}"))
        } catch (e: Exception) {
            emit(Resources.Error("Unexpected error: ${e.message}"))
        }
    }

}