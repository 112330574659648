package com.winiwayuser.feature_expert_consultation.data.remote

import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_consultation.data.remote.request.RequestConsultInfo
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class ExpertDetailsApiService: KtorApi() {
    companion object {
        const val GET_REQUEST_EXPERT_DETAILS = "consultant/"
    }

    suspend fun requestExpertDetailsInfo(id:String): Resources<RequestConsultInfo> {
        return withContext(Dispatchers.Default) {
            try {
                val sliderResponse = client.get{
                    url(BASE_URL+ GET_REQUEST_EXPERT_DETAILS +id)
                }.body<RequestConsultInfo>()
                if (sliderResponse.status) Resources.Success(sliderResponse) else throw Exception(sliderResponse.message)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}