package com.winiwayuser.feature_courses.domain

import com.winiwayuser.core.remote.Resources
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import com.winiwayuser.feature_courses.data.CoursesApi
import com.winiwayuser.feature_courses.data.request.CourseDetailsSubmitForm

class LandingPageRepo(val api: CoursesApi) {

    fun submitCourseDetails(request: CourseDetailsSubmitForm):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading())
            val response = api.postCourseDetailsRemote(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}