package com.winiwayuser.core.extenstion

import kotlin.random.Random

/**
 * Generates a random alphanumeric string of the specified [length].
 *
 * @param length The length of the generated string. Must be greater than 0.
 * @return A random alphanumeric string.
 */
fun generateRandomString(length: Int = 16): String {
    require(length > 0) { "Length must be greater than 0." }

    val allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    return (1..length)
        .map { allowedChars.random(Random) }
        .joinToString("")
}