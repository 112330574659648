import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import com.winiwayuser.core.decompose.DefaultRootComponent
import com.winiwayuser.core.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event


@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
fun main() {

    // Function to update MetaTitle and MetaDescription
    fun updateMetaTags(title: String, description: String) {
        // Update the document title
        document.title = title

        // Find or create the meta description tag
        val metaDescription = document.querySelector("meta[name='description']")
            ?: document.createElement("meta").apply {
                setAttribute("name", "description")
                document.head?.appendChild(this)
            }

        // Set the content of the meta description
        metaDescription.setAttribute("content", description)
    }

    // Call the function to update the title and description
    updateMetaTags(
        title = "Professional Astrology Consultation: Transform Your Life",
        description = "Unlock your future with a personalized astrology consultation. Talk to astrologer on relationships, career, and life path through birth chart analysis. Book now for clarity and cosmic guidance"
    )

    // Function to adjust layout when keyboard appears
    fun adjustLayoutOnKeyboard() {
        window.addEventListener("focusin", { _: Event ->
            val activeElement = document.activeElement
            if (activeElement?.tagName?.equals("INPUT", ignoreCase = true) == true ||
                activeElement?.tagName?.equals("TEXTAREA", ignoreCase = true) == true
            ) {
                // Push up content to avoid overlap
                (document.documentElement as? HTMLElement)?.style?.setProperty(
                    "transform",
                    "translateY(-150px)"
                )
            }
        })

        window.addEventListener("focusout", {
            // Reset the layout when the input loses focus
            (document.documentElement as? HTMLElement)?.style?.setProperty(
                "transform",
                "translateY(0)"
            )
        })
    }

    // Call the function to adjust layout on keyboard appearance
    adjustLayoutOnKeyboard()

    initKoin()
    val myLifecycleOwner = MyLifecycleOwner()
    val lifecycleRegistry = LifecycleRegistry()

    val root = DefaultRootComponent(
        componentContext = DefaultComponentContext(lifecycle = myLifecycleOwner.lifecycle),
        deepLink = DefaultRootComponent.DeepLink.Web(path = window.location.pathname),
        webHistoryController = DefaultWebHistoryController()
    )

    val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

    val skeletonLoader = document.getElementById("skeleton-loader") as? HTMLDivElement
    skeletonLoader?.style?.display = "block"
    onWasmReady {


        // Hide skeleton loader
        skeletonLoader?.style?.display = "none"
        lifecycleRegistry.attachToDocument()
        var density by mutableStateOf(Density(1.0f))

        fun updateDensity() {
            val dpr = window.devicePixelRatio.toFloat()
            density = Density(dpr)
        }

        // Update density initially
        updateDensity()

        // Listen for window resize events to update density
        window.addEventListener("resize", {
            updateDensity()
        })

        CanvasBasedWindow(title = "Professional Astrology Consultation: Transform Your Life") {
            CompositionLocalProvider(
                LocalDensity provides density,
                LocalLayoutDirection provides LayoutDirection.Ltr,
            ) {

                App(
                    darkTheme = prefersDarkTheme,
                    dynamicColor = true,
                    rootComponent = root
                )
            }

        }
    }
}

private fun LifecycleRegistry.attachToDocument() {
    val visibilityState = document.asDynamic().visibilityState as String
    fun onVisibilityChanged() {
        if (visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()
    document.addEventListener("visibilitychange", { _: Event ->
        onVisibilityChanged()
    })

}






