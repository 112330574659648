package com.winiwayuser.feature_courses.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseBuyRequest(
    @SerialName("course_id") val courseId: String,
    @SerialName("user_name") val userName: String,
    @SerialName("user_email") val userEmail: String,
    @SerialName("country_code") val countryCode: String,
    @SerialName("mobile") val mobile: String,
    @SerialName("coupon_code") val couponCode: String?,
    @SerialName("watch_option") val courseWatchOption: String?,
    @SerialName("watch_time") val courseWatchTime: String?,
)
