package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.BrowserType
import com.winiwayuser.core.persentation.getDeviceType
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.getBrowserTypeScale
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.feature_common.data.request.RequestUpComingLiveSessions
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.calender_semi_small_black
import com.winiwayuser.resources.share_icon_new
import com.winiwayuser.resources.subscribe_icon
import org.jetbrains.compose.resources.painterResource

@Composable
fun TopLiveSessionUI(
    component: HomeScreenTabComponent, onClick: @Composable (RequestUpComingLiveSessions) -> Unit
) {

    val sessionsData by component.liveSessionState.collectAsState()

    if (sessionsData?.data?.Upcoming.isNullOrEmpty()) return

    val deviceType = getDeviceType()
    val isBrowserType = deviceType.name in BrowserType.entries.map { it.name }


    Column(
        modifier = Modifier.padding(horizontal = 16.dp).fillMaxWidth()
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Upcoming Free Live Sessions",
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier.weight(1f)
            )
            Text(text = "See All",
                style = MaterialTheme.typography.bodySmall,
                color = primaryColor,
                modifier = Modifier.padding(top = 8.dp).clickable { /* Handle click action */ })
        }
        LazyRow(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            items(sessionsData?.data?.Upcoming!!) { session ->
                TopSessionCard(session = session, onClick = { onClick })
            }
        }
    }

}

@Composable
fun TopSessionCard(
    session: RequestUpComingLiveSessions, onClick: (RequestUpComingLiveSessions) -> Unit
) {
    Card(shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
        modifier = Modifier.padding(4.dp).size(
            width = 160.dp,
            height = 230.dp
        )
            .clickable { onClick(session) }) {
        Box(modifier = Modifier.clickable { onClick(session) } // Handle entire card click
        ) {
            // Background Image with Inner Content

            AsyncImage(
                model = session.expertProfile,
                contentDescription = null,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.FillBounds
            )

            // Semi-transparent black shade (10% opacity)
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Color.Black.copy(alpha = 0.18f)) // 10% black
            )

            Column(
                modifier = Modifier.fillMaxSize().padding(8.dp)
            ) {
                // Date and Time Row
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {

                    Spacer(modifier = Modifier.width(2.dp))
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(2.dp).background(Color.White).clip(
                            RoundedCornerShape(Scale.CORNER_SMALL)
                        ).padding(horizontal = 2.dp).wrapContentWidth()
                    ) {

                        Image(
                            painter = painterResource(Res.drawable.calender_semi_small_black),
                            contentDescription = "Calendar Icon",
                            modifier = Modifier.size(8.dp).wrapContentHeight()
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = session.liveDate!! + "|" + session.liveTime,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis,
                            fontSize = 10.sp,
                            color = Color.Black
                        )
                    }
                    Spacer(modifier = Modifier.weight(.5f))
                    Image(
                        painter = painterResource(Res.drawable.share_icon_new),
                        contentDescription = "Share Icon",
                        modifier = Modifier.size(15.dp).fillMaxHeight(),
                        colorFilter = ColorFilter.tint(Color.White)
                    )

                }

                // Expert Name
                Text(
                    text = session.expertName!!,
                    fontSize = 15.sp,
                    maxLines = 2,
                    color = Color.White,
                    fontWeight = FontWeight.Bold
                )

                Spacer(modifier = Modifier.weight(1f))

                // Live Topic
                Box(modifier = Modifier.fillMaxWidth()) {
                    Text(
                        text = session.liveTopic!!,
                        fontSize = 14.sp,
                        maxLines = 2,
                        color = Color.White,
                        textAlign = TextAlign.Start,
                        fontWeight = FontWeight.Bold
                    )
                }

                Spacer(modifier = Modifier.height(4.dp))
                // Action Row
                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier.fillMaxWidth().wrapContentHeight()
                ) {
                    Row(
                        modifier = Modifier.clickable { onClick(session) }
                            .background(Color.White, shape = CircleShape)
                            .padding(horizontal = 8.dp, vertical = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.subscribe_icon),
                            contentDescription = "Subscribe Icon",
                            modifier = Modifier.size(12.dp).fillMaxWidth()
                                .align(Alignment.CenterVertically)
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = "Notify Me",
                            fontSize = 13.sp,
                            color = Color.Black,
                            fontWeight = FontWeight.SemiBold
                        )
                    }
                }
            }
        }
    }
}