package com.winiwayuser.feature_call_consultation.persentation.call_success_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallPlaceInfoState
import com.winiwayuser.feature_call_consultation.domain.CallConsultationInfoRepo
import com.winiwayuser.feature_call_consultation.data.request.GetCallDurationRequest
import com.winiwayuser.feature_call_consultation.data.response.Data
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class CallSuccessComponent(
    context: ComponentContext,
    private val _expertId: String,
    private val _expertName: String,
    private val _expertSkills: String,
    private val _expertImage: String,
    val onClosePage: () -> Unit,
    private val onTimeOutOver: () -> Unit,
) : ComponentContext by context, KoinComponent {

    val expertId get() = _expertId
    val expertName get() = _expertName
    val expertSkills get() = _expertSkills
    val expertImage get() = _expertImage
    fun onClose() = onClosePage.invoke()
    fun timeOver() = onTimeOutOver.invoke()
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: CallConsultationInfoRepo by inject()
    private val _placeCallState =
        MutableStateFlow<CallPlaceInfoState>(CallPlaceInfoState.Loading(false))
    val placeCallState get() = _placeCallState.asStateFlow()

    init {
        lifecycle.doOnStart {
            coroutineScope.launch {
                Logger.d { "CallSuccessComponent on Start" }
                callPlace()
            }
        }
        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private suspend fun onPlaceCallError(msg: String) {
        _placeCallState.emit(CallPlaceInfoState.Error(msg))
    }

    private fun onCallPlaceSuccess(isCallDone: Data) = coroutineScope.launch {
        _placeCallState.emit(CallPlaceInfoState.PlaceCallSuccess(isCallDone))
        //CallWaitingSuccess(expertId = expertId, expertImage = expertImage, expertName = expertName, expertSkills = expertSkills)
    }

    suspend fun callPlace() {
        val request = GetCallDurationRequest(expertId)

        api.callPlace(request).collect {
            when (it) {
                is Resources.Error -> onPlaceCallError(it.msg ?: "UnexpectedError")
                is Resources.Loading -> _placeCallState.emit(CallPlaceInfoState.Loading(it.isLoading))
                is Resources.Success -> onCallPlaceSuccess(it.data!!)
            }
        }
    }
}