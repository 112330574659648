package com.winiwayuser.feature_courses.data.response.courses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Instructors(
    @SerialName("id") var id: Int? = null,
    @SerialName("expert_id") var expertId: Int? = null,
    @SerialName("name") var name: String? = null,
    @SerialName("profile_image") var profileImage: String? = null,
    @SerialName("profession") var profession: String? = null,
    @SerialName("bio") var bio: String? = null,
    @SerialName("reviews") var reviews: String? = null, //"1K + Reviews"
    @SerialName("students") var students: String? = null, //2K + Students taught
    @SerialName("experience") var experience: String? = null, //10 + Years of Experience
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null,
    @SerialName("pivot") var pivot: Pivot? = Pivot()
)
