package com.winiwayuser.feature_landing_page.persentation.widget

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.landing_page_course_details
import com.winiwayuser.feature_landing_page.data.request.CourseDetailsSubmitForm
import com.winiwayuser.feature_landing_page.persentation.LandingPageComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.science_astro_icon
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.koin.dsl.module

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CourseDetailsForm(component: LandingPageComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    var firstName by remember { mutableStateOf("") }
    var lastName by remember { mutableStateOf("") }
    var email by remember { mutableStateOf("") }
    var phoneNumber by remember { mutableStateOf("") }
    var selectedCourse by remember { mutableStateOf("") }
    var referenceCode by remember { mutableStateOf("") }
    var selectedCountryCode by remember { mutableStateOf("+91") }
    var expanded by remember { mutableStateOf(false) }
    var cuentryCodeExpanded by remember { mutableStateOf(false) }

    val countryCodes = listOf("+91", "+1", "+44", "+61")
    val courses = listOf("Vedic Astrology")
    val emailPattern = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$".toRegex()

    Box(modifier = Modifier
        .fillMaxSize()
        .padding(16.dp),
        contentAlignment = Alignment.Center
    ) {
        if (windowSizeClass == WindowWidthSizeClass.Compact) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                // Title and Image

                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .padding(16.dp), // Adding some padding for better layout
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                    // Title text
                    Text(
                        text = "Turn your Passion into Career",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        color = MaterialTheme.colorScheme.primary,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    // Sub-title text
                    Text(
                        text = "Learn Vedic Astrology from Best Astrologers in India",
                        fontSize = 16.sp,
                        textAlign = TextAlign.Center,
                        color = MaterialTheme.colorScheme.primary,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(12.dp))

                    // Course details text
                    Text(
                        text = "If you want to become a Vedic astrologer or learn to interpret your own kundli, this course is for you. Our Vedic Astrology course covers everything from the basics of Vedic astrology to advanced techniques, including chart signs, houses, transit systems, the Dasha system, and predictive techniques. By learning to interpret birth charts, you can discover personality traits, life events patterns, and create self-awareness.",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(12.dp))

                    // Detailed course description
                    Text(
                        text = "Understand how astrology can help in decision-making and empower you. Our experienced instructors provide practical exposure and interpret birth charts from a modern perspective. In our 100% live online classes, you can clarify all your doubts and deepen your knowledge of astrology.",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(16.dp))

                    // You can uncomment and use the following image for visual enhancement
                    // Image(
                    //     painter = painterResource(Res.drawable.science_astro_icon),
                    //     contentDescription = null
                    // )
                }

//                Text(
//                    text = "Unlocking The Secrets Of Ancient \n Vedic Science For A Better Future",
//                    maxLines = 2,
//                    fontSize = 20.sp,
//                    fontWeight = FontWeight.Bold,
//                    textAlign = TextAlign.Center,
//                    modifier = Modifier.padding(bottom = 16.dp)
//                )
//                Image(
//                    painter = painterResource(Res.drawable.science_astro_icon),
//                    contentDescription = null,
//                    modifier = Modifier
//                        .size(120.dp)
//                        .padding(bottom = 16.dp)
//                )

                // Form Fields
                Column(
                    modifier = Modifier
                        .border(1.dp, Color.Gray, RoundedCornerShape(Scale.CORNER_SMALL))
                        .padding(16.dp)
                        .fillMaxWidth()
                ) {

                    Text(
                        text = "Get the Course Details",
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center
                    )
                    // Name Fields
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        OutlinedTextField(
                            value = firstName,
                            onValueChange = { firstName = it },
                            label = { Text("First Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                        OutlinedTextField(
                            value = lastName,
                            onValueChange = { lastName = it },
                            label = { Text("Last Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Email Field
                    OutlinedTextField(
                        value = email,
                        onValueChange = { email = it },
                        label = { Text("Email") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        isError = email.isNotEmpty() && !email.matches(emailPattern),
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Email,
                            imeAction = ImeAction.Next
                        )
                    )
                    if (email.isNotEmpty() && !email.matches(emailPattern)) {
                        Text(
                            text = "Please enter a valid email address",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

//                    // Country Code & Phone Number Field
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 8.dp),
                        horizontalArrangement = Arrangement.spacedBy(4.dp),
                        verticalAlignment = Alignment.CenterVertically // Align vertically in the center
                    ) {
                        // Country Code Box
                        Box(
                            modifier = Modifier
                                .weight(0.3f) // Ensure proper spacing for country code
                                .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
                                .height(56.dp) // Match the height of the OutlinedTextField
                                .clickable { cuentryCodeExpanded = !cuentryCodeExpanded }
                                .padding(horizontal = 12.dp, vertical = 16.dp) // Padding inside the box
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(text = selectedCountryCode)
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }
                        }

                        DropdownMenu(
                            expanded = cuentryCodeExpanded,
                            onDismissRequest = { cuentryCodeExpanded = false }
                        ) {
                            countryCodes.forEach { code ->
                                DropdownMenuItem(
                                    text = { Text(code) },
                                    onClick = {
                                        selectedCountryCode = code
                                        cuentryCodeExpanded = false
                                    }
                                )
                            }
                        }

                        // Phone Number Field
                        OutlinedTextField(
                            value = phoneNumber,
                            onValueChange = { phoneNumber = it },
                            label = { Text("Phone Number") },
                            maxLines = 1,
                            modifier = Modifier.weight(0.7f), // Ensure phone number field takes more space
                            keyboardOptions = KeyboardOptions(
                                keyboardType = KeyboardType.Number,
                                imeAction = ImeAction.Next
                            ),
                            isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
                        )
                    }

                    if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
                        Text(
                            text = "Please enter a valid phone number",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }


                    Spacer(modifier = Modifier.height(12.dp))

                    // Course Dropdown
                    Text(text = "Course Interested In", fontSize = 14.sp, color = Color.Gray)

                    Column {
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .border(1.dp, Color.Gray, RoundedCornerShape(4.dp))
                                .clickable { expanded = !expanded }
                                .padding(12.dp) // Ensure padding is inside the Box, not affecting the outer layout
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(text = selectedCourse.ifEmpty { "-Select-" })
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }

                            // DropdownMenu positioned just below the Box
                            DropdownMenu(
                                expanded = expanded,
                                onDismissRequest = { expanded = false },
                               // modifier = Modifier.fillMaxWidth() // Ensure it matches the width of the Box
                            ) {
                                courses.forEach { course ->
                                    DropdownMenuItem(
                                        text = { Text(course) },
                                        onClick = {
                                            selectedCourse = course
                                            expanded = false
                                        }
                                    )
                                }
                            }
                        }


                    }

//                    Text(text = "Course Interested In", fontSize = 14.sp, color = Color.Gray)
//                    Column {
//                        Box(
//                            modifier = Modifier
//                                .fillMaxWidth()
//                                .border(1.dp, Color.Gray, RoundedCornerShape(4.dp))
//                                .padding(12.dp)
//                                .clickable { expanded = !expanded }
//                        ) {
//                            Row{
//
//                            }
//                            Text(text = selectedCourse.ifEmpty { "-Select-" })
//                            Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
//                        }
//
//                        DropdownMenu(
//                            expanded = expanded,
//                            onDismissRequest = { expanded = false }
//                        ) {
//                            courses.forEach { course ->
//                                DropdownMenuItem(
//                                    text = { Text(course) },
//                                    onClick = {
//                                        selectedCourse = course
//                                        expanded = false
//                                    }
//                                )
//                            }
//                        }
//                    }



                    Spacer(modifier = Modifier.height(12.dp))

                    // Reference Code
                    OutlinedTextField(
                        value = referenceCode,
                        onValueChange = { referenceCode = it },
                        label = { Text("Reference Code (Optional)") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Text,
                            imeAction = ImeAction.Done
                        )
                    )

                    Spacer(modifier = Modifier.height(24.dp))

                    // Terms and Submit Button
                    Text(
                        text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 4,
                        fontSize = 10.sp
                    )

                    Spacer(modifier = Modifier.height(24.dp))

                    // Buttons
                    Button(
                        onClick = {
                            CoroutineScope(Dispatchers.Default).launch {
                                component.postCourseDetails(
                                    CourseDetailsSubmitForm(
                                        firstName = firstName,
                                        lastName = lastName,
                                        mobile = selectedCountryCode + phoneNumber,
                                        email = email,
                                        courseInterestedIn = selectedCourse,
                                        referenceCode = referenceCode
                                    )
                                )
                            }
                        },
                        modifier = Modifier.fillMaxWidth(),
                        enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
                        shape = RoundedCornerShape(8.dp),
                        colors = ButtonDefaults.buttonColors(Color.Blue)
                    ) {
                        Text(text = "Get Course Details", color = Color.White)
                    }
                }
            }
        }else{
            Row( modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                horizontalArrangement = Arrangement.spacedBy(12.dp),
            ) {
                Column(
                    modifier = Modifier
                        .weight(1f)
                        .fillMaxHeight()
                        .padding(16.dp,), // Adding some padding for better layout
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                    // Title text
                    Text(
                        text = "Turn your Passion into Career",
                        fontSize = 20.sp,
                        color = MaterialTheme.colorScheme.primary,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.fillMaxWidth().padding(top = 10.dp)
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    // Sub-title text
                    Text(
                        text = "Learn Vedic Astrology from Best Astrologers in India",
                        fontSize = 16.sp,
                        color = MaterialTheme.colorScheme.primary,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(16.dp))

                    // Course details text
                    Text(
                        text = "If you want to become a Vedic astrologer or learn to interpret your own kundli, this course is for you. Our Vedic Astrology course covers everything from the basics of Vedic astrology to advanced techniques, including chart signs, houses, transit systems, the Dasha system, and predictive techniques. By learning to interpret birth charts, you can discover personality traits, life events patterns, and create self-awareness.",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(12.dp))

                    // Detailed course description
                    Text(
                        text = "Understand how astrology can help in decision-making and empower you. Our experienced instructors provide practical exposure and interpret birth charts from a modern perspective. In our 100% live online classes, you can clarify all your doubts and deepen your knowledge of astrology.",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start,
                        modifier = Modifier.fillMaxWidth()
                    )

                    Spacer(modifier = Modifier.height(16.dp))

                    // You can uncomment and use the following image for visual enhancement
                    // Image(
                    //     painter = painterResource(Res.drawable.science_astro_icon),
                    //     contentDescription = null
                    // )
                }

                Column(
                    modifier = Modifier
                        .weight(1f)
                        .border(width = 0.5.dp, color = Color.Black)
                        .padding(10.dp)
                ) {
                    Text(
                        text = "Get the Course Details",
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center
                    )

                    // First Name and Last Name Fields
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        OutlinedTextField(
                            value = firstName,
                            onValueChange = { firstName = it },
                            label = { Text("First Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                        OutlinedTextField(
                            value = lastName,
                            onValueChange = { lastName = it },
                            label = { Text("Last Name") },
                            maxLines = 1,
                            modifier = Modifier.weight(1f)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Email Field
                    OutlinedTextField(
                        value = email,
                        onValueChange = { email = it },
                        label = { Text("Email") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        isError = email.isNotEmpty() && !email.matches(emailPattern),
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Email,
                            imeAction = ImeAction.Next
                        )
                    )

                    if (email.isNotEmpty() && !email.matches(emailPattern)) {
                        Text(
                            text = "Please enter a valid email address",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Country Code & Phone Number Field
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 8.dp),
                        horizontalArrangement = Arrangement.spacedBy(4.dp),
                        verticalAlignment = Alignment.CenterVertically // Align vertically in the center
                    ) {
                        // Country Code Box
                        Box(
                            modifier = Modifier
                                .weight(0.2f) // Ensure proper spacing for country code
                                .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
                                .height(56.dp) // Match the height of the OutlinedTextField
                                .clickable { cuentryCodeExpanded = !cuentryCodeExpanded }
                                .padding(horizontal = 12.dp, vertical = 16.dp) // Padding inside the box
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(text = selectedCountryCode)
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }
                        }

                        DropdownMenu(
                            expanded = cuentryCodeExpanded,
                            onDismissRequest = { cuentryCodeExpanded = false }
                        ) {
                            countryCodes.forEach { code ->
                                DropdownMenuItem(
                                    text = { Text(code) },
                                    onClick = {
                                        selectedCountryCode = code
                                        cuentryCodeExpanded = false
                                    }
                                )
                            }
                        }

                        // Phone Number Field
                        OutlinedTextField(
                            value = phoneNumber,
                            onValueChange = { phoneNumber = it },
                            label = { Text("Phone Number") },
                            maxLines = 1,
                            modifier = Modifier.weight(0.8f), // Ensure phone number field takes more space
                            keyboardOptions = KeyboardOptions(
                                keyboardType = KeyboardType.Number,
                                imeAction = ImeAction.Next
                            ),
                            isError = phoneNumber.isNotEmpty() && phoneNumber.length < 10
                        )
                    }

                    if (phoneNumber.isNotEmpty() && phoneNumber.length < 10) {
                        Text(
                            text = "Please enter a valid phone number",
                            color = Color.Red,
                            fontSize = 12.sp,
                            modifier = Modifier.padding(start = 8.dp, top = 4.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Course Dropdown
//                    Text(text = "Course Interested In", color = Color.Gray, fontSize = 14.sp)
//                    Box(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(top = 4.dp)
//                            .border(BorderStroke(1.dp, Color.Gray), shape = RoundedCornerShape(4.dp))
//                            .clickable { expanded = !expanded }
//                            .padding(16.dp)
//                    ) {
//                        Text(text = selectedCourse.ifEmpty { "-Select-" })
//                        Icon(Icons.Default.ArrowDropDown, contentDescription = null, Modifier.align(Alignment.CenterEnd))
//                    }
//                    DropdownMenu(
//                        expanded = expanded,
//                        onDismissRequest = { expanded = false }
//                    ) {
//                        courses.forEach { course ->
//                            DropdownMenuItem(
//                                text = { Text(course) },
//                                onClick = {
//                                    selectedCourse = course
//                                    expanded = false
//                                }
//                            )
//                        }
//                    }

                    Text(text = "Course Interested In", fontSize = 14.sp, color = Color.Gray)

                    Column {
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .border(1.dp, Color.Gray, RoundedCornerShape(4.dp))
                                .clickable { expanded = !expanded }
                                .padding(12.dp) // Ensure padding is inside the Box, not affecting the outer layout
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(text = selectedCourse.ifEmpty { "-Select-" })
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                            }

                            // DropdownMenu positioned just below the Box
                            DropdownMenu(
                                expanded = expanded,
                                onDismissRequest = { expanded = false },
                                //modifier = Modifier.fillMaxWidth() // Ensure it matches the width of the Box
                            ) {
                                courses.forEach { course ->
                                    DropdownMenuItem(
                                        text = { Text(course) },
                                        onClick = {
                                            selectedCourse = course
                                            expanded = false
                                        }
                                    )
                                }
                            }
                        }


                    }

                    Spacer(modifier = Modifier.height(12.dp))

                    // Reference Code Field
                    OutlinedTextField(
                        value = referenceCode,
                        onValueChange = { referenceCode = it },
                        label = { Text("Reference Code (Optional)") },
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 1,
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Text,
                            imeAction = ImeAction.Done
                        )
                    )
                    Spacer(modifier = Modifier.height(12.dp))

                    Text(
                        text = "By submitting our web form, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third party platform.",
                        modifier = Modifier.fillMaxWidth(),
                        maxLines = 3,
                        fontSize = 10.sp
                    )
                    Spacer(modifier = Modifier.height(24.dp))
                    // Submit and WhatsApp Buttons
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(12.dp)
                    ) {
                        Button(
                            onClick = {
                                CoroutineScope(Dispatchers.Default).launch {
                                    component.postCourseDetails(
                                        CourseDetailsSubmitForm(
                                            firstName = firstName,
                                            lastName = lastName,
                                            mobile = selectedCountryCode + phoneNumber,
                                            email = email,
                                            courseInterestedIn = selectedCourse,
                                            referenceCode = referenceCode
                                        )
                                    )
                                }
//                                firstName = ""
//                                lastName = ""
//                                selectedCourse = ""
//                                phoneNumber = ""
//                                email = ""
//                                selectedCountryCode = "+91"
//                                referenceCode = ""
                            },
                            modifier = Modifier.weight(1f),
                            enabled = firstName.isNotEmpty() && lastName.isNotEmpty() && phoneNumber.isNotEmpty() && email.isNotEmpty() && selectedCourse.isNotEmpty(),
                            shape = RoundedCornerShape(8.dp),
                            colors = ButtonDefaults.buttonColors(Color.Blue)
                        ) {
                            Text(text = "Get Course Details", color = Color.White)
                        }

                    }
                }
            }
        }
    }
}
