package com.winiwayuser.feature_call_consultation.data.response.voip_agora_token

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoipAgoraTokenResponse(
    @SerialName("data")val data: VoipAgoraTokenData?,
    @SerialName("status")val status:Boolean,
    @SerialName("message")val msg: String?,
)

@Serializable
data class VoipAgoraTokenData(
    @SerialName("callDetails")val callDetails:CallConsultationDetails?,
    @SerialName("VoiceCalling")val voipCallDetails:VoipCallingDetails?
)
