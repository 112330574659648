package com.winiwayuser.core.razorpay

import com.winiwayuser.feature_winyway_wallet.persentation.razorpay_gateway_new.PaymentGatewayArguments

//actual fun startPayment(
//    orderId: String,
//    onPaymentSuccess: (String, String, String) -> Unit,
//    onPaymentFailed: (Throwable) -> Unit
//) {
//    startPaymentInBrowser(
//        orderId = orderId,
//        onResponsePayment = onPaymentSuccess,
//        onErrorResponse = onPaymentFailed
//    )
//}

actual fun startPayment(
    paymentGatewayArguments: PaymentGatewayArguments,
    onPaymentSuccess: (String, String, String) -> Unit,
    onPaymentFailed: (Throwable) -> Unit
) {
    injectRazorpayForm(
        paymentArguments = paymentGatewayArguments,
        onSuccessResponsePayment = onPaymentSuccess,
        onErrorResponse = onPaymentFailed
    )
}