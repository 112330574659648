package com.winiwayuser.core.persentation

import com.russhwolf.settings.Settings
import com.winiwayuser.core.platform.PlatformNavigator
import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.Resource

const val WINYWAY_IMAGE_URL =
    "https://live.winyway.com/images/logo/winyway_logo_icon.png"

expect fun getDeviceType(): DeviceType

enum class DeviceType {
    ANDROID, IOS, DESKTOP, NATIVE, WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

enum class BrowserType {
    WEB_HD, WEB_FULL_HD, WEB_2K, WEB_4K, WEB_UD
}

expect fun showToast(message: String)
expect fun openPlayStore(url: String)
expect fun clearCaptchaData()
expect fun refreshPage()


// Common code (shared module)
data class PaginationItems<ChatConversationData>(
    val items: List<ChatConversationData> // List of items for the current page
)

expect abstract class BasePagingSource<Value : Any>() {
    // Declare an expected function that platform-specific implementations must provide
    abstract suspend fun fetchData(page: Int, limit: Int): PaginationItems<Value>
}

expect suspend fun saveChatMessage(url: String, chatId: String, list: List<ChatMessage>): String

expect fun clearSessionOrCache()
/*Shared Precedence save and retirve data*/
expect val settings: Settings

expect fun clearData()
expect fun downloadBrochurePdf(res:String)


fun onPlayStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openPlayStore()
}

fun onAppStoreLinkClick(platformNavigator: PlatformNavigator) {
    platformNavigator.openAppStore()
}