package com.winiwayuser.feature_landing_page.persentation.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.winiwayuser.resources.JoinVedicAstrologyCourse
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.join_vedic_astrology_course_new
import org.jetbrains.compose.resources.painterResource

@Composable
fun AstrologyCourseScreen() {
    Box(
        modifier = Modifier
            .fillMaxSize()
    ) {
        Row(
            modifier = Modifier.fillMaxSize(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = painterResource(Res.drawable.join_vedic_astrology_course_new),
                contentDescription = null,
                modifier = Modifier.fillMaxSize(),
            )
        }
    }
}