package com.winiwayuser.feature_landing_page.persentation.widget

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType.Companion.Uri
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.downloadBrochurePdf
import com.winiwayuser.core.theme.introItems
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.banner1
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.imageResource

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalResourceApi::class)
@Composable
fun WhatWillYouLearn() {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        // Heading
        Text(
            text = "What will you learn",
            style = MaterialTheme.typography.titleMedium.copy(
                color = Color(0xFF5D289A), // Purple heading
                fontWeight = FontWeight.Bold,
                fontSize = 20.sp
            ),
            modifier = Modifier.padding(bottom = 16.dp),
            textAlign = if (windowSizeClass == WindowWidthSizeClass.Compact) TextAlign.Start else TextAlign.Center
        )

        // Two Column Layout for sections
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            // Introduction to Vedic Astrology
            Column(
                modifier = Modifier.weight(1f).padding(horizontal = 16.dp),
                horizontalAlignment = Alignment.Start
            ) {
                introItems.chunked(if (windowSizeClass == WindowWidthSizeClass.Compact) 1 else 2).forEach { pair ->
                    if (windowSizeClass == WindowWidthSizeClass.Compact) {
                        // Single column for Compact screen size
                        pair.forEach { item ->
                            Text(text = "• $item", modifier = Modifier.padding(bottom = 4.dp))
                        }
                    } else {
                        // Two columns for larger screen sizes
                        Row(modifier = Modifier.fillMaxWidth().align(Alignment.CenterHorizontally)) {
                            pair.forEach { item ->
                                Text(
                                    text = "• $item",
                                    modifier = Modifier
                                        .weight(1f)
                                        .padding(bottom = 4.dp)
                                )
                            }
                        }
                    }
                }

            }

        }

        // Download Brochure Button
        Button(
            onClick = {
               // val url = Res.getUri("drawable/brochure.pdf")
                downloadBrochurePdf("https://prod.winyway.com/public/backend/articleImage/Brochure-Final.pdf")
                //val pdfUri = Uri.parse("android.resource://${context.packageName}/${Res.drawable. .raw.your_pdf_file}") // Replace with actual resource file
                //downloadBrochurePdf("android.resource://${context.pa}/${Res.drawable.banner1}")

            },
            modifier = Modifier
                .padding(top = 16.dp)
                .align(Alignment.CenterHorizontally),
            colors = ButtonDefaults.buttonColors(Color(0xFFD03060)) // Pink button
        ) {
            Text(
                text = "Download Brochure",
                color = Color.White
            )
        }
    }
}

//fun saveDrawableToFile(context: Context, drawableId: Int, fileName: String): File {
//    val drawable = context.resources.getDrawable(drawableId, null)
//    val bitmap = (drawable as BitmapDrawable).bitmap
//
//    val file = File(context.cacheDir, fileName)
//    file.outputStream().use {
//        bitmap.compress(Bitmap.CompressFormat.PNG, 100, it)
//    }
//    return file
//}
//fun drawableResourceToString(drawableResource: DrawableResource): String {
//    // You might want to implement logic here to convert resourceId to a String path
//    return "drawableResourceId:${drawableResource.}"
//}