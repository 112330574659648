package com.winiwayuser.core.date_time

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun getCurrentDateTime(): String {
    val currentMoment = Clock.System.now()
    val datetime = currentMoment.toLocalDateTime(TimeZone.currentSystemDefault())
    return datetime.formatToString()
}

private fun LocalDateTime.formatToString(): String {
    val year = this.year.toString().padStart(4, '0')
    val month = this.monthNumber.toString().padStart(2, '0')
    val day = this.dayOfMonth.toString().padStart(2, '0')
    val hour = this.hour.toString().padStart(2, '0')
    val minute = this.minute.toString().padStart(2, '0')
    val second = this.second.toString().padStart(2, '0')
    return "$year-$month-$day $hour:$minute:$second"
}