package com.winiwayuser.feature_common.persentation.more_tab.component

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart

class TermsAndConditionsComponent(
    context: ComponentContext,
    private val onTermsAndConditionsEvent: (TermsAndConditionsEvent) ->  Unit
) : ComponentContext by context {

    init {
        Logger.d { "TermsAndConditions Screen Tab Component initialized" }
        lifecycle.doOnStart { Logger.d { "TermsAndConditions Screen Tab Component onStart" } }
        lifecycle.doOnDestroy { Logger.d { "TermsAndConditions Screen Tab Component onStart" } }
    }

    fun event(event: TermsAndConditionsEvent){
        onTermsAndConditionsEvent.invoke(event)
    }
}