package com.winiwayuser.feature_winyway_wallet.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class Referbalance(
    @SerialName("amount")
    val amount: String?,
    @SerialName("cash_amount")
    val cashAmount: String?,
    @SerialName("wallet_balance")
    val walletAmountUsed: String?,
    @SerialName("coin_percentage_value")
    val coinPercentageValue: String?,
    @SerialName("gst_percentage")
    val gstPercentage: String?,
    @SerialName("gst_percentage_amount")
    val gstPercentageAmount: String?,
    @SerialName("redeem_percentage")
    val redeemPercentage: String?,
    @SerialName("remain_coin")
    val remainCoin: String?,
    @SerialName("total_amount")
    val totalAmount: String?,
    @SerialName("total_coin")
    val totalCoin: String?,
    @SerialName("used_coin")
    val usedCoin: String?
)