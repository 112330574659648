package com.winiwayuser.feature_courses.persentation.course_page.widget.sections

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.feature_courses.data.response.courses.CourseDetails

@Composable
fun CurriculumSection(list: List<CourseDetails>) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {

        Spacer(modifier = Modifier.height(24.dp))

        list.forEachIndexed { index, step ->
            // Content Box
            var boxHeight by remember { mutableStateOf(0) }

            Row(
                verticalAlignment = Alignment.Top, // Align items to the top
                modifier = Modifier.fillMaxWidth()
            ) {

                StepLineAndDot(
                    height = boxHeight,
                    isLast = index == list.lastIndex
                )

                Spacer(modifier = Modifier.width(16.dp))

                Column(
                    modifier = Modifier.onGloballyPositioned { coordinates ->
                        // Get the height of the box
                        boxHeight = coordinates.size.height
                    }
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(Color.White, RoundedCornerShape(Scale.CORNER_SMALL))
                            .padding(16.dp)
                    ) {
                        Column {
                            Text(
                                text = step.title.toString(),
                                style = MaterialTheme.typography.bodyLarge.copy(fontWeight = FontWeight.Bold),
                                color = Color.Black
                            )
                            Spacer(modifier = Modifier.height(8.dp))
                            Text(
                                text = step.content.toString(),
                                style = MaterialTheme.typography.bodyMedium,
                                color = Color.Gray
                            )
                        }
                    }

                    Spacer(modifier = Modifier.height(8.dp))
                }

            }
        }

        Spacer(modifier = Modifier.height(42.dp))
    }
}


@Composable
fun StepLineAndDot(
    isLast: Boolean,
    height: Int = 0,
) {
    Box(
        modifier = Modifier
            .width(8.dp),
        contentAlignment = Alignment.Center
    ) {

        Canvas(
            modifier = Modifier.height(
                height.div(2).dp
            )
        ) {
            // Draw the top line
            drawLine(
                color = Color.LightGray,
                start = Offset(size.width / 2, 0f),
                end = Offset(size.width / 2, if (isLast) size.height.div(2) else size.height),
                strokeWidth = 1.5.dp.toPx()
            )

            // Draw the dot in the center
            drawCircle(
                color = primaryDark,
                radius = 6.dp.toPx(),
                center = Offset(size.width / 2, size.height / 2)
            )
        }
    }
}