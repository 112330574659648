package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.astrologer_icon
import com.winiwayuser.resources.astrology_courses
import com.winiwayuser.resources.banner1
import com.winiwayuser.resources.winyway_button_spiritual
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//
//    val categoryData by component.topCategory.collectAsState()
//
//    if (categoryData.isNullOrEmpty()) return
//
//    val spanCount = if (categoryData?.size!! > 2) 3 else 2
//
//    LazyVerticalGrid(
//        columns = GridCells.Fixed(spanCount),
//        contentPadding = PaddingValues(8.dp),
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .height(120.dp)
//            .padding(horizontal = 12.dp)
////            .fillMaxSize() // Ensure the grid fills the available space within the Box
//    ) {
//        items(categoryData!!) { category ->
//            CategoryCard(
//                categoryName = category,
//                onCategoryClick = onCategoryClick
//            )
//        }
//    }
//}

//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//    val categoryData by component.topCategory.collectAsState()
//
//    if (categoryData.isNullOrEmpty()) return
//
//    Row(
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .padding(horizontal = Scale.HORIZONTAL_PADDING),
//        horizontalArrangement = Arrangement.spacedBy(8.dp)
//    ) {
//        categoryData!!.forEach { category ->
//            Box(
//                modifier = Modifier
//                    .weight(1f)
//            ) {
//                CategoryCard(
//                    categoryName = category,
//                    onCategoryClick = onCategoryClick
//                )
//            }
//        }
//    }
//}
//
//
//
//@Composable
//fun CategoryCard(
//    categoryName: RequestCategory,
//    onCategoryClick: (RequestCategory) -> Unit,
//) {
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),  // Adjust the corner radius as needed
//        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_LARGE),  // Adjust the elevation as needed
//        modifier = Modifier
//            .fillMaxWidth()
//            //.width(100.dp)
//            //.height(120.dp)
//            .wrapContentHeight()
//            .clickable { onCategoryClick(categoryName) }
//    ) {
//        val resImage = when (categoryName.name) {
//            "Mental Wellness Therapist" -> Res.drawable.spritual_banner2
//            "Astrologer" -> Res.drawable.banner1
//            //"Scientific Astrology" -> Res.drawable.astrologer_icon
//            "Scientific Astrology" -> null
//            else -> null
//            //else -> Res.drawable.astrologer_icon
//        }
//
////        Image(
////            painter = painterResource(resource = resImage),
////            contentDescription = "",
////            contentScale = ContentScale.FillBounds,
////            modifier = Modifier
////                .fillMaxSize()
////                .clip(RoundedCornerShape(8.dp))  // Adjust the corner radius if needed
////        )
//
//        resImage?.let {
//            Image(
//                painter = painterResource(resource = it),
//                contentDescription = null,  // No need for contentDescription if not needed for accessibility
//                contentScale = ContentScale.FillBounds,
//                modifier = Modifier
//                    .fillMaxSize()
//                    .clip(RoundedCornerShape(8.dp))  // Adjust the corner radius if needed
//            )
//        }
//    }
//}

//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//    val categoryData by component.topCategory.collectAsState()
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    // Early return if no category data is available
//    if (categoryData.isNullOrEmpty()) return
//
//    if (windowSizeClass == WindowWidthSizeClass.Compact) {
//        Row(
//            modifier = Modifier
//                .fillMaxWidth()  // Use fillMaxWidth instead of fillMaxSize to avoid unwanted extra space
//                .padding(horizontal = Scale.HORIZONTAL_PADDING),
//            horizontalArrangement = Arrangement.spacedBy(8.dp)
//        ) {
//            categoryData!!.forEach { category ->
//                Box(
//                    modifier = Modifier
//                        .weight(1f)  // Distribute space evenly
//                        .aspectRatio(2f / 1f)  // Maintain aspect ratio if necessary
//                ) {
//                    CategoryCard(
//                        categoryName = category,
//                        onCategoryClick = onCategoryClick
//                    )
//                }
//            }
//        }
//    }
//    else {
//        // Display categories in a lazy row for larger width
//        LazyRow(
//            modifier = Modifier
//                .fillMaxWidth()
//                .wrapContentHeight()
//                .padding(horizontal = Scale.HORIZONTAL_PADDING),
//            horizontalArrangement = Arrangement.spacedBy(8.dp),
//            userScrollEnabled = true // Allow scrolling
//        ) {
//            items(categoryData!!.size) { category ->
//                val data = categoryData!![category]
//                CategoryCard(
//                    categoryName = data,
//                    onCategoryClick = onCategoryClick
//                )
//            }
//        }
//    }
//}
//
//@Composable
//fun CategoryCard(
//    categoryName: RequestCategory,
//    onCategoryClick: (RequestCategory) -> Unit,
//) {
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_LARGE),
//        modifier = Modifier
//           // .fillMaxWidth(0.9f) // Adjust width as needed
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .clickable { onCategoryClick(categoryName) }
//            .padding(8.dp) // Add padding if needed
//    ) {
//        val resImage = when (categoryName.name) {
//            "Mental Wellness Therapist" -> Res.drawable.spritual_banner2
//            "Astrologer" -> Res.drawable.banner1
//            "Scientific Astrology" -> null
//            else -> null
//        }
//
//        resImage?.let {
//            Image(
//                painter = painterResource(it),
//                contentDescription = null,
//                contentScale = ContentScale.Crop,
//                modifier = Modifier
//                    .fillMaxWidth()  // Ensure the image spans the full width of the card
//                    .aspectRatio(2f / 1f)  // Adjust aspect ratio as needed
//                    .clip(RoundedCornerShape(Scale.CORNER_SMALL))
//            )
//        }
//    }
//}





@Composable
fun TopCategoryList(
    onCategoryClick: (RequestCategory) -> Unit,
    component: HomeScreenTabComponent
) {
    val categoryData by component.topCategory.collectAsState()

    if (categoryData.isNullOrEmpty()) return

    val rearrangedCategories = categoryData
        ?.sortedBy { category ->
            if (category.name == "Scientific Astrology") 1 else 0
        }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .padding(horizontal = Scale.HORIZONTAL_PADDING),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        rearrangedCategories?.forEach { category ->
            Box(
                modifier = Modifier
                    .weight(1f)
            ) {
                CategoryCard(
                    categoryName = category,
                    onCategoryClick = onCategoryClick
                )
            }
        }
    }

}



@Composable
fun CategoryCard(
    categoryName: RequestCategory,
    onCategoryClick: (RequestCategory) -> Unit,
) {
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_EXTRA_LARGE),
        modifier = Modifier
            .fillMaxWidth()
            //.width(100.dp)
            //.height(120.dp)
            .wrapContentHeight()
            .clickable { onCategoryClick(categoryName) }
    ) {
        val resImage = when (categoryName.name) {
            "Mental Wellness Therapist" -> Res.drawable.winyway_button_spiritual
            "Astrologer" -> Res.drawable.banner1
            "Scientific Astrology" -> Res.drawable.astrology_courses
            else -> Res.drawable.astrologer_icon
        }

        Image(
            painter = painterResource(resource = resImage),
            contentDescription = "",
            contentScale = ContentScale.FillBounds,
            modifier = Modifier
                .fillMaxSize()
                .clip(RoundedCornerShape(8.dp))
        )
    }
}
