package com.winiwayuser.core.zoho_sales_iq

import kotlinx.browser.document
import org.w3c.dom.HTMLScriptElement


actual fun onClickSupport() {

    val widgetCode = "siqc4469a8a9ee884e5aca45ebf34ac5fe0b0ed44dcf695c6dcd2c34478a144dfe0"
    // Initialize Zoho SalesIQ
    // Replace "YOUR_WIDGET_CODE" with your actual widget code
    // and call the ready callback when initialization is complete

//    console.log("onClickSupport")
//
//    ZohoSalesIQ.initialize(widgetCode) {
//        println("Zoho SalesIQ is ready")
//        // You can now use Zoho SalesIQ features, e.g., track events
//        ZohoSalesIQ.trackEvent("AppStarted", null)
//    }
    initializeZohoSalesIQ(widgetCode) {
        println("Zoho SalesIQ is ready")
        // Additional logic to be executed when Zoho SalesIQ is ready
    }

//    injectZohoSalesIQ()
}

fun injectZohoSalesIQScript() {
    // Check if the script is already present to prevent duplicate injections
    if (document.getElementById("zsiqchat") == null) {
        // Create a script tag for the external Zoho SalesIQ script
        val zohoScript = document.createElement("script") as HTMLScriptElement
        zohoScript.type = "text/javascript"
        zohoScript.id = "zsiqchat"
        zohoScript.defer = true
        zohoScript.src = "/zoho-salesiq.js" // Replace with the correct path
        document.head?.appendChild(zohoScript)
    }
}

actual fun injectZohoSales() {
    injectZohoSalesIQScript()
}