package com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.imePadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Send
import androidx.compose.material.icons.filled.Timer
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.arkivanov.essenty.backhandler.BackCallback
import com.arkivanov.essenty.backhandler.BackHandler
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.persentation.ExpertProfileIcon
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.grey_700
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.white
import com.winiwayuser.core.timer.CountdownTimer
import com.winiwayuser.core.timer.formatTime
import com.winiwayuser.feature_chat_consultation.domain.Sender
import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import com.winiwayuser.feature_chat_consultation.persentation.chat_room_view.ChatRoomMsgState
import com.winiwayuser.feature_chat_consultation.persentation.chat_room_view.ChatRoomState
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.widget.ChatEndedDialog
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.widget.EndChatConfirmationDialog

@Composable
fun ChatRoomScreen(
    component: ChatRoomComponent
) {

    val state by component.state.collectAsState()
    var isDialogOpen by remember { mutableStateOf(false) }
    var isChatEndedDialogOpen by remember { mutableStateOf(false) }

    if (state is ChatRoomState.OnLoading) {
        val isLoading = (state as ChatRoomState.OnLoading).isLoading
        if (isLoading) LoadingProgressDialog { }
    }

    Logger.d("BackHandler -> ${component.backHandler}")
    BackHandler(backHandler = component.backHandler) {
        Logger.d("BackHandler - INIT")
        isDialogOpen = true
    }

    if (state is ChatRoomState.OnEndChatSuccess) {
        isChatEndedDialogOpen = true
    }

    ChatEndedDialog(
        onGoToDashboard = {
            isChatEndedDialogOpen = false
            component.onGoToDashBoard()
        }, isDialogOpen = isChatEndedDialogOpen
    )

    EndChatConfirmationDialog(onConfirm = {
        component.onEndChat()
        // Handle end chat logic
        isDialogOpen = false
    }, onDismiss = {
        // Handle dismiss logic
        isDialogOpen = false
    }, isDialogOpen = isDialogOpen
    )


    MaterialTheme(
            colorScheme = lightColorScheme()
        ) {

            if (state is ChatRoomState.OnChatStart) {

                Column(
                    modifier = Modifier.fillMaxSize().background(background_color)
                ) {
                    val data = (state as ChatRoomState.OnChatStart).chatData
                    ChatScreenExpert(
                        expertName = component.expertName,
                        expertImage = component.expertImage,
                        chatDuration = data.totalDurationToChat,
                        onEndChat = {
                            isDialogOpen = true
                        },
                        viewModel = component,
                    )
                    ChatScreen(
                        modifier = Modifier.fillMaxSize(),
                        viewModel = component
                    )

                }
            }
        }


}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ChatTopBar(
    modifier: Modifier,
    expertName: String,
    expertImage: String,
    chatDuration: Int,
    onEndChat: () -> Unit,
    viewModel: ChatRoomComponent,
    onBack: () -> Unit
) {
    val isExpertEnded by viewModel.endedByExpert.collectAsState()
    val countdownTimer =
        rememberSaveable { CountdownTimer(durationMillis = (chatDuration.times(1000).toLong())) }
    countdownTimer.start()
    countdownTimer.onFinish?.let {
        onEndChat.invoke()
    }

    val timeLeft by countdownTimer.timeLeft.collectAsState(initial = 0L)

    if (isExpertEnded) {
        countdownTimer.pause()
    }

    DisposableEffect(Unit) {
        onDispose {
            countdownTimer.cleanup()
        }
    }

    TopAppBar(
        modifier = modifier.background(white),
        title = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth().background(color = Color.White).padding(vertical = 12.dp),
                horizontalArrangement = Arrangement.SpaceBetween // Align items at the ends of the row
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    ExpertProfileIcon(
                        profileImageUrl = expertImage, modifier = Modifier.size(38.dp)
                    )
                    Spacer(modifier = Modifier.width(12.dp))
                    Column(

                        modifier = Modifier.wrapContentHeight()
                    ) {
                        Text(
                            text = expertName,
                            maxLines = 1,
                            style = MaterialTheme.typography.titleSmall
                        )
                        Row {
                            Icon(
                                imageVector = Icons.Default.Timer,
                                contentDescription = null,
                                tint = grey_700.copy(alpha = 0.7f),
                                modifier = Modifier.size(16.dp)
                            )
                            Spacer(modifier = Modifier.width(4.dp))
                            Text(
                                text = formatTime(timeLeft),
                                maxLines = 1,
                                style = MaterialTheme.typography.labelSmall
                            )
                        }

                    }
                }

                // This Row contains "Chat Again" button and icon
                CommonButton(buttonText = "End Chat", modifier = Modifier.height(35.dp), onClick = {
                    countdownTimer.pause()
                    onEndChat.invoke()
                })
            }
        },
        navigationIcon = {
            IconButton(onClick = {
                onBack.invoke()
            }) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Localized description"
                )
            }
        },
    )
}


@Composable
fun ChatScreenExpert(
    expertName: String,
    expertImage: String,
    chatDuration: Int,
    onEndChat: () -> Unit,
    viewModel: ChatRoomComponent
) {
    val isExpertEnded by viewModel.endedByExpert.collectAsState()
    val countdownTimer =
        rememberSaveable { CountdownTimer(durationMillis = (chatDuration.times(1000).toLong())) }
    countdownTimer.start()
    countdownTimer.onFinish?.let {
        onEndChat.invoke()
    }

    val timeLeft by countdownTimer.timeLeft.collectAsState(initial = 0L)

    if (isExpertEnded) {
        countdownTimer.pause()
    }

    DisposableEffect(Unit) {
        onDispose {
            countdownTimer.cleanup()
        }
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.fillMaxWidth().background(color = Color.White).padding(12.dp),
        horizontalArrangement = Arrangement.SpaceBetween // Align items at the ends of the row
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            ExpertProfileIcon(
                profileImageUrl = expertImage, modifier = Modifier.size(38.dp)
            )
            Spacer(modifier = Modifier.width(12.dp))
            Column(

                modifier = Modifier.wrapContentHeight()
            ) {
                Text(
                    text = expertName, maxLines = 1, style = MaterialTheme.typography.titleSmall
                )
                Row {
                    Icon(
                        imageVector = Icons.Default.Timer,
                        contentDescription = null,
                        tint = grey_700.copy(alpha = 0.7f),
                        modifier = Modifier.size(16.dp)
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = formatTime(timeLeft),
                        maxLines = 1,
                        style = MaterialTheme.typography.labelSmall
                    )
                }

            }
        }

        // This Row contains "Chat Again" button and icon
        CommonButton(buttonText = "End Chat", modifier = Modifier.height(35.dp), onClick = {
            countdownTimer.pause()
            onEndChat.invoke()
        })
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun ChatScreen(
    modifier: Modifier,
    viewModel: ChatRoomComponent
) {

    val msgList = viewModel.chatMsg
    val state by viewModel.msgState.collectAsState()
    val typingState by viewModel.typingState.collectAsState()
    val listState = rememberLazyListState()
    val isExpertEnded by viewModel.endedByExpert.collectAsState()

    Column(
        modifier = modifier // Ensures the column takes up the full screen
    ) {

        // Chat messages
        LaunchedEffect(msgList.size) {
            if (state is ChatRoomMsgState.MsgUpdated) {
                listState.animateScrollToItem(index = msgList.lastIndex)
            }
        }

        LazyColumn(
            verticalArrangement = Arrangement.Bottom,
            modifier = Modifier.weight(1f).imePadding(),
            state = listState,
            contentPadding = PaddingValues(vertical = 4.dp, horizontal = 8.dp)
        ) {

            if (state is ChatRoomMsgState.MsgUpdated) {
                items(msgList.size) { index ->
                    val message = msgList[index]
                    if (message.senderEnum == Sender.USER) {
                        UserMessage(
                            message = message, modifier = Modifier.animateItemPlacement()
                        )
                    } else {
                        ExpertMessage(
                            message = message, modifier = Modifier.animateItemPlacement()
                        )
                    }
                }
            }

            if (typingState) {
                item {
                    ExpertMessage(
                        message = ChatMessage(
                            id = null,
                            msg = "Typing...",
                            // sender = Sender.EXPERT,
                            sender = 0,
                            date = "",
                            msgType =null,
                            isAttachmentUploaded = false,
                            userConcern = null

                        ), modifier = Modifier.animateItemPlacement()
                    )

                }
            }

            if (isExpertEnded) {
                item {
                    ExpertIndicator()
                }
            }

        }

        ChatInputLayout(onSendChat = { msg ->
            if (isExpertEnded) {
                showToast("Chat Already Ended!")
                return@ChatInputLayout
            }
            viewModel.onSendMsg(msg)

        },
            onTyping = { isTyping ->
                viewModel.onTypingStatus(isTyping)
            },
            modifier = Modifier.padding(bottom = 16.dp, start = 16.dp, end = 16.dp).fillMaxWidth()
                .windowInsetsPadding(WindowInsets.ime)
        )
    }
}


@Composable
fun ExpertMessage(
    message: ChatMessage,
    modifier: Modifier,
) {
    Row(
        modifier = modifier.fillMaxWidth().padding(horizontal = 8.dp, vertical = 4.dp),
        horizontalArrangement = Arrangement.Start
    ) {
        Box(
            modifier = Modifier.background(
                primaryColor.copy(alpha = 0.15F), shape = RoundedCornerShape(
                    topStart = CornerSize(percent = 20),
                    topEnd = CornerSize(percent = 20),
                    bottomStart = CornerSize(0.dp),
                    bottomEnd = CornerSize(percent = 20)
                )
            ).padding(horizontal = 12.dp, vertical = 8.dp)
        ) {
            Text(
                text = message.msg, fontSize = 14.sp, // Adjust text size as needed
                textAlign = TextAlign.Start, modifier = Modifier.align(Alignment.Center)
            )
        }
        // Add any other UI components related to the expert message
    }
}

@Composable
fun UserMessage(
    message: ChatMessage, modifier: Modifier
) {
    Row(
        modifier = modifier.fillMaxWidth().padding(horizontal = 8.dp, vertical = 4.dp),
        horizontalArrangement = Arrangement.End
    ) {
        Box(
            modifier = Modifier.background(
                primaryColor, shape = RoundedCornerShape(
                    topStart = CornerSize(percent = 20),
                    topEnd = CornerSize(percent = 20),
                    bottomStart = CornerSize(percent = 20),
                    bottomEnd = CornerSize(0.dp)
                )
            ).padding(horizontal = 12.dp, vertical = 8.dp)
        ) {
            Text(
                text = message.msg,
                color = Color.White,
                fontSize = 14.sp, // Adjust text size as needed
                textAlign = TextAlign.Start,
                modifier = Modifier.align(Alignment.Center)
            )
        }
        // Add any other UI components related to the user message
    }
}


@Composable
fun ExpertIndicator() {
    Column(
        modifier = Modifier.padding(horizontal = 8.dp)
    ) {
        Spacer(modifier = Modifier.height(4.dp))
        HorizontalDivider(thickness = 1.dp, color = Color.Black.copy(alpha = 0.4F))
        Spacer(modifier = Modifier.height(2.dp))
        Text(
            text = "Expert Ended the Chat",
            fontSize = 12.sp,
            textAlign = TextAlign.Center,
            color = Color.Black.copy(alpha = 0.4f)
        )
        Spacer(modifier = Modifier.height(8.dp))
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ChatInputLayout(
    modifier: Modifier,
    onSendChat: (String) -> Unit,
    onTyping: (Boolean) -> Unit // Callback to indicate if the user is typing or stopped
) {
    var message by remember { mutableStateOf("") }
    var isTyping by remember { mutableStateOf(false) }

    // A coroutine to detect when the user has stopped typing
    LaunchedEffect(message) {
        if (message.isNotEmpty()) {
            // If the user is typing, invoke the typing callback
            if (!isTyping) {
                isTyping = true
                onTyping(true)
            }
            // Delay to detect when the user stops typing
            kotlinx.coroutines.delay(1000L) // 1 second delay
            if (message.isNotEmpty()) {
                isTyping = false
                onTyping(false) // User stopped typing
            }
        } else {
            isTyping = false
            onTyping(false)
        }
    }

    fun onSendChatDone() {
        val finalMsg = message.trim()
        if (finalMsg.isNotEmpty()) {
            onSendChat.invoke(finalMsg)
        }
        message = ""
    }

    Row(
        modifier = modifier, verticalAlignment = Alignment.CenterVertically,
    ) {

        TextField(value = message,
            onValueChange = {
                message = it
            },
            modifier = Modifier.weight(1f).height(50.dp),
            textStyle = MaterialTheme.typography.titleSmall,
            singleLine = true,
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            colors = TextFieldDefaults.textFieldColors(
                containerColor = Color.White,
                cursorColor = primaryColor,
                focusedIndicatorColor = Color.Transparent,
                unfocusedIndicatorColor = Color.Transparent,
            ),
            keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Done),
            keyboardActions = KeyboardActions(onDone = {
                onSendChatDone()
            }),
            leadingIcon = {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.Chat,
                    contentDescription = null,
                    tint = grey_700.copy(alpha = 0.4f),
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
                )
            })

        Spacer(modifier = Modifier.width(12.dp))

        CommonButton(
            buttonText = "Send",
            modifier = Modifier.height(50.dp),
            onClick = {
                onSendChatDone()
            },
            icon = Icons.Default.Send,
            isTextVisible = false
        )

    }
}


@Composable
fun BackHandler(backHandler: BackHandler, isEnabled: Boolean = true, onBack: () -> Unit) {
    val currentOnBack by rememberUpdatedState(onBack)

    val callback = remember {
        BackCallback(isEnabled = isEnabled) {
            currentOnBack()
        }
    }

    SideEffect { callback.isEnabled = isEnabled }

    DisposableEffect(backHandler) {
        backHandler.register(callback)
        onDispose { backHandler.unregister(callback) }
    }
}

//@Composable
//fun TypingIndicator(
//    modifier: Modifier
//) {
//    Row(
//        modifier = modifier.padding(vertical = 8.dp, horizontal = 8.dp).fillMaxWidth(),
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//        CircularProgressIndicator(
//            modifier = Modifier.size(16.dp),
//            color = primaryColor.copy(alpha = 0.55F),
//            strokeWidth = 2.dp
//        )
//        Spacer(modifier = Modifier.width(8.dp))
//        Text(
//            text = "Typing...",
//        )
//    }
//}
