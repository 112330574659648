package com.winiwayuser.feature_courses.persentation.reserv_video

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.shared.ShareService
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.feature_courses.common.VideoPlayer
import com.winiwayuser.feature_courses.persentation.course_page.widget.CourseTopBar

@Composable
fun ReserveVideoPage(
    component: ReserveVideoComponent
) {

//    val state by component.state.collectAsState()
//    val stateCourses by component.courses.collectAsState()


//    if ((state as Resources.Loading).isLoading) {
//        LoadingProgressDialog { }
//    }

//    val windowWidth = calculateWindowSizeClass().widthSizeClass

    Scaffold(
        topBar = {
            CourseTopBar { component.onBackPress() }
        },
        containerColor = background_color
    ) { paddingValues ->

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(paddingValues)

        ) {



            Text(
                text = "Title Text View of Project of any",
            )

            // Video Player
            Box(
                modifier = Modifier
                    .size(300.dp, 200.dp)
                    .align(Alignment.CenterHorizontally)
                    .padding(16.dp)
            ) {
               CommonButton(
                   modifier = Modifier,
                   buttonText = "",
                   onClick = {
//                       VideoPlayer(url = "https://fast.wistia.net/embed/iframe/usaaxfmybw")
                   }
               )
            }

            // Description
            Text(
                text = "This video demonstrates the key features of our multiplatform library project " +
                        "implemented using Material 3 design principles and targeting Android, iOS, " +
                        "and web platforms.",
                style = MaterialTheme.typography.bodyLarge,
                modifier = Modifier.padding(16.dp),
                color = MaterialTheme.colorScheme.onBackground
            )
        }
    }
}
