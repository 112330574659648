package com.winiwayuser.feature_winyway_wallet.persentation.all_coupons_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.data.response.RechargeInfoData
import com.winiwayuser.feature_winyway_wallet.data.response.all_coupon.AllCouponn
import com.winiwayuser.feature_winyway_wallet.data.response.all_coupon.CouponData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class AllCouponComponent(
    context: ComponentContext,
    private val onBackPressed:() -> Unit
): ComponentContext by context, KoinComponent {
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    val api: WalletRechargeApi by inject()
    private val _state = MutableStateFlow<RechargeInfoData?>(null)

    private val _allCouponState = MutableStateFlow<List<AllCouponn>>(emptyList())
    val allCouponState get() = _allCouponState.asStateFlow()
    fun onBackPress() = onBackPressed.invoke()
    init {
        lifecycle.doOnStart {
            Logger.d { "All Coupon Component onStart" }
            getAllCouponCode()
        }

        lifecycle.doOnDestroy {
            Logger.d { "All Coupon Component onDestroy" }
            coroutineScope.cancel()
        }
    }

    fun getAllCouponCode(){
        coroutineScope.launch {
            api.getAllCouponCodeInfo().collectLatest {
                when(it){
                    is Resources.Error -> {
                        Logger.d { "getAllCouponCode error: ${it.msg}" }
                    }
                    is Resources.Loading -> {
                        Logger.d { "getAllCouponCode loading: ${it.isLoading}" }
                    }
                    is Resources.Success -> {
                        onAllCouponLoadSuccess(it.data?.data!!)
                        Logger.d { "getAllCouponCode success: ${it.data.data.allCouponnList}" }
                    }
                }
            }
        }
    }


    private fun onAllCouponLoadSuccess(data: CouponData) = coroutineScope.launch{
        _allCouponState.value = data.allCouponnList!!
        //_allCouponState.emit(AllCouponState.AllCouponInfoSuccess(data))
        Logger.d { "onAllCouponLoadSuccess : ${data.allCouponnList}" }
    }

}