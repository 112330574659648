package com.winiwayuser.core.agora

import com.winiwayuser.core.agora.voice.AgoraRTCClient
import com.winiwayuser.core.agora.voice.AgoraRTCRemoteUser
import com.winiwayuser.core.agora.voice.AudioTrack
import com.winiwayuser.core.agora.voice.createClient
import com.winiwayuser.core.agora.voice.createMicrophoneAudioTrack
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch

// commonMain/src/commonMain/kotlin/AgoraRtmClient.kt
actual class AgoraRtmVoiceClientApp actual constructor(private val appId: String, private val callback: AgoraRtcCallback) {

    private var client: AgoraRTCClient? = null
    private var localAudioTrack: AudioTrack? = null
    private val scope = MainScope()

    actual fun initialize(
        onLoginSuccess: () -> Unit,
        onLoginError: (String) -> Unit
    ) {
        //console.log("Initializing AgoraRTC client...")
        client = createClient(js("{ mode: 'rtc', codec: 'vp8' }"))

        if (client != null) {
            console.log("AgoraRTC client created successfully.")
            setupEventListeners(
                onMicPermissionResult = { isGranted ->
                    if (isGranted)callback.onMicrophonePermissionGranted()
                    else callback.onMicrophonePermissionDenied()
                },
                isRemoteUserJoined = {isJoined->
                    if (isJoined) callback.onUserOnline(23)
                    else callback.onUserOffline(23)
                }, { isMuted ->
                    callback.onRemoteUserAudioStatusChanged(isMuted)
                }
            ) // Set up event listeners after client creation
            onLoginSuccess()
        } else {
            console.error("Failed to create AgoraRTC client.")
            onLoginError("Failed to create AgoraRTC client")
        }
    }

    private fun setupEventListeners(
        onMicPermissionResult: (Boolean) -> Unit,
        isRemoteUserJoined: (Boolean) -> Unit,
        onRemoteUserAudioStatusChanged: (Boolean) -> Unit
    ) {
        // Listen for microphone permission events
        scope.launch {
            createMicrophoneAudioTrack().then {
                console.log("Microphone permission granted.")
                onMicPermissionResult(true)
            }.catch { error ->
                console.error("Microphone permission denied: $error")
                onMicPermissionResult(false)
            }
        }

        // Listen for user-joined event
        client?.on("user-joined") { user, mediaType ->
            console.log("Remote user joined: ${user}")
            isRemoteUserJoined.invoke(true)
            // You can add more handling for the mediaType if needed
        }

        client?.on("user-published") { user: AgoraRTCRemoteUser, mediaType: String ->
            println("User published: ${user}, Media Type: $mediaType")
            if (mediaType == "audio") {
                client?.subscribe(user, mediaType)
                    ?.then {
                        println("Subscribed to user: ${user}'s audio")
                        user.audioTrack?.play() // Optionally specify options
//                        callbacks.onUserOnline(user, mediaType)
                        onRemoteUserAudioStatusChanged(false)
                    }
                    ?.catch {
                        println("Failed to subscribe to user: ${user}'s audio:")
                    }
            }
        }

        // Listener for when a remote user unpublishes their media (audio/video)
        client?.on("user-unpublished") { user: AgoraRTCRemoteUser, mediaType: String ->
            println("User unpublished: ${user}, Media Type: $mediaType")
            if (mediaType == "audio") {
                user.audioTrack?.close()
//                println("Stopped playing audio for user: ${user.uid}")
//                isRemoteUserJoined(false)
                onRemoteUserAudioStatusChanged(true)
            }
        }

        // Listener for when a remote user leaves the channel
        client?.on("user-left") { user: AgoraRTCRemoteUser, _ ->
            println("User left: $user")
            user.audioTrack?.close()
            println("Cleaned up resources for user: ${user}")
            isRemoteUserJoined(false)
        }

        // Listen for permission changes (speaker related)
        // There's no direct API for speaker permission, but you can handle it based on audio playback
    }


    actual fun onJoinChannel(
        peerId: String, // Not typically used in Agora RTC; might be part of your logic
        token: String,
        channel: String,
        onJoinChannelSuccess: () -> Unit,
        onJoinChaError: (String) -> Unit
    ) {
        client?.join(appId, channel, token, 0) // UID 0 lets Agora assign one
            ?.then { uid ->
                println("Joined channel: $channel with UID: $uid")
                onJoinChannelSuccess()
                createAndPublishLocalAudioTrack()
            }
            ?.catch { error ->
                println("Failed to join channel: $error")
                onJoinChaError(error.toString())
            }
    }

    private fun createAndPublishLocalAudioTrack() {
        createMicrophoneAudioTrack()
            .then { track ->
                localAudioTrack = track
                client?.publish(arrayOf(track))
                    ?.then {
                        println("Published local audio track")
                    }
                    ?.catch { error ->
                        println("Failed to publish local audio track: $error")
                    }
            }
            .catch { error ->
                println("Failed to create microphone audio track: $error")
            }
    }

    actual fun onMicrophoneChange(isMuted: Boolean) {
        localAudioTrack?.setEnabled(isMuted)
        println("Microphone ${if (isMuted) "muted" else "unmuted"}")
    }

    actual fun onSpeakerChange(isSpeakerOn: Boolean) {
        // Agora RTC Web SDK doesn't provide a direct method to toggle speaker.
        // Simulate speaker toggle by controlling audio playback of remote users.
        client?.remoteUsers?.forEach { user ->
            user.audioTrack?.let { audioTrack ->
                if (isSpeakerOn.not()) {
                    audioTrack.play()
                } else {
                    audioTrack.close()
                }
            }
        }
        println("Speaker turned ${if (isSpeakerOn) "on" else "off"}")

    }

    actual fun logout() {
        client?.leave()
            ?.then {
                println("Left channel successfully")
                localAudioTrack?.close()
                localAudioTrack = null
                client = null // Allow garbage collection
            }
            ?.catch { error ->
                println("Failed to leave channel: $error")
            }
    }

    actual fun getNetworkQuality(callback: (String?, String) -> Unit) {
        // Get local network stats
        client?.getLocalStats()
            ?.then { localStats ->
                val txQuality = localStats.txQuality
                val rxQuality = localStats.rxQuality
                callback(txQuality, rxQuality)
            }
            ?.catch { error ->
                println("Failed to get local network quality: $error")
                callback(null, "")
            }

        // Optionally, get stats for each remote user
        client?.remoteUsers?.forEach { user ->
            client?.getRemoteStats(user)
                ?.then { remoteStats ->
                    val txQuality = remoteStats.txQuality
                    val rxQuality = remoteStats.rxQuality
                    println("Remote User TX: $txQuality, RX: $rxQuality")
                    // You can aggregate or handle this data as needed
                }
                ?.catch { error ->
                    println("Failed to get remote network quality: $error")
                }
        }
    }

}