package com.winiwayuser.user_feature_authentication.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestOtpResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data") val data: RequestOtpData?
)

@Serializable
data class RequestOtpData(
    @SerialName("mobile") val mobile: String?,
    @SerialName("type") val type: String?,
    @SerialName("whatsapp_updates") val whatsAppUpdate: Int?
)
