package com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success

import com.arkivanov.decompose.ComponentContext

class RechargeSuccessComponent(
    context:ComponentContext,
    private val _topUpAmount:String,
    private val onDone:()->Unit,
    private val rechargeMore:()->Unit
):ComponentContext by context {

    val topUpAmount get() = _topUpAmount

    fun onDone(){
        onDone.invoke()
    }

    fun onRechargeMore(){
        rechargeMore.invoke()
    }

    fun onBackPress() {
        rechargeMore.invoke()
    }

}