package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class CustomOrderListData(
    @SerialName("category") var category: String?, // Scientific Astrology
    @SerialName("content_type") val contentType: String?, // Video
    @SerialName("created_at") val createdAt: String?, // 2024-04-15 17:21:24
    @SerialName("description") val description: String?, // Lorem Ipsum Dummy Text
    @SerialName("display_price") val displayPrice: String?, // 200
    @SerialName("duration") val duration: String?, // 30
    @SerialName("expert_id") val expertId: Int?, // 200
    @SerialName("heading") val heading: String?, // My Custom Order
    @SerialName("id") val id: Int, // 1
    @SerialName("image_url") val image: String?, // 1713181884.jpg
    @SerialName("language") val language: String?, // English
    @SerialName("lessions") val lessions: List<Lession>?,
    @SerialName("live_session") val liveSession: List<CustomOrderLiveSession>?,
    @SerialName("sections") val sections: List<Section>?,
    @SerialName("service") val service: String?, // Paid
    @SerialName("expert") val expertDetail: Expert?, // Paid
    @SerialName("status") val status: String?, // 0
    @SerialName("total_session") val totalSession: Int?, // 5
    @SerialName("updated_at") val updatedAt: String?, // 2024-04-15 17:21:24
    @SerialName("is_free") val isFree: Int?, // 2024-04-15 17:21:24
    @SerialName("enrollment_status") val enrollmentStatus: Int?, // 2024-04-15 17:21:24
)
