package com.winiwayuser.feature_common.data.response

import com.winiwayuser.feature_common.data.request.RequestConsultant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DashboardData(
    @SerialName("free_call_availability")
    val freeCallAvailability: Int? = null,
    @SerialName("top_consultants")
    val topConsultants: List<RequestConsultant>?
)