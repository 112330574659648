package com.winiwayuser.core.date_time

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun formatSecondsToHHMMSS(secondsStr: String): String {
    val seconds = secondsStr.toIntOrNull() ?: return "Invalid input"

    // Calculate hours, minutes, and seconds
    val hours = seconds / 3600
    val minutes = (seconds % 3600) / 60
    val secs = seconds % 60

    return when {
        hours > 0 -> {
            // If hours are greater than 0, include hours in the format
            "${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}"
        }
        else -> {
            // If hours are 0, exclude hours from the format
            "${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}"
        }
    }
}

fun setAmPmByTime(hour:Int,minute:Int):String{
    // Determine if it's AM or PM
    val amPm = if (hour >= 12) "PM" else "AM"
    // Convert hour to 12-hour format
    val formattedHour = if (hour == 0 || hour == 12) 12 else hour % 12

    // Format the time with AM/PM
    val formattedMinute = if (minute < 10) "0$minute" else "$minute"
    return "$formattedHour:$formattedMinute $amPm"
}

//fun Long.convertMillisToDate(): String {
//    // Convert the millis to an Instant
//    val instant = Instant.fromEpochMilliseconds(this)
//
//    // Convert to local date-time using the system's default time zone
//    val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())
//
//    // Format the date as "MMM dd, yyyy"
////    val month = localDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }
//    val month = localDateTime.month.ordinal
//    val day = localDateTime.dayOfMonth.toString().padStart(2, '0')
//    val year = localDateTime.year
//
////    return "$month $day, $year"
//    return "$year-$month-$day"
//}

fun Long.convertMillisToDate(): String {
    // Convert the millis to an Instant
    val instant = Instant.fromEpochMilliseconds(this)

    // Convert to local date-time using the system's default time zone
    val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())

    // Adjust the month to be 1-based by adding 1
    val month = (localDateTime.month.ordinal + 1).toString().padStart(2, '0')
    val day = localDateTime.dayOfMonth.toString().padStart(2, '0')
    val year = localDateTime.year

    return "$year-$month-$day"
}

fun formatDateDayOfWeek(inputDate: String?): String {
    // Parse the input date string
    if (inputDate.isNullOrBlank()) return "Invalid Date"

    val localDate = LocalDate.parse(inputDate)

    // Convert to a DayOfWeek and format it
    val dayOfWeek = localDate.dayOfWeek
    val day = localDate.dayOfMonth
    val month = localDate.month.name.lowercase().replaceFirstChar { it.uppercase() }
    // Format the output: "30 December (Monday)"
    return "$day $month ($dayOfWeek)"
}

fun formatPrice(price: String?): String {
    // Handle null or empty input
    if (price.isNullOrBlank()) return "0"

    // Try to parse the string as a Double
    val parsedPrice = price.toDoubleOrNull()

    // If parsing fails or price is non-positive, return "0"
    if (parsedPrice == null || parsedPrice <= 0) return "0"

    // Format the price
    val roundedPrice = kotlin.math.floor(parsedPrice).toInt() // Remove decimals
    return roundedPrice.toString()
        .reversed()
        .chunked(3) // Group digits in chunks of 3 from the right
        .joinToString(",")
        .reversed()
}

fun formatReviewCount(count: Int?): String {
    if (count == null || count <= 0) return "0" // Handle null or non-positive counts

    return when {
        count >= 1_000_000 -> "${count / 1_000_000}M+" // Millions
        count >= 1_000 -> "${count / 1_000}k+"        // Thousands
        else -> "$count+"                              // Less than 1,000
    }
}


