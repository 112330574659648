package com.winiwayuser.feature_courses.persentation.course_page.widget.dailog

import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.theme.GreenDark20
import com.winiwayuser.core.theme.primaryDark

@Composable
fun SuccessDialog(
    onDismiss: () -> Unit
) {

    AlertDialog(
        onDismissRequest = {
            onDismiss.invoke()
        },
        title = {
            Text(
                text = "Thank You!",
                style = MaterialTheme.typography.titleMedium,
                fontWeight = FontWeight.Bold
            )
        },
        text = {
            Text(
                text = "Thank you for showing your interest! We truly value your enthusiasm and look forward to connecting with you soon. Stay tuned for further updates as we prepare to assist you on your journey!",
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Center
            )
        },
        confirmButton = {
            CommonButton(
                onClick = {
                    onDismiss.invoke()
                },
                buttonText = "Ok",
                modifier = Modifier,
                buttonColor = primaryDark
            )
        },
        icon = {
            Icon(
                imageVector = Icons.Default.CheckCircle,
                contentDescription = "Success Icon",
                tint = GreenDark20,
                modifier = Modifier.size(48.dp)
            )
        }
    )

}
