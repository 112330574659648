package com.winiwayuser.feature_courses.data.response.course_order_id

import com.winiwayuser.core.data.CommonResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseOrderIdResponse(
    @SerialName("data") val data: CourseOrderIdData?
): CommonResponse()

@Serializable
data class CourseOrderIdData(
    @SerialName("razorpay_order_id") var orderId: String? = null,
)

