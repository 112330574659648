package com.winiwayuser.feature_courses.data.response

import com.winiwayuser.core.data.CommonResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseBuyResponse(
    @SerialName("data") val data: CourseBuyData?
): CommonResponse()

@Serializable
data class CourseBuyData(
    @SerialName("course_id") var courseId: String? = null,
    @SerialName("txn_id") var txnId: Int? = null,
    @SerialName("course_purchase_id") var coursePurchaseId: Double? = null,
    @SerialName("is_course_free") var isCourseFree: Boolean? = null,
    @SerialName("wistia_video") var wistiaVideo: WistiaVideo? = null
)

@Serializable
data class WistiaVideo(
    @SerialName("data") val wistiaData:Video? = null
):CommonResponse()

@Serializable
data class Video(
    @SerialName("embed_url")val embedVideoUrl:String
)

