package com.winiwayuser.feature_expert_consultation.data.remote.response

import com.winiwayuser.feature_common.data.request.RequestConsultant
import kotlinx.serialization.SerialName

@kotlinx.serialization.Serializable
data class FetchConsultantResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data") val data: DataCat?

)

@kotlinx.serialization.Serializable
data class DataCat(
    @SerialName("free_call_availability") val freeCallAvailability: Int?,
    val consultants: List<RequestConsultant>?
)

@kotlinx.serialization.Serializable
data class Consultant(
    val account_approval: Int?,
    val account_holder_name: String?,
    val account_number: String?,
    val account_type: String?,
    val year_of_experience: String?,
    val address: String?,
    val last_online_time: String = "",
    val bank_name: String?,
//    val cats: List<Cat>?,
    val education: List<Education>?,
    val profession: List<Profession>?,
//    var consultant_fav: ConsultantFav? = null,
    val certification: String?,
    val created_at: String?,
//    val device_details: String?,
//    val display_profession: Int?,
//    val display_skills: Int?,
//    val display_education_section: Int?,
//    val display_profession_section: Int?,
    val email: String?,
    val first_name: String?,
    val full_name: String?,
    var is_favrouite: Int?,
    val gender: String?,
    val id: Int,
    val ifsc_code: String?,
    val language: String?,
    val last_name: String?,
    val linkedin_url: String?,
    val login_device: String?,
    val mobile: String?,
    val notification_token: String?,
    val offer_letter: String?,
    val pan_image: String?,
    val pan_number: String?,
    val professional_approval: Int?,
    var consultant_rating: Double?,
    val profile_description: String?,
    val profile_image: String?,
    val profile_image_url: String?,
    val redirect_to: String?,
    val service_categories_description: String?,
    val service_type: String?,
    val skills: String?,
    val status: Int?,
    val consultant_price: Int?,
    val flash_flag: Int?,
    val flash_discount: Int?,
    val flash_price: Int?,
    val type: String?,
    val updated_at: String?,
    val gallery_images: List<GalleryImages>?,
//    var reviews: List<Review>?,
    val video: String?,
    val video_url: String?,
    val whatsapp_updates: Int?,
    val total_reviews_count: String?,
    val questions: List<Question>?,
    @SerialName("expert_flash_price") val expertFlashPrice: Float?,
    @SerialName("winyway_flash_price") val winywayFlashPrice: Float?,
    @SerialName("total_flash_price") val flashPrice: String?,
    @SerialName("is_free_call") val isFreeCall: Int?,
    @SerialName("callstatus") val callStatus: String?,
    @SerialName("chatstatus") val chatButtonStatus: String?,
    @SerialName("call_per_minute_price") val callPerMinutePrice: String?,
    @SerialName("is_premium") val premiumExpert: String?,
    @SerialName("is_trending") val trendingExpert: String?,
    @SerialName("is_celebrity") val celebrityExpert: String?,
    @SerialName("is_notify") var isAvailableNotify: Int?,
    @SerialName("is_new") var isExpertNew: String?,
    @SerialName("emergency_price") var emergencyPrice: String?,
    @SerialName("is_emergency") var isEmergency: String,
) {

    fun isAvailableNotifyDone(): Boolean {
        return this.isAvailableNotify == 1
    }

    fun isAnyUniqueExpertApplicable(): String? {
        if (premiumExpert == "1") return "Premium"
        if (trendingExpert == "1") return "Trending"
        if (celebrityExpert == "1") return "Celebrity"
        if (isExpertNew == "1") return "New"
        return null
    }

}

@kotlinx.serialization.Serializable
data class Cat(
    val cat: CatX,
    val category_id: Int,
    val created_at: String,
    val id: Int,
    val updated_at: String,
    val user_id: Int
)

data class UserPricing(
    val created_at: String,
    val id: Int,
    val mention_price: String,
    val question_1: String,
    val question_2: String,
    val slot_duration: String,
    val slot_for: String,
    val updated_at: String,
    val user_id: Int
)

@kotlinx.serialization.Serializable
data class GalleryImages(
    val id: Int,
    val expert_id: Int,
    val images: String,
    val created_at: String,
    val updated_at: String
)

@kotlinx.serialization.Serializable
data class CatX(
    val created_at: String,
    val description: String,
    val id: Int,
    val name: String,
    val parent_id: Int,
    val status: Int,
    val updated_at: String
)

@kotlinx.serialization.Serializable
data class Review(
    val consultant_id: Int,
    val created_at: String,
    @SerialName("user_name") val userName: String?,
    val id: Int,
    val rating: String,
    val remarks: String,
    val updated_at: String,
    val user_id: Int,
    val user: UserData?,
)

@kotlinx.serialization.Serializable
data class ConsultantFav(
    val consultant_id: Int, val user_id: Int
)

@kotlinx.serialization.Serializable
data class Education(
    val education: String,
    val specialization: String,
    val from: String,
    val id: Int,
    val till: String,
    val user_id: Int
)

@kotlinx.serialization.Serializable
data class Profession(
    val from: String,
    val id: Int,
    val organization_name: String,
    val profession: String,
    val till: String,
    val user_id: Int
)

@kotlinx.serialization.Serializable
data class Question(
    val created_at: String,
    val id: Int,
    val question: String,
    val updated_at: String,
    val user_id: Int
)


@kotlinx.serialization.Serializable
data class UserData(
    val id: Int?, val full_name: String?, val profile_image_url: String?, val video_url: String?
)
