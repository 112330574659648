package com.winiwayuser.feature_expert_consultation.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertApi
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.MarkFeavoriteState
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ExpertListRepo(val api: ExpertApi) {
//    fun markExpertFav(id: String): Flow<MarkFeavoriteState> {
//        return flow {
//            emit(MarkFeavoriteState.Loading(true))
//            try {
//                val response = api.postMarkFavorite(id)
//                if (response is Resources.Success) {
//                    emit(MarkFeavoriteState.Success(response.data!!))
//                } else if (response is Resources.Error) {
//                    emit(MarkFeavoriteState.Error(response.msg))
//                }
//            } catch (e: Exception) {
//                emit(MarkFeavoriteState.Error(e.message ?: "Unknown error"))
//            } finally {
//                emit(MarkFeavoriteState.Loading(false))
//            }
//        }
//    }
}