package com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.widget

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.lazy.grid.rememberLazyGridState
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.persentation.CallConsultationButton
import com.winiwayuser.core.persentation.ChatConsultationButton
import com.winiwayuser.core.persentation.ExpertPriceRow
import com.winiwayuser.core.persentation.ExpertProfileIcon
import com.winiwayuser.core.persentation.LoginDialog
import com.winiwayuser.core.persentation.SampleDialog
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.theme.Scale.CORNER_SMALL
import com.winiwayuser.core.theme.Scale.ELEVATION_SMALL
import com.winiwayuser.core.theme.call_and_chat_button_offline_color
import com.winiwayuser.core.theme.call_button_background_color
import com.winiwayuser.core.theme.call_button_color
import com.winiwayuser.core.theme.chat_and_call_button_busy_color
import com.winiwayuser.core.theme.chat_button_background_color
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.profile_image_background_color
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.persentation.favourite_tab.CategoryConsultListRowClickListener
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response.Expert
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageEvent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_language_24
import com.winiwayuser.resources.baseline_person_4_24
import com.winiwayuser.resources.ic_filled_star
import com.winiwayuser.resources.ic_heart_primary_color
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun ConsultantList(
//    component: ExpertListPageComponent,
//    selectedOption: String,
//    selectedSubCategory: String,
//    consultants: List<RequestConsultant>,
//    isFreeCallApplicable: Boolean
//) {
//
//    // Define sorting and filtering functions
//    fun sortExperts(experts: List<RequestConsultant>, option: String): List<RequestConsultant> {
//        return when (option) {
//            "Popularity" -> experts
//            "Price High to Low" -> experts.sortedByDescending { it.callPerMinutePrice }
//            "Price Low to High" -> experts.sortedBy { it.callPerMinutePrice }
//            "Experience High to Low" -> experts.sortedByDescending { it.year_of_experience }
//            "Rating High to Low" -> experts.sortedByDescending { it.consultant_rating }
//            "Free" -> experts.sortedByDescending { it.isFreeCall }
//            "Premium" -> experts.sortedByDescending { it.premiumExpert }
////            "Free" -> experts.filter { it.isFreeCall }
////            "Premium" -> experts.filter { it.premiumExpert }
//            else -> experts
//        }
//    }
//
//    fun filterByCategory(
//        experts: List<RequestConsultant>, category: String
//    ): List<RequestConsultant> {
//        return if (category.equals("All", ignoreCase = true)) {
//            experts
//        } else {
//            val completeMatches = experts.filter {
//                it.skills.equals(category, ignoreCase = true)
//            }
//
//            val partialMatches = experts.filter {
//                it.skills?.contains(
//                    category, ignoreCase = true
//                ) == true && !completeMatches.contains(it)
//            }
//
//            completeMatches + partialMatches
//        }
//    }
//
//
//    // Prepare sorted and filtered list
//    val sortedExperts = sortExperts(consultants, selectedOption)
//    val filteredExperts = filterByCategory(sortedExperts, selectedSubCategory)
//
//    // UI Layout
//    BoxWithConstraints {
//        val isWideScreen = maxWidth > 600.dp
//        if (isWideScreen) {
//            // Grid layout for wide screens
//            LazyVerticalGrid(
//                columns = GridCells.Fixed(2),
//                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
//            ) {
//                itemsIndexed(filteredExperts) { index, expert ->
//                    ConsultantItem(
//                        expert = expert,
//                        position = index,
//                        listener = CategoryConsultListRowClickListenerImpl(component),
////                        isFreeCallApplicable = selectedOption.equals("Free", ignoreCase = true),
//                        isFreeCallApplicable = isFreeCallApplicable,
//                        component = component
//                    )
//                }
//            }
//        } else {
//            // List layout for narrow screens
//            LazyColumn(
//                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
//            ) {
//                itemsIndexed(filteredExperts) { index, expert ->
//                    ConsultantItem(
//                        expert = expert,
//                        position = index,
//                        listener = CategoryConsultListRowClickListenerImpl(component),
////                        isFreeCallApplicable = selectedOption.equals("Free", ignoreCase = true),
//                        isFreeCallApplicable = isFreeCallApplicable,
//                        component = component
//                    )
//                }
//            }
//
//        }
//    }
//}

@Composable
fun ConsultantList(
    component: ExpertListPageComponent,
    selectedOption: String,
    selectedSubCategory: String,
    consultants: List<RequestConsultant>,
    isFreeCallApplicable: Boolean,
    onScrollStateChanged: (Int) -> Unit
) {
    // Define sorting and filtering functions
    fun sortExperts(experts: List<RequestConsultant>, option: String): List<RequestConsultant> {
        return when (option) {
            "Popularity" -> experts
            "Price High to Low" -> experts.sortedByDescending { it.callPerMinutePrice }
            "Price Low to High" -> experts.sortedBy { it.callPerMinutePrice }
            "Experience High to Low" -> experts.sortedByDescending { it.year_of_experience }
            "Rating High to Low" -> experts.sortedByDescending { it.consultant_rating }
            "Free" -> experts.sortedByDescending { it.isFreeCall }
            "Premium" -> experts.sortedByDescending { it.premiumExpert }
            else -> experts
        }
    }

    fun filterByCategory(
        experts: List<RequestConsultant>, category: String
    ): List<RequestConsultant> {
        return if (category.equals("All", ignoreCase = true)) {
            experts
        } else {
            val completeMatches = experts.filter {
                it.skills.equals(category, ignoreCase = true)
            }

            val partialMatches = experts.filter {
                it.skills?.contains(
                    category, ignoreCase = true
                ) == true && !completeMatches.contains(it)
            }

            completeMatches + partialMatches
        }
    }

    // Prepare sorted and filtered list
    val sortedExperts = sortExperts(consultants, selectedOption)
    val filteredExperts = filterByCategory(sortedExperts, selectedSubCategory)

    // UI Layout
    BoxWithConstraints {
        val isWideScreen = maxWidth > 600.dp

        // Create scroll states
        val lazyListState = rememberLazyListState()
        val lazyGridState = rememberLazyGridState()

        // Call the callback function whenever the scroll state changes for LazyColumn
        LaunchedEffect(lazyListState) {
            snapshotFlow { lazyListState.firstVisibleItemIndex }
                .collect { index ->
                    onScrollStateChanged(index)
                }
        }

        // Call the callback function whenever the scroll state changes for LazyVerticalGrid
        LaunchedEffect(lazyGridState) {
            snapshotFlow { lazyGridState.firstVisibleItemIndex }
                .collect { index ->
                    onScrollStateChanged(index)
                }
        }

        if (isWideScreen) {
            // Grid layout for wide screens
            LazyVerticalGrid(
                columns = GridCells.Fixed(2),
                state = lazyGridState, // Use LazyGridState here
                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
            ) {
                itemsIndexed(filteredExperts) { index, expert ->
                    ConsultantItem(
                        expert = expert,
                        position = index,
                        listener = CategoryConsultListRowClickListenerImpl(component),
                        isFreeCallApplicable = isFreeCallApplicable,
                        component = component
                    )
                }
            }
        } else {
            // List layout for narrow screens
            LazyColumn(
                state = lazyListState, // Use LazyListState here
                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 8.dp)
            ) {
                itemsIndexed(filteredExperts) { index, expert ->
                    ConsultantItem(
                        expert = expert,
                        position = index,
                        listener = CategoryConsultListRowClickListenerImpl(component),
                        isFreeCallApplicable = isFreeCallApplicable,
                        component = component
                    )
                }
            }
        }
    }
}





@Composable
fun openDialog(sheet: MutableState<Boolean>) {
    if (sheet.value) {
        SampleDialog(onDismissRequest = { sheet.value = false }, onPlayStore = {
            sheet.value = false
            openPlayStore(user_app_play_store_url)
            //showToast("Play Store")
        }
//            onAppStore = {
//                showToast("App Store")
//            }
        )
    }
}

/*This is Expert Card of Expert List Page
* This Contain Login and Register Button
* */
@Composable
fun ConsultantItem(
    component: ExpertListPageComponent,
    expert: RequestConsultant,
    position: Int,
    listener: CategoryConsultListRowClickListener,
    isFreeCallApplicable: Boolean
) {
    var isExpertFavoriteClickSelected by remember { mutableStateOf(false) }
    val callAvailability = expert.consultantCallAvailability()
    val chatAvailability = expert.consultantChatAvailability()
    var isUserLoggedIn by remember { mutableStateOf(true) }

    if (isExpertFavoriteClickSelected) {
        if (isUserLoggedIn) {
            CoroutineScope(Dispatchers.Default).launch {
                component.markExpertFavorite(expert.id.toString())
            }
        }
    }

    if (!isUserLoggedIn) {
        LoginDialog(onLoginClicked = {
            component.event(ExpertListPageEvent.onLoginClick)
        }, onSignUpClicked = {
            component.event(ExpertListPageEvent.onLoginClick)
        }, onDismiss = {
            isExpertFavoriteClickSelected = false
        })
    }



    Card(
        modifier = Modifier.fillMaxWidth().padding(8.dp)
            .clickable { listener.onItemClicks(position, expert) },
        shape = RoundedCornerShape(CORNER_SMALL),
        elevation = CardDefaults.elevatedCardElevation(ELEVATION_SMALL),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Row(
            modifier = Modifier.padding(4.dp).fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // User Image Column
            Column(
                modifier = Modifier.width(100.dp).wrapContentHeight(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                ExpertProfileIcon(
                    profileImageUrl = expert.profile_image_url, modifier = Modifier.size(84.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Text(
                    text = "${expert.year_of_experience} yrs",
                    color = Color.Black,
                    style = MaterialTheme.typography.bodyMedium
                )

                Spacer(modifier = Modifier.height(4.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color(0xFFFF9C27),
                        modifier = Modifier.size(16.dp)
                    )
                    ConsultantRating(expert.consultant_rating?.toDouble())
                }
            }

            Spacer(modifier = Modifier.width(8.dp))

            // Details Column
            Column(
                modifier = Modifier
                    //.weight(1f)
                    .fillMaxWidth().padding(end = 8.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = expert.full_name.orEmpty(),
                        style = MaterialTheme.typography.headlineSmall,
                        color = Color.Black,
                        fontSize = 16.sp,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier.weight(1f) // Pushes the favorite button to the end
                    )

                    // Add to favourite button
                    ActionFavoriteIconButton(
                        expertData = expert,
                        icon = painterResource(Res.drawable.ic_heart_primary_color),
                        onClick = {
                            if (getIsLogin()) isExpertFavoriteClickSelected = true else {
                                isUserLoggedIn = false
                            }
                        },
                        component = component
                    )
                }

                Spacer(modifier = Modifier.height(2.dp))

                Text(
                    text = expert.skills.orEmpty(),
                    style = MaterialTheme.typography.bodyMedium,
                    color = Color.Black,
                    maxLines = 1,
                    fontSize = 14.sp,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(2.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_language_24),
                        contentDescription = null,
                        modifier = Modifier.size(14.dp),
                        tint = Color.Black
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = expert.language.orEmpty(),
                        style = MaterialTheme.typography.bodyMedium,
                        color = Color.Black,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }

                Spacer(modifier = Modifier.height(2.dp))

                //ExpertCallPrice(expert, if (isFreeCallApplicable) true else expert.isFreeCall == 1)
                //ExpertCallPrice(expert, isFreeCallApplicable = expert.isFreeCall == 1 )

                ExpertPriceRow(
                    isFreeCallApplicable = isFreeCallApplicable,
                    //isFreeCallApplicable = if(consultant.isFreeCall?.equals(1) == true) true else false,
                    callPerMinutePrice = expert.callPerMinutePrice.toString(),
                    expertPrice = expert.expert_price ?: 0,
                    winywayPrice = expert.winyway_price ?: 0,
                    flashPrice = expert.flashPerMinutePrice,
                    horizontalArrangement = Arrangement.Start
                )
                Spacer(modifier = Modifier.height(2.dp))


                ConsultationButtons(expert = expert, position = position, listener = listener)
//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    horizontalArrangement = Arrangement.SpaceBetween,
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Box(
//                        modifier = Modifier.weight(1f)
//                    ) {
//                        CallConsultationButton(
//                            modifier = Modifier.fillMaxWidth(),
//                            chatStatus = expert.chatButtonStatus,
//                            callStatus = expert.callStatus,
//                            onClick = {
//                                listener.onCallClick(position, expert)
//                            },
//                            isTextVisible = true,
//                        )
//                    }
//
//                    Spacer(modifier = Modifier.width(3.dp))
//
//                    Box(
//                        modifier = Modifier.weight(1f)
//                    ) {
//
//                        ChatConsultationButton(
//                            modifier = Modifier.fillMaxWidth(),
//                            chatStatus = expert.chatButtonStatus,
//                            callStatus = expert.callStatus,
//                            onClick = {
//                                Logger.d("onChat Button Click")
//                                listener.onChatClick(position, expert)
//                            },
//                            isTextVisible = true
//                        )
//                    }
//                }
            }

//            // Add to favourite button
//            Box(modifier = Modifier.align(Alignment.Top)) {
//                ActionIconButton(
//                    icon = painterResource(Res.drawable.ic_heart_primary_color),
//                    onClick = {
//                        //listener.onConsultNowClick(position, expert)
//                    },
//                    //contentDescription = "Add to Favourites"
//                )
//            }
        }
    }
}

@Composable
fun ConsultationButtons(
    expert: RequestConsultant,
    position: Int,
    listener: CategoryConsultListRowClickListener,
) {
    BoxWithConstraints(
        modifier = Modifier.fillMaxWidth()
    ) {
        val isSmallScreen = maxWidth < 250.dp

        if (isSmallScreen) {
            // Column layout for small screens
            Column(
                modifier = Modifier.fillMaxWidth(),
                verticalArrangement = Arrangement.spacedBy(3.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                CallConsultationButton(
                    modifier = Modifier.fillMaxWidth(),
                    chatStatus = expert.chatButtonStatus,
                    callStatus = expert.callStatus,
                    onClick = {
                        listener.onCallClick(position, expert)
                    },
                    isTextVisible = true,
                )

                ChatConsultationButton(
                    modifier = Modifier.fillMaxWidth(),
                    chatStatus = expert.chatButtonStatus,
                    callStatus = expert.callStatus,
                    onClick = {
                        Logger.d("onChat Button Click")
                        listener.onChatClick(position, expert)
                    },
                    isTextVisible = true
                )
            }
        } else {
            // Row layout for larger screens
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    CallConsultationButton(
                        modifier = Modifier.fillMaxWidth(),
                        chatStatus = expert.chatButtonStatus,
                        callStatus = expert.callStatus,
                        onClick = {
                            listener.onCallClick(position, expert)
                        },
                        isTextVisible = true,
                    )
                }

                Spacer(modifier = Modifier.width(3.dp))

                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    ChatConsultationButton(
                        modifier = Modifier.fillMaxWidth(),
                        chatStatus = expert.chatButtonStatus,
                        callStatus = expert.callStatus,
                        onClick = {
                            Logger.d("onChat Button Click")
                            listener.onChatClick(position, expert)
                        },
                        isTextVisible = true
                    )
                }
            }
        }
    }
}
