package com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.extenstion.getMaxInputAbleWidthModifier
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.persentation.CommonTextButton
import com.winiwayuser.core.persentation.CommonTopAppBar
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.widget.DynamicFieldForm
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.widget.RequestAnswerData

private const val LAT = "Latitude"
private const val LONG = "Longitude"
private const val COUNTRY_CODE = "Country Code"

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun ChatConnectingScreen(
    concernComponent: ChatConcernComponent
) {

    val state by concernComponent.state.collectAsState()
    val questionState by concernComponent.stateQuestion.collectAsState()
    val inputValues = rememberSaveable { mutableStateMapOf<String, String>() }

    if (state is ChatConcernState.LoadingState) {
        val isLoading = (state as ChatConcernState.LoadingState).isLoading
        if (isLoading) LoadingProgressDialog { }
    }

    if (state is ChatConcernState.OnError) {
        val errorMsg = (state as ChatConcernState.OnError).msg
        ErrorScreen(errorMessage = errorMsg, onRetry = { concernComponent.onRetry() })
    }

    Scaffold(topBar = {
        CommonTopAppBar(title = "Chat Concern", onBackClick = {
            concernComponent.onClosePage()
        })
    }) { paddingValues ->

        val windowSizeClass = calculateWindowSizeClass().widthSizeClass

        Column(
            modifier = Modifier.fillMaxSize()
                .padding(paddingValues).windowInsetsPadding(WindowInsets.safeDrawing),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            val widthModifier =
                windowSizeClass.getMaxInputAbleWidthModifier().padding(start = 20.dp, end = 20.dp)

            Text(
                text = "Almost Done !",
                textAlign = TextAlign.Start,
                modifier = widthModifier,
                style = MaterialTheme.typography.titleMedium
            )

            Text(
                modifier = widthModifier,
                text = "Please enter the required details. It will help our expert understand your concerns better and provide you with better consultation.",
                style = MaterialTheme.typography.bodyMedium
            )

            Spacer(
                modifier = Modifier.height(8.dp)
            )

            if (questionState.isNullOrEmpty().not()) {
                questionState?.forEach {
                    Logger.d("Compose UI Success-> $it")
                    DynamicFieldForm(modifier = widthModifier.windowInsetsPadding(WindowInsets.ime),
                        questions = it,
                        component = concernComponent,
                        onValueChange = { value ->
                            inputValues[it.id] = value
                        },
                        onValueChangeGeo = { geo ->

                            /*For Latitude*/
                            val latId = getId(questionState, LAT)
                            if (latId != null) {
                                inputValues[latId] = geo.latitude.toString()
                            }/*For Longitude*/
                            val longId = getId(questionState, LONG)
                            if (longId != null) {
                                inputValues[longId] = geo.longitude.toString()
                            }/*For Country Code*/
                            val countryId = getId(questionState, COUNTRY_CODE)
                            if (countryId != null) {
                                inputValues[countryId] = geo.countryCode
                            }/*For Town Name*/
                            inputValues[it.id] = geo.placeName

                        })
                }
            }

            Spacer(modifier = Modifier.height(16.dp))
            CommonButton(
                buttonText = "Submit", onClick = {
                    val allAnswer = generateRequestData(inputValues)
                    concernComponent.onSubmitConcern(
                        answerData = allAnswer
                    )
                }, modifier = widthModifier
            )
            Spacer(modifier = Modifier.height(8.dp))
            CommonTextButton(
                buttonText = "Skip Concern",
                onClick = { concernComponent.onSubmitConcern(null) },
                modifier = widthModifier
            )
        }

    }

}

private fun getId(questionState: List<ConcernQuestions>?, inputType: String): String? {
    return questionState?.find { question ->
        question.inputType.contains(inputType) // Assuming some relation between placeName and question
    }?.id
}

// Function to map inputValues to a list of RequestAnswerData
fun generateRequestData(inputValues: Map<String, String>): List<RequestAnswerData> {
    return inputValues.map { (questionId, answer) ->
        RequestAnswerData(questionId = questionId, answer = answer)
    }
}

@Composable
fun ErrorScreen(
    errorMessage: String, onRetry: () -> Unit
) {
    // Center content vertically and horizontally
    Box(
        modifier = Modifier.fillMaxSize() // Fills the whole screen
            .padding(16.dp) // Padding around the content
    ) {
        Column(
            modifier = Modifier.align(Alignment.Center) // Align content to the center
                .padding(16.dp), // Optional padding inside the column
            horizontalAlignment = Alignment.CenterHorizontally // Align children horizontally centered
        ) {
            // Title
            Text(
                text = "Error", fontSize = 24.sp, color = MaterialTheme.colorScheme.error
            )

            Spacer(modifier = Modifier.height(16.dp)) // Space between title and error detail

            // Error details
            Text(
                text = errorMessage,
                fontSize = 16.sp,
                color = MaterialTheme.colorScheme.onBackground,
                style = MaterialTheme.typography.bodyMedium
            )

            Spacer(modifier = Modifier.height(32.dp)) // Space between error detail and button

            // Retry button
            CommonButton(
                buttonText = "Retry", onClick = { onRetry.invoke() }, modifier = Modifier
            )
        }
    }
}
