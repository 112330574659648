package com.winiwayuser.feature_winyway_wallet.persentation.razorpay_gateway_new


import com.winiwayuser.core.local.UserInfoProvider
import kotlinx.serialization.Serializable

// For Test RazorpayKey = "rzp_test_2Xyd6IzNwWLtkO",  // Replace with your actual key
// For Live RazorpayKey = "rzp_live_SOXGx6aTSzGwx2"
//@Serializable
//data class PaymentGatewayArguments(
//    val paymentUserName: String? = UserInfoProvider.getFullName(),
//    val paymentUserMobile: String? = UserInfoProvider.getMobileCode() + UserInfoProvider.getPhone(),
//    val paymentUserEmail: String? = UserInfoProvider.getEmail(),
//    val orderId: String,
//    val razorpayKey: String? = "rzp_live_SOXGx6aTSzGwx2",// If this null than Live Razorpay key will use
//    val paymentDescription: String = "Wallet Recharge of Talk to Astro"
//)

@Serializable
data class PaymentGatewayArguments(
    val appName:String,
    val themeColor:String,
    val paymentUserName: String?,
    val paymentUserMobile: String? ,
    val paymentUserEmail: String?,
    val orderId: String,
    //val razorpayKey: String? = "rzp_live_SOXGx6aTSzGwx2",// If this null than Live Razorpay key will use
    val razorpayKey: String? = "rzp_live_p53kUYNfXCDlKP",// If this null than Live Razorpay key will use
    val paymentDescription: String = "Wallet Recharge of Talk to Astro",
    val appIcon:String
)
