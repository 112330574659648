package com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatMassages(
    //msg_type -> //
    //0-concern Image
    //1- Normal Msg
    //2- Concern Answer
    //3- Concern Question
    //4- Normal Image
    @SerialName("id") val id: Int, // 112913
    @SerialName("message") val message: String, // RETNDFGDSFGDSFGDFG MDFG
    @SerialName("msg_type") val msgType: String, // 2
    @SerialName("sent_at") val dateTime: String, // 2023-11-03 18:16:46
    @SerialName("type") val type: String? // User
)
