package com.winiwayuser.feature_chat_consultation.persentation.chat_room_view

import com.winiwayuser.feature_chat_consultation.data.response.start_consultation.StartConsultationData

sealed class ChatRoomState {
    data object InitialState : ChatRoomState()
    data class OnErrorState(val errorMsg: String) : ChatRoomState()
    data class OnLoading(val isLoading: Boolean) : ChatRoomState()
    data class OnChatStart(val chatData: StartConsultationData) : ChatRoomState()
    data object OnEndChat:ChatRoomState()
    data object OnEndChatSuccess:ChatRoomState()
}