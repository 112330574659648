package com.winiwayuser.feature_winyway_wallet.data.response.all_coupon

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Coupon(
    @SerialName("cashback_percent") val cashbackPercent: Int, // 10
    @SerialName("cashback_value") val cashbackValue: Int, // 100
    @SerialName("coupon_code") val couponCode: String, // test
    @SerialName("coupon_text") val couponText: String, // Get 40% Extra
    @SerialName("created_at") val createdAt: String, // 2024-02-01 14:22:00
    @SerialName("description") val description: String,
    @SerialName("expiry_date") val expiryDate: String, // 2024-03-01
    @SerialName("id") val id: Int, // 14
    @SerialName("level") val level: Int, // 9
    @SerialName("minimum_value") val minimumValue: Int, // 300
    @SerialName("recharge_plan_id") val rechargePlanId: Int, // 0
    @SerialName("starting_date") val startingDate: String?, // null
    @SerialName("status") val status: Int, // 1
    @SerialName("title") val title: String,
    @SerialName("type") val type: String, // website
    @SerialName("updated_at") val updatedAt: String // 2024-02-01 14:22:00
)
