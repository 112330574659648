package com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.decompose

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view.ChatRoomComponent
import com.winiwayuser.feature_chat_consultation.persentation.chat_waiting_room.ChatWaitingComponent

interface HostRootComponent {

    val childStack: Value<ChildStack<*, Child>>

    sealed class Child {
        class ChatConcernChild(val component: ChatConcernComponent) : Child()
        class ChatWaitingChild(val component: ChatWaitingComponent) : Child()
        class ChatRoomChild(val component: ChatRoomComponent) : Child()
    }
}