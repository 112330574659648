package com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertDetailsApiService
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertDetailsApi
import com.winiwayuser.feature_expert_consultation.domain.ExpertDetailsRepo
import com.winiwayuser.feature_expert_consultation.data.remote.request.RelatedExpertRequest
import com.winiwayuser.feature_expert_consultation.data.remote.request.RequestReviewSubmit
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.expert_details_state_info.ExpertDetailsState
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.expert_details_state_info.SubmitExpertReviewState
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import kotlin.properties.Delegates

class ExpertDetailsPageComponent(
    context: ComponentContext,
    private val expertId: Int,
    private val onBackPressed: () -> Unit,
    private val onCallButtonClick: (String, String, String, String) -> Unit,
    private val onLoginClick: () -> Unit,
    private val onClickRelatedExpert: (Int) -> Unit,
    private val onChatStartClick: (String, String, String, String, String, String) -> Unit,
    private val onRechargeAgain: (RechargeData?) -> Unit,
    private val onVoIPCallConsultationStart: (String, CommonExpertInfo) -> Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    val _expertId get() = expertId
    private val api: ExpertDetailsRepo by inject()
    private val expertDetailsApi: ExpertDetailsApi by inject()
    private val api1: ExpertDetailsApiService by inject()

    private lateinit var relatedExpert: RequestConsultant
    var isFreeConsultation: Boolean = false

    private val _state =
        MutableStateFlow<ExpertDetailsState>(ExpertDetailsState.Loading(isLoading = true))
    val state get() = _state.asStateFlow()

    private val _relatedExpertState =
        MutableStateFlow<List<RequestConsultant>?>(null)
    val relatedExpertState: StateFlow<List<RequestConsultant>?> get() = _relatedExpertState

    private val _summitReviewState =
        MutableStateFlow<SubmitExpertReviewState>(SubmitExpertReviewState.Loading(isLoading = false))
    val submitReviewState get() = _summitReviewState.asStateFlow()

    private val _summitReviewStateConfirmation =
        MutableStateFlow<Boolean?>(false)
    val submitReviewStateConfirmation get() = _summitReviewStateConfirmation.asStateFlow()
    fun callLoginDialog() = onLoginClick.invoke()

    init {
        lifecycle.doOnStart {
            Logger.d { "Expert Details page start" }
            coroutineScope.launch {
                getExpertDetails()
                getRelatedExpert()
                Logger.d { "Call getRelatedExpert()  in doOnStart" }
            }
        }
        lifecycle.doOnDestroy {
            Logger.d { "Expert Details page end" }
            coroutineScope.cancel()
        }
    }

    fun event(event: ExpertDetailsPageEvent) {
        when (event) {
            is ExpertDetailsPageEvent.onBackButtonClick -> onBackPressed.invoke()
            is ExpertDetailsPageEvent.onCallButtonClick -> onCallButtonClick.invoke(
                event.expertId,
                event.expertName,
                event.expertImage,
                event.expertSkills
            )

            is ExpertDetailsPageEvent.onChatButtonClick -> onChatStartClick.invoke(
                event.expertId,
                event.expertName,
                event.expertImage,
                event.expertSkills,
                event.duration,
                event.category
            )

            ExpertDetailsPageEvent.onClickLoginButton -> onLoginClick.invoke()
            is ExpertDetailsPageEvent.onRelatedExpertCardClick -> onClickRelatedExpert.invoke(event.expertId)
            is ExpertDetailsPageEvent.onRechargeRequired -> onRechargeAgain.invoke(event.data)
            is ExpertDetailsPageEvent.OnVoIPCallConsultationStart -> onVoIPCallConsultationStart.invoke(
                event.duration, event.expertInfo
            )
        }
    }

    fun onBackPress() = onBackPressed.invoke()

    private suspend fun onError(msg: String) {
        _state.emit(ExpertDetailsState.Error(msg = msg))
    }

    private suspend fun onSuccess(data: RequestConsultant) {
        _state.emit(ExpertDetailsState.Success(data))
        Logger.d { "on Success getExpertDetails ${data.full_name}" }
        relatedExpert = data
    }

    suspend fun getExpertDetails() {
        api.getExpertDetails(expertId.toString()).collect { resource ->
            when (resource) {
                is Resources.Error -> {
                    Logger.d("getExpertDetails ${resource.msg}")
                    onError(resource.msg ?: "Unexpected Error")
                }

                is Resources.Loading -> {
                    Logger.d(
                        tag = "getExpertDetails",
                        messageString = "Loading: ${resource.isLoading}"
                    )
                    _state.emit(ExpertDetailsState.Loading(resource.isLoading))
                }

                is Resources.Success -> {
                    isFreeConsultation = resource.data?.freeCallAvailability == 1
                    resource.data?.consultant?.let { consultant ->
                        Logger.d(tag = "getExpertDetails", messageString = "Success: $consultant")
                        onSuccess(consultant)
                    } ?: onError("No data available")
                }
            }
        }
    }

    private suspend fun getRelatedExpert() {
        Logger.d { "Call getRelatedExpert" }
        coroutineScope.launch {
            if (::relatedExpert.isInitialized) {
                val categoryId = relatedExpert.cats?.getOrNull(0)?.cat?.id.toString()
                val data = RelatedExpertRequest(
                    category = categoryId,
                    expertCountRequired = 5
                )
                if (categoryId.isNotEmpty()) {
                    try {
                        val result = async { expertDetailsApi.getRelatedExpertList(data) }

                        if (result.await() is Resources.Success<*>) {
                            val consultants =
                                result.await().data?.consultants as List<RequestConsultant>
                            Logger.d { "API Success: ${consultants.size} consultants received" }
                            _relatedExpertState.value = consultants
                        } else {
                            Logger.e { "API Result Error: $result" }
                            _relatedExpertState.value = null
                        }
                    } catch (e: Exception) {
                        Logger.e { "API call failed: ${e.message}" }
                        e.printStackTrace()
                        _relatedExpertState.value = null
                    }
                } else {
                    Logger.e { "Category ID is empty" }
                    _relatedExpertState.value = null
                }
            }
        }
    }


    suspend fun postExpertReview(request: RequestReviewSubmit) {
        api.postExpertReview(request).collect { resource ->
            when (resource) {
                is Resources.Error -> {
                    _summitReviewState.emit(
                        SubmitExpertReviewState.Error(
                            resource.msg ?: "Unexpected Error"
                        )
                    )
                }

                is Resources.Loading -> {
                    _summitReviewState.emit(SubmitExpertReviewState.Loading(resource.isLoading))
                }

                is Resources.Success -> {
                    val updatedDetails =
                        ExpertDetailsApiService().requestExpertDetailsInfo(request.consultantId)
                    Logger.d(tag = "postExpertReview", messageString = "Success: ${resource.data}")
                    _summitReviewStateConfirmation.value = resource.data!!
                   onPostExpertReviewSuccess(resource.data ?: false)
                }
            }
        }
    }

    private fun onPostExpertReviewSuccess(data:Boolean) = coroutineScope.launch {
        _summitReviewState.emit(SubmitExpertReviewState.Success(data))
    }
}

