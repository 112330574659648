package com.winiwayuser.feature_courses.persentation.course_page.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DateRange
import androidx.compose.material.icons.filled.Timeline
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.persentation.CommonButton
import com.winiwayuser.core.persentation.StarRating
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.feature_courses.persentation.course_page.CourseInfo

@Composable
fun CourseHeaderSection(
    courseData: CourseInfo,
    windowWidthSizeClass: WindowWidthSizeClass,
    onButtonClick: () -> Unit = {}
) {

    val desktopView = windowWidthSizeClass == WindowWidthSizeClass.Expanded
    val isMobileView = windowWidthSizeClass == WindowWidthSizeClass.Compact

    Column(
        modifier = Modifier.padding(bottom = 24.dp)
    ) {

        val theme = if (isMobileView) MaterialTheme.typography.headlineMedium.copy(
            fontSize = 18.sp,
        ) else MaterialTheme.typography.headlineMedium

        Text(
            text = courseData.title ?: "",
            style = theme,
            fontWeight = FontWeight.Bold,
        )

        if (desktopView) {
            Row(
                modifier = Modifier.padding(top = 12.dp),
            ) {


                AsyncImage(
                    model = courseData.imagePath, // Replace with your image
                    contentDescription = null,
                    modifier = Modifier
                        .weight(2f)
                        .height(220.dp)
                        .clip(RoundedCornerShape(Scale.CORNER_SMALL))
                        .background(Color.LightGray),
                    contentScale = ContentScale.Crop
                )

                Spacer(modifier = Modifier.width(32.dp))

                Column(
                    modifier = Modifier.weight(2.5f)
                ) {

                    TextWithStartIcon(
                        modifier = Modifier,
                        text = "Start on",
                        textSecond = courseData.date ?: "Announce Soon",
                        icon = Icons.Default.DateRange,
                    )

                    Spacer(modifier = Modifier.height(16.dp))

                    TextWithStartIcon(
                        modifier = Modifier,
                        text = "Duration:",
                        textSecond = courseData.durationDays ?: "Announce soon",
                        icon = Icons.Default.Timeline,
                    )

                    Spacer(modifier = Modifier.height(24.dp))

                    HorizontalDivider(
                        modifier = Modifier.padding(vertical = 8.dp),
                        color = Color.Black,
                        thickness = 0.8.dp
                    )

                    Spacer(modifier = Modifier.height(16.dp))

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = "Rating:",
                            style = MaterialTheme.typography.titleMedium,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                        )

                        Spacer(modifier = Modifier.width(4.dp))

                        StarRating(
                            starSize = 16.dp,
                            rating = courseData.rating?.toFloatOrNull() ?: 5F,
                            filledStarColor = primaryDark
                        )

                        Spacer(modifier = Modifier.width(4.dp))

                        Text(
                            text = courseData.reviewsCount ?: "1k Student",
                            style = MaterialTheme.typography.titleSmall,
                            color = Color.Black,
                            fontWeight = FontWeight.SemiBold,
                        )
                    }

                    Spacer(modifier = Modifier.height(24.dp))

                    Text(
                        text = "Exclusive Price",
                        style = MaterialTheme.typography.bodyLarge,
                        color = MaterialTheme.colorScheme.onSurfaceVariant,
                        fontWeight = FontWeight.SemiBold
                    )

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        Text(
                            text = courseData.price,
                            style = MaterialTheme.typography.headlineMedium,
                            color = primaryDark,
                            fontWeight = FontWeight.ExtraBold
                        )

                        Spacer(modifier = Modifier.width(8.dp))

                        Text(
                            text = courseData.originalPrice,
                            style = MaterialTheme.typography.bodyLarge,
                            textDecoration = TextDecoration.LineThrough
                        )
                    }
                }
            }
        } else {

            // Tablet and Mobile View
            Column(
                modifier = Modifier.padding(top = 12.dp),
            ) {

                AsyncImage(
                    model = courseData.imagePath, // Replace with your image
                    contentDescription = null,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(220.dp)
                        .clip(RoundedCornerShape(Scale.CORNER_SMALL))
                        .background(Color.LightGray),
                    contentScale = ContentScale.Crop
                )

                Spacer(modifier = Modifier.height(8.dp))

                Column {

                    TextWithStartIcon(
                        modifier = Modifier,
                        text = "Start on",
                        textSecond = courseData.date ?: "Announce Soon",
                        icon = Icons.Default.DateRange,
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    TextWithStartIcon(
                        modifier = Modifier,
                        text = "Duration:",
                        textSecond = courseData.durationDays ?: "Announce soon",
                        icon = Icons.Default.Timeline,
                    )

                    Spacer(modifier = Modifier.height(12.dp))

                    HorizontalDivider(
                        modifier = Modifier.padding(vertical = 8.dp),
                        color = Color.Black,
                        thickness = 0.8.dp
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = "Rating:",
                            style = MaterialTheme.typography.titleMedium,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                        )

                        Spacer(modifier = Modifier.width(4.dp))

                        StarRating(
                            starSize = 16.dp,
                            rating = courseData.rating?.toFloatOrNull() ?: 5F,
                            filledStarColor = primaryDark
                        )

                        Spacer(modifier = Modifier.width(4.dp))

                        Text(
                            text = courseData.reviewsCount ?: "1k Student",
                            style = MaterialTheme.typography.titleSmall,
                            color = Color.Black,
                            fontWeight = FontWeight.SemiBold,
                        )
                    }

                    Spacer(modifier = Modifier.height(8.dp))

                    Text(
                        text = "Exclusive Price",
                        style = MaterialTheme.typography.bodyLarge,
                        color = MaterialTheme.colorScheme.onSurfaceVariant,
                        fontWeight = FontWeight.SemiBold
                    )

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        Text(
                            text = courseData.price,
                            style = MaterialTheme.typography.headlineMedium,
                            color = primaryDark,
                            fontWeight = FontWeight.ExtraBold
                        )

                        Spacer(modifier = Modifier.width(8.dp))

                        Text(
                            text = courseData.originalPrice,
                            style = MaterialTheme.typography.bodyLarge,
                            textDecoration = TextDecoration.LineThrough
                        )
                    }

                    if (isMobileView) {

                        Spacer(modifier = Modifier.height(12.dp))

                        CommonButton(
                            buttonText = "Enroll Now",
                            onClick = {
                                onButtonClick.invoke()
                            },
                            modifier = Modifier.fillMaxWidth(),
                            buttonColor = primaryDark
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun TextWithStartIcon(
    modifier: Modifier,
    text: String,
    textSecond: String,
    horizontalArrangement: Arrangement.Horizontal = Arrangement.Start,
    icon: ImageVector,
    iconModifier: Modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL),
    textStyle: TextStyle = TextStyle(
        fontSize = 15.sp,
        fontWeight = FontWeight.SemiBold,
        color = Color.Black
    )
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = horizontalArrangement,
        modifier = modifier
    ) {
        Icon(
            imageVector = icon,
            contentDescription = null,
            tint = Color.Black,
            modifier = iconModifier
        )

        Spacer(modifier = Modifier.width(8.dp))

        Text(
            text = text,
            style = textStyle,
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = textSecond,
            style = TextStyle(
                fontSize = 15.sp,
                fontWeight = FontWeight.SemiBold,
                color = primaryDark
            )
        )
    }
}