package com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_call_consultation.domain.CallConsultationInfoRepo
import com.winiwayuser.feature_call_consultation.data.request.RequestCallDuration
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class CallConsultationComponent(
    context: ComponentContext,
    private val _expertId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _expertSkills: String,
    private val onClose: () -> Unit,
    private val onNavigateRechargeCheckOut: (RechargeData) -> Unit,
    private val onLetsConnectButtonClick: () -> Unit,
    private val onNavigateChoseRechargePack: () -> Unit,
    private val onVoIpCallStart: (String) -> Unit,
    private val onNavigateCallWaitingPage: (String,String,String,String,()->Unit) -> Unit
) : ComponentContext by context, KoinComponent {

    companion object {
        private const val FREE_CALL_TEXT = "Free Call"
        private const val CALL_TYPE = "Call_Type"
    }

    val expertId get() = _expertId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val expertSkills get() = _expertSkills
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: CallConsultationInfoRepo by inject()
    private val _state =
        MutableStateFlow<CallInfoBottomSheetState>(CallInfoBottomSheetState.Loading(false))
    val state get() = _state.asStateFlow()


    fun onClosePage() = onClose.invoke()
    fun onVoIpCallStart(callDuration: String) = onVoIpCallStart.invoke(callDuration)

    fun event(event: CallConsultationEvent) {
        when (event) {
            is CallConsultationEvent.onNavigateRechargeCheckOut -> onNavigateRechargeCheckOut.invoke(
                event.rechargeData
            )

            is CallConsultationEvent.onNavigateChoseRechargePack -> onNavigateChoseRechargePack.invoke()
            is CallConsultationEvent.onNavigateCallWaitingPage ->  onNavigateCallWaitingPage.invoke(
                event._expertId,event._expertName,event._expertImage,event._expertSkills,event.onClose
            )
            //is CallConsultationEvent.onNavigateCallWaitingPage -> TODO()
        }
    }

    fun onClickLetsConnectButton() = onLetsConnectButtonClick.invoke()

    init {
        lifecycle.doOnStart {
            coroutineScope.launch {
                getConsultationInfo()
            }
        }
        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private suspend fun onError(msg: String) {
        _state.emit(CallInfoBottomSheetState.Error(msg))
    }

    private fun onSuccess(data:CallDurationData?) = coroutineScope.launch{
        Logger.d { "CallConsultationComponent onSuccess $data" }
        if(data ==null){
            onError("Something went wrong")
            return@launch
        }

        if (data.activeStatus.equals("Busy", true)) {
            onError("Sorry, our expert is currently occupied. Please try again later.")
            return@launch
        }

        if (data.type.equals(FREE_CALL_TEXT, true) && (data.balanceAsInt  ?: 0) <= (data.minimumBalanceAsInt ?: 0)
        ) {
            _state.emit(CallInfoBottomSheetState.FreeCallLowBalance(data))
            return@launch
        }

        if (data.minimumBalance != null && data.type.equals(FREE_CALL_TEXT, true).not()) {
            // Check if minimumBalanceAsInt is not null and type is not FREE_CHAT_TEXT
            // Low Balance
            if (data.balance != null && data.balance.toFloat() < data.minimumBalance.toFloat()) {
                // Check if balanceAsInt is not null and is less than minimumBalanceAsInt
                getRechargePlans(data)
                return@launch
            }
        }

        _state.emit(CallInfoBottomSheetState.CallInfoSuccess(data))
    }


    private suspend fun getConsultationInfo() {
        val request = RequestCallDuration(
            expertId
        )
        api.getCallConsultationInfo(request).collect {
            when (it) {
                is Resources.Error -> {
                    Logger.d("Error state in getConsultationInfo ${it.msg} ")
                    onError(it.msg ?: "Unexpected Error")
                }

                is Resources.Loading -> _state.emit(CallInfoBottomSheetState.Loading(it.isLoading))
                is Resources.Success -> onSuccess(data = it.data)
            }
        }
    }



    private suspend fun onRechargeListLoaded(data: CallDurationData, list: List<RechargeData>) {
        _state.emit(CallInfoBottomSheetState.NormalCallLowBalance(data, list))
    }

    private suspend fun getRechargePlans(callDurationData: CallDurationData) {
        val rechargePackState = WalletRechargeApi().requestRechargePlanList().data?.data
        if (rechargePackState.isNullOrEmpty()) {
            onError("Something Went Wrong While Loading")
            return
        }

        onRechargeListLoaded(callDurationData, rechargePackState)
    }
}