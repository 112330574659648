package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageEvent

@Composable
fun SearchField(
    modifier: Modifier = Modifier, onClick: () -> Unit
) {

    Row(
        modifier = modifier.fillMaxWidth().clickable(onClick = onClick),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(imageVector = Icons.Default.Search,
            contentDescription = "Search Icon",
            tint = Color.Gray.copy(alpha = 0.8f),
            modifier = Modifier.padding(horizontal = 8.dp, vertical = 8.dp).size(Scale.GLOBAL_ICON_SIZE)
                .clickable { onClick() })

        Text(
            text = "Any expert in just a click...",
            modifier = Modifier.weight(1f),
            style = MaterialTheme.typography.labelSmall,
            maxLines = 1,
        )
    }
}

//@Composable
//fun ExpertSearchField(
//    modifier: Modifier = Modifier, onClick: @Composable () -> Unit
//) {
//
//    Row(
//        modifier = modifier.fillMaxWidth().clickable(onClick = onClick),
//        verticalAlignment = Alignment.CenterVertically
//    ) {
//        Icon(imageVector = Icons.Default.Search,
//            contentDescription = "Search Icon",
//            tint = Color.Gray.copy(alpha = 0.8f),
//            modifier = Modifier.padding(horizontal = 8.dp, vertical = 8.dp).size(Scale.GLOBAL_ICON_SIZE)
//                .clickable { onClick() })
//
//        Text(
//            text = "Search Expert hare",
//            modifier = Modifier.weight(1f),
//            style = MaterialTheme.typography.labelSmall,
//            maxLines = 1,
//        )
//    }
//}

@Composable
fun SearchFieldPreview() {
    val navigateToAnotherPage = { /* Navigation logic goes here */ }
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = CardDefaults.cardColors(Color.White),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
        modifier = Modifier.padding(start = Scale.HORIZONTAL_PADDING, bottom = Scale.HORIZONTAL_PADDING, end = Scale.HORIZONTAL_PADDING).fillMaxWidth()
    ) {
        SearchField(
            modifier = Modifier, onClick = navigateToAnotherPage
        )
    }

}


//@Composable
//fun ExpertSearchFieldPreview() {
//    val navigateToAnotherPage = @androidx.compose.runtime.Composable {  SimpleDockedSearchBar() }
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        colors = CardDefaults.cardColors(Color.White),
//        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
//        modifier = Modifier.padding(start = Scale.HORIZONTAL_PADDING, bottom = Scale.HORIZONTAL_PADDING, end = Scale.HORIZONTAL_PADDING).fillMaxWidth()
//    ) {
//        ExpertSearchField(
//            modifier = Modifier, onClick = { navigateToAnotherPage() }
//        )
//    }
//
//}

@Composable
fun ExpertSearchField(
    modifier: Modifier = Modifier, onClick: () -> Unit
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .clickable { onClick() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            imageVector = Icons.Default.Search,
            contentDescription = "Search Icon",
            tint = Color.Gray.copy(alpha = 0.8f),
            modifier = Modifier
                .padding(horizontal = 8.dp, vertical = 8.dp)
                .size(Scale.GLOBAL_ICON_SIZE)
                .clickable { onClick() }
        )

        Text(
            text = "Search Expert here",
            modifier = Modifier.weight(1f),
            style = MaterialTheme.typography.labelSmall,
            maxLines = 1
        )
    }
}

@Composable
fun ExpertSearchFieldPreview(component: ExpertListPageComponent) {
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = CardDefaults.cardColors(Color.White),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
        modifier = Modifier
            .padding(start = Scale.HORIZONTAL_PADDING, bottom = Scale.HORIZONTAL_PADDING, end = Scale.HORIZONTAL_PADDING)
            .fillMaxWidth()
    ) {
        ExpertSearchField(
            modifier = Modifier,
            onClick = { component.event(ExpertListPageEvent.OnSearchFieldClick) }//component.event(ExpertListPageEvent.OnSearchFieldClick) }
        )
    }
}
