package com.winiwayuser.feature_courses.data.response.courses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseData(
    @SerialName("id") var id: Int? = null,
    @SerialName("title") var title: String? = null,
    @SerialName("description") var description: String? = null,
    @SerialName("date") var date: String? = null,
    @SerialName("duration_days") var durationDays: Int? = null,
    @SerialName("price") var price: String? = null,
    @SerialName("int_discounted_price") var priceInternational: String? = null,
    @SerialName("int_base_price") var originalPriceInternational: String? = null,
    @SerialName("original_price") var originalPrice: String? = null,
    @SerialName("category_type") var categoryType: String? = null,
    @SerialName("instructor_id") var instructorId: Int? = null,
    @SerialName("slug") var slug: String? = null,
    @SerialName("rating") var rating: String? = null,
    @SerialName("reviews_count") var reviewsCount: Int? = null,
    @SerialName("image_path") var imagePath: String? = null,
    @SerialName("upload_certificates") var uploadCertificates: List<String>? = null,
    @SerialName("status") var status: Int? = null,
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null,
    @SerialName("deleted_at") var deletedAt: String? = null,
    @SerialName("instructors") var instructors: ArrayList<Instructors> = arrayListOf(),
    @SerialName("course_details") var courseDetails: ArrayList<CourseDetails> = arrayListOf()
)