package com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info

import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

sealed class CallInfoBottomSheetState {
    data class Error(val msg: String) : CallInfoBottomSheetState()
    data class Loading(val isLoading: Boolean) : CallInfoBottomSheetState()
    data class CallInfoSuccess(val data: CallDurationData) : CallInfoBottomSheetState()
    data class FreeCallLowBalance(val data: CallDurationData) : CallInfoBottomSheetState()
    data class NormalCallLowBalance(
        val data: CallDurationData,
        val rechargeData: List<RechargeData>
    ) : CallInfoBottomSheetState()
//    data class
}