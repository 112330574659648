package com.winiwayuser.feature_winyway_wallet.persentation.all_coupons_page

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CardElevation
import androidx.compose.material3.Divider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.feature_winyway_wallet.data.CouponApplyListener
import com.winiwayuser.feature_winyway_wallet.data.response.all_coupon.Coupon

@Composable
fun CouponCard(data: Coupon, listener: CouponApplyListener, onDismiss: () -> Unit) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 12.dp, vertical = 12.dp),
        elevation = CardDefaults.cardElevation(4.dp)
    ) {
        Column(
            modifier = Modifier.padding(16.dp)
        ) {
            Text(
                text = data.couponCode,
                style = TextStyle(fontSize = 15.sp, fontWeight = FontWeight.Bold)
            )
            Spacer(modifier = Modifier.height(3.dp))
            Text(
                text = data.couponText,
                style = TextStyle(fontSize = 12.sp),
                maxLines = 3,
                overflow = TextOverflow.Ellipsis
            )
            Spacer(modifier = Modifier.height(8.dp))
            Divider(
                modifier = Modifier
                    .height(1.dp)
                    .padding(end = 10.dp),
                //color = MaterialTheme.colors.onSurface.copy(alpha = 0.12f)
            )
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    onClick = {
                        listener.onCouponApplied(data.couponCode)
                        onDismiss.invoke()
                    },
                    colors = ButtonDefaults.buttonColors(md_them_primary),
                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
                    elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
                ) {
                    Text(
                        text = "Apply",
                        color = Color.White,
                        textAlign = TextAlign.Center
                    )
                }
            }
        }
    }
}
