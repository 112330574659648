package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EmergencyChatDetails(
    @SerialName("chat_id") val chatId: Int, // 67782
    @SerialName("created_at") val createdAt: String, // 2024-07-05 15:31:56
    @SerialName("currency") val currency:  String?, // null
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("duration") val duration: String, // 3
    @SerialName("ended_by") val endedBy: String, // User
    @SerialName("expert_end_chat_date_time") val expertEndChatDateTime: String, // 2024-07-05 15:35:26
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("expert_info") val expertInfo: ExpertInfo?,
    @SerialName("expert_payment") val expertPayment: Int, // 63
    @SerialName("expert_price") val expertPrice: String, // 14
    @SerialName("expert_start_chat_date_time") val expertStartChatDateTime: String, // 2024-07-05 15:32:08
    @SerialName("flash_off") val flashOff: String?, // null
    @SerialName("id") val id: Int, // 67782
    @SerialName("international_rate") val internationalRate: String?, // null
    @SerialName("paid_amount") val paidAmount: String, // 67.5
    @SerialName("remaining_wallet_balance") val remainingWalletBalance: String, // 271.5
    @SerialName("status") val status: String, // End
    @SerialName("type") val type: String?, // null
    @SerialName("updated_at") val updatedAt: String, // 2024-07-05 15:35:26
    @SerialName("user_end_chat_date_time") val userEndChatDateTime: String, // 2024-07-05 15:35:26
    @SerialName("user_id") val userId: Int, // 95268
    @SerialName("user_rating") val userRating: String?, // null
    @SerialName("user_review") val userReview: String?, // null
    @SerialName("user_start_chat_date_time") val userStartChatDateTime: String, // 2024-07-05 15:32:10
    @SerialName("winyway_payment") val winywayPayment: Int, // 3
    @SerialName("winyway_price") val winywayPrice: String // 1
)
