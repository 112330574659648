package com.winiwayuser.feature_common.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestSlider(
    @SerialName ("id") val id: Int, // 1
    @SerialName("image") val image: String, // https://prod.winyway.com/backend/sliders/1694761834.jpg
    @SerialName("sort_by") val sortBy: Int, // 1
    @SerialName("title") val title: String // test
)
