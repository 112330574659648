package com.winiwayuser.feature_call_consultation.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_call_consultation.data.api.CallConsultationApi
import com.winiwayuser.feature_call_consultation.data.request.GetCallDurationRequest
import com.winiwayuser.feature_call_consultation.data.request.RequestCallDuration
import com.winiwayuser.feature_call_consultation.data.response.CallDurationData
import com.winiwayuser.feature_call_consultation.data.response.Data
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class CallConsultationInfoRepo(
    private val api: CallConsultationApi
) {
    fun getCallConsultationInfo(callDurationRequest: RequestCallDuration):Flow<Resources<CallDurationData>>{
        return flow {
            emit(Resources.Loading())
            val response = api.getCallDurationInfo(callDurationRequest)
            emit(response)
            emit(Resources.Loading(false))
        }
    }


    fun callPlace(placeCallRequest: GetCallDurationRequest): Flow<Resources<Data>>{
        return flow {
            emit(Resources.Loading())
            val response = api.placeCall(placeCallRequest)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}