package com.winiwayuser.core.decompose

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.popTo
import com.arkivanov.decompose.router.stack.popToFirst
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.pushNew
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import com.arkivanov.decompose.value.Value
import com.winiwayuser.core.config.COURSES_LIST
import com.winiwayuser.core.config.CUSTOM_ORDER_LIST
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.extenstion.generateRandomString
import com.winiwayuser.core.persentation.refreshPage
import com.winiwayuser.user_feature_authentication.persentation.login_page.LoginPageComponent
import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationComponent
import com.winiwayuser.user_feature_authentication.persentation.register_page.RegisterComponent
import com.winiwayuser.feature_common.persentation.bottom_navigation_bar.MainScreenComponent
import com.winiwayuser.feature_call_consultation.persentation.call_success_page.CallSuccessComponent
import com.winiwayuser.feature_call_consultation.persentation.voip_call_page.VoipCallComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import com.winiwayuser.feature_common.data.response.SubCategoriesModel
import com.winiwayuser.feature_common.persentation.home_tab.HomePageEvent
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.ChatConversationComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.ChatHistoryComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.EditProfileComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.MoreScreenEvent
import com.winiwayuser.feature_common.persentation.more_tab.component.MoreScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyEvent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsEvent
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.persentation.TransactionHistoryComponent
import com.winiwayuser.feature_courses.persentation.course_page.CourseComponent
import com.winiwayuser.feature_courses.persentation.courses_page.CoursesComponent
import com.winiwayuser.feature_courses.persentation.reserv_video.ReserveVideoComponent
import com.winiwayuser.feature_expert_Search.SearchScreenComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import com.winiwayuser.feature_landing_page.persentation.LandingPageComponent
import com.winiwayuser.feature_winyway_wallet.persentation.all_coupons_page.AllCouponComponent
import com.winiwayuser.feature_winyway_wallet.persentation.razorpay_gateway_new.PaymentGatewayArguments
import com.winiwayuser.feature_winyway_wallet.persentation.razorpay_gateway_new.StartGatewayComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure.RechargeFailureComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayArg
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.ChoseRechargePackComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success.RechargeSuccessComponent
import kotlinx.serialization.Serializable

class DefaultRootComponent @OptIn(
    ExperimentalDecomposeApi::class, ExperimentalDecomposeApi::class
) constructor(
    componentContext: ComponentContext,
    deepLink: DeepLink.Web,
    webHistoryController: WebHistoryController? = null,
) : RootComponent, ComponentContext by componentContext {
//    private var selectedCouponCode: String? = null

    private val navigation = StackNavigation<Configuration>()

    @OptIn(ExperimentalDecomposeApi::class)
    private val _stack = childStack(
        source = navigation, serializer = Configuration.serializer(), initialStack = {
            getInitialStack(
                webHistoryPaths = webHistoryController?.historyPaths, deepLink = deepLink
            )
        }, childFactory = ::createChild, handleBackButton = true
    )

    override val stack: Value<ChildStack<*, RootComponent.Child>> = _stack

    init {
        // Attach the WebHistoryController to sync the browser's history with your Decompose navigation stack
        webHistoryController?.attach(navigator = navigation,
            serializer = Configuration.serializer(),
            stack = _stack,
            getPath = Companion::getPathForConfig,
            getConfiguration = Companion::getConfigForPath,
            onWebNavigation = { previousStack, currentStack ->
                try {
                    println("Web Navigation Triggered: previousStack size = ${previousStack.size}, currentStack size = ${currentStack.size}")

                    when {
                        previousStack.size > currentStack.size -> {
                            println("User navigated back in web history.")
                        }

                        previousStack.size < currentStack.size -> {
                            println("User navigated forward in web history.")
                        }

                        else -> {
                            println("User refreshed or navigated to the same page.")
                        }
                    }
                } catch (e: IndexOutOfBoundsException) {
                    println("Index out of bounds error during navigation: ${e.message}")
                } catch (e: NullPointerException) {
                    println("Null pointer error during navigation: ${e.message}")
                } catch (e: Exception) {
                    println("General error during navigation: ${e.message}")
                }

                // Return true to allow the navigation to proceed
                true
            })
    }

    private fun createChild(
        configuration: Configuration, context: ComponentContext
    ): RootComponent.Child {
        return when (configuration) {

            Configuration.CourseVideoPlayerConfig -> RootComponent.Child.CourseVideoPlayerChild(
                ReserveVideoComponent(context = context, onBackButtonClick = {
                    navigation.pop()
                }

                ))

            Configuration.MoreScreenTab -> RootComponent.Child.MoreScreenTab(MoreScreenTabComponent(context = context,
                onMoreScreenEvent = { moreScreenEvent ->
                    when (moreScreenEvent) {
                        MoreScreenEvent.onClickWalletBlance -> navigation.push(Configuration.ChoseRechargePage)
                        MoreScreenEvent.onPricayPolicyClick -> navigation.push(Configuration.PrivacyPolicyPage)
                        MoreScreenEvent.onTermAndConditionClick -> navigation.push(Configuration.TermsAndConditions)
                        MoreScreenEvent.onClickEditPrifile -> navigation.push(Configuration.EditProfileScreen)
                        MoreScreenEvent.onBackButtonClick -> navigation.pop()
                        MoreScreenEvent.onTransactionHistoryScreenClick -> navigation.pushNew(
                            Configuration.TransactionHistoryComponent
                        )

                        MoreScreenEvent.onSignOutButtonClick -> {
                            refreshPage()
                            navigation.popTo(0)
                        }

                        MoreScreenEvent.onWalletButtonClick -> navigation.pushNew(Configuration.ChoseRechargePage)
                        MoreScreenEvent.onChatHistoryClick -> navigation.pushNew(Configuration.ChatHistory)
                    }
                }))

            is Configuration.LoginPage -> RootComponent.Child.LoginPage(LoginPageComponent(context = context,
                navigateToOtpValidationPage = { firstString, secondString, isWhatsappUpdatesChecked ->
                    navigation.pushNew(
                        Configuration.OtpValidationPage(
                            firstString, secondString, isWhatsappUpdatesChecked
                        )
                    )
                },
                onPrivacyPolicyClick = {
                    navigation.pushNew(Configuration.TermsAndConditions)
                }))

            is Configuration.OtpValidationPage -> RootComponent.Child.OtpValidationPage(
                OtpValidationComponent(mobileNumber = configuration.mobileNumber,
                    mobileCode = configuration.mobileCode,
                    isWhatsappUpdatesChecked = false,
                    componentContext = context,
                    onRegisterEvent = {
                        navigation.pushNew(Configuration.RegistrationPage)
                    },
                    onDashboardEvent = {
                        navigation.replaceAll(Configuration.HomeScreenTab())
                    }))

            is Configuration.RegistrationPage -> RootComponent.Child.RegistrationPage(
                RegisterComponent(context = context, onRegistration = {
                    navigation.replaceAll(Configuration.HomeScreenTab())
                }))

            is Configuration.ExpertListPage -> RootComponent.Child.ExpertListPage(
                ExpertListPageComponent(context = context,
                    categoryId = configuration.categoryId,
                    categoryName = configuration.categoryName,
                    subCategoriesGiven = configuration.subCategory,
                    onExpertListPageEvent = { navigation.push(Configuration.ExpertDetailsPage(0)) },
                    onBackPressed = { navigation.pop() },
                    onSearchFieldClick = { navigation.push(Configuration.DisplaySearchExpertList) },
                    onClickExpertCard = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkills ->
                        navigation.pushNew(
                            Configuration.CallWaitingSuccessComponent(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills
                            )
                        )
                    },
                    onChatButtonClick = { id: String, name: String, image: String, skill: String, duration: String, category: String ->
                        navigation.pushNew(
                            Configuration.ChatConcernPage(
                                expertId = id,
                                expertName = name,
                                expertImage = image,
                                expertSkills = skill,
                                chatDuration = duration,
                                category = category
                            )
                        )
                    },
                    onLoginClick = { navigation.pushNew(Configuration.LoginPage) },
                    onRechargeRequired = { rechargeData ->
                        if (rechargeData == null) {
                            navigation.pushNew(Configuration.ChoseRechargePage)
                            return@ExpertListPageComponent
                        }
                        navigation.push(
                            Configuration.RechargeCheckout(
                                rechargeData = rechargeData
                            )
                        )
                    },
                    onVoIPCallConsultationStart = { duration, expertInfo ->
                        navigation.pushNew(
                            Configuration.VoIPCallConfig(expertInfo, duration)
                        )

                    },
                    onLowBalance = {
                        navigation.pushNew(Configuration.ChoseRechargePage)
                    }))


            is Configuration.ExpertDetailsPage -> RootComponent.Child.ExpertDetailsPage(
                ExpertDetailsPageComponent(context = context,
                    expertId = configuration.expertId,
                    onBackPressed = {
                        navigation.pop()
                    },
                    onCallButtonClick = { expertId, expertName, expertImage, expertSkills ->
                        navigation.pushNew(
                            Configuration.CallWaitingSuccessComponent(
                                expertId = expertId,
                                expertName = expertName,
                                expertImage = expertImage,
                                expertSkills = expertSkills
                            )
                        )
                    },
                    onLoginClick = { navigation.pushNew(Configuration.LoginPage) },
                    onClickRelatedExpert = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    },
                    onRechargeAgain = { rechargeData ->
                        if (rechargeData == null) {
                            navigation.pushNew(Configuration.ChoseRechargePage)
                            return@ExpertDetailsPageComponent
                        }
                        navigation.push(
                            Configuration.RechargeCheckout(
                                rechargeData = rechargeData
                            )
                        )

                    },
                    onChatStartClick = { id, name, image, skill, duration, category ->
                        navigation.push(
                            Configuration.ChatConcernPage(
                                expertId = id,
                                expertName = name,
                                expertImage = image,
                                expertSkills = skill,
                                chatDuration = duration,
                                category = category
                            )
                        )
                    },
                    onVoIPCallConsultationStart = { duration, expertInfo ->
                        navigation.pushNew(
                            Configuration.VoIPCallConfig(expertInfo, duration)
                        )
                    },
                    onLowBalance = { navigation.pushNew(Configuration.ChoseRechargePage) }))

            is Configuration.ChoseRechargePage -> RootComponent.Child.ChoseRechargePage(
                ChoseRechargePackComponent(context = context,
                    onBackPressed = { navigation.pop() },
                    navigateToRechargeCheckout = {
                        navigation.pushNew(Configuration.RechargeCheckout(it))
                    }))

            is Configuration.HomeScreenTab -> RootComponent.Child.HomeScreenTab(
                HomeScreenTabComponent(context = context, onHomePageEvent = { event ->
                    when (event) {
                        is HomePageEvent.OnClickTopConsultant -> {
                            navigation.push(Configuration.ExpertDetailsPage(event.expertCategory))
                        }

//                            is HomePageEvent.OnClickCategory -> {
//                                if (event.category.name == "Scientific Astrology") {
//                                    navigation.push(Configuration.CoursesConfig)
//                                    // Open another page specific for Scientific Astrology
////                                    navigation.push(
////                                        Configuration.LandingPage
//////                                        Configuration.lan(
//////                                            event.category.name,
//////                                            event.category.id.toString(),
//////                                            event.category.subcategories!!
//////                                        )
////                                    )
//                                } else {
//                                    // Open ExpertListPage for other categories
//                                    navigation.push(
//                                        Configuration.ExpertListPage(
//                                            event.category.name.toString(),
//                                            event.category.id.toString(),
//                                            event.category.subcategories!!
//                                        )
//                                    )
//                                }
//                            }

                        is HomePageEvent.OnClickCategory -> {
                            when (event.category.name) {
                                COURSES_LIST -> {
                                    // Navigate to the LandingPage specific for Scientific Astrology
                                    navigation.push(Configuration.CoursesConfig)
//                                    navigation.pushNew(Configuration.CourseVideoPlayerConfig)
                                }

                                CUSTOM_ORDER_LIST -> {
                                    // Navigate to the CustomOrderPage
                                    Logger.d { "Navigate to CustomOrderPage" }
                                    //navigation.push(Configuration.CustomOrderPage)
                                }

                                else -> {
                                    // Navigate to ExpertListPage for other categories
                                    navigation.push(
                                        Configuration.ExpertListPage(
                                            event.category.name.toString(),
                                            event.category.id.toString(),
                                            event.category.subcategories!!
                                        )
                                    )
                                }
                            }
                        }


                        is HomePageEvent.OnLiveSessionClick -> Unit
                        is HomePageEvent.OnSearchClick -> navigation.pushNew(Configuration.DisplaySearchExpertList)
                        is HomePageEvent.OnClickLogin -> navigation.push(Configuration.LoginPage)
                        HomePageEvent.OnClickProfileIcon -> navigation.push(Configuration.MoreScreenTab)
                        HomePageEvent.OnClickPrivacyPolicy -> navigation.push(Configuration.PrivacyPolicyPage)
                        HomePageEvent.OnClickTermsAndConditions -> navigation.push(Configuration.TermsAndConditions)
                        HomePageEvent.OnClickWalletIcon -> navigation.push(Configuration.ChoseRechargePage)
                        is HomePageEvent.OnChatConsultationStart -> navigation.push(
                            Configuration.ChatConcernPage(
                                expertId = event.consultantId.toString(),
                                expertName = event.consultantName,
                                expertImage = event.consultantImage,
                                expertSkills = event.consultantSkills,
                                chatDuration = event.chatDuration,
                                category = event.category
                            )
                        )

                        is HomePageEvent.OnCallConsultationStart -> navigation.push(
                            Configuration.CallWaitingSuccessComponent(
                                event.consultantId.toString(),
                                event.consultantName,
                                event.consultantImage,
                                event.consultantSkills
                            )
                        )

                        is HomePageEvent.OnRequiredRecharge -> {
                            if (event.rechargeData == null) {
                                navigation.pushNew(Configuration.ChoseRechargePage)
                                return@HomeScreenTabComponent
                            }
                            navigation.push(
                                Configuration.RechargeCheckout(
                                    rechargeData = event.rechargeData
                                )
                            )
                        }

                        HomePageEvent.onLandingPageClick -> navigation.pushNew(Configuration.CoursesConfig)
                        is HomePageEvent.OnVoIPCallConsultationStart -> navigation.pushNew(
                            Configuration.VoIPCallConfig(
                                expertInfo = event.expertInfo, callDuration = event.duration

                            )
                        )

                        is HomePageEvent.OnNavigateCallWaitingPage -> TODO()
                    }
                }))

            Configuration.MainScreen -> RootComponent.Child.MainScreen(MainScreenComponent(context = context) { event ->
//                    handleNavigationEvent(event)

            })

            Configuration.PrivacyPolicyPage -> RootComponent.Child.PrivacyPolicyPage(
                PrivacyPolicyComponent(context = context) { privacyPolicyEvent ->
                    when (privacyPolicyEvent) {
                        PrivacyPolicyEvent.OnBackKeyPress -> navigation.pop()
                    }

                })

            Configuration.TermsAndConditions -> RootComponent.Child.TermsAndConditions(
                TermsAndConditionsComponent(context = context) { termsAndConditionsEvent ->
                    when (termsAndConditionsEvent) {
                        TermsAndConditionsEvent.OnBackKeyPress -> navigation.pop()
                    }
                })

            Configuration.EditProfileScreen -> RootComponent.Child.EditProfileScreen(
                EditProfileComponent(context = context, onBackPress = {
                    navigation.pop()
                })
            )

            is Configuration.RechargeCheckout -> RootComponent.Child.RechargeCheckout(
                RechargeCheckoutComponent(
                    context = context,
                    _rechargeData = configuration.rechargeData,
                    onProceedToPayment = { paymentGatewayArg ->
                        navigation.replaceCurrent(
                            Configuration.PaymentGateway(
                                paymentGatewayArg, isCustomOrder = false
                            )
                        )
                        //navigation.replaceCurrent(Configuration.PaymentGateway(paymentGatewayArg,isCustomOrder = configuration.isCustomOrder))
                    },
                    onBackPressed = { navigation.pop() },
                    onShowAllCouponClick = {
                        navigation.push(Configuration.AllCouponCode)
                    },
                    context1 = context
                    //_selectedCouponCode = selectedCouponCode!!
                ), AllCouponComponent(
                    onBackPressed = { navigation.pop() }, context = context
                )
            )

            is Configuration.StartGatewayConfig -> RootComponent.Child.StartGatewayChild(
                StartGatewayComponent(context = context,
                    paymentGatewayArg = configuration.paymentGatewayArg,
                    onRechargeSuccess = { orderId, paymentId, signature ->
                        configuration.onRechargeSuccess(orderId, paymentId, signature)
                    },
                    onRechargeFailed = { msg ->
                        configuration.onRechargeFailed(msg)
                    }))


            is Configuration.PaymentGateway -> RootComponent.Child.PaymentGateway(

                PaymentGatewayComponent(context = context,
                    paymentGatewayArg = configuration.paymentGatewayArg,
                    onRechargeSuccess = { topUpAmount, customOrderId ->
                        if (topUpAmount.equals("CustomOrder", true) && !customOrderId.isEmpty()) {
                            //  navigation.replaceCurrent(Configuration.CustomOrderPaymentConfirm(customOrderId = customOrderId))
                        } else {
                            navigation.replaceCurrent(
                                Configuration.RechargeSuccess(
                                    topUpAmount
                                )
                            )
                        }
                    },
                    _isCustomOrder = configuration.isCustomOrder,
                    onRechargeFailed = { topUpAmount ->
                        Logger.d { "onRechargeFailed navigate" }
                        if (topUpAmount.equals("CustomOrder", true)) {
                            //navigation.replaceCurrent(Configuration.CustomOrderPaymentError)
                        } else {
                            navigation.replaceCurrent(Configuration.RechargeFailure(topUpAmount = topUpAmount))
                        }


                    }))

            is Configuration.RechargeFailure -> {
                RootComponent.Child.RechargeFailure(RechargeFailureComponent(context = context,
                    _topUpAmount = configuration.topUpAmount,
                    rechargeAgain = {
                        navigation.pop()
                    },
                    getSupport = {
                        navigation.popToFirst()
                    }))
            }

            is Configuration.RechargeSuccess -> {
                RootComponent.Child.RechargeSuccess(RechargeSuccessComponent(context = context,
                    _topUpAmount = configuration.topUpAmount,
                    onDone = {
                        navigation.popToFirst()
                    },
                    rechargeMore = {
                        navigation.pop()
                    }))
            }

            Configuration.DisplaySearchExpertList -> {
                RootComponent.Child.DisplaySearchExpertList(component = SearchScreenComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    onClickExpertCard = { expertId ->
                        navigation.pushNew(
                            Configuration.ExpertDetailsPage(
                                expertId
                            )
                        )
                    }))
            }

            is Configuration.ChatConcernPage -> {
                RootComponent.Child.ChatHostChild(
                    HostDefaultComponent(
                        expertId = configuration.expertId,
                        expertName = configuration.expertName,
                        expertImage = configuration.expertImage,
                        expertSkills = configuration.expertSkills,
                        chatDuration = configuration.chatDuration,
                        componentContext = context,
                        onClose = { navigation.pop() },
                        category = configuration.category
                    )
                )
            }

            is Configuration.CallPlaceSuccessval -> RootComponent.Child.CallPlaceSuccess(component = CallSuccessComponent(
                context = context,
                _expertId = configuration.expertId,
                _expertName = configuration.expertName,
                _expertSkills = configuration.expertSkills,
                _expertImage = configuration.expertImage,
                onClosePage = {
                    navigation.popTo(0)
                },
                onTimeOutOver = {
                    navigation.replaceAll(Configuration.HomeScreenTab())
                })

            )

            is Configuration.CallWaitingSuccessComponent -> RootComponent.Child.CallWaitingSuccess(
                CallSuccessComponent(context = context,
                    _expertId = configuration.expertId,
                    _expertName = configuration.expertName,
                    _expertImage = configuration.expertImage,
                    _expertSkills = configuration.expertSkills,
                    onClosePage = { navigation.popTo(0) },
                    onTimeOutOver = { navigation.replaceAll(Configuration.HomeScreenTab()) }))

            Configuration.LandingPage -> RootComponent.Child.LandingPage(LandingPageComponent(
                context = context,
                onBackButtonClick = { navigation.pop() },
            ), HomeScreenTabComponent(context = context, onHomePageEvent = { event ->
                when (event) {
                    is HomePageEvent.OnClickTopConsultant -> {
                        navigation.push(Configuration.ExpertDetailsPage(event.expertCategory))
                    }

                    is HomePageEvent.OnClickCategory -> {
                        navigation.push(
                            Configuration.ExpertListPage(
                                event.category.name.toString(),
                                event.category.id.toString(),
                                event.category.subcategories!!
                            )
                        )
                    }

                    is HomePageEvent.OnLiveSessionClick -> Unit
                    is HomePageEvent.OnSearchClick -> navigation.pushNew(Configuration.DisplaySearchExpertList)
                    is HomePageEvent.OnClickLogin -> navigation.push(Configuration.LoginPage)
                    HomePageEvent.OnClickProfileIcon -> navigation.push(Configuration.MoreScreenTab)
                    HomePageEvent.OnClickPrivacyPolicy -> navigation.push(Configuration.PrivacyPolicyPage)
                    HomePageEvent.OnClickTermsAndConditions -> navigation.push(Configuration.TermsAndConditions)
                    HomePageEvent.OnClickWalletIcon -> navigation.push(Configuration.ChoseRechargePage)
                    is HomePageEvent.OnChatConsultationStart -> navigation.push(
                        Configuration.ChatConcernPage(
                            expertId = event.consultantId.toString(),
                            expertName = event.consultantName,
                            expertImage = event.consultantImage,
                            expertSkills = event.consultantSkills,
                            chatDuration = event.chatDuration,
                            category = event.category
                        )
                    )

                    is HomePageEvent.OnCallConsultationStart -> navigation.pushNew(
                        Configuration.CallWaitingSuccessComponent(
                            event.consultantId.toString(),
                            event.consultantName,
                            event.consultantImage,
                            event.consultantSkills
                        )
                    )

                    is HomePageEvent.OnRequiredRecharge -> {
                        navigation.push(
                            Configuration.RechargeCheckout(
                                rechargeData = event.rechargeData
                            )
                        )
                    }

                    HomePageEvent.onLandingPageClick -> navigation.pushNew(Configuration.LandingPage)
                    is HomePageEvent.OnVoIPCallConsultationStart -> navigation.pushNew(
                        Configuration.VoIPCallConfig(
                            expertInfo = event.expertInfo, callDuration = event.duration
                        )
                    )

                    is HomePageEvent.OnNavigateCallWaitingPage -> navigation.pushNew(
                        Configuration.CallPlaceSuccessval(
                            expertId = "",
                            expertName = "",
                            expertImage = "",
                            expertSkills = ""
                        )
                    )
                }
            }))

            is Configuration.TransactionHistoryComponent -> RootComponent.Child.TransactionHistory(
                TransactionHistoryComponent(context = context,
                    onBackButtonClick = { navigation.pop() }))

            is Configuration.VoIPCallConfig -> RootComponent.Child.VoIPCall(VoipCallComponent(
                context = context,
                expertInfo = configuration.expertInfo,
                _callDuration = configuration.callDuration,
                onClose = {
                    navigation.pop()
                }))

            is Configuration.AllCouponCode -> RootComponent.Child.DisplayAllCouponCode(
                AllCouponComponent(context = context, onBackPressed = {
                    // Logger.d { "on AllCouponComponent pop click $sectedCouponCode" }
                    ///selectedCouponCode = sectedCouponCode
                    navigation.pop()
                }))

            is Configuration.CoursesConfig -> RootComponent.Child.Courses(CoursesComponent(context = context,
                onBackButtonClick = {
                    navigation.pop()
                },
                onRegisterButtonClick = { courseData ->
                    navigation.pushNew(
                        Configuration.CourseConfig(
                            slug = courseData
                        )
                    )
                })
            )

            is Configuration.CourseConfig -> RootComponent.Child.Course(
                CourseComponent(
                    context = context, slug = configuration.slug
                )
            )


            is Configuration.ChatHistory -> RootComponent.Child.ChatHistory(
                ChatHistoryComponent(context = context,
                    onBackPress = { navigation.pop() },
                    onChatCardClick = { chatId, name, image, duration ->
                        navigation.pushNew(
                            Configuration.ChatConversationScreen(
                                chatId, name, image, duration
                            )
                        )
                    }), ExpertDetailsPageComponent(context = context,
                    expertId = 0,
                    onBackPressed = {},
                    onCallButtonClick = { s: String, s1: String, s2: String, s3: String -> },
                    onLoginClick = {},
                    onClickRelatedExpert = {},
                    onChatStartClick = { s: String, s1: String, s2: String, s3: String, s4: String, s5: String -> },
                    onRechargeAgain = {},
                    onVoIPCallConsultationStart = { s: String, commonExpertInfo: CommonExpertInfo -> },
                    onLowBalance = { navigation.pushNew(Configuration.ChoseRechargePage) })
            )

            is Configuration.ChatConversationScreen -> RootComponent.Child.ChatConversationScreen(
                ChatConversationComponent(
                    context = context,
                    onBackPress = { navigation.pop() },
                    _chatId = configuration.chatId,
                    _expertName = configuration.name,
                    _expertImage = configuration.image,
                    _chatDuration = configuration.duration
                )
            )
        }
    }

    override fun onBackClicked() {
        navigation.pop()
    }

    override fun onBackClicked(toIndex: Int) {
        navigation.popTo(index = toIndex)
    }

    private companion object {


        private fun getInitialStack(
            webHistoryPaths: List<String>?, deepLink: DeepLink
        ): List<Configuration> {
            // Convert web history paths to configurations if available
            val historyConfigs =
                webHistoryPaths?.filterNot { it.isEmpty() } // Filter out any empty paths
                    ?.map(Companion::getConfigForPath) // Only map to non-null configurations
                    ?.distinct() // Ensure no duplicate configurations
                    ?: emptyList()

            // If historyConfigs is not empty, return it; otherwise, fallback to deep link logic
            return historyConfigs.ifEmpty {
                getInitialStack(deepLink)
            }
        }


        private fun getInitialStack(deepLink: DeepLink): List<Configuration> {
            return when (deepLink) {
                is DeepLink.None -> listOf(Configuration.HomeScreenTab()) // Default route
                is DeepLink.Web -> {
                    Logger.d("Deeplin web -> path -> ${deepLink.path}")
                    listOfNotNull(
                        Configuration.HomeScreenTab(),
                        getConfigForPath(deepLink.path) // Ensure this returns a non-null configuration
                    ).distinct()
                }
            }
        }

        private const val LOGIN_PAGE = "login"
        private const val OTP_VALIDATION_PAGE = "validate-otp"
        private const val REGISTRATION_PAGE = "registration"

        //private const val EXPERT_LIST_PAGE = "expert-list"
        private const val EXPERT_LIST_PAGE = "fetch-consultants"
        private const val EXPERT_DETAILS_PAGE = "expert-details"
        private const val CHOOSE_RECHARGE_PAGE = "choose-recharge"
        private const val RECHARGE_CHECKOUT_PAGE = "recharge-checkout"
        private const val MORE_SCREEN_TAB = "more"

        //private const val HOME_SCREEN_TAB = "home"
        private const val HOME_SCREEN_TAB = ""
        private const val MAIN_SCREEN = "main"
        private const val PRIVACY_POLICY_PAGE = "privacy-policy"
        private const val TERMS_AND_CONDITIONS_PAGE = "terms-and-conditions"
        private const val EDIT_PROFILE_SCREEN = "edit-profile"
        private const val PAYMENT_GATEWAY_PAGE = "payment-gateway"
        private const val RECHARGE_FAILURE_PAGE = "recharge-failure"
        private const val RECHARGE_SUCCESS_PAGE = "recharge-success"
        private const val START_GATEWAY_PAGE = "payment-gateway"
        private const val CHAT_CONSULTATION_INFO_PAGE = "chat-consultation-info"
        private const val DISPLAY_SEARCH_EXPERT_LIST_PAGE = "display-search-expert-list"
        private const val CHAT_CONCERN_PAGE = "chat-concern"
        private const val CALL_CONSULTATION_INFO_PAGE = "call-consultation-info"
        private const val CALL_CONSULTATION_OPTION = "call-consultation-options"
        private const val CALL_PLACE_SUCCESS = "call-place-success"
        private const val CALL_WAITING_SUCCESS_COMPONENT = "call-waiting-success"
        private const val LANDING_PAGE_COMPONENT = "astrology_course"
        private const val TRANSACTION_HISTORY = "transaction_history"
        private const val VOIP_CALL_CONSULTAION = "voip_call_consultation"
        private const val ALL_COUPON_CODE = "all_coupon_code"
        private const val CHAT_HISTORY = "chat_history"
        private const val CHAT_CONVERSATION_SCREEN = "chat_conversation_screen"
        private const val COURSES = "courses"
        private const val COURSE_PLAYER = "reservation"

        private fun getPathForConfig(config: Configuration): String {
            Logger.d("get path For Config -. $config")
            return when (config) {
                // Authentication
                is Configuration.LoginPage -> "/$LOGIN_PAGE"
                is Configuration.OtpValidationPage -> "/$OTP_VALIDATION_PAGE"
                is Configuration.RegistrationPage -> "/$REGISTRATION_PAGE"

                // Expert related pages
                is Configuration.ExpertListPage -> "/$EXPERT_LIST_PAGE?category=${config.categoryName}&cat_source=${config.categoryId}"
                is Configuration.ExpertDetailsPage -> "/$EXPERT_DETAILS_PAGE?expert=${config.expertId}"

                // Recharge related pages
                is Configuration.ChoseRechargePage -> "/$CHOOSE_RECHARGE_PAGE"
                is Configuration.RechargeCheckout -> "/$RECHARGE_CHECKOUT_PAGE?amount=${config.rechargeData?.amount}&amount_source=${config.rechargeData?.id}&plan=${config.rechargeData?.planName}"// val amount = queryParams?.get("amount") ?: ""
                is Configuration.RechargeFailure -> "/$RECHARGE_FAILURE_PAGE?amount=${config.topUpAmount}"
                is Configuration.RechargeSuccess -> "/$RECHARGE_SUCCESS_PAGE?amount=${config.topUpAmount}"

                // Tabs and main screens
                is Configuration.MoreScreenTab -> "/$MORE_SCREEN_TAB"
                is Configuration.HomeScreenTab -> "/$HOME_SCREEN_TAB"
                is Configuration.MainScreen -> "/$MAIN_SCREEN"

                // Legal pages
                is Configuration.PrivacyPolicyPage -> "/$PRIVACY_POLICY_PAGE"
                is Configuration.TermsAndConditions -> "/$TERMS_AND_CONDITIONS_PAGE"

                // Profile related pages
                is Configuration.EditProfileScreen -> "/$EDIT_PROFILE_SCREEN"

                // Payment
                is Configuration.PaymentGateway -> "/$PAYMENT_GATEWAY_PAGE"

                // Chat consultation feature
//                is Configuration.ChatConsultationInfo -> "/$CHAT_CONSULTATION_INFO_PAGE?expert=${config.expertId}"
                is Configuration.DisplaySearchExpertList -> "/$DISPLAY_SEARCH_EXPERT_LIST_PAGE"
                is Configuration.ChatConcernPage -> "/$CHAT_CONCERN_PAGE?expert=${config.expertId}"

                // Call consultation feature
//                is Configuration.CallConsultationInfo -> "/$CALL_CONSULTATION_INFO_PAGE?expert=${config.expertId}"
//                is Configuration.CallConsultationOptions -> "/$CALL_CONSULTATION_OPTION?expert=${config.expertId}"
                is Configuration.CallPlaceSuccessval -> "/$CALL_PLACE_SUCCESS?expert_source=${config.expertId}&expert=${config.expertName}&image=${config.expertImage}&skills=${config.expertSkills}"
                is Configuration.CallWaitingSuccessComponent -> "/$CALL_WAITING_SUCCESS_COMPONENT?expert_source=${config.expertId}&expert=${config.expertName}&image=${config.expertImage}&skills=${config.expertSkills}"

                is Configuration.LandingPage -> "/$LANDING_PAGE_COMPONENT"
                //TransactionHistory
                is Configuration.TransactionHistoryComponent -> "/$TRANSACTION_HISTORY"
                is Configuration.VoIPCallConfig -> "/$VOIP_CALL_CONSULTAION"
                is Configuration.AllCouponCode -> "/$ALL_COUPON_CODE"
                is Configuration.ChatHistory -> "/$CHAT_HISTORY"
                is Configuration.ChatConversationScreen -> "/$CHAT_CONVERSATION_SCREEN"
                is Configuration.StartGatewayConfig -> "/$START_GATEWAY_PAGE"
                is Configuration.CoursesConfig -> "/$COURSES"
                is Configuration.CourseConfig -> "/$COURSES/${config.slug}"
                is Configuration.CourseVideoPlayerConfig -> "/$COURSE_PLAYER"

            }
        }

        fun parseQueryParameters(query: String?): Map<String, String>? {
            return query?.split("&")?.mapNotNull { param ->
                    val keyValuePair = param.split("=")
                    if (keyValuePair.size == 2) {
                        val key = keyValuePair[0].trim()
                        val value = keyValuePair[1].trim()
                        if (key.isNotEmpty()) key to value else null
                    } else {
                        null
                    }
                }?.toMap()
        }


        private fun getConfigForPath(path: String): Configuration {
            Logger.d("get Config For Path -> $path")

            val pathWithoutPrefix = path.removePrefix("/")
            val parts = pathWithoutPrefix.split("?")
            val route = parts[0]  // The path part
            val query = parts.getOrNull(1)  // The query string part
            val pathSegments = pathWithoutPrefix.split("/")

            // Parse query parameters
            val queryParams = parseQueryParameters(query)
            if (pathSegments.firstOrNull() == COURSES) {
                val slug = pathSegments.getOrNull(1)
                return if (slug.isNullOrBlank()) Configuration.CoursesConfig
                else Configuration.CourseConfig(slug)
            }
            return when (route) {
                // Authentication
                COURSES -> Configuration.CoursesConfig
                LOGIN_PAGE -> Configuration.LoginPage
                OTP_VALIDATION_PAGE -> {
                    Configuration.LoginPage
//                    Configuration.OtpValidationPage(
//                        mobileNumber = "",  // Add default or dummy values if needed
//                        mobileCode = "",
//                        isWhatsappUpdatesChecked = false
//                    )
                }

                REGISTRATION_PAGE -> Configuration.RegistrationPage

                // Expert related pages
                EXPERT_LIST_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val categoryName = queryParams?.get("category") ?: ""
                    val categoryId = queryParams?.get("cat_source") ?: ""
                    Configuration.ExpertListPage(
                        categoryName = categoryName,  // Add default or dummy values if needed
                        categoryId = categoryId, subCategory = null
                    )
                }

                EXPERT_DETAILS_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(
                        expertId = expertId // Add default or dummy values if needed
                    )
                }

                // Recharge related pages
                CHOOSE_RECHARGE_PAGE -> Configuration.ChoseRechargePage
                RECHARGE_CHECKOUT_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val amount = queryParams?.get("amount") ?: ""
                    val id = queryParams?.get("amount_source") ?: ""
                    val planName = queryParams?.get("plan") ?: ""
                    val pathSegments = pathWithoutPrefix.split("/")

//                    pathSegments.firstOrNull() == COURSES -> {
//                        val slug = pathSegments.getOrNull(1)
//                        Logger.d("Slug -> $slug")
//                        if (slug.isNullOrBlank()) {
//                            Configuration.CoursesConfig
//                        } else {
//                            Configuration.CourseConfig(slug = slug)
//                        }
//                    }

                    Configuration.RechargeCheckout(
                        rechargeData = RechargeData(
                            amount = amount.toIntOrNull() ?: 0,
                            id = id.toIntOrNull() ?: 0,
                            planName = planName,
                            cupon = null,
                        )  // Add default or dummy values if needed
                    )
                }

                RECHARGE_FAILURE_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val amount = queryParams?.get("amount") ?: ""
                    Configuration.RechargeFailure(
                        topUpAmount = amount  // Add default or dummy values if needed
                    )
                }

                RECHARGE_SUCCESS_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val amount = queryParams?.get("amount") ?: ""
                    Configuration.RechargeSuccess(
                        topUpAmount = amount  // Add default or dummy values if needed
                    )
                }

                // Tabs and main screens
                MORE_SCREEN_TAB -> Configuration.MoreScreenTab
                HOME_SCREEN_TAB -> Configuration.HomeScreenTab()
                MAIN_SCREEN -> Configuration.MainScreen

                START_GATEWAY_PAGE -> Configuration.CoursesConfig
                // Legal pages
                PRIVACY_POLICY_PAGE -> Configuration.PrivacyPolicyPage
                TERMS_AND_CONDITIONS_PAGE -> Configuration.TermsAndConditions

                // Profile related pages
                EDIT_PROFILE_SCREEN -> Configuration.EditProfileScreen

                // Payment
                PAYMENT_GATEWAY_PAGE -> Configuration.ChoseRechargePage
//                    Configuration.PaymentGateway(
//                    paymentGatewayArg = PaymentGatewayArg(
//                        txnId = "",
//                        coinUsed = 0,
//                        orderId = "",
//                        cashPayment = ""
//                    )  // Add default or dummy values if needed
//                )

                // Chat consultation feature
                CHAT_CONSULTATION_INFO_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(
                        expertId = expertId // Add default or dummy values if needed
                    )
                }

                DISPLAY_SEARCH_EXPERT_LIST_PAGE -> Configuration.DisplaySearchExpertList
                CHAT_CONCERN_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(
                        expertId = expertId // Add default or dummy values if needed
                    )
                }

                // Call consultation feature
                CALL_CONSULTATION_INFO_PAGE -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(
                        expertId = expertId // Add default or dummy values if needed
                    )
                }

                CALL_CONSULTATION_OPTION -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert")?.toIntOrNull() ?: 0
                    Configuration.ExpertDetailsPage(
                        expertId = expertId // Add default or dummy values if needed
                    )
                }

                CALL_PLACE_SUCCESS -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert_source")
                    val expertName = queryParams?.get("expert")
                    val expertImage = queryParams?.get("image")
                    val expertSkills = queryParams?.get("skills")
                    Configuration.CallPlaceSuccessval(
                        expertId = expertId ?: "",  // Add default or dummy values if needed
                        expertName = expertName ?: "",
                        expertImage = expertImage ?: "",
                        expertSkills = expertSkills ?: ""
                    )
                }

                CALL_WAITING_SUCCESS_COMPONENT -> {
                    val queryParams = parseQueryParameters(query)
                    val expertId = queryParams?.get("expert_source")
                    val expertName = queryParams?.get("expert")
                    val expertImage = queryParams?.get("image")
                    val expertSkills = queryParams?.get("skills")
                    Configuration.CallWaitingSuccessComponent(
                        expertId = expertId ?: "",  // Add default or dummy values if needed
                        expertName = expertName ?: "",
                        expertImage = expertImage ?: "",
                        expertSkills = expertSkills ?: ""
                    )
                }

                LANDING_PAGE_COMPONENT -> Configuration.LandingPage

                TRANSACTION_HISTORY -> Configuration.TransactionHistoryComponent

                ALL_COUPON_CODE -> Configuration.AllCouponCode

                CHAT_HISTORY -> Configuration.ChoseRechargePage

                "home" -> Configuration.LoginPage


                // Default case (if no path matches)
                else -> {
                    Logger.d { "in else part $path" }
                    Configuration.HomeScreenTab()
                }
            }
        }

    }


    @Serializable
    sealed class Configuration {
        @Serializable
        data object LoginPage : Configuration()

        @Serializable
        data class OtpValidationPage(
            val mobileNumber: String, val mobileCode: String, val isWhatsappUpdatesChecked: Boolean
        ) : Configuration()

        @Serializable
        data object RegistrationPage : Configuration()

        @Serializable
        data class ExpertListPage(
            val categoryName: String,
            val categoryId: String,
            val subCategory: List<SubCategoriesModel>?
        ) : Configuration()

        @Serializable
        data class ExpertDetailsPage(val expertId: Int) : Configuration()

        @Serializable
        data object ChoseRechargePage : Configuration()

        @Serializable
        data class RechargeCheckout(val rechargeData: RechargeData?) : Configuration()

        @Serializable
        data object MoreScreenTab : Configuration()

        @Serializable
        data class HomeScreenTab(val identity: String = generateRandomString()) : Configuration()

        @Serializable
        data object MainScreen : Configuration()

        @Serializable
        data object CoursesConfig : Configuration()

        @Serializable
        data class CourseConfig(val slug: String) : Configuration()

        @Serializable
        data object PrivacyPolicyPage : Configuration()

        @Serializable
        data object TermsAndConditions : Configuration()

        @Serializable
        data object EditProfileScreen : Configuration()

        @Serializable
        data class PaymentGateway(
            val paymentGatewayArg: PaymentGatewayArg,
            val isCustomOrder: Boolean = false,
            val txnId: String = "",
            val cusOrderId: String = "",
        ) : Configuration()

        @Serializable
        data class StartGatewayConfig(
            val paymentGatewayArg: PaymentGatewayArguments,
            val onRechargeSuccess: (orderId: String, paymentId: String, signature: String) -> Unit,
            val onRechargeFailed: (message: String) -> Unit
        ) : Configuration()

        @Serializable
        data class RechargeFailure(val topUpAmount: String) : Configuration()

        @Serializable
        data class RechargeSuccess(val topUpAmount: String) : Configuration()

        @Serializable
        data object DisplaySearchExpertList : Configuration()

        @Serializable
        data class ChatConcernPage(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String,
            val chatDuration: String,
            val category: String,
        ) : Configuration()

        @Serializable
        data class CallPlaceSuccessval(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data class CallWaitingSuccessComponent(
            val expertId: String,
            val expertName: String,
            val expertImage: String,
            val expertSkills: String
        ) : Configuration()

        @Serializable
        data object LandingPage : Configuration()

        @Serializable
        data object TransactionHistoryComponent : Configuration()

        /*VoIP Call*/
        @Serializable
        data class VoIPCallConfig(
            val expertInfo: CommonExpertInfo,
            val callDuration: String,
        ) : Configuration()

        //All Coupon
        @Serializable
        data object AllCouponCode : Configuration()

        //Chat History
        @Serializable
        data object ChatHistory : Configuration()

        @Serializable
        data object CourseVideoPlayerConfig : Configuration()

        //Chat Conversation Screen
        @Serializable
        data class ChatConversationScreen(
            val chatId: String, val name: String, val image: String, val duration: String
        ) : Configuration()
    }

    sealed interface DeepLink {
        data object None : DeepLink
        class Web(val path: String) : DeepLink
    }
}

