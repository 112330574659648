package com.winiwayuser.feature_expert_consultation.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SearchExpertRequest(
    @SerialName("type") val type:String?,
   // @SerialName("category_id") val categoryId:String?,
    @SerialName("search") val searchQuery:String?,
)

