package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowForward
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowLeft
import androidx.compose.material.icons.automirrored.filled.Login
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material.icons.filled.GetApp
import androidx.compose.material.icons.filled.KeyboardArrowLeft
import androidx.compose.material.icons.filled.Wallet
import androidx.compose.material.icons.filled.Whatsapp
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.persentation.SampleDialog
import com.winiwayuser.core.persentation.openPlayStore
import com.winiwayuser.core.persentation.openWhatsApp
import com.winiwayuser.core.platform.isJS
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.ACTION_BAR_BUTTON_SIZE
import com.winiwayuser.core.theme.Scale.CORNER_SMALL
import com.winiwayuser.core.theme.Scale.GLOBAL_ICON_SIZE
import com.winiwayuser.core.theme.Scale.WINYWAY_LOGO_SIZE
import com.winiwayuser.core.theme.Scale.isBrowserType
import com.winiwayuser.core.theme.initializeCurrency
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.core.theme.md_theme_light_primary
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.theme.user_app_play_store_url
import com.winiwayuser.feature_common.persentation.home_tab.HomePageEvent
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_notifications_24
import com.winiwayuser.resources.google_play
import com.winiwayuser.resources.ic_wallet
import com.winiwayuser.resources.icon_logo
import com.winiwayuser.resources.person_small_icon
import com.winiwayuser.resources.profile_icon_avtar
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import kotlin.math.roundToInt

//@Composable
//fun ToolBar(
//    onClickLogin: () -> Unit,
//    onClickProfile: () -> Unit,
//    onClickWallet: () -> Unit,
//    component: HomeScreenTabComponent
//) {
//    val isLoggedIn = remember {
//        UserInfoProvider.getIsLogin()
//    }
//
//    val walletMoney = component.walletState.collectAsState()
//
//    Box(
//        contentAlignment = Alignment.Center,
//        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(horizontal = Scale.HORIZONTAL_PADDING)
//
//    ) {
//        Column(
//            horizontalAlignment = Alignment.CenterHorizontally,
//            verticalArrangement = Arrangement.Center,
//            modifier = Modifier.fillMaxWidth()
//        ) {
//
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth(),
//                horizontalArrangement = Arrangement.SpaceBetween,
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                // "Winyway" Title with Logo
//                Box(
//                    //modifier = Modifier.size(30.dp)
//                    modifier = Modifier.size(WINYWAY_LOGO_SIZE)
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.icon_logo),
//                        contentDescription = "Logo",
//                        contentScale = ContentScale.Crop,
//                        modifier = Modifier.size(WINYWAY_LOGO_SIZE)
////                        modifier = Modifier.size(30.dp)
//                    )
//                }
//
//                Text(
//                    text = "Winyway",
//                    modifier = Modifier
//                        .weight(1f)
//                        .padding(start = 6.dp),
//                    style = MaterialTheme.typography.titleMedium,
//                    maxLines = 1,
//                )
//
//                Row(
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    if (isLoggedIn) {
//                        Spacer(modifier = Modifier.width(10.dp))
//
//                        // Wallet Icon Section
//                        Row(
//                            horizontalArrangement = Arrangement.Start,
//                            verticalAlignment = Alignment.CenterVertically,
//                            modifier = Modifier
//                                .height(ACTION_BAR_BUTTON_SIZE)
//                                .clip(RoundedCornerShape(CORNER_SMALL))
//                                .background(Color.White)
//                                .clickable { onClickWallet.invoke() }
//                                .padding(horizontal = 10.dp, vertical = 4.dp)
//                        ) {
//                            Icon(
//                                painter = painterResource(Res.drawable.ic_wallet), // Replace with actual icon resource
//                                contentDescription = "Wallet Icon",
//                                tint = primaryDark,
//                                modifier = Modifier
//                                    .size(Scale.GLOBAL_ICON_SIZE)
//
//                            )
//
//                            Spacer(modifier = Modifier.width(8.dp))
//
//                            Text(
//                                text = "${initializeCurrency()} ${walletMoney.value.toString()}",
//                                maxLines = 1,
//                                fontWeight = FontWeight.SemiBold,
//                                style = MaterialTheme.typography.bodySmall
//                            )
//                        }
//
//                        Spacer(modifier = Modifier.width(10.dp))
//
//                        // Notification section
//                        Box(
//                            modifier = Modifier
//                                .size(ACTION_BAR_BUTTON_SIZE)
//                                .clip(RoundedCornerShape(CORNER_SMALL))
//                                .background(Color.White)
//                                .padding(8.dp)
//                        ) {
//                            Icon(
//                                painter = painterResource(Res.drawable.baseline_notifications_24), // Replace with actual icon resource
//                                contentDescription = "Notifications Icon",
//                                tint = md_them_primary,
//                                modifier = Modifier.align(Alignment.Center).size(GLOBAL_ICON_SIZE)
//                            )
//                            if (/* Notification count condition */ true) {
//                                Box(
//                                    modifier = Modifier
//                                        .size(10.dp)
//                                        .clip(RoundedCornerShape(100))
//                                        .background(MaterialTheme.colorScheme.error)
//                                        .align(Alignment.TopEnd)
//                                        .offset(x = 4.dp, y = (-4).dp),
//                                    contentAlignment = Alignment.Center
//                                ) {
//                                    Text(
//                                        text = "0", // Replace with actual notification count
//                                        color = Color.White,
//                                        fontSize = 10.sp,
//                                        fontWeight = FontWeight.Bold,
//                                    )
//                                }
//                            }
//                        }
//
//                        Spacer(modifier = Modifier.width(10.dp))
//
//                        // Profile Icon
//                        Card(
//                            elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
//                            modifier = Modifier
//                                .size(ACTION_BAR_BUTTON_SIZE)
//                                .clip(RoundedCornerShape(CORNER_SMALL))
//                                .background(Color.White)
//                                .clickable { onClickProfile.invoke() }
//                        ) {
//                            Image(
//                                painter = painterResource(Res.drawable.profile_icon_avtar), // Replace with actual icon resource
//                                contentDescription = "Profile Icon",
//                                modifier = Modifier.fillMaxSize(),
//                                contentScale = ContentScale.Crop,
//                            )
//                        }
//                    } else {
//                        TextWithImageAsButton(
//                            onClick = { onClickLogin.invoke() },
//                            text = "Login/Register",
//                            imageVector = painterResource(Res.drawable.person_small_icon)
//                        )
//                    }
//                }
//            }
//        }
//
//        // Draggable state for floating section
//        val offsetX = remember { mutableStateOf(if (isBrowserType) 200f else 100f) }
//        val offsetY = remember { mutableStateOf(1f) }
//
//        // Floating Open App section
//        if (isBrowserType && !getIsLogin()) {
////            InstallAppButton()
//            BoxWithConstraints(
//                modifier = Modifier
//                    .wrapContentSize() // Ensure it wraps around its content size
//                    .padding(8.dp) // Increased padding for better placement
//                    .offset {
//
//                        IntOffset(offsetX.value.roundToInt(), offsetY.value.roundToInt())
//                    }
//                    .pointerInput(Unit) {
//                        detectDragGestures { change, dragAmount ->
//                            change.consume()
//                            offsetX.value += dragAmount.x
//                            offsetY.value += dragAmount.y
//                        }
//                    },
////                    .background(Color.White, shape = RoundedCornerShape(16.dp)),
//                //.border(BorderStroke(2.dp, Color.Gray), shape = RoundedCornerShape(16.dp)),
//                contentAlignment = Alignment.Center
//            ) {
//                // Animated visibility to attract user attention
//                val infiniteTransition = rememberInfiniteTransition()
//                val scale by infiniteTransition.animateFloat(
//                    initialValue = 0.9f,
//                    targetValue = 1f,
//                    animationSpec = infiniteRepeatable(
//                        animation = tween(durationMillis = 800, easing = LinearEasing),
//                        repeatMode = RepeatMode.Reverse
//                    )
//                )
//
//                Button(
//                    onClick = { openPlayStore(user_app_play_store_url) },
//                    colors = ButtonDefaults.buttonColors(containerColor = primaryDark),
//                    contentPadding = PaddingValues(horizontal = 12.dp, vertical = 4.dp),
//                    shape = RoundedCornerShape(CORNER_SMALL),
//                    modifier = Modifier
//                        .scale(scale)
//                        .wrapContentSize() // Ensure the button size is wrapped correctly
//                ) {
//                    Image(
//                        contentDescription = "plase Store",
//                        painter = painterResource(Res.drawable.google_play),
//                        contentScale = ContentScale.Fit,
//                        modifier = Modifier.wrapContentHeight().wrapContentWidth()
//                        )
////                    Text(
////                        text = "OPEN IN APP",
////                        color = Color.White,
////                        style = MaterialTheme.typography.bodySmall
////                    )
//                }
//            }
//        }
//
//
//    }
//}
//
//fun TextWithImageAsButton(onClick: () -> Unit, imageVector: Painter) {
//
//}
//
//@Composable
//fun InstallAppButton() {
//    BoxWithConstraints(
//        modifier = Modifier
//            .fillMaxSize()
//            .padding(8.dp)
//    ) {
//        val containerWidth = constraints.maxWidth
//        val containerHeight = constraints.maxHeight
//
//        // Calculate initial offset for centering horizontally and positioning at the bottom
//        val initialOffsetX = (containerWidth / 2).toFloat()
//        val initialOffsetY =
//            (containerHeight - 50.dp.toPx()).toFloat() // Assuming button height is 50.dp
//
//        val offsetX = remember { mutableStateOf(initialOffsetX) }
//        val offsetY = remember { mutableStateOf(initialOffsetY) }
//
//        // Draggable button box
//        Box(
//            modifier = Modifier
//                .offset { IntOffset(offsetX.value.roundToInt(), offsetY.value.roundToInt()) }
//                .pointerInput(Unit) {
//                    detectDragGestures { change, dragAmount ->
//                        change.consume()
//                        offsetX.value += dragAmount.x
//                        offsetY.value += dragAmount.y
//                    }
//                }
//                .wrapContentSize(),
//            contentAlignment = Alignment.Center
//        ) {
//            // Animated scale transition
//            val infiniteTransition = rememberInfiniteTransition()
//            val scale by infiniteTransition.animateFloat(
//                initialValue = 0.9f,
//                targetValue = 1f,
//                animationSpec = infiniteRepeatable(
//                    animation = tween(durationMillis = 800, easing = LinearEasing),
//                    repeatMode = RepeatMode.Reverse
//                )
//            )
//
//            Button(
//                onClick = { openPlayStore(user_app_play_store_url) },
//                colors = ButtonDefaults.buttonColors(containerColor = primaryDark),
//                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 4.dp),
//                shape = RoundedCornerShape(4.dp), // Adjust corner radius as needed
//                modifier = Modifier
//                    .scale(scale)
//                    .wrapContentSize()
//            ) {
//                Text(
//                    text = "OPEN IN APP",
//                    color = Color.White,
//                    style = MaterialTheme.typography.bodySmall
//                )
//            }
//        }
//    }
//}
//
//@Composable
//fun Dp.toPx(): Float {
//    return with(LocalDensity.current) { this@toPx.toPx() }
//}
//
//
//@Preview
//@Composable
//fun TextWithImageAsButton(
//    onClick: () -> Unit,
//    text: String,
//    imageVector: Painter,
//    contentPadding: PaddingValues = PaddingValues(6.dp)
//) {
//    Button(
//        onClick = onClick,
//        modifier = Modifier.height(Scale.ACTION_BAR_BUTTON_SIZE),
//        colors = ButtonDefaults.textButtonColors(Color.White),
//        contentPadding = contentPadding,
//        elevation = ButtonDefaults.buttonElevation(
//            defaultElevation = Scale.ELEVATION_SMALL, pressedElevation = 3.dp
//        ),
//        shape = RoundedCornerShape(CORNER_SMALL)
//    ) {
//        Row(
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            Image(
//                painter = imageVector,
//                contentDescription = null,
//                modifier = Modifier.size(GLOBAL_ICON_SIZE),
//                alignment = Alignment.Center,
//                colorFilter = ColorFilter.tint(primaryColor)
//            )
//            Spacer(modifier = Modifier.width(4.dp))
//            Text(
//                text = text, color = primaryColor, // Set text color to #1B98F0
//                style = MaterialTheme.typography.labelMedium,
//                fontWeight = FontWeight.SemiBold
//            )
//        }
//    }
//}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ToolBar(
    onClickLogin: () -> Unit,
    onClickProfile: () -> Unit,
    onClickWallet: () -> Unit,
    component: HomeScreenTabComponent
) {

    val walletState by component.walletState.collectAsState(null)
    val openDialog = remember { mutableStateOf(false) }


    val isLoggedIn = remember {
        UserInfoProvider.getIsLogin()
    }

    if (openDialog.value) {
        SampleDialog(
            onDismissRequest = { openDialog.value = false },
            onPlayStore = {
                openPlayStore(user_app_play_store_url)
            },
        )
    }

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier.fillMaxWidth().wrapContentHeight()

    ) {

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth()
        ) {

            if (windowSizeClass == WindowWidthSizeClass.Compact &&  isJS()) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(Color.White).padding(horizontal = Scale.HORIZONTAL_PADDING, vertical = 4.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    // "Talk to Astro" Title with Logo
                    WhatsAppSupportTxt()

                    VerticalDivider(
                        thickness = 1.dp,
                        modifier = Modifier.width(1.dp).height(30.dp).padding(vertical = 4.dp),
                    )

                    DownloadAppTxt()
                }

            }


            Row(
                modifier = Modifier
                    .fillMaxWidth().padding(horizontal = Scale.HORIZONTAL_PADDING, vertical = 6.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                // "Talk to Astro" Title with Logo
                Row(
                    horizontalArrangement = Arrangement.Start,
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    Image(
                        painter = painterResource(Res.drawable.icon_logo),
                        contentDescription = "Logo",
                        contentScale = ContentScale.Inside,
                        modifier = Modifier.size(WINYWAY_LOGO_SIZE).padding(4.dp)
                    )

                    Text(
                        text = "Winyway",
                        fontSize = 16.sp,
                        style = MaterialTheme.typography.titleMedium,
                        fontWeight = FontWeight.SemiBold,
                        color = primaryDark,
                        maxLines = 1,
                    )
                }

                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    if (windowSizeClass != WindowWidthSizeClass.Compact){

                        SearchFieldPreview(modifier = Modifier.weight(1f), onClick = {
                            component.event(HomePageEvent.OnSearchClick(""))
                        })

                        WhatsAppSupportTxt(

                        )

                        Spacer(modifier = Modifier.width(4.dp))
                        VerticalDivider(
                            modifier = Modifier.height(30.dp)
                        )
                        Spacer(modifier = Modifier.width(4.dp))

                        DownloadAppTxt(
                            text = "Get App"
                        )

                    }

                    if (isLoggedIn) {
                        Spacer(modifier = Modifier.width(8.dp))

                        // Wallet Icon Section
                        Surface(
                            modifier = Modifier.height(ACTION_BAR_BUTTON_SIZE),
                            color = Color.White,
                            shadowElevation = Scale.ELEVATION_SMALL,
                            shape = RoundedCornerShape(CORNER_SMALL)

                        ) {
                            Row(
                                modifier = Modifier.clickable {
                                    onClickWallet.invoke()
                                },
                                horizontalArrangement = Arrangement.Start,
                                verticalAlignment = Alignment.CenterVertically,
                            ) {

                                Spacer(modifier = Modifier.width(8.dp))

                                Icon(
                                    imageVector = Icons.Default.Wallet, // Replace with actual icon resource
                                    contentDescription = "Wallet Icon",
                                    tint = Color.Black,
                                    modifier = Modifier
                                        .size(GLOBAL_ICON_SIZE)

                                )

                                Spacer(modifier = Modifier.width(6.dp))

                                Text(
                                    text = "${initializeCurrency()}$walletState",
                                    maxLines = 1,
                                    fontWeight = FontWeight.SemiBold,
                                    style = MaterialTheme.typography.bodySmall
                                )

                                Spacer(modifier = Modifier.width(8.dp))

                            }
                        }


                        Spacer(modifier = Modifier.width(10.dp))

                        // Notification section
                        Surface (
                            shape = RoundedCornerShape(CORNER_SMALL),
                            color = Color.White,
                            shadowElevation = Scale.ELEVATION_SMALL,
                        ) {

                            Box(
                                modifier = Modifier
                                    .clickable { openDialog.value = true }
                                    .size(ACTION_BAR_BUTTON_SIZE)
                                    .padding(4.dp)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.baseline_notifications_24), // Replace with actual icon resource
                                    contentDescription = "Notifications Icon",
                                    tint = Color.Black,
                                    modifier = Modifier.size(GLOBAL_ICON_SIZE).align(Alignment.Center)
                                )
                                if (/* Notification count condition */ true) {
                                    Box(
                                        modifier = Modifier
                                            .size(10.dp)
                                            .clip(RoundedCornerShape(100))
                                            .background(primaryColor)
                                            .align(Alignment.TopEnd)
                                            .offset(x = 4.dp, y = (-4).dp),
                                        contentAlignment = Alignment.Center
                                    ) {
                                        Text(
                                            text = "9", // Replace with actual notification count
                                            color = Color.White,
                                            fontSize = 4.sp,
                                            fontWeight = FontWeight.Thin,
                                        )
                                    }
                                }
                            }

                        }

                        Spacer(modifier = Modifier.width(10.dp))

                        // Profile Icon
                        Surface(
                            tonalElevation = Scale.ELEVATION_SMALL,
                            shape = RoundedCornerShape(CORNER_SMALL),
                            color = Color.White,
                            modifier = Modifier
                                .size(ACTION_BAR_BUTTON_SIZE)
                                .clickable { onClickProfile.invoke() }
                        ) {
                            Image(
                                painter = painterResource(Res.drawable.profile_icon_avtar), // Replace with actual icon resource
                                contentDescription = "Profile Icon",
                                modifier = Modifier.fillMaxSize(),
                                contentScale = ContentScale.Crop,
                            )
                        }
                    } else {
                        TextWithImageAsButton(
                            onClick = { onClickLogin.invoke() },
                            text = "Login/Register",
                        )
                    }
                }
            }
        }

    }
}

@Composable
fun WhatsAppRowWithScroll(isVisible: Boolean) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(45.dp)
            .offset(y = if (isVisible) 0.dp else -45.dp) // Hide Row by moving it off-screen
            .padding(16.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        WhatsAppSupportTxt()

        VerticalDivider(
            thickness = 1.dp,
            modifier = Modifier.width(1.dp).height(30.dp).padding(vertical = 4.dp),
        )

        Text(
            modifier = Modifier.clickable {
                openPlayStore(user_app_play_store_url)
            },
            text = "OPEN IN APP",
            color = primaryColor,
            style = MaterialTheme.typography.bodySmall
        )
    }
}

@Composable
fun InstallAppButton() {
    BoxWithConstraints(
        modifier = Modifier
            .fillMaxSize()
            .padding(8.dp)
    ) {
        val containerWidth = constraints.maxWidth
        val containerHeight = constraints.maxHeight

        // Calculate initial offset for centering horizontally and positioning at the bottom
        val initialOffsetX = (containerWidth / 2).toFloat()
        val initialOffsetY =
            (containerHeight - 50.dp.toPx()).toFloat() // Assuming button height is 50.dp

        val offsetX = remember { mutableStateOf(initialOffsetX) }
        val offsetY = remember { mutableStateOf(initialOffsetY) }

        // Draggable button box
        Box(
            modifier = Modifier
                .offset { IntOffset(offsetX.value.roundToInt(), offsetY.value.roundToInt()) }
                .pointerInput(Unit) {
                    detectDragGestures { change, dragAmount ->
                        change.consume()
                        offsetX.value += dragAmount.x
                        offsetY.value += dragAmount.y
                    }
                }
                .wrapContentSize(),
            contentAlignment = Alignment.Center
        ) {
            // Animated scale transition
            val infiniteTransition = rememberInfiniteTransition()
            val scale by infiniteTransition.animateFloat(
                initialValue = 0.9f,
                targetValue = 1f,
                animationSpec = infiniteRepeatable(
                    animation = tween(durationMillis = 800, easing = LinearEasing),
                    repeatMode = RepeatMode.Reverse
                )
            )

            Button(
                onClick = { openPlayStore(user_app_play_store_url) },
                colors = ButtonDefaults.buttonColors(containerColor = primaryDark),
                contentPadding = PaddingValues(horizontal = 12.dp, vertical = 4.dp),
                shape = RoundedCornerShape(4.dp), // Adjust corner radius as needed
                modifier = Modifier
                    .scale(scale)
                    .wrapContentSize()
            ) {
                Text(
                    text = "OPEN IN APP",
                    color = Color.White,
                    style = MaterialTheme.typography.bodySmall
                )
            }
        }
    }
}

@Composable
fun WhatsAppSupportTxt(
    modifier: Modifier = Modifier
){
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start,
        modifier = modifier.clickable {
            openWhatsApp("8860095202")
        }.padding(vertical = 4.dp, horizontal = 8.dp)
    ) {
        Image(
            imageVector = Icons.Default.Whatsapp,
            contentDescription = "Whatsapp Icon",
            modifier = Modifier.size(GLOBAL_ICON_SIZE)
        )
        Spacer(modifier = Modifier.width(4.dp))
        Text(
            text = "91-8860095202",
            fontSize = 15.sp,
            color = Color.Black.copy(alpha = 0.7f),
            fontWeight = FontWeight.SemiBold
        )
    }

}

@Composable
fun DownloadAppTxt(
    modifier: Modifier = Modifier,
    text: String = "Download App"
){
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start,
        modifier = Modifier.clickable {
            openPlayStore(user_app_play_store_url)
        }.padding(vertical = 4.dp, horizontal = 8.dp)
    ) {
        Image(
            imageVector = Icons.Default.GetApp,
            contentDescription = "Download App Icon",
            modifier = Modifier.size(GLOBAL_ICON_SIZE)
        )
        Spacer(modifier = Modifier.width(4.dp))
        Text(
            text = text,
            fontSize = 15.sp,
            color = Color.Black.copy(alpha = 0.7f),
            fontWeight = FontWeight.SemiBold
        )
    }
}

@Composable
fun Dp.toPx(): Float {
    return with(LocalDensity.current) { this@toPx.toPx() }
}

@Composable
fun TextWithImageAsButton(
    onClick: () -> Unit,
    text: String,
    contentPadding: PaddingValues = PaddingValues(6.dp)
) {
    Button(
        onClick = onClick,
        modifier = Modifier.height(ACTION_BAR_BUTTON_SIZE),
        colors = ButtonDefaults.textButtonColors(Color.White),
        contentPadding = contentPadding,
        elevation = ButtonDefaults.buttonElevation(
            defaultElevation = Scale.ELEVATION_SMALL, pressedElevation = 3.dp
        ),
        shape = RoundedCornerShape(CORNER_SMALL)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                imageVector = Icons.AutoMirrored.Filled.Login,
                contentDescription = null,
                modifier = Modifier.size(GLOBAL_ICON_SIZE),
                alignment = Alignment.Center,
            )
            Spacer(modifier = Modifier.width(4.dp))
            Text(
                text = text, color = Color.Black, // Set text color to #1B98F0
                style = MaterialTheme.typography.labelMedium,
                fontWeight = FontWeight.SemiBold
            )
        }
    }
}