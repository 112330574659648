package com.winiwayuser.feature_chat_consultation_new.persentation.chat_room_view

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.backhandler.BackCallback
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.persentation.saveChatMessage
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation.data.request.StartChatRequest
import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import com.winiwayuser.feature_chat_consultation.domain.Sender
import com.winiwayuser.feature_chat_consultation.data.response.start_consultation.StartConsultationData
import com.winiwayuser.feature_chat_consultation_new.domain.ChatRoomRepo
import com.winiwayuser.feature_chat_consultation.domain.MsgType
import com.winiwayuser.feature_chat_consultation.persentation.chat_room_view.ChatRoomMsgState
import com.winiwayuser.feature_chat_consultation.persentation.chat_room_view.ChatRoomState
import com.winiwayuser.feature_chat_consultation_new.data.api.ChatConsultationApi.Companion.SAVE_CHAT_CONVERSATION
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatRoomComponent(
    val expertName: String,
    val expertImage: String,
    val expertSkills: String,
    val expertId: String,
    val chatId: String,
    private val event: (ChatRoomEvent) -> Unit,
    context: ComponentContext,
) : ComponentContext by context, KoinComponent {

    private val backCallback = BackCallback {
        // Logic when back button is pressed
    }

    init {
        backHandler.register(backCallback)
    }


    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatRoomRepo by inject()
    private val _state: MutableStateFlow<ChatRoomState> =
        MutableStateFlow(ChatRoomState.InitialState)
    val state = _state.asStateFlow()

    private val _msgState = MutableStateFlow<ChatRoomMsgState>(ChatRoomMsgState.InitialState)
    val msgState = _msgState.asStateFlow()

    private val _typingState = MutableStateFlow(false)
    val typingState = _typingState.asStateFlow()

    private val _endedByExpert = MutableStateFlow(false)
    var endedByExpert = _endedByExpert.asStateFlow()


    private val _chatMsg: MutableList<ChatMessage> = mutableListOf()
    val chatMsg get() = _chatMsg
    private var chatData: StartConsultationData? = null

    private fun updateMsg(msg: String, sender: Sender) {
        _chatMsg.add(
            ChatMessage(
                id = null,
                msg = msg,
                //sender = sender,
                sender = if(sender == Sender.USER) 1 else 0,
                date = "",
                msgType = MsgType.NORMAL_MSG,
                isAttachmentUploaded = false,
                userConcern = null
            )
        )
    }

    private fun updateMsg(msg: String, sender: Sender, msgType: MsgType) {
        _chatMsg.add(
            ChatMessage(
                id = null,
                msg = msg,
                //sender = sender,
                sender = if(sender == Sender.USER) 1 else 0,
                date = "",
                msgType = msgType,
                isAttachmentUploaded = false,
                userConcern = null
            )
        )
    }

    private fun onUpdateMsgState(msg: String) {
        coroutineScope.launch {
            _msgState.emit(ChatRoomMsgState.MsgUpdated(msg))
        }
    }

    fun onGoToDashBoard() = event.invoke(ChatRoomEvent.GoToDashBoard)
    fun onTypingStatus(isTyping: Boolean) = event.invoke(
        ChatRoomEvent.OnUserTyping(
            isTyping = isTyping,
            expertUid = chatData?.expertUid.toString()
        )
    )

//    fun onEndChat() {
//        coroutineScope.launch {
//            onEndChatApi()
//        }
//        event.invoke(ChatRoomEvent.EndChatMsg(chatData?.expertUid.toString()))
//    }

    fun onEndChat() {
        coroutineScope.launch {
            // Call your end chat API (if you have any)
            onEndChatApi()

            // Prepare data for saving chat messages
            val chatId = chatId ?: return@launch // Ensure chatId is valid
            val expertUid = chatData?.expertUid.toString()
            val url = SAVE_CHAT_CONVERSATION

            // Convert the conversation list to the required format
            val listOfConversation: List<ChatMessage> = _chatMsg.map { chatMessage ->
                ChatMessage(
                    id = chatMessage.id,
                    msg = chatMessage.msg,
                    date = chatMessage.date,
                    msgType = chatMessage.msgType,
                    isAttachmentUploaded = chatMessage.isAttachmentUploaded,
                    sender = chatMessage.sender,
                    userConcern = null // Adjust this field as per the real data
                )
            }

            // Call saveChatMessage to save the chat conversation
            val saveResult = saveChatMessage(url, chatId, listOfConversation)

            // Check save result or handle errors
            if (saveResult.contains("Error")) {
                // Handle error (e.g., show message to the user)
                println("Failed to save chat: $saveResult")
                Logger.d { "Failed to save chat: $saveResult" }
            } else {
                // Successfully saved the chat
                println("Chat successfully saved: $saveResult")
                Logger.d { "Chat successfully saved: $saveResult" }
            }

            // Trigger the event to end the chat room
            event.invoke(ChatRoomEvent.EndChatMsg(expertUid))
        }
    }

    private fun onEndSuccessState() {
        coroutineScope.launch {
            _state.emit(ChatRoomState.OnEndChatSuccess)
        }
    }

    private fun onEndChatApi() {
        coroutineScope.launch {
            val request = StartChatRequest(
                expertId = chatId, type = null
            )
            api.endChat(request).collectLatest {

                when (it) {
                    is Resources.Error -> {
                        Logger.d("On Error -> ${it.msg}")
//                        _state.emit(ChatRoomState.OnErrorState(it.msg!!))
                        onEndSuccessState()
                    }

                    is Resources.Loading -> {
                        Logger.d("On Loading -> ${it.isLoading}")
                        _state.emit(ChatRoomState.OnLoading(it.isLoading))
                    }

                    is Resources.Success -> {
                        Logger.d("on Success -> ${it.data}")
                        onEndSuccessState()
                    }
                }
            }
        }
    }

    fun onSendMsg(msg: String) {
        coroutineScope.launch(Dispatchers.Main) {
            updateMsg(msg, Sender.USER)
            onUpdateMsgState(msg)
            event.invoke(
                ChatRoomEvent.SendMsg(
                    msg = msg,
                    peerId = chatData?.expertUid.toString(),
                    onError = {})
            )
        }
    }

    private fun onEndChatByExpert() {
        event.invoke(ChatRoomEvent.OnLogOutAgora)
        coroutineScope.launch {
            _endedByExpert.emit(true)
        }
    }

    init {
        context.doOnStart {
            coroutineScope.launch {
                startConsultation()
            }
            event.invoke(ChatRoomEvent.StartRecieveMsg(onRecieveMsg = { msg ->
                updateMsg(
                    msg = msg,
                    sender = Sender.EXPERT
                )
            }))
        }
        context.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private fun setUserTypingStatus(isTyping: Boolean) {
        coroutineScope.launch {
            _typingState.emit(isTyping)
        }
    }

    private fun onRecieveMsg(msg: String) {
        msg.let {

            if (msg.equals(USER_TYPING_START)) {
                setUserTypingStatus(true)
                return
            }

            if (msg.equals(USER_TYPING_END)) {
                setUserTypingStatus(false)
                return
            }

            if (msg.equals(END_CHAT_MSG)) {
                onEndChatByExpert()
                return
            }

            if (msg.contains(IMAGE_TYPE)) {
                val imgUrl = msg.substringAfter("@")
                updateMsg(
                    msg = imgUrl, sender = Sender.EXPERT, msgType = MsgType.NORMAL_IMAGE
                )
                return
            }

            onUpdateMsgState(msg)
            updateMsg(msg = msg, sender = Sender.EXPERT)
        }
    }


    companion object {
        const val END_CHAT_MSG = "@winyway8943end978688Consultation3423h4234223jkhkj"
        const val IMAGE_TYPE = "winyway8943Image_recieved@"
        private const val PAUSE_CHAT_MSG = "winyway09853jksd90f4ConsultationPause34RF@"
        private const val RESUME_CHAT_MSG = "@winyway09853jksd90f4ConsultationResume34jl"
        const val USER_TYPING_START = "@winyway4234Start#$@Typing"
        const val USER_TYPING_END = "@winyway4234END#$@Typing"
        private const val CHAT_MSG_DURATION = "#winyway8943end97#duration#fjklasdf#chat@"
    }

    private fun onSuccessConsultation(data: StartConsultationData) {
        val msg = "$CHAT_MSG_DURATION${data.totalDurationToChat}"
        event.invoke(
            ChatRoomEvent.SendMsg(
                msg = msg,
                peerId = data.expertUid.toString(),
                onError = {})
        )
        coroutineScope.launch {
            _state.emit(ChatRoomState.OnChatStart(data))
        }
    }

    private suspend fun startConsultation() {
        val request = StartChatRequest(
            expertId = chatId, type = null
        )
        api.startChat(request).collectLatest {

            when (it) {
                is Resources.Error -> {
                    Logger.d("On Error -> ${it.msg}")
                    _state.emit(ChatRoomState.OnErrorState(it.msg!!))
                }

                is Resources.Loading -> {
                    Logger.d("On Loading -> ${it.isLoading}")
                    _state.emit(ChatRoomState.OnLoading(it.isLoading))
                }

                is Resources.Success -> {
                    Logger.d("on Success -> ${it.data}")
                    it.data?.let { d ->
                        chatData = d
                        onSuccessConsultation(d)
                        event.invoke(ChatRoomEvent.StartRecieveMsg(onRecieveMsg = { msg ->
                            onRecieveMsg(msg)
                        }))
                    }
                }
            }
        }
    }


}