package com.winiwayuser.feature_chat_consultation_new.data.request


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PlaceChatRequestBody(
    @SerialName("question_id")val questionId:String?,
    @SerialName("expert_id")val expertId:String,
    @SerialName("category")val category:String?,
    @SerialName("answers")val answers:AnswerRequest?,
    @SerialName("flash_off")val flashOff:String?,
    @SerialName("images")val images:String?,
    @SerialName("type")val type:String?,
)
