package com.winiwayuser.core.extenstion

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.ui.Modifier

fun WindowWidthSizeClass.getMaxInputAbleWidthModifier(
    medium: Float = 0.6f, expand: Float = 0.45f
): Modifier {
    return when (this) {
        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(medium)
        else -> Modifier.fillMaxWidth(expand)
    }
}