@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.winiwayuser.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val Astrology_Png: DrawableResource by 
      lazy { init_Astrology_Png() }

  public val JoinVedicAstrologyCourse: DrawableResource by 
      lazy { init_JoinVedicAstrologyCourse() }

  public val app_stor: DrawableResource by 
      lazy { init_app_stor() }

  public val astologer_img: DrawableResource by 
      lazy { init_astologer_img() }

  public val astrologer_icon: DrawableResource by 
      lazy { init_astrologer_icon() }

  public val astrology_course: DrawableResource by 
      lazy { init_astrology_course() }

  public val astrology_courses: DrawableResource by 
      lazy { init_astrology_courses() }

  public val banner1: DrawableResource by 
      lazy { init_banner1() }

  public val baseline_attach_file_24: DrawableResource by 
      lazy { init_baseline_attach_file_24() }

  public val baseline_auto_premium_24: DrawableResource by 
      lazy { init_baseline_auto_premium_24() }

  public val baseline_check_24: DrawableResource by 
      lazy { init_baseline_check_24() }

  public val baseline_info_24: DrawableResource by 
      lazy { init_baseline_info_24() }

  public val baseline_language_24: DrawableResource by 
      lazy { init_baseline_language_24() }

  public val baseline_local_offer_24: DrawableResource by 
      lazy { init_baseline_local_offer_24() }

  public val baseline_notifications_24: DrawableResource by 
      lazy { init_baseline_notifications_24() }

  public val baseline_person_4_24: DrawableResource by 
      lazy { init_baseline_person_4_24() }

  public val baseline_support_agent_24: DrawableResource by 
      lazy { init_baseline_support_agent_24() }

  public val baseline_tips_and_updates_24: DrawableResource by 
      lazy { init_baseline_tips_and_updates_24() }

  public val baseline_voice_chat_24: DrawableResource by 
      lazy { init_baseline_voice_chat_24() }

  public val brochure: DrawableResource by 
      lazy { init_brochure() }

  public val calender_semi_small_black: DrawableResource by 
      lazy { init_calender_semi_small_black() }

  public val call1: DrawableResource by 
      lazy { init_call1() }

  public val call_icon_small: DrawableResource by 
      lazy { init_call_icon_small() }

  public val chat2: DrawableResource by 
      lazy { init_chat2() }

  public val chat_icon_medium: DrawableResource by 
      lazy { init_chat_icon_medium() }

  public val chat_icon_small: DrawableResource by 
      lazy { init_chat_icon_small() }

  public val coin_balance: DrawableResource by 
      lazy { init_coin_balance() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val consultation_call_small_icon: DrawableResource by 
      lazy { init_consultation_call_small_icon() }

  public val done_icon: DrawableResource by 
      lazy { init_done_icon() }

  public val ellipse: DrawableResource by 
      lazy { init_ellipse() }

  public val error_icon: DrawableResource by 
      lazy { init_error_icon() }

  public val facebook1: DrawableResource by 
      lazy { init_facebook1() }

  public val facebook2: DrawableResource by 
      lazy { init_facebook2() }

  public val footer_background: DrawableResource by 
      lazy { init_footer_background() }

  public val footer_verify_buttons: DrawableResource by 
      lazy { init_footer_verify_buttons() }

  public val google_play: DrawableResource by 
      lazy { init_google_play() }

  public val google_play_logo: DrawableResource by 
      lazy { init_google_play_logo() }

  public val ic_chronic: DrawableResource by 
      lazy { init_ic_chronic() }

  public val ic_filled_star: DrawableResource by 
      lazy { init_ic_filled_star() }

  public val ic_heart_primary_color: DrawableResource by 
      lazy { init_ic_heart_primary_color() }

  public val ic_heart_primary_color_fill: DrawableResource by 
      lazy { init_ic_heart_primary_color_fill() }

  public val ic_help_center: DrawableResource by 
      lazy { init_ic_help_center() }

  public val ic_next: DrawableResource by 
      lazy { init_ic_next() }

  public val ic_next_background: DrawableResource by 
      lazy { init_ic_next_background() }

  public val ic_otp_screen: DrawableResource by 
      lazy { init_ic_otp_screen() }

  public val ic_person: DrawableResource by 
      lazy { init_ic_person() }

  public val ic_pricing: DrawableResource by 
      lazy { init_ic_pricing() }

  public val ic_privacy_policy: DrawableResource by 
      lazy { init_ic_privacy_policy() }

  public val ic_reload: DrawableResource by 
      lazy { init_ic_reload() }

  public val ic_send: DrawableResource by 
      lazy { init_ic_send() }

  public val ic_sign_out: DrawableResource by 
      lazy { init_ic_sign_out() }

  public val ic_term_service: DrawableResource by 
      lazy { init_ic_term_service() }

  public val ic_wallet: DrawableResource by 
      lazy { init_ic_wallet() }

  public val ic_whatsapp: DrawableResource by 
      lazy { init_ic_whatsapp() }

  public val icon_logo: DrawableResource by 
      lazy { init_icon_logo() }

  public val image_loading_square: DrawableResource by 
      lazy { init_image_loading_square() }

  public val instagram1: DrawableResource by 
      lazy { init_instagram1() }

  public val instagram2: DrawableResource by 
      lazy { init_instagram2() }

  public val join_vedic_astrology_course_new: DrawableResource by 
      lazy { init_join_vedic_astrology_course_new() }

  public val landing_page_ast: DrawableResource by 
      lazy { init_landing_page_ast() }

  public val lawayer: DrawableResource by 
      lazy { init_lawayer() }

  public val linkedin1: DrawableResource by 
      lazy { init_linkedin1() }

  public val linkedin2: DrawableResource by 
      lazy { init_linkedin2() }

  public val new_caht2: DrawableResource by 
      lazy { init_new_caht2() }

  public val new_call1: DrawableResource by 
      lazy { init_new_call1() }

  public val notification_with_background_icon: DrawableResource by 
      lazy { init_notification_with_background_icon() }

  public val person_small_icon: DrawableResource by 
      lazy { init_person_small_icon() }

  public val premium_background: DrawableResource by 
      lazy { init_premium_background() }

  public val profile_edit_icon: DrawableResource by 
      lazy { init_profile_edit_icon() }

  public val profile_icon_avtar: DrawableResource by 
      lazy { init_profile_icon_avtar() }

  public val purchase_histor_bag: DrawableResource by 
      lazy { init_purchase_histor_bag() }

  public val purchase_icon_with_background: DrawableResource by 
      lazy { init_purchase_icon_with_background() }

  public val rating_background: DrawableResource by 
      lazy { init_rating_background() }

  public val round_person_4_24: DrawableResource by 
      lazy { init_round_person_4_24() }

  public val round_sort_24: DrawableResource by 
      lazy { init_round_sort_24() }

  public val round_star_outline_24: DrawableResource by 
      lazy { init_round_star_outline_24() }

  public val science_astro_icon: DrawableResource by 
      lazy { init_science_astro_icon() }

  public val secure_payment: DrawableResource by 
      lazy { init_secure_payment() }

  public val share_icon_new: DrawableResource by 
      lazy { init_share_icon_new() }

  public val sim_icon: DrawableResource by 
      lazy { init_sim_icon() }

  public val spritual_banner2: DrawableResource by 
      lazy { init_spritual_banner2() }

  public val subscribe_icon: DrawableResource by 
      lazy { init_subscribe_icon() }

  public val therpy_icon: DrawableResource by 
      lazy { init_therpy_icon() }

  public val toolbar_heart: DrawableResource by 
      lazy { init_toolbar_heart() }

  public val verified_expert_astrologer: DrawableResource by 
      lazy { init_verified_expert_astrologer() }

  public val verified_expert_astrologer_mobile: DrawableResource by 
      lazy { init_verified_expert_astrologer_mobile() }

  public val verify_expert: DrawableResource by 
      lazy { init_verify_expert() }

  public val wallet_transaction_icon: DrawableResource by 
      lazy { init_wallet_transaction_icon() }

  public val welcome_banner_one: DrawableResource by 
      lazy { init_welcome_banner_one() }

  public val welcome_banner_three: DrawableResource by 
      lazy { init_welcome_banner_three() }

  public val welcome_banner_two: DrawableResource by 
      lazy { init_welcome_banner_two() }

  public val wifi_icon: DrawableResource by 
      lazy { init_wifi_icon() }

  public val winyway_button_spiritual: DrawableResource by 
      lazy { init_winyway_button_spiritual() }

  public val youtube1: DrawableResource by 
      lazy { init_youtube1() }

  public val youtube2: DrawableResource by 
      lazy { init_youtube2() }
}

public val Res.drawable.Astrology_Png: DrawableResource
  get() = CommonMainDrawable0.Astrology_Png

private fun init_Astrology_Png(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:Astrology_Png",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/Astrology_Png.png", -1, -1),
    )
)

public val Res.drawable.JoinVedicAstrologyCourse: DrawableResource
  get() = CommonMainDrawable0.JoinVedicAstrologyCourse

private fun init_JoinVedicAstrologyCourse(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:JoinVedicAstrologyCourse",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/JoinVedicAstrologyCourse.jpg", -1, -1),
    )
)

public val Res.drawable.app_stor: DrawableResource
  get() = CommonMainDrawable0.app_stor

private fun init_app_stor(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_stor",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/app_stor.png", -1, -1),
    )
)

public val Res.drawable.astologer_img: DrawableResource
  get() = CommonMainDrawable0.astologer_img

private fun init_astologer_img(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:astologer_img",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/astologer_img.webp", -1, -1),
    )
)

public val Res.drawable.astrologer_icon: DrawableResource
  get() = CommonMainDrawable0.astrologer_icon

private fun init_astrologer_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:astrologer_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/astrologer_icon.jpg", -1, -1),
    )
)

public val Res.drawable.astrology_course: DrawableResource
  get() = CommonMainDrawable0.astrology_course

private fun init_astrology_course(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:astrology_course",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/astrology_course.jpg", -1, -1),
    )
)

public val Res.drawable.astrology_courses: DrawableResource
  get() = CommonMainDrawable0.astrology_courses

private fun init_astrology_courses(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:astrology_courses",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/astrology_courses.jpg", -1, -1),
    )
)

public val Res.drawable.banner1: DrawableResource
  get() = CommonMainDrawable0.banner1

private fun init_banner1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:banner1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/banner1.jpg", -1, -1),
    )
)

public val Res.drawable.baseline_attach_file_24: DrawableResource
  get() = CommonMainDrawable0.baseline_attach_file_24

private fun init_baseline_attach_file_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_attach_file_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_attach_file_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_auto_premium_24: DrawableResource
  get() = CommonMainDrawable0.baseline_auto_premium_24

private fun init_baseline_auto_premium_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_auto_premium_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_auto_premium_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_check_24: DrawableResource
  get() = CommonMainDrawable0.baseline_check_24

private fun init_baseline_check_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_check_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_check_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_info_24: DrawableResource
  get() = CommonMainDrawable0.baseline_info_24

private fun init_baseline_info_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_info_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_info_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_language_24: DrawableResource
  get() = CommonMainDrawable0.baseline_language_24

private fun init_baseline_language_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_language_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_language_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_local_offer_24: DrawableResource
  get() = CommonMainDrawable0.baseline_local_offer_24

private fun init_baseline_local_offer_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_local_offer_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_local_offer_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_notifications_24: DrawableResource
  get() = CommonMainDrawable0.baseline_notifications_24

private fun init_baseline_notifications_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_notifications_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_notifications_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_person_4_24: DrawableResource
  get() = CommonMainDrawable0.baseline_person_4_24

private fun init_baseline_person_4_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_person_4_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_person_4_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_support_agent_24: DrawableResource
  get() = CommonMainDrawable0.baseline_support_agent_24

private fun init_baseline_support_agent_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_support_agent_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_support_agent_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_tips_and_updates_24: DrawableResource
  get() = CommonMainDrawable0.baseline_tips_and_updates_24

private fun init_baseline_tips_and_updates_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_tips_and_updates_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_tips_and_updates_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_voice_chat_24: DrawableResource
  get() = CommonMainDrawable0.baseline_voice_chat_24

private fun init_baseline_voice_chat_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_voice_chat_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/baseline_voice_chat_24.xml", -1, -1),
    )
)

public val Res.drawable.brochure: DrawableResource
  get() = CommonMainDrawable0.brochure

private fun init_brochure(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:brochure",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/brochure.pdf", -1, -1),
    )
)

public val Res.drawable.calender_semi_small_black: DrawableResource
  get() = CommonMainDrawable0.calender_semi_small_black

private fun init_calender_semi_small_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:calender_semi_small_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/calender_semi_small_black.xml", -1, -1),
    )
)

public val Res.drawable.call1: DrawableResource
  get() = CommonMainDrawable0.call1

private fun init_call1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:call1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/call1.png", -1, -1),
    )
)

public val Res.drawable.call_icon_small: DrawableResource
  get() = CommonMainDrawable0.call_icon_small

private fun init_call_icon_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:call_icon_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/call_icon_small.xml", -1, -1),
    )
)

public val Res.drawable.chat2: DrawableResource
  get() = CommonMainDrawable0.chat2

private fun init_chat2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:chat2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/chat2.png", -1, -1),
    )
)

public val Res.drawable.chat_icon_medium: DrawableResource
  get() = CommonMainDrawable0.chat_icon_medium

private fun init_chat_icon_medium(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chat_icon_medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/chat_icon_medium.xml", -1, -1),
    )
)

public val Res.drawable.chat_icon_small: DrawableResource
  get() = CommonMainDrawable0.chat_icon_small

private fun init_chat_icon_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chat_icon_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/chat_icon_small.xml", -1, -1),
    )
)

public val Res.drawable.coin_balance: DrawableResource
  get() = CommonMainDrawable0.coin_balance

private fun init_coin_balance(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:coin_balance",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/coin_balance.xml", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

public val Res.drawable.consultation_call_small_icon: DrawableResource
  get() = CommonMainDrawable0.consultation_call_small_icon

private fun init_consultation_call_small_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:consultation_call_small_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/consultation_call_small_icon.xml", -1, -1),
    )
)

public val Res.drawable.done_icon: DrawableResource
  get() = CommonMainDrawable0.done_icon

private fun init_done_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:done_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/done_icon.xml", -1, -1),
    )
)

public val Res.drawable.ellipse: DrawableResource
  get() = CommonMainDrawable0.ellipse

private fun init_ellipse(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ellipse",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ellipse.png", -1, -1),
    )
)

public val Res.drawable.error_icon: DrawableResource
  get() = CommonMainDrawable0.error_icon

private fun init_error_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:error_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/error_icon.xml", -1, -1),
    )
)

public val Res.drawable.facebook1: DrawableResource
  get() = CommonMainDrawable0.facebook1

private fun init_facebook1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/facebook1.png", -1, -1),
    )
)

public val Res.drawable.facebook2: DrawableResource
  get() = CommonMainDrawable0.facebook2

private fun init_facebook2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/facebook2.png", -1, -1),
    )
)

public val Res.drawable.footer_background: DrawableResource
  get() = CommonMainDrawable0.footer_background

private fun init_footer_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:footer_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/footer_background.jpg", -1, -1),
    )
)

public val Res.drawable.footer_verify_buttons: DrawableResource
  get() = CommonMainDrawable0.footer_verify_buttons

private fun init_footer_verify_buttons(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:footer_verify_buttons",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/footer_verify_buttons.png", -1, -1),
    )
)

public val Res.drawable.google_play: DrawableResource
  get() = CommonMainDrawable0.google_play

private fun init_google_play(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/google_play.png", -1, -1),
    )
)

public val Res.drawable.google_play_logo: DrawableResource
  get() = CommonMainDrawable0.google_play_logo

private fun init_google_play_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_play_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/google_play_logo.jpg", -1, -1),
    )
)

public val Res.drawable.ic_chronic: DrawableResource
  get() = CommonMainDrawable0.ic_chronic

private fun init_ic_chronic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_chronic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_chronic.xml", -1, -1),
    )
)

public val Res.drawable.ic_filled_star: DrawableResource
  get() = CommonMainDrawable0.ic_filled_star

private fun init_ic_filled_star(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_filled_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_filled_star.xml", -1, -1),
    )
)

public val Res.drawable.ic_heart_primary_color: DrawableResource
  get() = CommonMainDrawable0.ic_heart_primary_color

private fun init_ic_heart_primary_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_heart_primary_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_heart_primary_color.xml", -1, -1),
    )
)

public val Res.drawable.ic_heart_primary_color_fill: DrawableResource
  get() = CommonMainDrawable0.ic_heart_primary_color_fill

private fun init_ic_heart_primary_color_fill(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_heart_primary_color_fill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_heart_primary_color_fill.xml", -1, -1),
    )
)

public val Res.drawable.ic_help_center: DrawableResource
  get() = CommonMainDrawable0.ic_help_center

private fun init_ic_help_center(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_help_center",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_help_center.xml", -1, -1),
    )
)

public val Res.drawable.ic_next: DrawableResource
  get() = CommonMainDrawable0.ic_next

private fun init_ic_next(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_next.xml", -1, -1),
    )
)

public val Res.drawable.ic_next_background: DrawableResource
  get() = CommonMainDrawable0.ic_next_background

private fun init_ic_next_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_next_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_next_background.xml", -1, -1),
    )
)

public val Res.drawable.ic_otp_screen: DrawableResource
  get() = CommonMainDrawable0.ic_otp_screen

private fun init_ic_otp_screen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_otp_screen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_otp_screen.xml", -1, -1),
    )
)

public val Res.drawable.ic_person: DrawableResource
  get() = CommonMainDrawable0.ic_person

private fun init_ic_person(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_person.xml", -1, -1),
    )
)

public val Res.drawable.ic_pricing: DrawableResource
  get() = CommonMainDrawable0.ic_pricing

private fun init_ic_pricing(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_pricing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_pricing.xml", -1, -1),
    )
)

public val Res.drawable.ic_privacy_policy: DrawableResource
  get() = CommonMainDrawable0.ic_privacy_policy

private fun init_ic_privacy_policy(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_privacy_policy.xml", -1, -1),
    )
)

public val Res.drawable.ic_reload: DrawableResource
  get() = CommonMainDrawable0.ic_reload

private fun init_ic_reload(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_reload",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_reload.xml", -1, -1),
    )
)

public val Res.drawable.ic_send: DrawableResource
  get() = CommonMainDrawable0.ic_send

private fun init_ic_send(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_send",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_send.xml", -1, -1),
    )
)

public val Res.drawable.ic_sign_out: DrawableResource
  get() = CommonMainDrawable0.ic_sign_out

private fun init_ic_sign_out(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_sign_out",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_sign_out.xml", -1, -1),
    )
)

public val Res.drawable.ic_term_service: DrawableResource
  get() = CommonMainDrawable0.ic_term_service

private fun init_ic_term_service(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_term_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_term_service.xml", -1, -1),
    )
)

public val Res.drawable.ic_wallet: DrawableResource
  get() = CommonMainDrawable0.ic_wallet

private fun init_ic_wallet(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_wallet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_wallet.xml", -1, -1),
    )
)

public val Res.drawable.ic_whatsapp: DrawableResource
  get() = CommonMainDrawable0.ic_whatsapp

private fun init_ic_whatsapp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/ic_whatsapp.xml", -1, -1),
    )
)

public val Res.drawable.icon_logo: DrawableResource
  get() = CommonMainDrawable0.icon_logo

private fun init_icon_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/icon_logo.png", -1, -1),
    )
)

public val Res.drawable.image_loading_square: DrawableResource
  get() = CommonMainDrawable0.image_loading_square

private fun init_image_loading_square(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:image_loading_square",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/image_loading_square.xml", -1, -1),
    )
)

public val Res.drawable.instagram1: DrawableResource
  get() = CommonMainDrawable0.instagram1

private fun init_instagram1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/instagram1.png", -1, -1),
    )
)

public val Res.drawable.instagram2: DrawableResource
  get() = CommonMainDrawable0.instagram2

private fun init_instagram2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/instagram2.png", -1, -1),
    )
)

public val Res.drawable.join_vedic_astrology_course_new: DrawableResource
  get() = CommonMainDrawable0.join_vedic_astrology_course_new

private fun init_join_vedic_astrology_course_new(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:join_vedic_astrology_course_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/join_vedic_astrology_course_new.jpg", -1, -1),
    )
)

public val Res.drawable.landing_page_ast: DrawableResource
  get() = CommonMainDrawable0.landing_page_ast

private fun init_landing_page_ast(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:landing_page_ast",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/landing_page_ast.webp", -1, -1),
    )
)

public val Res.drawable.lawayer: DrawableResource
  get() = CommonMainDrawable0.lawayer

private fun init_lawayer(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:lawayer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/lawayer.xml", -1, -1),
    )
)

public val Res.drawable.linkedin1: DrawableResource
  get() = CommonMainDrawable0.linkedin1

private fun init_linkedin1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:linkedin1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/linkedin1.png", -1, -1),
    )
)

public val Res.drawable.linkedin2: DrawableResource
  get() = CommonMainDrawable0.linkedin2

private fun init_linkedin2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:linkedin2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/linkedin2.png", -1, -1),
    )
)

public val Res.drawable.new_caht2: DrawableResource
  get() = CommonMainDrawable0.new_caht2

private fun init_new_caht2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:new_caht2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/new_caht2.png", -1, -1),
    )
)

public val Res.drawable.new_call1: DrawableResource
  get() = CommonMainDrawable0.new_call1

private fun init_new_call1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:new_call1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/new_call1.png", -1, -1),
    )
)

public val Res.drawable.notification_with_background_icon: DrawableResource
  get() = CommonMainDrawable0.notification_with_background_icon

private fun init_notification_with_background_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:notification_with_background_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/notification_with_background_icon.xml", -1, -1),
    )
)

public val Res.drawable.person_small_icon: DrawableResource
  get() = CommonMainDrawable0.person_small_icon

private fun init_person_small_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:person_small_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/person_small_icon.xml", -1, -1),
    )
)

public val Res.drawable.premium_background: DrawableResource
  get() = CommonMainDrawable0.premium_background

private fun init_premium_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:premium_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/premium_background.xml", -1, -1),
    )
)

public val Res.drawable.profile_edit_icon: DrawableResource
  get() = CommonMainDrawable0.profile_edit_icon

private fun init_profile_edit_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_edit_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/profile_edit_icon.xml", -1, -1),
    )
)

public val Res.drawable.profile_icon_avtar: DrawableResource
  get() = CommonMainDrawable0.profile_icon_avtar

private fun init_profile_icon_avtar(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_icon_avtar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/profile_icon_avtar.xml", -1, -1),
    )
)

public val Res.drawable.purchase_histor_bag: DrawableResource
  get() = CommonMainDrawable0.purchase_histor_bag

private fun init_purchase_histor_bag(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:purchase_histor_bag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/purchase_histor_bag.xml", -1, -1),
    )
)

public val Res.drawable.purchase_icon_with_background: DrawableResource
  get() = CommonMainDrawable0.purchase_icon_with_background

private fun init_purchase_icon_with_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:purchase_icon_with_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/purchase_icon_with_background.xml", -1, -1),
    )
)

public val Res.drawable.rating_background: DrawableResource
  get() = CommonMainDrawable0.rating_background

private fun init_rating_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:rating_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/rating_background.xml", -1, -1),
    )
)

public val Res.drawable.round_person_4_24: DrawableResource
  get() = CommonMainDrawable0.round_person_4_24

private fun init_round_person_4_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_person_4_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/round_person_4_24.xml", -1, -1),
    )
)

public val Res.drawable.round_sort_24: DrawableResource
  get() = CommonMainDrawable0.round_sort_24

private fun init_round_sort_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_sort_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/round_sort_24.xml", -1, -1),
    )
)

public val Res.drawable.round_star_outline_24: DrawableResource
  get() = CommonMainDrawable0.round_star_outline_24

private fun init_round_star_outline_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_star_outline_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/round_star_outline_24.xml", -1, -1),
    )
)

public val Res.drawable.science_astro_icon: DrawableResource
  get() = CommonMainDrawable0.science_astro_icon

private fun init_science_astro_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:science_astro_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/science_astro_icon.jpg", -1, -1),
    )
)

public val Res.drawable.secure_payment: DrawableResource
  get() = CommonMainDrawable0.secure_payment

private fun init_secure_payment(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:secure_payment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/secure_payment.png", -1, -1),
    )
)

public val Res.drawable.share_icon_new: DrawableResource
  get() = CommonMainDrawable0.share_icon_new

private fun init_share_icon_new(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:share_icon_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/share_icon_new.xml", -1, -1),
    )
)

public val Res.drawable.sim_icon: DrawableResource
  get() = CommonMainDrawable0.sim_icon

private fun init_sim_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:sim_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/sim_icon.xml", -1, -1),
    )
)

public val Res.drawable.spritual_banner2: DrawableResource
  get() = CommonMainDrawable0.spritual_banner2

private fun init_spritual_banner2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spritual_banner2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/spritual_banner2.jpg", -1, -1),
    )
)

public val Res.drawable.subscribe_icon: DrawableResource
  get() = CommonMainDrawable0.subscribe_icon

private fun init_subscribe_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:subscribe_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/subscribe_icon.xml", -1, -1),
    )
)

public val Res.drawable.therpy_icon: DrawableResource
  get() = CommonMainDrawable0.therpy_icon

private fun init_therpy_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:therpy_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/therpy_icon.jpg", -1, -1),
    )
)

public val Res.drawable.toolbar_heart: DrawableResource
  get() = CommonMainDrawable0.toolbar_heart

private fun init_toolbar_heart(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:toolbar_heart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/toolbar_heart.xml", -1, -1),
    )
)

public val Res.drawable.verified_expert_astrologer: DrawableResource
  get() = CommonMainDrawable0.verified_expert_astrologer

private fun init_verified_expert_astrologer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:verified_expert_astrologer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/verified_expert_astrologer.png", -1, -1),
    )
)

public val Res.drawable.verified_expert_astrologer_mobile: DrawableResource
  get() = CommonMainDrawable0.verified_expert_astrologer_mobile

private fun init_verified_expert_astrologer_mobile(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:verified_expert_astrologer_mobile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/verified_expert_astrologer_mobile.png", -1, -1),
    )
)

public val Res.drawable.verify_expert: DrawableResource
  get() = CommonMainDrawable0.verify_expert

private fun init_verify_expert(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:verify_expert",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/verify_expert.png", -1, -1),
    )
)

public val Res.drawable.wallet_transaction_icon: DrawableResource
  get() = CommonMainDrawable0.wallet_transaction_icon

private fun init_wallet_transaction_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:wallet_transaction_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/wallet_transaction_icon.xml", -1, -1),
    )
)

public val Res.drawable.welcome_banner_one: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_one

private fun init_welcome_banner_one(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_one",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/welcome_banner_one.webp", -1, -1),
    )
)

public val Res.drawable.welcome_banner_three: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_three

private fun init_welcome_banner_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/welcome_banner_three.webp", -1, -1),
    )
)

public val Res.drawable.welcome_banner_two: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_two

private fun init_welcome_banner_two(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/welcome_banner_two.webp", -1, -1),
    )
)

public val Res.drawable.wifi_icon: DrawableResource
  get() = CommonMainDrawable0.wifi_icon

private fun init_wifi_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:wifi_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/wifi_icon.xml", -1, -1),
    )
)

public val Res.drawable.winyway_button_spiritual: DrawableResource
  get() = CommonMainDrawable0.winyway_button_spiritual

private fun init_winyway_button_spiritual(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:winyway_button_spiritual",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/winyway_button_spiritual.png", -1, -1),
    )
)

public val Res.drawable.youtube1: DrawableResource
  get() = CommonMainDrawable0.youtube1

private fun init_youtube1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:youtube1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/youtube1.png", -1, -1),
    )
)

public val Res.drawable.youtube2: DrawableResource
  get() = CommonMainDrawable0.youtube2

private fun init_youtube2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:youtube2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.winiwayuser.resources/drawable/youtube2.png", -1, -1),
    )
)
