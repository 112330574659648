package com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EditProfileModel(
    @SerialName("status") val status:Boolean,
    @SerialName("code") val code: String,
    @SerialName("message") val message:String,
    @SerialName("data") val `data`: ProfileDetails?,
)

@Serializable
data class ProfileDetails(
    val email: String?,
    val full_name: String?,
    val gender: String?,
    val profileimg: String?,
    val user_id: Int?
)
