package com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget

import androidx.compose.animation.animateContentSize
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Error
import androidx.compose.material.icons.filled.Star
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.data.remote.request.RequestReviewSubmit
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_review_submit.ExpertReviewSubmitHost
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_filled_star
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import kotlin.math.round

//@Composable
//fun RatingScreen() {
//    var rating by remember { mutableStateOf(0) }
//    var reviewText by remember { mutableStateOf("") }
//    var errorMessage by remember { mutableStateOf("") }
//
//    Column(modifier = Modifier.padding(16.dp)) {
//        Text(
//            text = "Your opinion matters to us!",
//            style = TextStyle(fontSize = 16.sp, fontWeight = FontWeight.Bold),
//            modifier = Modifier.padding(bottom = 10.dp).align(Alignment.CenterHorizontally)
//        )
//        CustomRatingBar(
//            maxRating = 5,
//            initialRating = rating,
//            onRatingChanged = { newRating -> rating = newRating },
//        )
//        Spacer(modifier = Modifier.height(16.dp))
////        OutlinedTextField(
////            value = reviewText,
////            onValueChange = { reviewText = it },
////            label = { Text("Write your review here") },
////            modifier = Modifier.fillMaxWidth()
////        )
//        OutlinedTextField(
//            value = reviewText,
//            onValueChange = {
//                if (it.length <= 2000) {
//                    reviewText = it
//                    errorMessage = ""
//                } else {
//                    errorMessage = "Max Text Limit 2000"
//                }
//            },
//            label = { Text("Write your review here") },
//            isError = errorMessage.isNotEmpty(),
//            modifier = Modifier.fillMaxWidth(),
//            trailingIcon = {
//                if (errorMessage.isNotEmpty()) {
//                    Icon(
//                        imageVector = Icons.Default.Error,
//                        contentDescription = "Error",
//                        tint = MaterialTheme.colorScheme.error
//                    )
//                }
//            }
//        )
//
//        if (errorMessage.isNotEmpty()) {
//            Text(
//                text = errorMessage,
//                color = MaterialTheme.colorScheme.error,
//                style = MaterialTheme.typography.titleSmall,
//                modifier = Modifier.padding(start = 16.dp, top = 4.dp)
//            )
//        }
//        Spacer(modifier = Modifier.height(16.dp))
//        Button(
//            onClick = {
//
//            },
//            modifier = Modifier.fillMaxWidth(),
//            colors = ButtonDefaults.buttonColors(Color(0xFF1069BC)),
//            shape = RoundedCornerShape(Scale.CORNER_SMALL)
//        ) {
//            Text("Submit")
//        }
////        Spacer(modifier = Modifier.height(16.dp))
////        Divider() // Add a divider between items
////        Spacer(modifier = Modifier.height(10.dp))
//    }
//}

@Composable
fun RatingScreen(
    onLoginClick: () -> Unit,
    component: ExpertDetailsPageComponent,
    id: String?
) {
    var rating by remember { mutableStateOf(0) }
    var reviewText by remember { mutableStateOf("") }
    var errorMessage by remember { mutableStateOf("") }
    var ratingErrorMessage by remember { mutableStateOf("") }
    var reviewErrorMessage by remember { mutableStateOf("") }

    var allReviewFieldCorrect by remember { mutableStateOf(false) }

    Column(modifier = Modifier.padding(16.dp)) {
        Text(
            text = "Your opinion matters to us!",
            style = TextStyle(fontSize = 16.sp, fontWeight = FontWeight.Bold),
            modifier = Modifier
                .padding(bottom = 10.dp)
                .align(Alignment.CenterHorizontally)
        )
        CustomRatingBar(
            maxRating = 5,
            initialRating = rating,
            onRatingChanged = { newRating ->
                rating = newRating
                ratingErrorMessage = ""
            },
        )
        if (ratingErrorMessage.isNotEmpty()) {
            Text(
                text = ratingErrorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(start = 16.dp, top = 4.dp)
            )
        }
        Spacer(modifier = Modifier.height(16.dp))

        OutlinedTextField(
            value = reviewText,
            onValueChange = {
                if (it.length <= 2000) {
                    reviewText = it
                    reviewErrorMessage = ""
                } else {
                    reviewErrorMessage = "Max Text Limit 2000"
                }
            },
            label = { Text("Write your review here") },
            isError = reviewErrorMessage.isNotEmpty(),
            modifier = Modifier.fillMaxWidth(),
            trailingIcon = {
                if (reviewErrorMessage.isNotEmpty()) {
                    Icon(
                        imageVector = Icons.Default.Error,
                        contentDescription = "Error",
                        tint = MaterialTheme.colorScheme.error
                    )
                }
            }
        )

        if (reviewErrorMessage.isNotEmpty()) {
            Text(
                text = reviewErrorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(start = 16.dp, top = 4.dp)
            )
        }
        Spacer(modifier = Modifier.height(16.dp))

        if (allReviewFieldCorrect) {
            ExpertReviewSubmitHost(
                { onLoginClick.invoke() },
                { allReviewFieldCorrect = false },
                {
                    CoroutineScope(Dispatchers.Default).launch {
                        val result = component.postExpertReview(
                            RequestReviewSubmit(
                                rating = rating.toString(),
                                remarks = reviewText,
                                //consultantId = data.id.toString()
                                consultantId = (id ?: 0).toString()
                            )
                        )
                        }
                }
            )
        }

        Button(
            onClick = {
                // Validate the inputs when the button is clicked
                var isValid = true

                if (rating == 0) {
                    ratingErrorMessage = "Please select a rating"
                    isValid = false
                }

                if (reviewText.isBlank()) {
                    reviewErrorMessage = "Review text cannot be empty"
                    isValid = false
                } else if (reviewText.length > 2000) {
                    reviewErrorMessage = "Max Text Limit 2000"
                    isValid = false
                }

                if (isValid) {
                    allReviewFieldCorrect = true
                } else {
                    errorMessage = "Please correct the errors above"
                }
            },
            modifier = Modifier.fillMaxWidth(),
            colors = ButtonDefaults.buttonColors(Color(0xFF1069BC)),
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        ) {
            Text("Submit")
        }

        if (errorMessage.isNotEmpty()) {
            Text(
                text = errorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(top = 8.dp)
            )
        }
    }
}



@Composable
fun CustomRatingBar(
    maxRating: Int = 5,
    initialRating: Int = 0,
    onRatingChanged: (Int) -> Unit,

    ) {
    var rating by remember { mutableStateOf(initialRating) }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center,
        modifier = Modifier
            .fillMaxWidth()
            .padding(20.dp) // Padding around the rating bar
    ) {
        repeat(maxRating) { index ->
            val isSelected = index < rating

            // Animate the star size and scale
            val animatedSize by animateDpAsState(targetValue = if (isSelected) 58.dp else 50.dp)
            val animatedScale by animateFloatAsState(targetValue = if (isSelected) 1.2f else 1.0f)

            Icon(
                imageVector = Icons.Default.Star,
                contentDescription = null,
                modifier = Modifier
                    .size(animatedSize) // Animated size of the star
                    .padding(4.dp)
                    .clickable {
                        rating = index + 1
                        onRatingChanged(rating)
                    }
                    .graphicsLayer(
                        scaleX = animatedScale,
                        scaleY = animatedScale
                    )
                    .animateContentSize(), // Smooth transition for size changes
                tint = if (isSelected) Color(0xFFFFD700) else Color(0xFFB0B0B0) // Color change based on selection
            )
        }
    }
}


@Composable
fun RatingsLayout(rating: Double) {
    Row(
        modifier = Modifier
            .padding(20.dp)
            .fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = "What Others\nthink about the\nExpert",
            modifier = Modifier.padding(top = 16.dp)
        )

        Box(
            modifier = Modifier
                .background(Color(0xFFEEEEEE))
                .padding(8.dp)
        ) {
            Column(horizontalAlignment = Alignment.CenterHorizontally) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color(0xFFFF9C27),
                        modifier = Modifier.size(18.dp)
                    )
                    val roundedRating =
                        (rating.coerceIn(0.0, 5.0).let { round(it * 10) / 10 } ?: 5.0)
                    Text(
                        text = roundedRating.toString(),
                        modifier = Modifier.padding(start = 4.dp)
                    )
                }
                Text(
                    textAlign = TextAlign.Center,
                    text = "Average\nCustomer Rating",
                    modifier = Modifier.padding(start = 8.dp)
                )
            }
        }
    }
}

