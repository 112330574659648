package com.winiwayuser.feature_courses.persentation.course_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.winiwayuser.core.config.APP_NAME
import com.winiwayuser.core.config.RAZORPAY_COURSE_BUY_DESCRIPTION
import com.winiwayuser.core.config.RAZORPAY_KEY
import com.winiwayuser.core.config.RAZORPAY_THEME_COLOR
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.persentation.WINYWAY_IMAGE_URL
import com.winiwayuser.core.razorpay.startPayment
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_courses.common.injectFbEventForCouse
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import com.winiwayuser.feature_courses.data.request.CourseBuyRequest
import com.winiwayuser.feature_courses.data.request.CourseBuyVerifyRequest
import com.winiwayuser.feature_courses.data.request.CourseDetailsSubmitForm
import com.winiwayuser.feature_courses.domain.CourseRepo
import com.winiwayuser.feature_courses.persentation.course_page.widget.CouponDailogState
import com.winiwayuser.feature_winyway_wallet.persentation.razorpay_gateway_new.PaymentGatewayArguments
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime

class CourseComponent(
    context: ComponentContext,
    slug: String,
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)
    private val api: CourseRepo by inject()

    private val _state = MutableStateFlow<Resources<CourseInfo>>(Resources.Loading(false))
    val state get() = _state.asStateFlow()

    private val _courses = MutableStateFlow<CourseInfo?>(null)
    val courses get() = _courses.asStateFlow()

    fun onBackPress() {}
    fun onCancelCoupon(course: CourseInfo) {
        coroutineScope.launch {
            api.removeCoupon(course).collectLatest {
                if (it.data != null) _courses.emit(it.data)
            }
        }

    }

    fun onSubmitForm(
        name: String, mobile: String, email: String, courseId: String, countryCode: String
    ) {
        coroutineScope.launch {
            postCourseDetails(
                CourseDetailsSubmitForm(
                    fullName = name,
                    mobile = mobile,
                    email = email,
                    courseId = courseId,
                    countryCode = countryCode
                )
            )
        }
    }

    fun onBuyCourse(
        name: String,
        mobile: String,
        email: String,
        courseId: String,
        countryCode: String,
        couponCode: String?
    ) {
        coroutineScope.launch {
            onCourseBuy(
                CourseBuyRequest(
                    courseId = courseId,
                    userName = name,
                    userEmail = email,
                    mobile = mobile,
                    countryCode = countryCode,
                    couponCode = couponCode,
                    courseWatchOption = selectedWatchOption.value,
                    courseWatchTime = selectedTimeOption.value,
                )
            )
        }
    }

    init {
        lifecycle.doOnCreate {
            coroutineScope.launch {
                injectFbEventForCouse()
                getCoursesByRemote(slug)
            }
        }
    }

    private suspend fun getCoursesByRemote(slug: String) {
        api.getCourse(slug = slug).collectLatest {
            when (it) {
                is Resources.Error -> {
                    printLog("Courses Response Error -> ${it.msg}")
                    _state.emit(Resources.Error(it.msg))
                }

                is Resources.Loading -> {
                    printLog("Courses Response Loading -> ${it.isLoading}")
                    _state.emit(Resources.Loading(it.isLoading))
                }

                is Resources.Success -> {
                    printLog("Courses Response Success -> ${it.data}")
                    _state.emit(Resources.Success(it.data))
                    it.data?.let { course ->
                        _courses.emit(course)
                    }
                }

            }
        }
    }

    private val _event = MutableStateFlow<RegistrationEvent>(RegistrationEvent.InitialEvent)
    val event get() = _event.asStateFlow()

    fun setForEventInitial() {
        coroutineScope.launch {
            _event.emit(RegistrationEvent.InitialEvent)
        }
    }

    private val _eventBuy = MutableStateFlow<RegistrationEvent>(RegistrationEvent.InitialEvent)
    val eventBuy get() = _eventBuy.asStateFlow()

    fun setForBuyEventInitial() {
        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.InitialEvent)
        }
    }


    private suspend fun onCourseBuy(request: CourseBuyRequest) {
        api.onBuyCourse(request).collect {
            when (it) {
                is Resources.Error -> {
                    _eventBuy.emit(RegistrationEvent.OnError(it.msg ?: "Unexpected error"))
                }

                is Resources.Loading -> {
                    _eventBuy.emit(RegistrationEvent.IsLoading)
                }

                is Resources.Success -> {
                    val isPaymentRequired = it.data?.isPaymentRequired

                    if (isPaymentRequired == false) {
                        _eventBuy.emit(RegistrationEvent.OnSuccess(it.data.wistiaVideo?.wistiaData?.embedVideoUrl))
                        return@collect
                    }


                    val paymentArg = PaymentGatewayArguments(
                        orderId = it.data?.orderId.toString(),
                        paymentUserName = UserInfoProvider.getFullName(),
                        paymentUserMobile = UserInfoProvider.getMobileCode() + UserInfoProvider.getPhone(),
                        paymentUserEmail = UserInfoProvider.getEmail(),
                        razorpayKey = RAZORPAY_KEY,
                        paymentDescription = RAZORPAY_COURSE_BUY_DESCRIPTION,
                        appIcon = WINYWAY_IMAGE_URL,
                        appName = APP_NAME,
                        themeColor = RAZORPAY_THEME_COLOR
                    )


                    startPayment(
                        paymentGatewayArguments = paymentArg,
                        onPaymentSuccess = ::onSuccessPayment,
                        onPaymentFailed = ::onFailedPayment
                    )
                }
            }
        }
    }


    private suspend fun postCourseDetails(request: CourseDetailsSubmitForm) {
        api.submitCourseDetails(request).collect {
            when (it) {
                is Resources.Error -> {
                    _event.emit(RegistrationEvent.OnError(it.msg ?: "Something Wrong Happen"))
                    Logger.d { "postCourseDetails error ${it.msg}" }
                }

                is Resources.Loading -> {
                    _event.emit(RegistrationEvent.IsLoading)
                }

                is Resources.Success -> {
                    _event.emit(RegistrationEvent.OnSuccess(null))
                    Logger.d { "postCourseDetails success ${it.data}" }
                }
            }
        }
    }

    private fun onSuccessPayment(
        orderId: String, paymentId: String, signature: String
    ) {

        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.OnSuccess(null))

            api.onVerifyPayment(
                CourseBuyVerifyRequest(
                    orderId = orderId,
                    paymentId = paymentId,
                    paymentSignature = signature
                )
            ).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        Logger.d("onVerifyPayment success ${it.data}")
                    }

                    else -> Unit
                }
            }
        }

    }

    private fun onFailedPayment(exception: Throwable) {
        coroutineScope.launch {
            _eventBuy.emit(RegistrationEvent.OnError(exception.message ?: "Unexpected error"))
        }
    }

    private fun printLog(msg: String) {
        Logger.d("COURSE_LIST_PAGE - $msg")
    }

    private val _couponState = MutableStateFlow<CouponDailogState>(CouponDailogState.Message(null))
    val couponState get() = _couponState.asStateFlow()
    fun setCouponNull() {
        coroutineScope.launch {
            _couponState.emit(CouponDailogState.Message(null))
        }
    }

    fun validateCoupon(
        course: CourseInfo, coupon: String
    ) {
        coroutineScope.launch {
            api.validateCoupon(
                coupon = coupon, course
            ).collectLatest {
                when (it) {
                    is Resources.Error -> {
                        _couponState.emit(CouponDailogState.Message(it.msg ?: "Unexpected Error"))
                    }

                    is Resources.Loading -> {
                        if (it.isLoading) _couponState.emit(CouponDailogState.LoadingState)
                    }

                    is Resources.Success -> {
                        _couponState.emit(CouponDailogState.Success)
                        if (it.data != null) _courses.emit(it.data)

                    }
                }
            }
        }
    }


    /*Other Implementation for Drop down*/
// Get the current system timezone
    private val timeZone = TimeZone.currentSystemDefault()

    // Get today's date
    private val today = Clock.System.now().toLocalDateTime(timeZone).date

    // Generate formatted dates for next days
    private val nextDayFormatted = formatDate(today.plus(1, DateTimeUnit.DAY))
    private val nextAfterTomorrowFormatted = formatDate(today.plus(2, DateTimeUnit.DAY))

    // Options for first dropdown with dates
    val watchOptions = listOf(
        "Watch Now Instantly",
        "Watch Later Today",
        nextDayFormatted,   // Example: "Wed, February 26, 2025"
        nextAfterTomorrowFormatted  // Example: "Thu, February 27, 2025"
    )

    // State for selected values
    private val _selectedWatchOption = MutableStateFlow(watchOptions.first())
    val selectedWatchOption = _selectedWatchOption.asStateFlow()

    private val _timeOptions = MutableStateFlow(listOf("Start Now"))
    val timeOptions = _timeOptions.asStateFlow()

    private val _selectedTimeOption = MutableStateFlow("Start Now")
    val selectedTimeOption = _selectedTimeOption.asStateFlow()

    init {
        updateTimeOptions(watchOptions.first()) // Initialize with first option
    }

    fun onWatchOptionSelected(option: String) {
        _selectedWatchOption.value = option
        updateTimeOptions(option)
    }

    fun onTimeOptionSelected(option: String) {
        _selectedTimeOption.value = option
    }

    private fun updateTimeOptions(selectedOption: String) {
        val times = when (selectedOption) {
            "Watch Now Instantly" -> listOf("Start Now")
            "Watch Later Today" -> listOf("Watch Now") + getUpcomingHours()
            nextDayFormatted, nextAfterTomorrowFormatted -> getFullDayHours()
            else -> emptyList()
        }
        _timeOptions.value = times
        _selectedTimeOption.value = times.firstOrNull() ?: "Select Time"
    }

    private fun getUpcomingHours(): List<String> {
        val now = Clock.System.now().toLocalDateTime(timeZone)
        val currentHour = now.hour

        return (currentHour + 1..23).filter { it in 8..23 }.map { formatHour(it) }
    }

    private fun getFullDayHours(): List<String> {
        return (8..23).map { formatHour(it) }
    }

    private fun formatHour(hour: Int): String {
        val period = if (hour < 12) "AM" else "PM"
        val formattedHour = if (hour == 0) 12 else if (hour > 12) hour - 12 else hour
        return "$formattedHour:00 $period"
    }

    private fun formatDate(date: LocalDate): String {
        val dayOfWeek = date.dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }.take(3)
        val month = date.month.name.lowercase().replaceFirstChar { it.uppercase() }
        val day = date.dayOfMonth
        val year = date.year

        return "$dayOfWeek, $month $day, $year"
    }

}