package com.winiwayuser.core.persentation

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ElevatedButton
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.theme.seed
import com.winiwayuser.core.theme.white
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun CommonButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: ImageVector? = null,
    isTextVisible: Boolean = true,
    buttonColor: Color = primaryColor, // Normal button color
    contentColor: Color = Color.White, // Normal content color
//    pressedColor: Color = primaryColor.copy(alpha = 0.8f) // Pressed color
) {
    val interactionSource = remember { MutableInteractionSource() }

    Button(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.buttonElevation(
            defaultElevation = Scale.ELEVATION_MEDIUM, // Customize elevation value
            pressedElevation = Scale.ELEVATION_LARGE, // Elevation when pressed
            disabledElevation = 0.dp,
            focusedElevation = Scale.ELEVATION_MEDIUM,
            hoveredElevation = Scale.ELEVATION_MEDIUM // Elevation when disabled

        ),
        colors = ButtonDefaults.buttonColors(
            containerColor = buttonColor,
            contentColor = contentColor,
        ),
        interactionSource = interactionSource,
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            if (icon != null) {
                Icon(
                    imageVector = icon,
                    contentDescription = "Icon Button",
                    tint = white,
                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
                )
            }

            if (isTextVisible) {
                Spacer(modifier = Modifier.width(4.dp))
                AutoSizeText(
                    text = buttonText,
                    maxLines = 1,
                    color = contentColor
                )
            }
        }
    }
}

@Composable
fun HeadLineTextWithMoreButton(
    modifier: Modifier = Modifier,
    title: String ,
    onClickMoreButton: () -> Unit,
) {

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        Text(
            text = title,
            maxLines = 1,
            style = MaterialTheme.typography.titleLarge.copy(
                fontWeight = FontWeight.SemiBold,
                fontSize = 17.sp
            ),
            modifier = Modifier
                .weight(1f),
            fontWeight = FontWeight.SemiBold
        )

        SeeAllButton {
            onClickMoreButton.invoke()
        }
    }

}

@Composable
fun SeeAllButton(
    modifier: Modifier = Modifier,
    text: String = "See All",
    onClick: () -> Unit
){

    TextButton(onClick) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            Text(
                text = text,
                style = MaterialTheme.typography.labelMedium,
                textAlign = TextAlign.Center,
                fontWeight = FontWeight.SemiBold,
                color = Color.Black
            )

            Spacer(modifier.width(4.dp))

            Icon(
                imageVector = Icons.AutoMirrored.Filled.KeyboardArrowRight,
                contentDescription = "See All",
                tint = Color.White,
                modifier = Modifier.size(20.dp).clip(shape = CircleShape).background(
                    color = primaryDark,
                    shape = CircleShape
                ),
            )
        }
    }

}


/*Tonal Button*/
@Composable
fun AppTonalButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    FilledTonalButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@FilledTonalButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Outline Button */
@Composable
fun CommonOutlineButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    OutlinedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = seed,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@OutlinedButton
        }

        if (icon != null) {
            Icon(
                painterResource(icon),
                modifier = Modifier.size(10.dp),
                contentDescription = "Favorite"
            )
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

/*Elevated Button*/
@Composable
fun AppElevatedButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading:Boolean = false
) {
    ElevatedButton(
        onClick = onClick,
        modifier = modifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = Color.Blue,
            contentColor = Color.White,
            disabledContainerColor = Color.Gray,
            disabledContentColor = Color.LightGray
        ),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        contentPadding = PaddingValues(16.dp),
        elevation = ButtonDefaults.buttonElevation(Scale.ELEVATION_SMALL),
        border = BorderStroke(1.dp, Color.Black)
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@ElevatedButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun CommonTextButton(
    buttonText: String,
    modifier: Modifier,
    onClick: () -> Unit,
    icon: DrawableResource? = null,
    isLoading: Boolean = false
) {
    TextButton(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier
                    .size(24.dp)
                    .align(Alignment.CenterVertically),
                color = Color.White,
                strokeWidth = 5.dp
            )
            return@TextButton
        }

        if (icon != null) {
            Icon(painterResource(icon), contentDescription = "Favorite")
            Spacer(modifier = Modifier.width(8.dp))
        }
        Text(buttonText)
    }
}

@Composable
fun CommonLoadingButton(
    text: String,
    isLoading: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    buttonColors: ButtonColors = ButtonDefaults.buttonColors(),
    shape: Shape = RoundedCornerShape(Scale.CORNER_SMALL)
) {
    Button(
        onClick = {
            if (!isLoading) {
                onClick()
            }
        },
        colors = buttonColors,
        shape = shape,
        modifier = modifier,
    ) {
        if (isLoading) {
            CircularProgressIndicator(
                modifier = Modifier.size(24.dp),
                color = Color.White,
                strokeWidth = 2.dp
            )
        } else {
            Text(text = text)
        }
    }
}

@Composable
fun RechargeButton(
    selectedRecharge: RechargeData?,
    onRechargeSelected: (RechargeData) -> Unit
) {
    Button(
        onClick = {
            selectedRecharge?.let {
                onRechargeSelected(it)
            } ?: run {
                // Handle case where no amount is selected
                // Show a Toast, Snackbar, or any appropriate feedback
            }
        },
        modifier = Modifier
            .fillMaxWidth()
            .height(50.dp),
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.primary)
    ) {
        Text(
            text = "Recharge Now",
            color = MaterialTheme.colorScheme.onPrimary,
            fontWeight = FontWeight.Bold
        )
    }
}