package com.winiwayuser.feature_common.persentation.more_tab.component

import androidx.compose.runtime.Composable
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.persentation.BasePagingSource
import com.winiwayuser.core.persentation.PaginationItems
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.ChatConversationState
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.api.ChatHistoryApi
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.request.ChatHistoryDetailRequest
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.request.ChatHistoryListRequest
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.response.ChatConversationData
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.response.ChatHistoryListData
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject


//class ChatConversationComponent(
//    context: ComponentContext,
//    private val _chatId: String,
//    private val _expertName:String,
//    private val _expertImage:String,
//    private val _chatDuration:String,
//    private val onBackPress: () -> Unit
//) : ComponentContext by context, KoinComponent,  BasePagingSource<ChatConversationData>() {
//    val chatId get() = _chatId
//    val expertName get() = _expertName
//    val expertImage get() = _expertImage
//    val chatDuration get() = _chatDuration
//    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
//        Logger.e { "Coroutine Exception: ${throwable.message}" }
//        throwable.printStackTrace()  // Ensure you print the full stack trace for debugging
//    }
//
//    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
//    private val _state = MutableStateFlow<ChatConversationState>(ChatConversationState.Loading)
//    val state: StateFlow<ChatConversationState> = _state
//    fun onBackPress() = onBackPress.invoke()
//    private val api: ChatHistoryApi by inject()
//
//    // Page number for pagination
//    private var currentPage = 1
//    var isLastPage = false
//     var isLoading = false
//
//    init {
//        lifecycle.doOnStart {
//            Logger.d { "Chat Conversation History Screen Component onStart" }
//            coroutineScope.launch {
//                loadNextPage()
//            }
//        }
//
//        lifecycle.doOnDestroy {
//            Logger.d { "Chat History Component onDestroy" }
//            coroutineScope.cancel()
//        }
//    }
//
//    private suspend fun loadNextPage() {
//        if (isLoading || isLastPage) return
//
//        isLoading = true
//        Logger.d { "Loading page: $currentPage" }
//
//        val request = ChatHistoryDetailRequest(page = currentPage, chatId = chatId)
//
//        try {
//            _state.value = ChatConversationState.Loading  // Update state to loading
//            val response = api.loadChatConversationData(request)
//
//            when (response) {
//                is Resources.Error -> {
//                    Logger.d { "Error fetching chat history: ${response.msg}" }
//                    _state.value = ChatConversationState.Error(response.msg!!)
//                }
//                is Resources.Loading -> {
//                    Logger.d { "Loading: ${response.isLoading}" }
//                }
//                is Resources.Success -> {
////                    val chatConversationListData = response.data?.chats?.chatMassages
////                        ?.filterIsInstance<ChatConversationData?>() ?: emptyList()
//                    val chatConversationListData = response.data
//
//                    Logger.d { "Fetched chat history: ${response.data}" }
//
//                    if (chatConversationListData == null) {
//                        isLastPage = true
//                        _state.value = ChatConversationState.Success(emptyList())  // No more pages
//                    } else {
//                        // Combine the current state with the new chat messages
//                        val currentState = (state.value as? ChatConversationState.Success)?.chatMessages ?: emptyList()
//                        val newChatHistory = currentState + chatConversationListData
//                        _state.value = ChatConversationState.Success(newChatHistory)
//                        currentPage++
//                    }
//                }
//            }
//        } catch (e: Exception) {
//            Logger.e { "Exception occurred while loading chat history: ${e.message}" }
//            _state.value = ChatConversationState.Error(e.message ?: "Unknown error")
//        } finally {
//            isLoading = false
//        }
//    }
//
//    fun loadMoreChatHistory() {
//        Logger.d { "loadMoreChatHistory function call" }
//        coroutineScope.launch {
//            loadNextPage()
//        }
//    }
//
//    override suspend fun fetchData(page: Int, limit: Int): PaginationItems<ChatConversationData> {
//        // Example: You would replace this with actual logic to fetch chat conversation data
//        return try {
//            val chatData = // Fetch the conversation data from your API or repository
//                PaginationItems(
//                    items = chatData // A list of ChatConversationData objects
//                )
//        } catch (e: Exception) {
//            // Handle any errors
//            throw e
//        }
//    }
//}


//class ChatConversationComponent(
//    context: ComponentContext,
//    private val _chatId: String,
//    private val _expertName: String,
//    private val _expertImage: String,
//    private val _chatDuration: String,
//    private val onBackPress: () -> Unit
//) : ComponentContext by context, KoinComponent, BasePagingSource<ChatConversationData>() {
//
//    // Public getters for data
//    val chatId get() = _chatId
//    val expertName get() = _expertName
//    val expertImage get() = _expertImage
//    val chatDuration get() = _chatDuration
//    fun onBackPress() = onBackPress.invoke()
//    // Exception handler for coroutines
//    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
//        Logger.e { "Coroutine Exception: ${throwable.message}" }
//        throwable.printStackTrace() // Print full stack trace for debugging
//    }
//
//    // Coroutine scope
//    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
//
//    // State for managing UI
//    private val _state = MutableStateFlow<ChatConversationState>(ChatConversationState.Loading)
//    val state: StateFlow<ChatConversationState> = _state
//
//    // Dependency injection of API
//    private val api: ChatHistoryApi by inject()
//
//    // Pagination properties
//    private var currentPage = 1
//    var isLastPage = false
//    var isLoading = false
//
//    init {
//        lifecycle.doOnStart {
//            Logger.d { "Chat Conversation History Screen Component onStart" }
//            coroutineScope.launch {
//                //loadNextPage()
//            }
//        }
//
//        lifecycle.doOnDestroy {
//            Logger.d { "Chat History Component onDestroy" }
//            coroutineScope.cancel()
//        }
//    }
//
//    // Fetch data for the current page
//    private suspend fun loadNextPage() {
//        if (isLoading || isLastPage) return
//
//        isLoading = true
//        Logger.d { "Loading page: $currentPage" }
//
//        val request = ChatHistoryDetailRequest(page = currentPage, chatId = chatId)
//
//        try {
//            _state.value = ChatConversationState.Loading  // Set state to loading
//            val response = api.loadChatConversationData(request)
//
//            when (response) {
//                is Resources.Error -> {
//                    Logger.d { "Error fetching chat history: ${response.msg}" }
//                    _state.value = ChatConversationState.Error(response.msg!!)
//                }
//                is Resources.Loading -> {
//                    Logger.d { "Loading: ${response.isLoading}" }
//                }
//                is Resources.Success -> {
//                    val chatConversationListData = response.data
//
//                    Logger.d { "Fetched chat history: ${response.data}" }
//
//                    if (chatConversationListData == null) {
//                        isLastPage = true
//                        _state.value = ChatConversationState.Success(emptyList())  // No more pages
//                    } else {
//                        // Combine the current state with the new chat messages
//                        val currentState = (state.value as? ChatConversationState.Success)?.chatMessages ?: emptyList()
//                        val newChatHistory = currentState + chatConversationListData
//                        _state.value = ChatConversationState.Success(newChatHistory)
//                        currentPage++ // Increment page number
//                    }
//                }
//            }
//        } catch (e: Exception) {
//            Logger.e { "Exception occurred while loading chat history: ${e.message}" }
//            _state.value = ChatConversationState.Error(e.message ?: "Unknown error")
//        } finally {
//            isLoading = false
//        }
//    }
//
//    // Trigger to load more chat history
////    fun loadMoreChatHistory() {
////        Logger.d { "loadMoreChatHistory function call" }
////        coroutineScope.launch {
////            loadNextPage()
////        }
////    }
//
//    // Implement fetchData from BasePagingSource
//    override suspend fun fetchData(page: Int, limit: Int): PaginationItems<ChatConversationData> {
//        return try {
//            val request = ChatHistoryDetailRequest(page = page, chatId = chatId)
//            val response = api.loadChatConversationData(request)
//
//            when (response) {
//                is Resources.Error -> {
//                    Logger.d { "Error fetching chat history: ${response.msg}" }
//                    throw Exception(response.msg ?: "Unknown error")
//                }
//                is Resources.Loading -> {
//                    Logger.d { "Loading chat data..." }
//                    PaginationItems(emptyList())
//                }
//                is Resources.Success -> {
//                    val chats = response.data
//                    val chatData: List<ChatConversationData> = listOf(chats!!)
//
//                    val chatConversationListData = response.data
//
//                    Logger.d { "Fetched chat history: ${response.data}" }
//
//                    if (chatConversationListData == null) {
//                        isLastPage = true
//                        _state.value = ChatConversationState.Success(emptyList())  // No more pages
//                    } else {
//                        // Combine the current state with the new chat messages
//                        val currentState = (state.value as? ChatConversationState.Success)?.chatMessages ?: emptyList()
//                        val newChatHistory = currentState + chatConversationListData
//                        _state.value = ChatConversationState.Success(newChatHistory)
//                    }
//
//                    PaginationItems(items = chatData)
//                }
//            }
//        } catch (e: Exception) {
//            Logger.e { "Exception occurred: ${e.message}" }
//            throw e
//        }
//    }
//}

class ChatConversationComponent(
    context: ComponentContext,
    private val _chatId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _chatDuration: String,
    private val onBackPress: () -> Unit
) : ComponentContext by context, KoinComponent, BasePagingSource<ChatConversationData>() {

    // Public getters for data
    val chatId get() = _chatId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val chatDuration get() = _chatDuration
    fun onBackPress() = onBackPress.invoke()

    // Coroutine scope
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)

    // State for managing UI
    private val _state = MutableStateFlow<ChatConversationState>(ChatConversationState.Loading)
    val state: StateFlow<ChatConversationState> = _state

    // Dependency injection of API
    private val api: ChatHistoryApi by inject()

    // Pagination properties
    private var currentPage = 1
    var isLastPage = false
    var isLoading = false

    init {
        lifecycle.doOnStart {
            Logger.d { "Chat Conversation History Screen Component onStart" }
            loadChatHistory()
        }

        lifecycle.doOnDestroy {
            Logger.d { "Chat History Component onDestroy" }
            coroutineScope.cancel()
        }
    }

    // Load chat history
    fun loadChatHistory() {
        coroutineScope.launch {
            try {
                fetchData(currentPage, 10) // Assuming a limit of 20 messages per page
            } catch (e: Exception) {
                Logger.e { "Failed to load chat history: ${e.message}" }
                _state.value = ChatConversationState.Error(e.message ?: "Unknown error")
            }
        }
    }

    // Implement fetchData from BasePagingSource
    override suspend fun fetchData(page: Int, limit: Int): PaginationItems<ChatConversationData> {
        return try {
            val request = ChatHistoryDetailRequest(page = page, chatId = chatId)
            val response = api.loadChatConversationData(request)

            when (response) {
                is Resources.Error -> {
                    Logger.d { "Error fetching chat history: ${response.msg}" }
                    throw Exception(response.msg ?: "Unknown error")
                }
                is Resources.Loading -> {
                    Logger.d { "Loading chat data..." }
                    PaginationItems(emptyList())
                }
                is Resources.Success -> {
                    val chats = response.data
                    val chatData: List<ChatConversationData> = listOfNotNull(chats)

                    Logger.d { "Fetched chat history: ${response.data}" }

                    if (chats == null) {
                        isLastPage = true
                        _state.value = ChatConversationState.Success(emptyList())
                    } else {
                        val currentState = (state.value as? ChatConversationState.Success)?.chatMessages ?: emptyList()
                        val newChatHistory = currentState + chatData
                        _state.value = ChatConversationState.Success(newChatHistory)
                        currentPage++
                    }
                    PaginationItems(items = chatData)
                }
            }
        } catch (e: Exception) {
            Logger.e { "Exception occurred: ${e.message}" }
            throw e
        }
    }
}
