package com.winiwayuser.feature_expert_consultation.presentation.expert_search_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_expert_consultation.data.remote.SearchExpertApi
import com.winiwayuser.feature_expert_consultation.data.remote.request.SearchExpertRequest
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class SearchScreenComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit
): ComponentContext by context, KoinComponent {

    fun event(event: SearchScreenEvent) {
        when (event) {
            SearchScreenEvent.OnBackKeyPress -> onBackPress.invoke()
        }
    }

    init {
        lifecycle.doOnStart {
            Logger.d { "Search Screen Component onStart" }

        }

        lifecycle.doOnDestroy {
            Logger.d { "Search Screen Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }

    }

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val searchExpertApi: SearchExpertApi by inject()
    private val _state = MutableStateFlow<List<RequestConsultant>>(emptyList())
    val state: StateFlow<List<RequestConsultant>> get() = _state

    fun getExpertByRemote(request: SearchExpertRequest) {
        coroutineScope.launch {
            searchExpertApi.getExpertListRemote(request).collect {
                when (it) {
                    is Resources.Success -> {
                        //_state.value = (it.data?.consultants ?: emptyList()) as List<RequestConsultant>
                        _state.value = it.data?.consultants?.data!!
                    }

                    else -> Unit
                }
            }
        }
    }
}