package com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure

import com.arkivanov.decompose.ComponentContext

class RechargeFailureComponent(
    context: ComponentContext,
    private val _topUpAmount: String,
    private val rechargeAgain: () -> Unit,
    private val getSupport: () -> Unit
) : ComponentContext by context {

    val topUpAmount get() = _topUpAmount

    fun onClickGetSupport() = getSupport.invoke()
    fun onClickRechargeAgain() = rechargeAgain.invoke()

}