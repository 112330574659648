package com.winiwayuser.feature_common.data.remote

import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.data.response.CategoryData
import com.winiwayuser.feature_common.data.response.UpcomingLiveData
import com.winiwayuser.feature_common.data.response.RequestCategoryResponse
import com.winiwayuser.feature_common.data.response.RequestDashboardResponse
import com.winiwayuser.feature_common.data.response.RequestSliderResponse
import com.winiwayuser.feature_common.data.response.RequestUpcomingLiveSessionResponse
import com.winiwayuser.feature_common.data.response.wallet_balance.WalletRupeeResponse
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class HomeApiService : KtorApi() {

    companion object {
        const val GET_REQUEST_SLIDER = "sliders"
        const val GET_REQUEST_LIVE_SESSIONS = "upcoming-live-session"
        const val GET_REQUEST_CATEGORY = "categories"
        const val GET_REQUEST_TOP_CONSULTANT = "user-dashboard"
        const val GET_WALLET_MONEY = "get-wallet-balance"
        const val GET_ALL_POST = "getallpost"
        const val GET_SHORTS = "get-instagram-reels"
        const val GET_TOP_PRODUCTS = "woocommerce-products"
        const val GET_RELATED_EXPERT = "sort-by-expert-category"

    }

//    private suspend fun getProduct(): TopProductResponse {
//        return client.get {
//            url(GET_TOP_PRODUCTS)
//        }.body()
//    }

//    private suspend fun getAllPost(request: PostRequest): PostPagingResponse {
//        return client.get {
//            url(GET_ALL_POST)
//            parameter("page", request.page)
//        }.body()
//    }

//    private suspend fun getTodayShorts() = client.get {
//        url(GET_SHORTS)
//    }.body<InstaListResponse>()


//    private suspend fun getRelatedExpert(data: BestCallAndChatExpertRequest) = client.post {
//        url(GET_RELATED_EXPERT)
//        setBody(data)
//    }.body<BsetExpertResponse>()

    suspend fun requestHomeScreenSlider(): Resources<RequestSliderResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val sliderResponse =
                    client.post { url(GET_REQUEST_SLIDER) }.body<RequestSliderResponse>()
                if (sliderResponse.status) Resources.Success(sliderResponse) else throw Exception(
                    sliderResponse.message
                )
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenUpcomingLiveSessions(): Resources<RequestUpcomingLiveSessionResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val upcomingLiveSessions = client.post(GET_REQUEST_LIVE_SESSIONS)
                    .body<RequestUpcomingLiveSessionResponse>()

                if (upcomingLiveSessions.data != null) {
                    val upcoming = upcomingLiveSessions.data?.Upcoming
                    val maxUpcomingList = ArrayList(upcoming?.take(5) ?: emptyList())
                    val upcomingLiveData = UpcomingLiveData(maxUpcomingList)
                    Resources.Success(RequestUpcomingLiveSessionResponse(upcomingLiveData))
                } else {
                    throw Exception("Response data is null")
                }
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenCategory(): Resources<CategoryData> {
        return withContext(Dispatchers.Default) {
            try {
                val categoryResponse =
                    client.post(GET_REQUEST_CATEGORY).body<RequestCategoryResponse>()

                run {
                    val categories = categoryResponse.data.categories
                    Resources.Success(CategoryData(categories))
                }
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun requestHomeScreenTopConsultant(): Resources<RequestDashboardResponse> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = client.get(GET_REQUEST_TOP_CONSULTANT).body<RequestDashboardResponse>()
                Resources.Success(topConsultantResponse)
            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

//    suspend fun requestGetAllPost(request: PostRequest): Resources<PostPagingData> {
//        return withContext(Dispatchers.Default) {
//            try {
//                val response = getAllPost(request)
//                if (!response.status.equals("success", true)) {
//                    return@withContext Resources.Error(
//                        "Unexpected Error"
//                    )
//                }
//                return@withContext Resources.Success(response.postPagingData)
//            } catch (e: ClientRequestException) {
//                Resources.Error("Client request error: ${e.message}")
//            } catch (e: ServerResponseException) {
//                Resources.Error("Server response error: ${e.message}")
//            } catch (e: Exception) {
//                Resources.Error("Unexpected error: ${e.message}")
//            }
//        }
//    }

//    suspend fun requestGetTodayShorts(): Resources<List<InstaList>> {
//        return withContext(Dispatchers.Default) {
//            try {
//                val response = getTodayShorts()
//                if (response.status.not()) {
//                    return@withContext Resources.Error(
//                        "Unexpected Error"
//                    )
//                }
//                return@withContext Resources.Success(response.data)
//            } catch (e: ClientRequestException) {
//                Resources.Error("Client request error: ${e.message}")
//            } catch (e: ServerResponseException) {
//                Resources.Error("Server response error: ${e.message}")
//            } catch (e: Exception) {
//                Resources.Error("Unexpected error: ${e.message}")
//            }
//        }
//    }

//    suspend fun getExpert(request: BestCallAndChatExpertRequest): Resources<DataCat> {
//        return withContext(Dispatchers.Default) {
//            try {
//                val response = getRelatedExpert(request)
//                println(response)
//                if (response.data == null) {
//                    return@withContext Resources.Error(
//                        "Unexpected Error"
//                    )
//                }
//                return@withContext Resources.Success(response.data)
//            } catch (e: ClientRequestException) {
//                Resources.Error("Client request error: ${e.message}")
//            } catch (e: ServerResponseException) {
//                Resources.Error("Server response error: ${e.message}")
//            } catch (e: Exception) {
//                Resources.Error("Unexpected error: ${e.message}")
//            }
//        }
//    }

//    suspend fun getTopProductsApi(): Resources<List<TopProductList>?> {
//        return makeApiCall(
//            apiCall = { getProduct() },
//            transform = { it.topProductList }
//        )
//    }
}