package com.winiwayuser.feature_expert_consultation.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestReviewSubmit(
    @SerialName("rating") val rating: String,
    @SerialName("remarks") val remarks: String,
    @SerialName("consultant_id") val consultantId: String
)