package com.winiwayuser.feature_chat_consultation_new.data.request

import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SaveChatConversationRequest(
    @SerialName("messages") val msg:Array<ChatMessage>,
    @SerialName("chat_id")val chatId:String
)