package com.winiwayuser.feature_common.persentation.more_tab.component

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.EditProfileApiService
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.EditProfileRequest
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.data.ProfileDetails
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.di.EditProfileState
import com.winiwayuser.feature_common.persentation.more_tab.edit_profile.domain.EditProfileUserUseCases
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject


class EditProfileComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api = EditProfileApiService()
    private val onEditProfileUserUseCases: EditProfileUserUseCases by inject()

    private val _updateProfile = MutableStateFlow<ProfileDetails?>(null)
    val updateProfile: StateFlow<ProfileDetails?> get() = _updateProfile

    private val _state = MutableStateFlow<EditProfileState>(EditProfileState.OnLoading(false))
    val state: StateFlow<EditProfileState> get() = _state

    init {
        Logger.d { "Edit Profile Screen Tab Component initialized" }
        lifecycle.doOnStart {
//            submitUpdateProfile()
            Logger.d { "Edit Profile Screen Tab Component onDestroy" }
        }
        lifecycle.doOnDestroy { Logger.d { "Edit Profile Screen Tab Component onDestroy" } }
    }

    private fun submitUpdateProfile(request: EditProfileRequest) {
        coroutineScope.launch {
            val response = onEditProfileUserUseCases.onEditProfile(request)
            response.collect {

                when (it) {
                    is Resources.Error -> {
                        Logger.d("submit otp response error -> ${it.msg}")
                        onErrorState(it.msg ?: "Unexpected Error")
                    }

                    is Resources.Loading -> {
                        Logger.d("submit otp response loading -> ${it.isLoading}")
                        onLoadingState(it.isLoading)
                    }

                    is Resources.Success -> {
                        onSuccessState(it.data)
                    }
                }
            }
        }
    }



    private fun onSuccessState(data: ProfileDetails?) {
        _state.value = EditProfileState.onSuccess(data!!)
    }

    private fun onErrorState(message: String) {
        _state.value = EditProfileState.OnError(message)
    }

    private fun onLoadingState(isLoading: Boolean) {
        _state.value = EditProfileState.OnLoading(isLoading)
    }


    fun event(event:EditProfileEvent){
//        onEditProfileEvent.invoke(event)
        when(event){
            EditProfileEvent.OnBackKeyPress -> onBackPress.invoke()
            is EditProfileEvent.OnSaveProfileButtonClick -> {
                submitUpdateProfile(event.data)
            }
        }
    }
}