package com.winiwayuser.feature_winyway_wallet.data.response.all_coupon

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AllCouponsResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val `data`: CouponData,
    @SerialName("message") val message: String, // List fetched successfully
    @SerialName("status") val status: Boolean // true
)