package com.winiwayuser.feature_courses.data.response.status_verify

import com.winiwayuser.core.data.CommonResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseBuyStatusResponse(
    @SerialName("data") val data: String
): CommonResponse()
