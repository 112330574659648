package com.winiwayuser.core.extenstion

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.ui.Modifier

fun WindowWidthSizeClass.getMaxInputAbleWidthModifier(): Modifier {
    return when (this) {
        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.6f)
        else -> Modifier.fillMaxWidth(0.45f)
    }
}