package com.winiwayuser.feature_courses.di

import org.koin.dsl.module
import com.winiwayuser.feature_courses.data.CoursesApi
import com.winiwayuser.feature_courses.domain.CourseRepo
import com.winiwayuser.feature_courses.domain.CoursesRepo
import com.winiwayuser.feature_courses.domain.LandingPageRepo

val coursePageModel = module {
    single { CoursesApi() }
    factory { LandingPageRepo(get()) }
    factory { CoursesRepo(get()) }
    factory { CourseRepo(get()) }
}