package com.winiwayuser.feature_courses.data.response.coupon

import com.winiwayuser.core.data.CommonResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ValidateCouponResponse(
    @SerialName("data") val data: ValidateCouponData?,
): CommonResponse()

@Serializable
data class ValidateCouponData(
    @SerialName("cashback") val cashback: String?,
    @SerialName("coupon_id") val couponId: Int?,
    @SerialName("coupon_code") val couponCode: String?
)