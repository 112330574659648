package com.winiwayuser.feature_winyway_wallet.data.response.razorpay_order_id


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RazorPayOrderData(
    @SerialName("razorpay_order_id")
    val razorpayOrderId: String?,
    @SerialName("referbalance")
    val referbalance: Referbalance,
    @SerialName("transation_details")
    val transationDetails: TransationDetails
)