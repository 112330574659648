package com.winiwayuser.core.remote

import co.touchlab.kermit.Logger
import com.winiwayuser.core.data.CommonResponse
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

suspend fun <T : CommonResponse, R> makeApiCall(
    apiCall: suspend () -> T,
    transform: (T) -> R
): Resources<R> {
    return withContext(Dispatchers.Default) {
        try {
            val response = apiCall()
            Logger.d("Response -> $response")

            if (!response.status) {
                return@withContext Resources.Error(response.msg)
            }
            // Transform the response to the desired type (extract data)
            val result = transform(response)
            return@withContext Resources.Success(result)
        } catch (e: ClientRequestException) {
            Resources.Error("Client request error: ${e.message}")
        } catch (e: ServerResponseException) {
            Resources.Error("Server response error: ${e.message}")
        } catch (e: IOException) {
            Resources.Error("No internet connection: ${e.message}")
        } catch (e: Exception) {
            Resources.Error("Unexpected error: ${e.message}")
        }
    }
}