package com.winiwayuser.feature_landing_page.persentation

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_landing_page.data.domain.LandingPageRepo
import com.winiwayuser.feature_landing_page.data.request.CourseDetailsSubmitForm
import com.winiwayuser.feature_landing_page.data.state.LandingPageState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class LandingPageComponent(
    context: ComponentContext,
    private val onBackButtonClick: () -> Unit
):ComponentContext by context, KoinComponent {
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: LandingPageRepo by inject()
    private val _state = MutableStateFlow<LandingPageState>(LandingPageState.Loading(false))
    val state get() = _state.asStateFlow()
    init {
        lifecycle.doOnStart {
            Logger.d { "Landing Page onStart" }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private fun onSuccess(conformation:Boolean){
        coroutineScope.launch {
            _state.emit(LandingPageState.Success(conformation))
            showToast("Thank you for submitting the form! Our team will be in touch with you shortly.")
        }

    }
    fun onNavigationBack() = onBackButtonClick.invoke()

    suspend fun postCourseDetails(request: CourseDetailsSubmitForm){
        api.submitCourseDetails(request).collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "postCourseDetails error ${it.msg}" }
//                    if(it.msg.equals("Quote request saved successfully",true)){
//                        onSuccess(true)
//                    }
                    _state.emit(LandingPageState.Error(it.msg ?: "Unexpected Error"))
                }
                is Resources.Loading -> {
                    Logger.d { "postCourseDetails loading ${it.isLoading}" }
                    _state.emit(LandingPageState.Loading(it.isLoading))
                }
                is Resources.Success -> {
                    Logger.d { "postCourseDetails success ${it.data}" }
                    Logger.d { "postCourseDetails success ${it.msg}" }
                    onSuccess(it.data!!)
                }
            }
        }
    }
}