package com.winiwayuser.feature_chat_consultation_new.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ConcernQuestionRequest(
    @SerialName("category") val category:String,
    @SerialName("type") val type:String?,
    @SerialName("expert_id")val expertId:String?
)
