package com.winiwayuser.feature_winyway_wallet.di

import com.winiwayuser.feature_winyway_wallet.data.api.WalletRechargeApi
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import org.koin.dsl.module

//val walletModule = module {
//    scope<RechargeCheckoutComponent> {
//        scoped { WalletRechargeApi() }
//    }
//}

val walletModule = module {
    factory { WalletRechargeApi() }
}
