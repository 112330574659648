package com.winiwayuser.feature_common.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestUpComingLiveSessions(
    @SerialName("live_id") var liveId: Int? = null,
    @SerialName("expert_id") var expertId: Int? = null,
    @SerialName("profile_image") var expertProfile: String? = null,
    @SerialName("expert_name") var expertName: String? = null,
    @SerialName("expert_category") var expertCategory: Int? = null,
    @SerialName("live_topic") var liveTopic: String? = null,
    @SerialName("live_date") var liveDate: String? = null,
    @SerialName("live_time") var liveTime: String? = null,
    @SerialName("status") var status: String? = null,
    @SerialName("live_Status") var liveStatus: String? = null,
    @SerialName("notify_status") var notifyButtonStatus: Int? = null,
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("profile_description") var profileDescription: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null
)