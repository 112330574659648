package com.winiwayuser.feature_common.persentation.home_tab

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.persentation.HomePageLoadingProgressDialog
import com.winiwayuser.core.platform.isJS
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.zoho_sales_iq.injectZohoSales
import com.winiwayuser.core.zoho_sales_iq.onClickSupport
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.persentation.home_tab.footer.Footer
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.ConsultantListItemUI
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.SearchFieldPreview
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.ToolBar
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.TopCategoryList
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.TopLiveSessionUI
import com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget.WelcomeSlide
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.baseline_support_agent_24
import org.jetbrains.compose.resources.painterResource

fun HomeScreenTabComponent.onCategoryClick(categoryId: RequestCategory) {
    event(HomePageEvent.OnClickCategory(categoryId))
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun HomeScreenTab(
    component: HomeScreenTabComponent
) {

    val sliderState by component.slidersData.collectAsState()
    val consultantState by component.topConsultant.collectAsState(null)
//    val topHomeProduct by component.topProducts.collectAsState(null)
//    val shortsState by component.dalyShorts.collectAsState(emptyList())
//    val postState by component.postState.collectAsState(emptyList())
    val bestCallExpertState by component.bestCallExpert.collectAsState(emptyList())
    val bestChatExpertState by component.bestChatExpert.collectAsState(emptyList())

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    LaunchedEffect(Unit) {
        injectZohoSales()
    }


    // Track scroll state
    val scrollState = rememberScrollState()
    // Detect scroll direction and hide TopBar when scrolling down

    Scaffold(
        containerColor = background_color,
        topBar = {

            ToolBar(
                onClickLogin = { component.event(HomePageEvent.OnClickLogin) },
                onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
                onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
                component = component
            )

        }, floatingActionButton = {
            if (!isJS()) {
                FloatingActionButton(
                    onClick = { onClickSupport() },
                    containerColor = primaryDark,
                    shape = CircleShape
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_support_agent_24),
                        contentDescription = "Support",
                        tint = Color.White
                    )
                }
            }
        }) { innerPadding ->

        Column(
            modifier = Modifier.fillMaxSize().padding(innerPadding).verticalScroll(scrollState)

        ) {

            if (windowSizeClass == WindowWidthSizeClass.Compact) {

                SearchFieldPreview(
                    elevation = Scale.ELEVATION_SMALL,
                    modifier = Modifier.fillMaxWidth(),
                    onClick = {
                        component.event(HomePageEvent.OnSearchClick(""))
                    })

                Spacer(modifier = Modifier.height(8.dp))
            } else {
                Spacer(modifier = Modifier.height(8.dp))
            }

            AnimatedContent(targetState = sliderState) { state ->
                WelcomeSlide(state, modifier = widthModifier)
            }

            AnimatedContent(
                component
            ) { state ->
                TopCategoryList(
                    onCategoryClick ={
                        component.event(HomePageEvent.OnClickCategory(it))
                    },
                    component = state,
                    modifier = widthModifier,
                    windowWidthSizeClass = windowSizeClass
                )
            }

            AnimatedContent(
                targetState = consultantState
            ) { state ->
                ConsultantListItemUI(
                    modifier = widthModifier,
                    context = component,
                    component = state,
                    onLoginClick = {
                        component.event(HomePageEvent.OnClickLogin)
                    },
                    onChatConsultation = { chatData, duration, category ->
                        component.event(
                            HomePageEvent.OnChatConsultationStart(
                                consultantId = chatData.id!!,
                                consultantName = chatData.full_name!!,
                                consultantImage = chatData.profile_image!!,
                                consultantSkills = chatData.skills!!,
                                chatDuration = duration,
                                category = category
                            )
                        )
                    },
                    onItemClick = { consultant ->
                        val category_id: Int? = consultant.id
                        if (category_id != null) {
                            component.event(
                                HomePageEvent.OnClickTopConsultant(
                                    category_id
                                )
                            )
                        }
                    },
                    onCallConsultation = { callData ->
                        component.event(
                            HomePageEvent.OnCallConsultationStart(
                                consultantId = callData.id!!,
                                consultantName = callData.full_name!!,
                                consultantImage = callData.profile_image!!,
                                consultantSkills = callData.skills!!
                            )
                        )
                    },
                    onRechargeRequired = { rechargeData ->
                        component.event(
                            HomePageEvent.OnRequiredRecharge(
                                rechargeData
                            )
                        )
                    },
                    onVoIPClick = { duration, expertInfo -> },
                    onNavigateCallWaitingPage = { CommonExpertInfo, onClose -> },
                    onLowBalance = {
                        component.event(HomePageEvent.OnClickWalletIcon)
                    },
                    onSeeAllClick = { category ->
                        //Logger.d { "Subcategory $category" }
                        component.event(HomePageEvent.OnClickCategory(category))
                    }
                )
            }

//            topHomeProduct?.let {
//                AnimatedContent(it) { state ->
//                    HomeTopProductSegment(
//                        widthModifier,
//                        state,
//                        onClickProduct = { productUrl ->
//                            openUrlInBrowser(productUrl)
//                        },
//                        onClickSeeAll = {
//                            openUrlInBrowser(WINYWAY_SHOP_LINK)
//                        }
//                    )
//                }
//            }


//            AnimatedContent(targetState = shortsState) { state ->
//                VideoCardList(
//                    modifier = widthModifier,
//                    component = state,
//                    onSeeAllClick = {
//                        showToast("Coming Soon")
//                    }
//                )
//            }

           // Spacer(modifier = Modifier.height(10.dp))

//            AnimatedContent(targetState = postState) { state ->
//                PostCardList(
//                    modifier = widthModifier,
//                    component = state,
//                    onSeeAllClick = {
//                        component.event(HomePageEvent.OnClickPostSeeAll)
//                    },
//                    onReadPostClick = { post ->
//                        component.event(HomePageEvent.OnClickReadPost(post.tableId.toString()))
//                    }
//                )
//            }
//            var isVisible by remember { mutableStateOf(true) }
//
//            AnimatedContent(targetState = isVisible, label = "BestChatExpertAnimation") {
//                BestChatExpertGrid(
//                    modifier = widthModifier
//                )
//            }

//            AnimatedContent(bestChatExpertState) { state ->
//                BestChatExpertGrid(
//                    modifier = widthModifier,
//                    expertList = state,
//                    title = "Best Chat Expert",
//                    context = component,
//                    onSeeAllClick = { category ->
//                        component.event(HomePageEvent.OnClickCategory(category))
//                    },
//                    onItemClick = { consultant->
//                        val categoryId: Int? = consultant.id
//                        if (categoryId != null) {
//                            component.event(
//                                HomePageEvent.OnClickTopConsultant(
//                                    categoryId
//                                )
//                            )
//                        }
//                    }
//                )
//            }

//            AnimatedContent(bestCallExpertState) { state ->
//                BestChatExpertGrid(
//                    modifier = widthModifier,
//                    expertList = state,
//                    title = "Best Call Expert",
//                    context = component,
//                    onSeeAllClick = { category ->
//                        component.event(HomePageEvent.OnClickCategory(category))
//                    },
//                    onItemClick = { consultant->
//                        val categoryId: Int? = consultant.id
//                        if (categoryId != null) {
//                            component.event(
//                                HomePageEvent.OnClickTopConsultant(
//                                    categoryId
//                                )
//                            )
//                        }
//                    }
//                )
//            }


            Spacer(modifier = Modifier.height(10.dp))

            if (isJS()) {
                Footer(component)
            }

        }
    }
}



//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun HomeScreenTab(component: HomeScreenTabComponent) {
//    //val isLoadingComplete by component.isLoadingComplete.collectAsState()
//
//    val sliderState by component.slidersData.collectAsState()
//    val consultantState by component.topConsultant.collectAsState(null)
////    val topHomeProduct by component.topProducts.collectAsState(null)
////    val shortsState by component.dalyShorts.collectAsState(emptyList())
////    val postState by component.postState.collectAsState(emptyList())
//    val bestCallExpertState by component.bestCallExpert.collectAsState(emptyList())
//    val bestChatExpertState by component.bestChatExpert.collectAsState(emptyList())
//
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val widthModifier = when (windowSizeClass) {
//        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
//        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
//        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
//        else -> WINDOW_WIDTH_SIZE_ELSE
//    }
//
//    LaunchedEffect(Unit) {
//        injectZohoSales()
//    }
//
//
//    // Track scroll state
//    val scrollState = rememberScrollState()
//
//    if (sliderState.data.isNullOrEmpty()) {
//        Box(
//            modifier = Modifier.fillMaxSize().background(background_color),
//            contentAlignment = Alignment.Center
//        ) {
//            HomePageLoadingProgressDialog(
//                onDismissRequest = { /* Handle dismiss if necessary */ },
//                backgroundColor = Color(0xFF2A2A2A),
//                loadingColor = Color.White
//            )
//        }
//    } else {
//        Box(
//            modifier = Modifier.fillMaxSize().background(background_color),
//            contentAlignment = Alignment.Center,
//        ) {
//            Box(
//                modifier = widthModifier.fillMaxSize().background(background_color),
//                contentAlignment = Alignment.Center
//            ) {
//                Scaffold(topBar = {
//                    ToolBar(
//                        onClickLogin = {
//                            component.event(HomePageEvent.OnClickLogin)
//                        },
//                        onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
//                        onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
//                        component = component
//                    )
//                }, floatingActionButton = {
//                    if (!isJS()) {
//                        FloatingActionButton(
//                            onClick = { onClickSupport() },
//                            containerColor = primaryDark,
//                            shape = CircleShape
//                        ) {
//                            Icon(
//                                painter = painterResource(Res.drawable.baseline_support_agent_24),
//                                contentDescription = "Support",
//                                tint = Color.White
//                            )
//                        }
//                    }
//                }) { innerPadding ->
//
//                    Column(
//                        modifier = Modifier.fillMaxSize().padding(innerPadding).verticalScroll(scrollState)
//
//                    ) {
//
//                        if (windowSizeClass == WindowWidthSizeClass.Compact) {
//
//                            SearchFieldPreview(
//                                elevation = Scale.ELEVATION_SMALL,
//                                modifier = Modifier.fillMaxWidth(),
//                                onClick = {
//                                    component.event(HomePageEvent.OnSearchClick(""))
//                                })
//
//                            Spacer(modifier = Modifier.height(8.dp))
//                        } else {
//                            Spacer(modifier = Modifier.height(8.dp))
//                        }
//
//                        AnimatedContent(targetState = sliderState) { state ->
//                            WelcomeSlide(state, modifier = widthModifier)
//                        }
//
//                        AnimatedContent(
//                            component
//                        ) { state ->
//                            TopCategoryList(
//                                onCategoryClick = {
//                                    component.event(HomePageEvent.OnClickCategory(it))
//                                },
//                                component = state,
//                                modifier = widthModifier,
//                                windowWidthSizeClass = windowSizeClass
//                            )
//                        }
//
//                        AnimatedContent(
//                            targetState = consultantState
//                        ) { state ->
//                            ConsultantListItemUI(
//                                modifier = widthModifier,
//                                context = component,
//                                component = state,
//                                onLoginClick = {
//                                    component.event(HomePageEvent.OnClickLogin)
//                                },
//                                onChatConsultation = { chatData, duration, category ->
//                                    component.event(
//                                        HomePageEvent.OnChatConsultationStart(
//                                            consultantId = chatData.id!!,
//                                            consultantName = chatData.full_name!!,
//                                            consultantImage = chatData.profile_image!!,
//                                            consultantSkills = chatData.skills!!,
//                                            chatDuration = duration,
//                                            category = category
//                                        )
//                                    )
//                                },
//                                onItemClick = { consultant ->
//                                    val category_id: Int? = consultant.id
//                                    if (category_id != null) {
//                                        component.event(
//                                            HomePageEvent.OnClickTopConsultant(
//                                                category_id
//                                            )
//                                        )
//                                    }
//                                },
//                                onCallConsultation = { callData ->
//                                    component.event(
//                                        HomePageEvent.OnCallConsultationStart(
//                                            consultantId = callData.id!!,
//                                            consultantName = callData.full_name!!,
//                                            consultantImage = callData.profile_image!!,
//                                            consultantSkills = callData.skills!!
//                                        )
//                                    )
//                                },
//                                onRechargeRequired = { rechargeData ->
//                                    component.event(
//                                        HomePageEvent.OnRequiredRecharge(
//                                            rechargeData
//                                        )
//                                    )
//                                },
//                                onVoIPClick = { duration, expertInfo -> },
//                                onNavigateCallWaitingPage = { CommonExpertInfo, onClose -> },
//                                onLowBalance = {
//                                    component.event(HomePageEvent.OnClickWalletIcon)
//                                },
//                                onSeeAllClick = { category ->
//                                    //Logger.d { "Subcategory $category" }
//                                    component.event(HomePageEvent.OnClickCategory(category))
//                                }
//                            )
//                        }
//
////                        topHomeProduct?.let {
////                            AnimatedContent(it) { state ->
////                                HomeTopProductSegment(
////                                    widthModifier,
////                                    state,
////                                    onClickProduct = { productUrl ->
////                                        openUrlInBrowser(productUrl)
////                                    },
////                                    onClickSeeAll = {
////                                        openUrlInBrowser(WINYWAY_SHOP_LINK)
////                                    }
////                                )
////                            }
////                        }
//
//
////                        AnimatedContent(targetState = shortsState) { state ->
////                            VideoCardList(
////                                modifier = widthModifier,
////                                component = state,
////                                onSeeAllClick = {
////                                    showToast("Coming Soon")
////                                }
////                            )
////                        }
//
//                        Spacer(modifier = Modifier.height(10.dp))
//
////                        AnimatedContent(targetState = postState) { state ->
//////                            PostCardList(
//////                                modifier = widthModifier,
//////                                component = state,
//////                                onSeeAllClick = {
//////                                    component.event(HomePageEvent.OnClickPostSeeAll)
//////                                },
//////                                onReadPostClick = { post ->
//////                                    component.event(HomePageEvent.OnClickReadPost(post.tableId.toString()))
//////                                }
//////                            )
////                        }
////            var isVisible by remember { mutableStateOf(true) }
////
////            AnimatedContent(targetState = isVisible, label = "BestChatExpertAnimation") {
////                BestChatExpertGrid(
////                    modifier = widthModifier
////                )
////            }
//
//                        AnimatedContent(bestChatExpertState) { state ->
////                            BestChatExpertGrid(
////                                modifier = widthModifier,
////                                expertList = state,
////                                title = "Best Chat Expert",
////                                context = component,
////                                onSeeAllClick = { category ->
////                                    component.event(HomePageEvent.OnClickCategory(category))
////                                },
////                                onItemClick = { consultant ->
////                                    val categoryId: Int? = consultant.id
////                                    if (categoryId != null) {
////                                        component.event(
////                                            HomePageEvent.OnClickTopConsultant(
////                                                categoryId
////                                            )
////                                        )
////                                    }
////                                }
////                            )
//                        }
//
//                        AnimatedContent(bestCallExpertState) { state ->
////                            BestChatExpertGrid(
////                                modifier = widthModifier,
////                                expertList = state,
////                                title = "Best Call Expert",
////                                context = component,
////                                onSeeAllClick = { category ->
////                                    component.event(HomePageEvent.OnClickCategory(category))
////                                },
////                                onItemClick = { consultant ->
////                                    val categoryId: Int? = consultant.id
////                                    if (categoryId != null) {
////                                        component.event(
////                                            HomePageEvent.OnClickTopConsultant(
////                                                categoryId
////                                            )
////                                        )
////                                    }
////                                }
////                            )
//                        }
//
//
//                        Spacer(modifier = Modifier.height(10.dp))
//
//                        if (isJS()) {
//                            Footer(component)
//                        }
//                    }
////                    LazyColumn(
////                        modifier = Modifier.background(background_color).fillMaxSize()
////                            .padding(innerPadding)
////                    ) {
////
////                        if (windowSizeClass == WindowWidthSizeClass.Compact) {
////
////                            item{
////                                SearchFieldPreview(
////                                    elevation = Scale.ELEVATION_SMALL,
////                                    modifier = Modifier.fillMaxWidth(),
////                                    onClick = {
////                                        component.event(HomePageEvent.OnSearchClick(""))
////                                    })
////
////                                Spacer(modifier = Modifier.height(8.dp))
////                            }
////
////                        } else {
////                            item {
////                                Spacer(modifier = Modifier.height(8.dp))
////                            }
////                        }
////
////                        item {
////                            WelcomeSlide(component)
////                        }
////                        item {
////                            TopCategoryList(
////                                onCategoryClick = component::onCategoryClick, component = component
////                            )
////                        }
////                        item {
////                            ConsultantListItemUI(
////                                component = component,
////                                onLoginClick = {
////                                    component.event(HomePageEvent.OnClickLogin)
////                                },
////                                onChatConsultation = { chatData, duration, category ->
////                                    component.event(
////                                        HomePageEvent.OnChatConsultationStart(
////                                            consultantId = chatData.id!!,
////                                            consultantName = chatData.full_name!!,
////                                            consultantImage = chatData.profile_image!!,
////                                            consultantSkills = chatData.skills!!,
////                                            chatDuration = duration,
////                                            category = category
////                                        )
////                                    )
////                                },
////                                onItemClick = { consultant ->
////                                    val category_id: Int? = consultant.id
////                                    if (category_id != null) {
////                                        component.event(
////                                            HomePageEvent.OnClickTopConsultant(
////                                                category_id
////                                            )
////                                        )
////                                    }
////                                },
////                                onCallConsultation = { callData ->
////                                    component.event(
////                                        HomePageEvent.OnCallConsultationStart(
////                                            consultantId = callData.id!!,
////                                            consultantName = callData.full_name!!,
////                                            consultantImage = callData.profile_image!!,
////                                            consultantSkills = callData.skills!!
////                                        )
////                                    )
////                                },
////                                onRechargeRequired = { rechargeData ->
////                                    component.event(
////                                        HomePageEvent.OnRequiredRecharge(
////                                            rechargeData
////                                        )
////                                    )
////                                },
////                                onVoIPClick = { duration, expertInfo ->
////                                    component.event(
////                                        HomePageEvent.OnVoIPCallConsultationStart(
////                                            duration = duration,
////                                            expertInfo = expertInfo
////                                        )
////                                    )
////                                },
////                                onNavigateCallWaitingPage = { CommonExpertInfo,onClose ->
////                                    component.event(
////                                        HomePageEvent.OnNavigateCallWaitingPage(
////                                            onClose = onClose,
////                                            expertInfo = CommonExpertInfo
////                                        )
////                                    )
////                                },
////                                onLowBalance = {
////                                    component.event(HomePageEvent.OnClickWalletIcon)
////                                },
////                                context = component,
////                                onSeeAllClick = { category ->
////                                    component.event(HomePageEvent.OnClickCategory(category))
////                                }
////                            )
////                        }
////                        item {
////                            TopLiveSessionUI(component = component,
////                                onClick = { component.event(HomePageEvent.OnLiveSessionClick(0)) })
////                        }
////                        item { Spacer(modifier = Modifier.height(10.dp)) }
////                        item {
////                            if (isJS()) {
////                                Footer(component)
////                            }
////                        }
////                    }
//                }
//            }
//        }
//    }
//}


//<iframe width="932" height="524" src="https://www.youtube.com/embed/2IXNyRSXQuw" title="Powerful Hanuman Chalisa | HanuMan | Teja Sajja | Saicharan | Hanuman Jayanti Song | Jai Hanuman" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>