package com.winiwayuser.feature_call_consultation.persentation.call_consultation_options

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.winiwayuser.core.persentation.LoadingProgressDialog
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.wifi_call_coming_soon
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallPlaceInfoState
import com.winiwayuser.feature_call_consultation.persentation.expert_busy.ErrorBottomSheetCallConsultation
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.sim_icon
import com.winiwayuser.resources.wifi_icon
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallConsultationOptionScreen(
    component: CallConsultationOptionsComponent
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    var simCallPlaced by remember { mutableStateOf(false) }
    val state by component.placeCallState.collectAsState()

    if(simCallPlaced){
        //HandleCallState(state, component)
    }


    if (windowSizeClass == WindowWidthSizeClass.Compact) {

        ModalBottomSheet(
            sheetState = sheetState,
            onDismissRequest = {
                coroutineScope.launch {
                    sheetState.hide()
                    component.onClosePage()
                }
            }
        ) {
            CallConsultationOptionsContent(
                onSimCallPlaced = {
                    component.onClickPlaceCall()
                    //simCallPlaced = true
                    coroutineScope.launch {
                        sheetState.hide()
                        //component.onClosePage()
                    }
                    coroutineScope.launch { component.callPlace() }
                },
                onInternetCallPlaced = {

                    coroutineScope.launch {
                        sheetState.hide()
                        component.onInternetCalling()
                    }
                }
            )
        }
    } else{
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                component.onClosePage()
//                consultationComponent.onClosePage()
//                onDismiss.invoke()
            },
            text = {
                CallConsultationOptionsContent(
                    onSimCallPlaced = {
                        simCallPlaced = true
                        component.onClickPlaceCall()
                        coroutineScope.launch {
                            sheetState.hide()
                            //component.onClosePage()
                        }
                       // coroutineScope.launch { component.callPlace() }
                    },
                    onInternetCallPlaced = {

                        coroutineScope.launch {
                            sheetState.hide()
                            component.onInternetCalling()
                        }
                    }
                )
            },
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {
//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            },
            dismissButton = {

//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            }
        )
    }
}


@Composable
fun CallOptionCard(
    title: String,
    description: String,
    additionalInfo: String,
    icon: DrawableResource,
    isComingSoon: Boolean,
    onCallPlaced: () -> Unit
) {
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation =  CardDefaults.elevatedCardElevation(Scale.ELEVATION_MEDIUM),
        modifier = Modifier
            .fillMaxWidth()
            .height(70.dp)
            .clickable { onCallPlaced() }
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier
                .fillMaxSize()
                .padding(10.dp)
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier.padding(12.dp)
                ){
                    Icon(
                        painter = painterResource(icon),
                        contentDescription = null,
                        modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
                    )
                }

                Spacer(modifier = Modifier.width(4.dp))

                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start
                ) {
                    if (isComingSoon) {
                        Column {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.Center,
                                modifier = Modifier.wrapContentSize()
                            ) {
                                Text(
                                    text = "$title - ",
                                    style = MaterialTheme.typography.titleMedium
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(
                                    text = description,
                                    style = MaterialTheme.typography.titleMedium
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Box(
                                    modifier = Modifier
                                        .wrapContentSize()
                                        .background(
                                            color = Color.Black.copy(alpha = 0.3f),
                                            shape = RoundedCornerShape(2.dp)
                                        )
                                        .padding(horizontal = 4.dp, vertical = 2.dp),
                                ) {
                                    Text(
                                        text = "Experimental",
                                        color = Color.White,
                                        fontSize = 11.sp,
                                        style = MaterialTheme.typography.labelSmall
                                    )
                                }
                            }

                            Text(
                                text = additionalInfo,
                                style = MaterialTheme.typography.labelMedium
                            )
                        }
                    } else {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(
                                text = "$title - ",
                                style = MaterialTheme.typography.titleMedium
                            )
                            Spacer(modifier = Modifier.width(4.dp))
                            Text(
                                text = description,
                                style = MaterialTheme.typography.titleMedium
                            )
                        }

                        Text(
                            text = additionalInfo,
                            style = MaterialTheme.typography.labelMedium
                        )
                    }
                }
            }
        }
    }
}


@Composable
fun HandleCallState(state: CallPlaceInfoState, component: CallConsultationOptionsComponent) {
    when (state) {
        is CallPlaceInfoState.Error -> ErrorBottomSheetCallConsultation(state.msg) {
            component.onClose()
        }
        is CallPlaceInfoState.Loading -> LoadingProgressDialog{}
        is CallPlaceInfoState.PlaceCallSuccess -> {
            //component.onClickPlaceCall()
//            Logger.d { "HandleCallState Success" }

            Logger.d { "${state.data}" }
//            CallWaitingSuccess(
//                component = CallWaitingSuccessComponent(
//                    context = component,
//                    _expertId = component.expertId,
//                    _expertName = component.expertName,
//                    _expertImage = component.expertImage,
//                    _expertSkills = component.expertSkills,
//                    onClose = {
//                        component.onClosePage
//                    }
//
//                ),
//                inComingCallMobileNumber =  state.data.emergencyNumber,
//                expertId = component.expertId,
//                expertName = component.expertName,
//                expertImage = component.expertImage,
//                expertSkills = component.expertSkills,
//                onTimeOutOver = component.onClosePage
//            )
        }
    }
}

@Composable
fun CallConsultationOptionsContent(
    onSimCallPlaced: () -> Unit,
    onInternetCallPlaced: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    ) {
        Text(
            text = "Call Consultation Option",
            modifier = Modifier
                .padding(start = 16.dp),
            fontSize = 16.sp,
            fontWeight = FontWeight.Bold
        )

        Spacer(modifier = Modifier.height(16.dp))

        CallOptionCard(
            title = "SIM",
            description = "Reliable Call Quality",
            additionalInfo = "Expert will not see your personal number",
            icon = Res.drawable.sim_icon,
            isComingSoon = false,
            onCallPlaced = onSimCallPlaced
        )

        Spacer(modifier = Modifier.height(12.dp))

        CallOptionCard(
            title = "INTERNET",
            description = "Better Connectivity",
            additionalInfo = "Powerful features, Need good internet",
            icon = Res.drawable.wifi_icon,
            isComingSoon = true,
            onCallPlaced = onInternetCallPlaced
        )

        Spacer(modifier = Modifier.height(24.dp))
    }
}


