package com.winiwayuser.core.agora.chat

import kotlinx.browser.window

// Function to initialize RTM client with callbacks and dynamic channel name
@OptIn(ExperimentalJsExport::class)
@JsExport
fun initializeRtmClient(
    userId: String,
    channelName: String,
    onLogin: () -> Unit,
    onJoin: () -> Unit,
    onError: (String) -> Unit
) {
    window.asDynamic().initializeRtmClient(userId,
        channelName,
        { onLogin() },
        { onJoin() },
        { error -> onError(error as String) })
}

// Function to send messages
@OptIn(ExperimentalJsExport::class)
@JsExport
fun sendMessage(message: String) {
    window.asDynamic().sendMessage(message)
}

// Handle messages received from JavaScript
@OptIn(ExperimentalJsExport::class)
@JsExport
fun onMessageReceived(message: String) {
    println("Message received from JavaScript: $message")
    // Handle the received message here
}