package com.winiwayuser.feature_courses.persentation.courses_page

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class CourseModelList(
    @SerialName("data") var data: List<CourseModel>? = null,
    @SerialName("categoryList") var categoryList: List<String>? = null
)

@Serializable
data class CourseModel(
    @SerialName("title") var title: String? = null,
    @SerialName("date") var date: String? = null,
    @SerialName("duration_days") var durationDays: String? = null,
    @SerialName("price") var price: String? = null,
    @SerialName("original_price") var originalPrice: String? = null,
    @SerialName("category_type") var categoryType: String? = null,
    @SerialName("slug") var slug: String? = null,
    @SerialName("rating") var rating: String? = null,
    @SerialName("reviews_count") var reviewsCount: String? = null,
    @SerialName("image_path") var imagePath: String? = null
)