package com.winiwayuser.feature_call_consultation.di

import com.winiwayuser.feature_call_consultation.data.api.CallConsultationApi
import com.winiwayuser.feature_call_consultation.domain.CallConsultationInfoRepo
import com.winiwayuser.feature_call_consultation.domain.VoIPCallRepo
import org.koin.dsl.module

val callConsultationModule = module {
    single { CallConsultationApi() }
    single { CallConsultationInfoRepo(get()) }
    single { VoIPCallRepo(get()) }
}