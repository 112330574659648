package com.winiwayuser.core.decompose

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.backhandler.BackHandlerOwner
import com.winiwayuser.user_feature_authentication.persentation.login_page.LoginPageComponent
import com.winiwayuser.feature_authentication.persentation.otp_validation_page.OtpValidationComponent
import com.winiwayuser.user_feature_authentication.persentation.register_page.RegisterComponent
import com.winiwayuser.feature_common.persentation.bottom_navigation_bar.MainScreenComponent
import com.winiwayuser.feature_call_consultation.persentation.call_bottom_sheet_info.CallConsultationComponent
import com.winiwayuser.feature_call_consultation.persentation.call_consultation_options.CallConsultationOptionsComponent
import com.winiwayuser.feature_call_consultation.persentation.call_success_page.CallSuccessComponent
import com.winiwayuser.feature_call_consultation.persentation.voip_call_page.VoipCallComponent
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.ChatConversationComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.ChatHistoryComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.EditProfileComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.MoreScreenTabComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsComponent
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.persentation.TransactionHistoryComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_search_page.SearchScreenComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import com.winiwayuser.feature_landing_page.persentation.LandingPageComponent
import com.winiwayuser.feature_winyway_wallet.persentation.all_coupons_page.AllCouponComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure.RechargeFailureComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_getway.PaymentGatewayComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.ChoseRechargePackComponent
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success.RechargeSuccessComponent

interface RootComponent : BackHandlerOwner {

    val stack: Value<ChildStack<*, Child>>

    fun onBackClicked()
    fun onBackClicked(toIndex: Int)

    sealed class Child {
        data class LoginPage(val component: LoginPageComponent) : Child()
        data class OtpValidationPage(val component: OtpValidationComponent) : Child()
        data class RegistrationPage(val component: RegisterComponent) : Child()

        data class ExpertListPage(val component: ExpertListPageComponent) : Child()
        data class ExpertDetailsPage(val component: ExpertDetailsPageComponent) : Child()
        data class ChoseRechargePage(val component: ChoseRechargePackComponent) : Child()

        data class MoreScreenTab(val component: MoreScreenTabComponent) : Child()
        data class HomeScreenTab(val component: HomeScreenTabComponent) : Child()
        data class MainScreen(val component: MainScreenComponent) : Child()
        data class PrivacyPolicyPage(val component: PrivacyPolicyComponent) : Child()
        data class TermsAndConditions(val component: TermsAndConditionsComponent) : Child()
        data class EditProfileScreen(val component: EditProfileComponent) : Child()

        //feature_winyway_wallet
        data class RechargeCheckout(val component: RechargeCheckoutComponent,val secondComponent: AllCouponComponent) : Child()
        data class PaymentGateway(val component: PaymentGatewayComponent) : Child()
        data class RechargeSuccess(val component: RechargeSuccessComponent) : Child()
        data class RechargeFailure(val component: RechargeFailureComponent) : Child()

        data class LandingPage(val component: LandingPageComponent, val homeComponent: HomeScreenTabComponent) : Child()



        // feature_expert_search
        data class DisplaySearchExpertList(val component: SearchScreenComponent) : Child()

        data class ChatHostChild(
            val component: HostDefaultComponent
        ) : Child()

        data class CallConsultationOptions(
            val component: CallConsultationOptionsComponent
        ): Child()

        data class CallConsultationInfo(
            val component: CallConsultationComponent
        ): Child()

        data class CallPlaceSuccess(
            val component: CallSuccessComponent
        ): Child()

        data class CallWaitingSuccess(
            val component: CallSuccessComponent
        ):Child()

        /*VoIP Call*/
        data class VoIPCall(
            val component: VoipCallComponent
        ):Child()

        //TransactionHistory
        data class TransactionHistory(val component: TransactionHistoryComponent):Child()

        // All Coupon Screen
        data class DisplayAllCouponCode(val component: AllCouponComponent):Child()

        // Chat History Screen
        data class ChatHistory(val component: ChatHistoryComponent,val expertDetailsPageComponent: ExpertDetailsPageComponent):Child()

        // Chat Conversation Screen
        data class ChatConversationScreen(val component: ChatConversationComponent):Child()
    }
}