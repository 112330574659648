package com.winiwayuser.feature_courses.persentation.course_page.widget

import androidx.compose.foundation.ScrollState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.winiwayuser.feature_courses.persentation.course_page.CourseComponent

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun WatchSelectorScreen(
    viewModel: CourseComponent,
    modifier: Modifier = Modifier,
) {
    val watchOptions = viewModel.watchOptions
    val selectedWatchOption by viewModel.selectedWatchOption.collectAsState()
    val timeOptions by viewModel.timeOptions.collectAsState()
    val selectedTimeOption by viewModel.selectedTimeOption.collectAsState()

    // Manage Dropdown Expansion
    var isWatchDropdownExpanded by remember { mutableStateOf(false) }
    var isTimeDropdownExpanded by remember { mutableStateOf(false) }

    Column(modifier = modifier) {

        // First Dropdown
        ExposedDropdownMenuBox(
            expanded = isWatchDropdownExpanded,
            onExpandedChange = { isWatchDropdownExpanded = it }
        ) {
            OutlinedTextField(
                value = selectedWatchOption,
                onValueChange = {},
                readOnly = true,
                modifier = Modifier
                    .fillMaxWidth()
                    .menuAnchor(),
                label = { Text("Select Watch Option") },
                trailingIcon = { ExposedDropdownMenuDefaults.TrailingIcon(expanded = isWatchDropdownExpanded) }
            )
            DropdownMenu(
                expanded = isWatchDropdownExpanded,
                onDismissRequest = { isWatchDropdownExpanded = false }
            ) {
                watchOptions.forEach { option ->
                    DropdownMenuItem(
                        text = { Text(option) },
                        onClick = {
                            viewModel.onWatchOptionSelected(option)
                            isWatchDropdownExpanded = false
                        }
                    )
                }
            }
        }

        Spacer(modifier = Modifier.height(16.dp))

        // Second Dropdown
        ExposedDropdownMenuBox(
            expanded = isTimeDropdownExpanded,
            onExpandedChange = { isTimeDropdownExpanded = it }
        ) {
            OutlinedTextField(
                value = selectedTimeOption,
                onValueChange = {},
                readOnly = true,
                modifier = Modifier
                    .fillMaxWidth()
                    .menuAnchor(),
                label = { Text("Select Time") },
                trailingIcon = { ExposedDropdownMenuDefaults.TrailingIcon(expanded = isTimeDropdownExpanded) }
            )
            DropdownMenu(
                modifier = Modifier.heightIn(max = 312.dp).drawVerticalScrollbar(rememberScrollState()).width(150.dp),
                expanded = isTimeDropdownExpanded,
                onDismissRequest = { isTimeDropdownExpanded = false }
            ) {
                timeOptions.forEach { option ->
                    DropdownMenuItem(
                        text = { Text(option) },
                        onClick = {
                            viewModel.onTimeOptionSelected(option)
                            isTimeDropdownExpanded = false
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun Modifier.drawVerticalScrollbar(scrollState: ScrollState): Modifier {
    return this.then(
        Modifier.drawWithContent {
            drawContent()
            val isScrollbarNeeded = scrollState.maxValue > 0
            if (isScrollbarNeeded) {
                val proportion = size.height / (size.height + scrollState.maxValue)
                val scrollbarHeight = proportion * size.height
                val scrollOffset = (scrollState.value / scrollState.maxValue.toFloat()) * (size.height - scrollbarHeight)

                drawRect(
                    color = Color.Gray.copy(alpha = 0.6f),
                    topLeft = Offset(size.width - 8.dp.toPx(), scrollOffset),
                    size = Size(4.dp.toPx(), scrollbarHeight)
                )
            }
        }
    )
}


