package com.winiwayuser.feature_courses.domain

import com.winiwayuser.core.date_time.formatDateDayOfWeek
import com.winiwayuser.core.date_time.formatPrice
import com.winiwayuser.core.date_time.formatReviewCount
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.remote.Resources
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import com.winiwayuser.feature_courses.data.CoursesApi
import com.winiwayuser.feature_courses.data.request.CourseBuyRequest
import com.winiwayuser.feature_courses.data.request.CourseBuyVerifyRequest
import com.winiwayuser.feature_courses.data.request.CourseDetailsSubmitForm
import com.winiwayuser.feature_courses.data.request.CourseRazorpayOrderRequest
import com.winiwayuser.feature_courses.data.request.ValidateCouponRequest
import com.winiwayuser.feature_courses.persentation.course_page.CourseInfo

class CourseRepo(
    val api: CoursesApi
) {

    suspend fun getCourse(slug: String): Flow<Resources<CourseInfo>> {
        return flow {
            emit(Resources.Loading())
            when (val response = api.getCourseApi(slug)) {
                is Resources.Error -> emit(Resources.Error(response.msg))
                is Resources.Loading -> emit(Resources.Loading(response.isLoading))
                is Resources.Success -> {
                    if (response.data == null) {
                        emit(Resources.Error("No Data Found"))
                        return@flow
                    } else {
                        with(response.data) {
                            val gst = calculateGSTFromString(price.toString())
                            val curruncy = getCurrencySign()
//                            val price = getPriceValue(price, int)
                            emit(
                                Resources.Success(
                                    CourseInfo(
                                        id = id,
                                        title = title,
                                        description = description,
                                        date = formatDateDayOfWeek(date),
                                        durationDays = "$durationDays Days",
                                        price = "₹${formatPrice(price)}",
                                        originalPriceValue = originalPrice,
                                        priceValue = price,
                                        originalPrice = "₹${formatPrice(originalPrice)}",
                                        savingPrice = "₹${
                                            calculateDifferenceFormatted(
                                                originalPrice,
                                                price
                                            )
                                        }",
                                        categoryType = categoryType,
                                        slug = slug,
                                        rating = rating,
                                        reviewsCount = "(${formatReviewCount(reviewsCount)} Students)",
                                        imagePath = imagePath,
                                        status = status,
                                        instructors = instructors[0].copy(
                                            reviews = "${formatReviewCount(instructors[0].reviews?.toIntOrNull())} Reviews",
                                            students = "${formatReviewCount(instructors[0].students?.toIntOrNull())} Students",
                                            experience = "${instructors[0].experience}+ Years of Experience"
                                        ),
                                        uploadCertificates = uploadCertificates,
                                        overviewSection = courseDetails.filter {
                                            it.section == "Overview"
                                        },
                                        curriculamSection = courseDetails.filter {
                                            it.section == "Curriculum"
                                        },
                                        faqSection = courseDetails.filter {
                                            it.section == "faq"
                                        },
                                        discountPrice = "₹${
                                            calculateDifferenceFormatted(
                                                originalPrice,
                                                price
                                            )
                                        }",
                                        gstAmount = "₹${formatPrice(gst?.first.toString())}",
                                        gstPercentage = "₹${formatPrice(gst?.second.toString())}"
                                    )
                                )
                            )
                        }
                    }
                }
            }
            emit(Resources.Loading(false))
        }
    }

    suspend fun onBuyCourse(request: CourseBuyRequest): Flow<Resources<PaymentVerificationModel>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getCourseTransactionIdApi(request)
            if (response is Resources.Success) {

                if (response.data?.isCourseFree == true) {
                    emit(
                        Resources.Success(
                            PaymentVerificationModel(
                                orderId = "",
                                isPaymentRequired = false,
                                wistiaVideo = response.data.wistiaVideo
                            )
                        )
                    )
                    return@flow
                }

                val requestId = CourseRazorpayOrderRequest(
                    courseId = request.courseId,
                    coursePurchageId = response.data?.coursePurchaseId.toString(),
                    txnId = response.data?.txnId.toString(),
                    domainName = "talktoastro.com"
                )

                val orderIdResponse = api.getCourseOrderIdApi(
                    requestId
                )
                if (orderIdResponse is Resources.Success) {

                    val orderId = orderIdResponse.data?.orderId
                    if (orderId == null) {
                        emit(Resources.Error("Invalid Course, Order Error Code -009. Please Contact Administrator for support"))
                        return@flow
                    }

                    emit(
                        Resources.Success(
                            PaymentVerificationModel(
                                orderId = orderId,
                                isPaymentRequired = true,
                                wistiaVideo = null
                            )
                        )
                    )
                    return@flow
                }

                if (orderIdResponse is Resources.Error) {
                    emit(Resources.Error(response.msg))
                    return@flow
                }

            } else {
                emit(Resources.Error(response.msg))
                return@flow
            }
        }
    }

    fun onVerifyPayment(request: CourseBuyVerifyRequest): Flow<Resources<Boolean>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getCourseBuyStatusApi(request)
            if (response is Resources.Success) {
                emit(Resources.Success(true))
                return@flow
            }
            if (response is Resources.Error) {
                emit(Resources.Error(response.msg))
                return@flow
            }

        }
    }

    fun submitCourseDetails(request: CourseDetailsSubmitForm): Flow<Resources<Boolean>> {
        return flow {
            emit(Resources.Loading())
            val response = api.postCourseDetailsRemote(request)
            if (response is Resources.Success) {
                emit(Resources.Success(true))
                return@flow
            }
            if (response is Resources.Error) {
                emit(Resources.Error(response.msg))
                return@flow
            }
        }
    }

    suspend fun removeCoupon(course: CourseInfo): Flow<Resources<CourseInfo>> {
        return flow {
            emit(Resources.Loading())
            val gst = calculateGSTFromString(course.priceValue)
            emit(
                Resources.Success(
                    course.copy(
                        couponApplied = null,
                        couponAppliedDiscount = null,
                        savingPrice = "₹${
                            formatPrice(
                                calculateDifferenceFormatted(
                                    course.originalPriceValue,
                                    course.priceValue
                                )
                            )
                        }",
                        gstAmount = "₹${formatPrice(gst?.first.toString())}",
                        gstPercentage = "₹${formatPrice(gst?.second.toString())}"
                    )
                )
            )
        }
    }

    suspend fun validateCoupon(coupon: String, course: CourseInfo): Flow<Resources<CourseInfo?>> {
        return flow {
            try {

                // Emit loading state
                emit(Resources.Loading(true))
                // Fetch API response
                val response = api.validateCouponApi(
                    ValidateCouponRequest(
                        courseId = course.id.toString(),
                        price = course.priceValue,
                        couponCode = coupon
                    )
                )

                // Emit success or error based on response
                if (response is Resources.Success) {

                    val finalPrice =
                        calculateDifferenceFormatted(course.priceValue, response.data?.cashback)
                    val savingPrice = calculateDifferenceFormatted(
                        course.originalPriceValue,
                        course.priceValue
                    )

                    val gst = calculateGSTFromString(finalPrice)

                    emit(
                        Resources.Success(
                            course.copy(
                                couponApplied = coupon,
                                couponAppliedDiscount = formatPrice(response.data?.cashback),
                                savingPrice = "₹${
                                    formatPrice(
                                        calculateSumFormatted(
                                            savingPrice,
                                            response.data?.cashback
                                        )
                                    )
                                }",
                                gstAmount = "₹${formatPrice(gst?.first.toString())}",
                                gstPercentage = "₹${formatPrice(gst?.second.toString())}"
                            )
                        )
                    )

                } else if (response is Resources.Error) {
                    emit(Resources.Error(response.msg))
                }

            } catch (e: Exception) {
                // Handle exceptions by emitting an error state
                emit(Resources.Error("An error occurred: ${e.message}"))
            }
        }
    }
}

fun getPriceValue(indian:String,abroad:String):String{
    val mobileCode = UserInfoProvider.getMobileCode().trim()
    return if (mobileCode.isEmpty() || mobileCode.equals("+91",true)) indian
    else abroad
}

fun getCurrencySign():String{
    val mobileCode = UserInfoProvider.getMobileCode().trim()
    return if (mobileCode.isEmpty() || mobileCode.equals("+91",true)) "₹"
    else "$"
}

fun calculateGSTFromString(originalPrice: String?, gstRate: Double = 18.0): Pair<Double, Double>? {
    return try {
        val price = originalPrice?.toDouble() ?: 0.0 // Convert String to Double
        val gstAmount = price * (gstRate / 100)
        val totalPrice = price + gstAmount
        Pair(gstAmount, totalPrice)
    } catch (e: NumberFormatException) {
        println("Invalid input: $originalPrice is not a valid number.")
        null
    }
}

fun calculateSumFormatted(originalPrice: String?, price: String?): String {
    // Parse the input strings to Double, default to 0.0 if null or invalid
    val original = originalPrice?.toDoubleOrNull() ?: 0.0
    val current = price?.toDoubleOrNull() ?: 0.0
    // Calculate the sum
    val sum = original + current

    // Format the result
    return if (sum % 1 == 0.0) {
        sum.toInt().toString() // Convert to Int if whole number
    } else {
        sum.toString() // Keep as Double if decimal
    }
}


fun calculateDifferenceFormatted(originalPrice: String?, price: String?): String {
    val original = originalPrice?.toDoubleOrNull() ?: 0.0
    val current = price?.toDoubleOrNull() ?: 0.0
    val difference = original - current

    // Format result
    return if (difference % 1 == 0.0) {
        difference.toInt().toString() // Convert to Int if whole number
    } else {
        difference.toString() // Keep as Double if decimal
    }
}