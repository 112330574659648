package com.winiwayuser.feature_winyway_wallet.persentation.recharge_payment_success

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.Done
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.childContext
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.done_icon
import com.winiwayuser.resources.ic_wallet
import org.jetbrains.compose.resources.painterResource

//@OptIn(ExperimentalMaterial3Api::class)
//@Composable
//fun RechargeSuccess(
//    rechargeSuccessComponent: RechargeSuccessComponent
//) {
//
//    Scaffold(
//        topBar = {
////            Text(text = "Edit Profile")?
//            TopAppBar(
//                title = { },
////                colors = TopAppBarDefaults.topAppBarColors(background_color),
//                navigationIcon = {
//                    Icon(
//                        imageVector = Icons.AutoMirrored.Filled.ArrowBack, // Use the correct resource ID
//                        contentDescription = null,
//                        modifier = Modifier.clickable {
//                            rechargeSuccessComponent.onBackPress()
//                        }
//                            .size(40.dp)
//                            .padding(start = 10.dp)
//                            .clip(CircleShape).clickable { }, // Clip image to circle
//                        tint = Color.Black // Adjust tint color as needed
//                    )
//                }
//            )
//        }
//    ) {
//        Column(modifier = Modifier
//            .fillMaxSize()
//            .background(color = background_color)
//        ) {
//            RechargeFailureScreen(
//                topUpAmount = rechargeSuccessComponent.topUpAmount,
//                onDoneClick = {
//                    rechargeSuccessComponent.onDone()
//                },
//                onRechargeAgainClick = {
//                    rechargeSuccessComponent.onRechargeMore()
//                }
//            )
//        }
//    }
//}
//
//
//@Composable
//fun RechargeFailureScreen(
//    topUpAmount: String,
//    onDoneClick: () -> Unit,
//    onRechargeAgainClick: () -> Unit
//) {
//    Column(
//        modifier = Modifier
//            .fillMaxSize()
//            .padding(16.dp),
//        verticalArrangement = Arrangement.SpaceBetween,
//        horizontalAlignment = Alignment.CenterHorizontally
//    ) {
//        Column(
//            verticalArrangement = Arrangement.Center,
//            horizontalAlignment = Alignment.CenterHorizontally,
//            modifier = Modifier.weight(1f)
//        ) {
//            Icon(
//                imageVector = Icons.Default.Done,
//                contentDescription = null,
//                modifier = Modifier.padding(bottom = 16.dp).size(16.dp)
//            )
////            Icon(
////                painter = painterResource(Res.drawable.done_icon),
////                contentDescription = null,
////                modifier = Modifier.padding(bottom = 16.dp)
////            )
//            Text(
//                text = "Top Up Success!",
//            )
//            Text(
//                text = "Your top up has been successfully done.",
//                modifier = Modifier.padding(bottom = 16.dp),
//                maxLines = 2
//            )
//            Text(
//                text = "Total top-up",
//                modifier = Modifier
//            )
//
//            Text(
//                text = "₹ $topUpAmount",
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
//        }
//        Column(
//            verticalArrangement = Arrangement.Bottom,
//            horizontalAlignment = Alignment.CenterHorizontally,
//            modifier = Modifier.fillMaxWidth()
//        ) {
//            Text(
//                text = "Your wallet balance is now",
//                modifier = Modifier.padding(bottom = 4.dp)
//            )
//            Box(
//                modifier = Modifier
//                    .background(
//                        Color(0xFFFFFFFFFF),
//                        shape = RoundedCornerShape(8.dp)
//                    ) // Change the color as needed
//            ) {
//                Row(
////                    verticalAlignment = Alignment.CenterVertically,
//                    modifier = Modifier.padding(10.dp)
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.ic_wallet),
//                        contentDescription = null,
//                        modifier = Modifier.padding(end = 8.dp)
//                    )
//                    Spacer(modifier = Modifier.width(8.dp))
//                    Column {
//                        Text(
//                            text = "Wallet Balance",
//                            modifier = Modifier.padding(bottom = 2.dp)
//                        )
//                        Text(
//                            text = UserInfoProvider.getTotalWalletMoney().toString(),
//                            modifier = Modifier.padding(bottom = 2.dp)
//                        )
//                    }
//                }
//            }
//            Spacer(modifier = Modifier.height(24.dp))
//
//            Button(
//                onClick = onDoneClick,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = "Done", color = Color.White)
//            }
//
//            Button(
//                onClick = onRechargeAgainClick,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RoundedCornerShape
//                colors = ButtonDefaults.buttonColors(Color.Transparent),
//                border = BorderStroke(
//                    1.dp,
//                    Color.Black
//                ) // Add border with specified width and color
//            ) {
//                Text(text = "Top up Money Again", color = md_them_primary)
//            }
//        }
//    }
//}
//
//
//

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun RechargeSuccess(
    rechargeSuccessComponent: RechargeSuccessComponent
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    Box(modifier = Modifier.fillMaxSize().background(background_color), contentAlignment = Alignment.Center){
        Scaffold(
            modifier = widthModifier.fillMaxSize().background(background_color),
            topBar = {
                TopAppBar(
                    title = {},
                    navigationIcon = {
                        Icon(
                            imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                            contentDescription = "Back",
                            modifier = Modifier
                                .size(40.dp)
                                .padding(start = 10.dp)
                                .clip(CircleShape)
                                .clickable { rechargeSuccessComponent.onBackPress() },
                            tint = Color.Black
                        )
                    }
                )
            }
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .background(color = background_color)
                    .padding(16.dp)
            ) {
                RechargeSuccessScreen(
                    topUpAmount = rechargeSuccessComponent.topUpAmount,
                    onDoneClick = { rechargeSuccessComponent.onDone() },
                    onRechargeAgainClick = { rechargeSuccessComponent.onRechargeMore() }
                )
            }
        }
    }

}

@Composable
fun RechargeSuccessScreen(
    topUpAmount: String,
    onDoneClick: () -> Unit,
    onRechargeAgainClick: () -> Unit
) {
    var isVisible by remember { mutableStateOf(true) }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .animateContentSize(), // Animate size changes
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        AnimatedVisibility(visible = isVisible) {
            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier.weight(1f)
            ) {
                Icon(
                    imageVector = Icons.Default.Done,
                    contentDescription = "Success Icon",
                    modifier = Modifier
                        .padding(bottom = 16.dp)
                        .size(48.dp), // Larger icon for better visibility
                    tint = Color.Green // Change to a success color
                )
                Text(
                    text = "Top Up Success!",
                    style = MaterialTheme.typography.titleLarge // Use Material typography
                )
                Text(
                    text = "Your top-up has been successfully completed.",
                    modifier = Modifier.padding(bottom = 16.dp),
                    style = MaterialTheme.typography.bodyMedium,
                    textAlign = TextAlign.Center // Center text
                )
                Text(
                    text = "Total top-up",
                    style = MaterialTheme.typography.bodyLarge
                )
                Text(
                    text = "₹ $topUpAmount",
                    style = MaterialTheme.typography.bodyLarge.copy(fontWeight = FontWeight.Bold),
                    modifier = Modifier.padding(bottom = 16.dp)
                )
            }
        }

        Column(
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = "Your wallet balance is now",
                style = MaterialTheme.typography.bodyMedium,
                modifier = Modifier.padding(bottom = 4.dp)
            )
            Box(
                modifier = Modifier
                    .background(Color.White, shape = RoundedCornerShape(8.dp))
                    .padding(10.dp)
            ) {
                Row {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = "Wallet Icon",
                        modifier = Modifier.padding(end = 8.dp)
                    )
                    Column {
                        Text(
                            text = "Wallet Balance",
                            style = MaterialTheme.typography.bodyMedium
                        )
                        Text(
                            text = UserInfoProvider.getTotalWalletMoney().toString(),
                            style = MaterialTheme.typography.bodyLarge.copy(fontWeight = FontWeight.Bold)
                        )
                    }
                }
            }
            Spacer(modifier = Modifier.height(24.dp))

            Button(
                onClick = {
                    onDoneClick()
                    isVisible = false // Optionally hide the success message
                },
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp),
                colors = ButtonDefaults.buttonColors(md_them_primary)
            ) {
                Text(text = "Done", color = Color.White)
            }

            Button(
                onClick = onRechargeAgainClick,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp),
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(1.dp, Color.Black)
            ) {
                Text(text = "Top up Money Again", color = md_them_primary)
            }
        }
    }
}

