package com.winiwayuser.feature_chat_consultation.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_chat_consultation_new.data.api.ChatConsultationApi
import com.winiwayuser.feature_chat_consultation.data.request.StartChatRequest
import com.winiwayuser.feature_chat_consultation.data.response.end_consultation.ChatConsultationEndData
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ChatWaitingRepo(
    private val api: ChatConsultationApi
) {

    fun endChat(request: StartChatRequest): Flow<Resources<ChatConsultationEndData>> {
        return flow {
            emit(Resources.Loading())
            val response = api.endConsultationBeforeJoin(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}