package com.winiwayuser.feature_expert_consultation.data.remote

import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_consultation.data.response.Data
import com.winiwayuser.feature_expert_consultation.data.response.SearchExpertResponse
import com.winiwayuser.feature_expert_consultation.data.remote.request.SearchExpertRequest
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class SearchExpertApi:KtorApi() {
    companion object {
        const val SEARCH_EXPERT_QUERY = "search-consultant"
    }

    private suspend fun getExpertList(request: SearchExpertRequest) = client.post {
        url(SEARCH_EXPERT_QUERY)
        contentType(ContentType.Application.Json)
        setBody(request)
    }.body<SearchExpertResponse>()



    suspend fun getExpertListRemote(request: SearchExpertRequest): Flow<Resources<Data>> {

        return flow {
            emit(Resources.Loading(true))
            try {
                val response = getExpertList(request)

                if (response.status.not()) {
                    return@flow emit(Resources.Error(response.message))
                }
                emit(Resources.Success(response.data))

            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                emit(Resources.Loading(false))
            }

        }
    }
}