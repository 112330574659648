package com.winiwayuser.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Surface
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.config.ASTROLOGY_LIST
import com.winiwayuser.core.config.COURSES_LIST
import com.winiwayuser.core.config.CUSTOM_ORDER_LIST
import com.winiwayuser.core.config.STORE_LIST
import com.winiwayuser.core.config.WINYWAY_SHOP_LINK
import com.winiwayuser.core.persentation.openUrlInBrowser
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_common.data.request.RequestCategory
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.astrologer_icon
import com.winiwayuser.resources.astrology_courses
import com.winiwayuser.resources.astrology_courses_new
import com.winiwayuser.resources.astrology_new
import com.winiwayuser.resources.banner1
import com.winiwayuser.resources.share_icon_new
import com.winiwayuser.resources.spritual_banner2
import com.winiwayuser.resources.wellness_therapy_new
import com.winiwayuser.resources.winyway_button_spiritual
import com.winiwayuser.resources.winyway_shop
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//
//    val categoryData by component.topCategory.collectAsState()
//
//    if (categoryData.isNullOrEmpty()) return
//
//    val spanCount = if (categoryData?.size!! > 2) 3 else 2
//
//    LazyVerticalGrid(
//        columns = GridCells.Fixed(spanCount),
//        contentPadding = PaddingValues(8.dp),
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .height(120.dp)
//            .padding(horizontal = 12.dp)
////            .fillMaxSize() // Ensure the grid fills the available space within the Box
//    ) {
//        items(categoryData!!) { category ->
//            CategoryCard(
//                categoryName = category,
//                onCategoryClick = onCategoryClick
//            )
//        }
//    }
//}

//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//    val categoryData by component.topCategory.collectAsState()
//
//    if (categoryData.isNullOrEmpty()) return
//
//    Row(
//        modifier = Modifier
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .padding(horizontal = Scale.HORIZONTAL_PADDING),
//        horizontalArrangement = Arrangement.spacedBy(8.dp)
//    ) {
//        categoryData!!.forEach { category ->
//            Box(
//                modifier = Modifier
//                    .weight(1f)
//            ) {
//                CategoryCard(
//                    categoryName = category,
//                    onCategoryClick = onCategoryClick
//                )
//            }
//        }
//    }
//}
//
//
//
//@Composable
//fun CategoryCard(
//    categoryName: RequestCategory,
//    onCategoryClick: (RequestCategory) -> Unit,
//) {
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),  // Adjust the corner radius as needed
//        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_LARGE),  // Adjust the elevation as needed
//        modifier = Modifier
//            .fillMaxWidth()
//            //.width(100.dp)
//            //.height(120.dp)
//            .wrapContentHeight()
//            .clickable { onCategoryClick(categoryName) }
//    ) {
//        val resImage = when (categoryName.name) {
//            "Mental Wellness Therapist" -> Res.drawable.spritual_banner2
//            "Astrologer" -> Res.drawable.banner1
//            //"Scientific Astrology" -> Res.drawable.astrologer_icon
//            "Scientific Astrology" -> null
//            else -> null
//            //else -> Res.drawable.astrologer_icon
//        }
//
////        Image(
////            painter = painterResource(resource = resImage),
////            contentDescription = "",
////            contentScale = ContentScale.FillBounds,
////            modifier = Modifier
////                .fillMaxSize()
////                .clip(RoundedCornerShape(8.dp))  // Adjust the corner radius if needed
////        )
//
//        resImage?.let {
//            Image(
//                painter = painterResource(resource = it),
//                contentDescription = null,  // No need for contentDescription if not needed for accessibility
//                contentScale = ContentScale.FillBounds,
//                modifier = Modifier
//                    .fillMaxSize()
//                    .clip(RoundedCornerShape(8.dp))  // Adjust the corner radius if needed
//            )
//        }
//    }
//}

//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun TopCategoryList(
//    onCategoryClick: (RequestCategory) -> Unit,
//    component: HomeScreenTabComponent // Replace with your actual data type
//) {
//    val categoryData by component.topCategory.collectAsState()
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    // Early return if no category data is available
//    if (categoryData.isNullOrEmpty()) return
//
//    if (windowSizeClass == WindowWidthSizeClass.Compact) {
//        Row(
//            modifier = Modifier
//                .fillMaxWidth()  // Use fillMaxWidth instead of fillMaxSize to avoid unwanted extra space
//                .padding(horizontal = Scale.HORIZONTAL_PADDING),
//            horizontalArrangement = Arrangement.spacedBy(8.dp)
//        ) {
//            categoryData!!.forEach { category ->
//                Box(
//                    modifier = Modifier
//                        .weight(1f)  // Distribute space evenly
//                        .aspectRatio(2f / 1f)  // Maintain aspect ratio if necessary
//                ) {
//                    CategoryCard(
//                        categoryName = category,
//                        onCategoryClick = onCategoryClick
//                    )
//                }
//            }
//        }
//    }
//    else {
//        // Display categories in a lazy row for larger width
//        LazyRow(
//            modifier = Modifier
//                .fillMaxWidth()
//                .wrapContentHeight()
//                .padding(horizontal = Scale.HORIZONTAL_PADDING),
//            horizontalArrangement = Arrangement.spacedBy(8.dp),
//            userScrollEnabled = true // Allow scrolling
//        ) {
//            items(categoryData!!.size) { category ->
//                val data = categoryData!![category]
//                CategoryCard(
//                    categoryName = data,
//                    onCategoryClick = onCategoryClick
//                )
//            }
//        }
//    }
//}
//
//@Composable
//fun CategoryCard(
//    categoryName: RequestCategory,
//    onCategoryClick: (RequestCategory) -> Unit,
//) {
//    Card(
//        shape = RoundedCornerShape(Scale.CORNER_SMALL),
//        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_LARGE),
//        modifier = Modifier
//           // .fillMaxWidth(0.9f) // Adjust width as needed
//            .fillMaxWidth()
//            .wrapContentHeight()
//            .clickable { onCategoryClick(categoryName) }
//            .padding(8.dp) // Add padding if needed
//    ) {
//        val resImage = when (categoryName.name) {
//            "Mental Wellness Therapist" -> Res.drawable.spritual_banner2
//            "Astrologer" -> Res.drawable.banner1
//            "Scientific Astrology" -> null
//            else -> null
//        }
//
//        resImage?.let {
//            Image(
//                painter = painterResource(it),
//                contentDescription = null,
//                contentScale = ContentScale.Crop,
//                modifier = Modifier
//                    .fillMaxWidth()  // Ensure the image spans the full width of the card
//                    .aspectRatio(2f / 1f)  // Adjust aspect ratio as needed
//                    .clip(RoundedCornerShape(Scale.CORNER_SMALL))
//            )
//        }
//    }
//}





@Composable
fun TopCategoryList(
    onCategoryClick: (RequestCategory) -> Unit,
    component: HomeScreenTabComponent,
    modifier: Modifier,
    windowWidthSizeClass: WindowWidthSizeClass
) {
    val categoryState by component.topCategory.collectAsState()

    val categories = listOf(
        Pair(Res.drawable.astrology_new, ASTROLOGY_LIST),
        Pair(Res.drawable.spritual_banner2, CUSTOM_ORDER_LIST),
        Pair(Res.drawable.astrology_courses_new, COURSES_LIST),
        Pair(Res.drawable.winyway_shop, STORE_LIST)
    )

    val categoryClickHandler: (String) -> Unit = { category ->
        println("category -> $category")
        when (category) {
//            CUSTOM_ORDER_LIST, COURSES_LIST -> {
//                onCategoryClick.invoke(RequestCategory(
//                    name = category,
//                    id = 9,
//                    image_url = "",
//                    subcategories = null,
//                    image = ""
//                ))
//            }

            COURSES_LIST -> {
                onCategoryClick.invoke(RequestCategory(
                    name = category,
                    id = 9,
                    image_url = "",
                    subcategories = null,
                    image = ""
                ))
            }
            CUSTOM_ORDER_LIST ->{
                showToast("Coming Soon")
            }
            STORE_LIST ->{
                openUrlInBrowser(WINYWAY_SHOP_LINK)
            }
            else -> {
                println("category -> $category")
                if (categoryState != null) {
                    println("categoryState -> $categoryState")
                    onCategoryClick.invoke(categoryState!!)
                }else{
                    println("category state is null")
                }
            }
        }
    }

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        if (windowWidthSizeClass == WindowWidthSizeClass.Compact) {
            CompactCategoryLayout(categories, categoryClickHandler, modifier)
        } else {
            ExpandedCategoryLayout(categories, categoryClickHandler, modifier)
        }
    }
}

@Composable
fun CompactCategoryLayout(
    categories: List<Pair<DrawableResource, String>>,
    onCategoryClick: (String) -> Unit,
    modifier: Modifier
) {
    Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
        categories.chunked(2).forEach { rowItems ->
            Row(
                modifier = modifier
                    .wrapContentHeight()
                    .padding(horizontal = Scale.HORIZONTAL_PADDING),
                horizontalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                rowItems.forEach { (resImage, category) ->
                    Box(modifier = Modifier.weight(1f)) {
                        CategoryCard(
                            category = category,
                            resImage = resImage,
                            onClickCard = onCategoryClick,
                            windowWidthSizeClass = WindowWidthSizeClass.Compact
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun ExpandedCategoryLayout(
    categories: List<Pair<DrawableResource, String>>,
    onCategoryClick: (String) -> Unit,
    modifier: Modifier
) {
    Row(
        modifier = modifier
            .wrapContentHeight()
            .padding(horizontal = Scale.HORIZONTAL_PADDING),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        categories.forEach { (resImage, category) ->
            Box(modifier = Modifier.weight(1f)) {
                CategoryCard(
                    category = category,
                    resImage = resImage,
                    onClickCard = onCategoryClick,
                    windowWidthSizeClass = WindowWidthSizeClass.Expanded
                )
            }
        }
    }
}

@Composable
fun CategoryCard(
    category: String,
    resImage: DrawableResource,
    onClickCard: (String) -> Unit,
    windowWidthSizeClass: WindowWidthSizeClass
) {

    val sizeOfImage = when(windowWidthSizeClass){
        WindowWidthSizeClass.Expanded -> 90.dp
        WindowWidthSizeClass.Medium -> 75.dp
        else -> 65.dp
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        shadowElevation = Scale.ELEVATION_SMALL,
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
    ) {
        // Box to overlay the Image and the content
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .wrapContentHeight()
                .clickable { onClickCard.invoke(category) }
        ) {
            // Background image
            Image(
                painter = painterResource(resImage),
                contentDescription = "",
                contentScale = ContentScale.Crop,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(sizeOfImage) // Adjust the height as per the image aspect ratio
            )

            // Overlay Box for the category text and icon
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .align(Alignment.BottomStart)
//                    .padding(horizontal = 12.dp, vertical = 12.dp)
//
//            ) {
//                Column(
//                    horizontalAlignment = Alignment.Start,
//                    verticalArrangement = Arrangement.Bottom,
//                    modifier = Modifier
//                        .wrapContentSize()
//                ) {
//                    Text(
//                        text = split[0],
//                        style = MaterialTheme.typography.bodyMedium,
//                        fontWeight = FontWeight.SemiBold,
//                                color = Color.White
//                    )
//
//                    Row(
//                        horizontalArrangement = Arrangement.Start,
//                        verticalAlignment = Alignment.CenterVertically
//                    ) {
//
//                        Text(
//                            text = split[1],
//                            style = MaterialTheme.typography.bodyMedium,
//                            fontWeight = FontWeight.SemiBold,
//                            color = Color.White
//                        )
//
//
//                        Spacer(modifier = Modifier.width(2.dp))
//
//                        Icon(
//                            imageVector = Icons.AutoMirrored.Filled.KeyboardArrowRight,
//                            contentDescription = "Arrow Right Icon",
//                            modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE),
//                                    tint = Color.White
//                        )
//
//                    }
//                }
//            }
        }
    }
}
