package com.winiwayuser.feature_chat_consultation_new.persentation.chat_concern

import com.winiwayuser.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions

sealed class ChatConcernState {
    data object OnChatStart : ChatConcernState()
    data class OnError(val msg:String) : ChatConcernState()
    data class LoadingState(val isLoading:Boolean) : ChatConcernState()
    data class OnSuccessQuestion(val data: List<ConcernQuestions>) : ChatConcernState()
    data object InitialState: ChatConcernState()
}