package com.winiwayuser.feature_common.persentation.more_tab.chat_history.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.persentation.ChatTopAppBar
import com.winiwayuser.core.persentation.PaginationItems
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.ChatConversationState
import com.winiwayuser.feature_common.persentation.more_tab.chat_history.data.response.ChatConversationData
import com.winiwayuser.feature_common.persentation.more_tab.component.ChatConversationComponent
import kotlinx.coroutines.flow.StateFlow

@Composable
fun ChatConversationComponentUI(chatConversationComponent: ChatConversationComponent) {
    Logger.d {"ChatConversationComponentUI ${chatConversationComponent.state.value}" }
    val state = chatConversationComponent.state.collectAsState()
    ChatConversationScreen(state = chatConversationComponent.state,chatConversationComponent)
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ChatConversationScreen(state: StateFlow<ChatConversationState>,component: ChatConversationComponent) {
    val chatConversationState by state.collectAsState()

    Logger.d { "ChatConversationScreen: ${chatConversationState}" }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    Box(modifier = Modifier.fillMaxSize().background(background_color), contentAlignment = Alignment.Center){
        Column(
            modifier = widthModifier
                .fillMaxSize()
                .background(Color(0xFFF4F1FF))
        ) {
            ChatTopAppBar(component.expertName,component.expertImage,component.chatDuration, onBackClick = {component.onBackPress()})
            Spacer(modifier = Modifier.height(16.dp))
            ChatConversationContent(chatConversationState,component)
        }
    }

}

//@Composable
//fun ChatConversationContent(chatConversationState: ChatConversationState, component: ChatConversationComponent) {
//    when (chatConversationState) {
//        is ChatConversationState.Loading -> {
//            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
//                CircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
//            }
//        }
//
//        is ChatConversationState.Error -> {
//            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
//                Text(
//                    text = (chatConversationState as ChatConversationState.Error).message,
//                    color = Color.Red,
//                    modifier = Modifier.align(Alignment.TopCenter)
//                )
//            }
//        }
//
//        is ChatConversationState.Success -> {
//            val chatMessages = (chatConversationState as ChatConversationState.Success).chatMessages
//            Logger.d { "ChatConversationScreen on Success: ${chatMessages.size}" }
//            val listState = rememberLazyListState()
//            val isLoading = remember { mutableStateOf(false) }
//            val isAtBottom by remember {
//                derivedStateOf {
//                    val lastVisibleItem = listState.layoutInfo.visibleItemsInfo.lastOrNull()
//                    lastVisibleItem != null && lastVisibleItem.index == listState.layoutInfo.totalItemsCount - 1
//                }
//            }
//
//
////            LaunchedEffect(isAtBottom) {
////                if (isAtBottom && !isLoading.value) {
////                    isLoading.value = true
////                    component.loadMoreChatHistory()
////                    isLoading.value = false
////                }
////            }
//
//            LazyColumn(
//                state = listState,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .padding(horizontal = 16.dp)
//            ) {
//                item {
//                    ChatStartLabel()
//                    Spacer(modifier = Modifier.height(8.dp))
//                }
//
//                chatMessages.reversed().forEach { chatConversationData ->
//                    chatConversationData?.chats?.chatMassages?.reversed()
//                        ?.forEach { chatMessage ->
//                            item(key = chatMessage.id) {
//                                ChatMessage(
//                                    isUser = chatMessage.type.equals("User", ignoreCase = true),
//                                    message = chatMessage.message
//                                )
//                            }
//                        }
//                }
//
//                item {
//                    Spacer(modifier = Modifier.height(8.dp))
//                    ChatEndLabel()
//                    Spacer(modifier = Modifier.height(16.dp))
//                }
//            }
//        }
//    }
//}

//@Composable
//fun ChatConversationContent(chatConversationState: ChatConversationState, component: ChatConversationComponent) {
//    val listState = rememberLazyListState()
//
//    // Load more when scrolled close to the top
//    LaunchedEffect(listState) {
//        snapshotFlow { listState.firstVisibleItemIndex }
//            .collect { index ->
//                if (index <= 50 && !component.isLoading && !component.isLastPage) {
//                    component.loadMoreChatHistory()  // Load more messages if scrolled near the top
//                }
//            }
//    }
//
//    when (chatConversationState) {
//        is ChatConversationState.Loading -> {
//            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
//                CircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
//            }
//        }
//
//        is ChatConversationState.Error -> {
//            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
//                Text(
//                    text = chatConversationState.message,
//                    color = Color.Red,
//                    modifier = Modifier.align(Alignment.TopCenter)
//                )
//            }
//        }
//
//        is ChatConversationState.Success -> {
//            val chatMessages = chatConversationState.chatMessages
//
//            LazyColumn(
//                state = listState,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .padding(horizontal = 16.dp)
//            ) {
//                item {
//                    ChatStartLabel()
//                    Spacer(modifier = Modifier.height(8.dp))
//                }
//
//                // Iterate through chat messages
//                chatMessages.reversed().forEach { chatConversationData ->
//                    chatConversationData?.chats?.chatMassages?.reversed()
//                        ?.forEach { chatMessage ->
//                            item(key = chatMessage.id) {
//                                ChatMessage(
//                                    isUser = chatMessage.type.equals("User", ignoreCase = true),
//                                    message = chatMessage.message
//                                )
//                            }
//                        }
//                }
//
//                item {
//                    Spacer(modifier = Modifier.height(8.dp))
//                    ChatEndLabel()
//                    Spacer(modifier = Modifier.height(16.dp))
//                }
//            }
//        }
//    }
//}

@Composable
fun ChatConversationContent(chatConversationState: ChatConversationState, component: ChatConversationComponent) {
    val listState = rememberLazyListState()
    LaunchedEffect(listState) {
        snapshotFlow { listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
            .collect { index ->
                if (index != null && index >= listState.layoutInfo.totalItemsCount - 1 && !component.isLoading && !component.isLastPage) {
                    component.loadChatHistory()
                }
            }
    }

    when (chatConversationState) {
        is ChatConversationState.Loading -> {
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
                CircularProgressIndicator(modifier = Modifier.align(Alignment.Center))
            }
        }

        is ChatConversationState.Error -> {
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
                Text(
                    text = chatConversationState.message,
                    color = Color.Red,
                    modifier = Modifier.align(Alignment.TopCenter)
                )
            }
        }

        is ChatConversationState.Success -> {
            val paginationItems = chatConversationState.chatMessages
             val chatMessages = paginationItems

            LazyColumn(
                state = listState,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp)
            ) {
                item {
                    ChatStartLabel()
                    Spacer(modifier = Modifier.height(8.dp))
                }

                // Iterate through chat messages
//                chatMessages.forEachIndexed { index, chatConversationData ->
//                    chatConversationData?.chats?.chatMassages?.forEach { chatMessage ->
//                        item(key = "${chatMessage.id}_$index") { // Use a unique key
//                            ChatMessage(
//                                isUser = chatMessage.type.equals("User", ignoreCase = true),
//                                message = chatMessage.message
//                            )
//                        }
//                    }
//                }
                chatMessages.asReversed().forEachIndexed { index, chatConversationData ->
                    chatConversationData?.chats?.chatMassages?.asReversed()?.forEach { chatMessage ->
                        item(key = "${chatMessage.id}_$index") { // Use a unique key
                            ChatMessage(
                                isUser = chatMessage.type.equals("User", ignoreCase = true),
                                message = chatMessage.message
                            )
                        }
                    }
                }

                item {
                    Spacer(modifier = Modifier.height(8.dp))
                    ChatEndLabel()
                    Spacer(modifier = Modifier.height(16.dp))
                }
            }

        }
    }
}






@Composable
fun ConcernSection() {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        elevation = CardDefaults.cardElevation(4.dp)
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = "Your Concern",
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp,
                color = Color.Gray
            )
            Spacer(modifier = Modifier.height(8.dp))
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(100.dp)
                    .background(Color(0xFFF5F5F5))
            )
        }
    }
}

@Composable
fun ChatStartLabel() {
    // Chat Start Label
    Text(
        text = "Chat Start",
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        color = Color.Gray,
        textAlign = TextAlign.Center
    )
}

@Composable
fun ChatEndLabel() {
    // Chat End Label
    Text(
        text = "Chat End",
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        color = Color.Gray,
        textAlign = TextAlign.Center
    )
}

@Composable
fun ChatMessage(isUser: Boolean, message: String) {
    val alignment = if (isUser) Arrangement.End else Arrangement.Start
    val backgroundColor = if (isUser) Color(0xFFEDEAFF) else Color.White

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 4.dp),
        horizontalArrangement = alignment
    ) {
        Card(
            modifier = Modifier.background(backgroundColor),
            shape = RoundedCornerShape(8.dp),
            elevation = CardDefaults.cardElevation(4.dp),
        ) {
            Text(
                text = message,
                modifier = Modifier.padding(12.dp),
                color = Color.Black
            )
        }
    }
}

