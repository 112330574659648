package com.winiwayuser.feature_winyway_wallet.persentation.recharge_failure

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.initializeCurrency
import com.winiwayuser.core.theme.md_them_primary
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.done_icon
import com.winiwayuser.resources.error_icon
import org.jetbrains.compose.resources.painterResource


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RechargeFailure(
 component: RechargeFailureComponent
) {

    Scaffold(
        topBar = {
            TopAppBar(
                title = { },
//                colors = TopAppBarDefaults.topAppBarColors(background_color),
                navigationIcon = {
                    Icon(
                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                        contentDescription = null,
                        modifier = Modifier
                            .size(40.dp)
                            .padding(start = 10.dp)
                            .clip(CircleShape)
                            .clickable {
                                component.onClickRechargeAgain()
                            },
                        tint = Color.Black
                    )
                }
            )
        }
    ) {
        Column(modifier = Modifier.fillMaxSize().background(color = background_color)) {
            RechargeFailureScreen(
                topUpAmount = component.topUpAmount,
                onGetSupportClick = {
                    component.onClickGetSupport()
                },
                onRechargeAgainClick = {
                    component.onClickRechargeAgain()
                }
            )
        }
    }
}


@Composable
fun RechargeFailureScreen(
    topUpAmount: String,
    onGetSupportClick: () -> Unit,
    onRechargeAgainClick: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.weight(1f)
        ) {

//            Icon(
//                imageVector = Icons.Default.er,
//                contentDescription = null,
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
//            Icon(
//                painter = painterResource(Res.drawable.error_icon),
//                contentDescription = null,
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
            Text(
                text = "Payment for top-up has failed.",
                modifier = Modifier.padding(bottom = 16.dp)
            )
            Text(
                text = "Payment for top up has been failed, if your payment is done wait for confirmation.",
                modifier = Modifier.padding(bottom = 16.dp),
                maxLines = 2
            )
            Text(
                text = "Total top-up",
                modifier = Modifier.padding(bottom = 16.dp)
            )


            Text(
                text = "${initializeCurrency()} $topUpAmount",
                modifier = Modifier.padding(bottom = 16.dp)
            )


        }
        Column(
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxWidth()
        ) {
            Button(
                onClick = onGetSupportClick,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
                colors = ButtonDefaults.buttonColors(md_them_primary)
            ) {
                Text(text = "Get Support", color = Color.White)
            }

            Button(
                onClick = onRechargeAgainClick,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(55.dp)
                    .padding(bottom = 8.dp),
                shape = RoundedCornerShape(5.dp), // Set the shape to RoundedCornerShape
                colors = ButtonDefaults.buttonColors(Color.Transparent),
                border = BorderStroke(
                    1.dp,
                    Color.Black
                ) // Add border with specified width and color
            ) {
                Text(text = "Top up Money Again", color = md_them_primary)
            }
        }
    }
}