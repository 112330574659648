package com.winiwayuser.feature_winyway_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CheckCouponCodeRequest(
    @SerialName("coupon_code")val couponId:String,
    @SerialName("txn_id")val txnId:String
)
