package com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

class ChoseRechargePackComponent(context: ComponentContext,
   private val  navigateToRechargeCheckout:(RechargeData) -> Unit,
    private val onBackPressed:()->Unit
) : ComponentContext by context {

    fun onBackPress() = onBackPressed.invoke()
    init {

    }
    fun onSelectRechargePack(data:RechargeData){
        Logger.d(tag = "CHOSE_PAGE_COMPONENT",
            messageString = "On chose recharge pack let's recharge Clicked! -> amount -> $data")
        navigateToRechargeCheckout(data)
    }
}