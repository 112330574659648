package com.winiwayuser.feature_call_consultation.persentation.voip_call_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.agora.AgoraRtcCallback
import com.winiwayuser.core.agora.AgoraRtmVoiceClientApp
import com.winiwayuser.core.data.CommonExpertInfo
import com.winiwayuser.core.remote.Resources
import com.winiwayuser.core.timer.MyCoroutineCountDownTimer
import com.winiwayuser.core.timer.VoiPCallState
import com.winiwayuser.feature_call_consultation.data.request.EndVoipCallRequest
import com.winiwayuser.feature_call_consultation.data.request.GetVoiceAgoraTokenRequest
import com.winiwayuser.feature_call_consultation.data.request.StartVoiceCallRequest
import com.winiwayuser.feature_call_consultation.data.request.VoIPCallStatus
import com.winiwayuser.feature_call_consultation.data.response.voip_agora_token.VoipAgoraTokenData
import com.winiwayuser.feature_call_consultation.domain.VoIPCallRepo
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class VoipCallComponent(
    context: ComponentContext,
    private val expertInfo: CommonExpertInfo,
    private val _callDuration: String,
    private val onClose: () -> Unit,
) : ComponentContext by context, KoinComponent, AgoraRtcCallback {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val voipRepository: VoIPCallRepo by inject()
    private var agoraClient: AgoraRtmVoiceClientApp? = null

    //    private var mediaPlayerFactory: MediaPlayerFactory? = null
    val expertDetail get() = expertInfo

//    private fun startRingingMedia(factory: MediaPlayerFactory?) {
//        factory?.playAudio("/voip_call_tone.mp3", 1 * 60 * 1000L) // Play for 2 minutes
//    }

    fun onClosePage() = onClose.invoke()

    private var countdownTimer: MyCoroutineCountDownTimer? = null
    private var voIPData: VoipAgoraTokenData? = null
    val callDuration get() = _callDuration.toIntOrNull()?.times(1000)?.toLong() ?: 5000

    private val _isRemoteMicMuted: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isRemoteMicMuted get() = _isRemoteMicMuted.asStateFlow()

    /*Call Timer*/
    private val _callState: MutableStateFlow<Pair<String, String>> =
        MutableStateFlow(Pair("", ""))
    val callState get() = _callState.asStateFlow()

    private fun setCallTimer(remainingTime: String, elapsedTime: String) {
        coroutineScope.launch {
            _callState.emit(Pair(remainingTime, elapsedTime))
        }
    }

    /*Call Status*/
    private val _callStatus: MutableStateFlow<String> = MutableStateFlow("Initializing")
    val callStatus get() = _callStatus.asStateFlow()

    private fun setCallStatus(status: String) {
        coroutineScope.launch {
            _callStatus.emit(status)
        }
    }

    private val _callEndedState = MutableStateFlow(false)
    val callEndedState get() = _callEndedState.asStateFlow()

    private val _networkStatus: MutableStateFlow<String> = MutableStateFlow("Good")
    val networkStatusState get() = _networkStatus.asStateFlow()

    private val _state: MutableStateFlow<VoiPCallState> =
        MutableStateFlow(VoiPCallState.OnLoading(true))
    val state get() = _state.asStateFlow()

    /*User Event from Ui*/
    fun onMuteToggle(isMuted: Boolean) {
        // Handle mute toggle logic
        agoraClient?.onMicrophoneChange(isMuted)
    }

    fun onEndCallByUser() {
        onEndCallByExpert("Call Ended!")
    }

    fun onSpeakerToggle(isSpeakerOn: Boolean) {
        agoraClient?.onSpeakerChange(isSpeakerOn)
    }
    /*User Event ended*/

    init {
        context.lifecycle.doOnStart {
            getAgoraToken()
        }

//        context.doOnCreate {
//            mediaPlayerFactory = MediaPlayerFactory()
//        }
        context.lifecycle.doOnDestroy {
//            mediaPlayerFactory?.stopAudio()
//            mediaPlayerFactory = null
            agoraClient = null
            countdownTimer?.stopTimer()
            countdownTimer = null
            coroutineScope.cancel()
        }
    }

    private fun onUSerOnline() {
        coroutineScope.launch {
//            mediaPlayerFactory?.stopAudio()
            countdownTimer = MyCoroutineCountDownTimer(
                timeInSeconds = callDuration,
                onComplete = {
                    onEndCallByExpert("Low Amount")
                },
                onTick = { remainingTime, elapsedTime ->
                    setCallTimer(remainingTime, elapsedTime)
                },
            )
            countdownTimer?.startTime()
//            startTime = getCurrentDateTime()
            setCallStatus("Call Progress")
            setChangeStatus(VoIPCallStatus.IN_PROGRESS)
        }
    }

    private fun onEndCallByExpert(status: String) {
        coroutineScope.launch {
            agoraClient?.logout()
//            endTime = getCurrentDateTime()
            setCallStatus(status)
            countdownTimer?.stopTimer()
            countdownTimer = null
            val job = async {
                endCallLogToServer()
                _callEndedState.emit(true) // Emit after setChangeStatus completes
            }
            job.await()
        }
    }

    private fun onChangeNetworkStatus(status: String) {
        _networkStatus.value = status
    }

    private fun onError(error: String) = coroutineScope.launch {
        coroutineScope.launch {
            _state.emit(VoiPCallState.OnError(msg = error))
        }
    }

    private fun onLoading(isLoading: Boolean) = coroutineScope.launch {
        coroutineScope.launch {
            _state.emit(VoiPCallState.OnLoading(isLoading = isLoading))
        }
    }

    private fun onSuccess(data: VoipAgoraTokenData) = coroutineScope.launch {
        coroutineScope.launch {
            _state.emit(VoiPCallState.OnSuccess(data = data))
        }
    }

    private fun onPermissionChanged(isGranted: Boolean) {
        coroutineScope.launch {
            _state.emit(VoiPCallState.OnMicPermissionChanged(isGranted))
        }
    }

    fun getAgoraToken() {
        coroutineScope.launch {
            val request = GetVoiceAgoraTokenRequest(expertInfo.expertId.toString())
            voipRepository.getVoipAgoraToken(request).collectLatest { response ->
                when (response) {
                    is Resources.Error -> onError(response.msg ?: "Unexpected Error")
                    is Resources.Loading -> onLoading(response.isLoading)
                    is Resources.Success -> {
                        voIPData = response.data
                        agoraClient = AgoraRtmVoiceClientApp(
                            appId = "414eb4458bcc43039fd697e672dd0284", this@VoipCallComponent
                        )
                        voIPData?.voipCallDetails?.let {
                            agoraClient?.initialize(
                                onLoginSuccess = {
                                    agoraClient?.onJoinChannel(
                                        peerId = it.expertId,
                                        token = it.token,
                                        channel = it.channelName,
                                        onJoinChannelSuccess = {
                                            setCallStatus("Waiting for Expert")
                                        },
                                        onJoinChaError = { msg ->
                                            onError(msg)
                                        }
                                    )
                                    agoraClient?.getNetworkQuality { txQuality, _ ->
                                        val text = when (txQuality?.toIntOrNull()) {
                                            0, 1, 2 -> "GOOD"
                                            3, 4, 5 -> "POOR"
                                            else -> "DOWN"
                                        }
                                        onChangeNetworkStatus(text)
                                    }
                                },
                                onLoginError = { msg ->
                                    onError(msg)
                                }
                            )
                        }

                    }
                }
            }
        }
    }

    private fun setChangeStatus(status: VoIPCallStatus) {
        val request = StartVoiceCallRequest(
            consultationId = voIPData?.voipCallDetails?.voipCallId.toString(),
            status = status.status
        )
        coroutineScope.launch {
            voipRepository.startVoipCall(request).collectLatest {
                Logger.d("VoIP Status Log Server")
            }
        }
    }

    private fun endCallLogToServer() {
        val request = EndVoipCallRequest(
            consultationId = voIPData?.voipCallDetails?.voipCallId.toString()
        )
        coroutineScope.launch {
            voipRepository.endVoipCall(request).collectLatest {
                Logger.d("End Call Log Server")
            }
        }
    }


    override fun onUserOnline(userId: Int) {
        Logger.d("on USer online")
        if (countdownTimer != null) return
        onUSerOnline()
    }

    override fun onUserOffline(userId: Int) {
        Logger.d("on User Offline")
        onEndCallByExpert("Expert Ended Call")
    }

    override fun onMicrophonePermissionGranted() {
        Logger.d("on Microphone Permission granted")
        onPermissionChanged(true)
//        startRingingMedia(mediaPlayerFactory)
        onSuccess(voIPData!!)
    }

    override fun onMicrophonePermissionDenied() {
//        onError("Microphone Permission Denied!")
        Logger.d("on Microphone Permission not Granted")
        onPermissionChanged(false)
    }

    override fun onRemoteUserAudioStatusChanged(isMuted: Boolean) {
        Logger.d("on Expert Mute State $isMuted")
        coroutineScope.launch {
            _isRemoteMicMuted.emit(isMuted)
        }
    }

}