package com.winiwayuser.feature_call_consultation.data.response.voip_agora_token

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoipCallingDetails(
    @SerialName("channel_name") val channelName: String, // N2Y0HrPE
    @SerialName("created_at") val createdAt: String, // 2024-04-09 10:20:01
    @SerialName("expert_id") val expertId: String, // 200
    @SerialName("id") val id: Int, // 4
    @SerialName("is_request") val isRequest: Int, // 1
    @SerialName("token") val token: String, // 006414eb4458bcc43039fd697e672dd0284IACNPQ5W4Ako3mg5gvqNfDFd4nrCX1TmszHbnhIrbqnRDtN+sVmxWvJ3IgCxSAAAeRoWZgQAAQB5Ax5mAwB5Ax5mAgB5Ax5mBAB5Ax5m
    @SerialName("uid") val uid: Int, // 84
    @SerialName("updated_at") val updatedAt: String, // 2024-04-09 10:20:01
    @SerialName("user_id") val userId: Int, // 84
    @SerialName("voip_call_id") val voipCallId: Int // 15586
)