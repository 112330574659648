package com.winiwayuser.core.persentation


import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import com.winiwayuser.feature_chat_consultation_new.data.request.SaveChatConversationRequest
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.HTMLAnchorElement

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun clearCaptchaData() {
}

actual fun clearSessionOrCache() {
    // Clear localStorage
    js("localStorage.clear()")

    // Clear sessionStorage
    js("sessionStorage.clear()")

    // Clear cookies (if session or cache is stored in cookies)
    js("document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });")
}

actual fun refreshPage() {
    window.document.cookie = ""
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = window.location.href + "?refresh=" + js("Date.now()")
}

actual fun clearData() {
    console.log("Attempting to clear data...")
    if (js("typeof localStorage !== 'undefined'") as Boolean) {
        console.log("Clearing localStorage...")
        localStorage.clear()
    } else {
        console.log("localStorage is not available!")
    }

    if (js("typeof sessionStorage !== 'undefined'") as Boolean) {
        console.log("Clearing sessionStorage...")
        sessionStorage.clear()
    } else {
        console.log("sessionStorage is not available!")
    }
}

actual abstract class BasePagingSource<Value : Any> {
    actual abstract suspend fun fetchData(
        page: Int,
        limit: Int
    ): PaginationItems<Value>

    suspend fun load(page: Int, limit: Int): PaginationItems<Value> {
        return try {
            val response = fetchData(page, limit)
            response
        } catch (e: Exception) {
            throw e
        }
    }
}


actual fun downloadBrochurePdf(res: String) {
    val anchor = window.document.createElement("a") as HTMLAnchorElement
    anchor.href = res
    anchor.download = res.substringAfterLast("/")
    anchor.click()
}

private fun triggerFileDownload(url: String, fileName: String) {
    val anchor = window.document.createElement("a") as HTMLAnchorElement
    anchor.href = url
    anchor.download = fileName  // Makes it a downloadable file
    anchor.click()  // Programmatically clicks the anchor element to trigger the download
}

actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>
): String {
    // Create an instance of KtorApi
    val ktorApi = object : KtorApi() {}
    // Create the request object with the messages list and chatId
    val request = SaveChatConversationRequest(msg = list.toTypedArray(), chatId)

    // Debug log to check the serialized JSON
    val jsonBody = Json.encodeToString(SaveChatConversationRequest.serializer(), request)
    console.log("Serialized Request Body: $jsonBody")

    return try {
        // Perform POST request with JSON body using the KtorApi instance's client
        val response: String = ktorApi.client.post {
            url("${KtorApi.BASE_URL}$url") // Construct the full URL using BASE_URL
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()

        response
    } catch (e: Exception) {
        console.log("API call failed: ${e.message}")
        "Error: ${e.message}" // Return error message if something goes wrong
    }
}