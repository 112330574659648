package com.winiwayuser.feature_courses.persentation.reserv_video

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import com.winiwayuser.core.remote.Resources
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import com.winiwayuser.feature_courses.domain.CoursesRepo

class ReserveVideoComponent(
    context: ComponentContext,
    private val onBackButtonClick: () -> Unit,
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)
    private val api: CoursesRepo by inject()

    private val _state = MutableStateFlow<Resources<String>>(Resources.Loading(true))
    val state get() = _state.asStateFlow()

    fun onBackPress() = onBackButtonClick.invoke()

    init {
        lifecycle.doOnStart {
            Logger.d { "Landing Page onStart" }
        }

        lifecycle.doOnCreate {
            coroutineScope.launch {
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private fun printLog(msg: String) {
        Logger.d("COURSE_LIST_PAGE - $msg")
    }

}