package com.winiwayuser.feature_expert_consultation.presentation.expert_detail_success_page


import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import com.winiwayuser.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.feature_chat_consultation_new.persentation.chat_duration_dailog.ChatConsultationStarter
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.feature_common.data.request.Review
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageEvent
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget.ProfileBox
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget.RatingScreen
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget.RatingsLayout
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget.RelatedConsultantListItemUI
import com.winiwayuser.feature_expert_consultation.presentation.expert_detail_page.widget.ReviewLayout
import com.winiwayuser.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.ic_filled_star
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalFoundationApi::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ExpertDetailsSuccess(component: ExpertDetailsPageComponent, expertData: RequestConsultant) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    val scrollState = rememberLazyListState()
    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (isChatClickSelected != null) {
        ChatConsultationStarter(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                component.event(ExpertDetailsPageEvent.onClickLoginButton)
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { duration, category ->
                component.event(
                    ExpertDetailsPageEvent.onChatButtonClick(
                        expertId = expertData.id.toString(),
                        expertName = expertData.full_name.toString(),
                        expertImage = expertData.profile_image_url.toString(),
                        expertSkills = expertData.skills.toString(),
                        duration = duration,
                        category = category
                    )
                )
            },
            context = component,
            onRechargeAgain = { rechargeData: RechargeData? ->
                component.event(
                    ExpertDetailsPageEvent.onRechargeRequired(
                        rechargeData
                    )
                )
            },
        )
    }

    Box(
        modifier = Modifier.fillMaxSize().background(background_color),
        //.padding(16.dp), // Optional padding for better appearance
        contentAlignment = Alignment.Center
    ) {
        LazyColumn(
            modifier = widthModifier.fillMaxSize().background(background_color),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
            state = scrollState
        ) {
            // Sticky ToolBar
            stickyHeader {
                ToolBar(scrollState = scrollState, component)
            }
            item {
                ProfileBox(
                    component = component,
                    onLoginClick = {
                        component.event(ExpertDetailsPageEvent.onClickLoginButton)
                    },
                    onCallConsultation = { callData ->
                        component.event(
                            ExpertDetailsPageEvent.onCallButtonClick(
                                expertId = callData.id.toString(),
                                expertName = callData.full_name.toString(),
                                expertImage = callData.profile_image_url.toString(),
                                expertSkills = callData.skills.toString()
                            )
                        )
                    },
                    onChatConsultation = {
                        isChatClickSelected = expertData
                    },
                    expertData = expertData,
                    modifier = Modifier.fillMaxWidth().padding(horizontal = 16.dp, vertical = 8.dp),
                )
            }

            item {
                ReviewLayout(
                    expertData.total_reviews_count.toString(),
                    expertData.year_of_experience.toString(),
                    expertData.consultant_rating!!.toDouble()
                )
            }

            item {
                DescriptionTextView(expertData.profile_description.toString())
            }
            item {
                RelatedConsultantListItemUI(
                    component = component,
                    onLoginClick = {
                        component.event(ExpertDetailsPageEvent.onClickLoginButton)
                    },
                    onCallConsultation = { callData ->
                        component.event(
                            ExpertDetailsPageEvent.onCallButtonClick(
                                expertId = callData.id.toString(),
                                expertName = callData.full_name.toString(),
                                expertImage = callData.profile_image_url.toString(),
                                expertSkills = callData.skills.toString()
                            )
                        )
                    },
                    onChatConsultation = { chatData, duration, category ->
                        component.event(
                            ExpertDetailsPageEvent.onChatButtonClick(
                                expertId = chatData.id.toString(),
                                expertName = chatData.full_name.toString(),
                                expertImage = chatData.profile_image_url.toString(),
                                expertSkills = chatData.skills.toString(),
                                duration = duration,
                                category = category
                            )
                        )

                    },
                    onItemClick = { expertData ->
                        component.event(ExpertDetailsPageEvent.onRelatedExpertCardClick(expertId = expertData.id!!))

                    },
                    onVoIPCallConsultationStart = { duration, commonExpertInfo ->
                        component.event(
                            ExpertDetailsPageEvent.OnVoIPCallConsultationStart(
                                duration,
                                commonExpertInfo
                            )
                        )
                    },
                    onRequiredRecharge = { rechargeData: RechargeData? ->
                        component.event(
                            ExpertDetailsPageEvent.onRechargeRequired(
                                rechargeData
                            )
                        )

                    },
                )
            }
            item {
                RatingScreen(
                    { component.event(ExpertDetailsPageEvent.onClickLoginButton) },
                    component = component,
                    id = expertData.id.toString()
                )
                Spacer(modifier = Modifier.height(16.dp))
                HorizontalDivider()
                Spacer(modifier = Modifier.height(10.dp))
            }
            item {
                RatingsLayout(expertData.consultant_rating!!.toDouble())
                Spacer(modifier = Modifier.height(16.dp))
                HorizontalDivider()
                Spacer(modifier = Modifier.height(10.dp))
            }
            item {
                expertData.reviews?.forEach { review ->
                    UserReview(review)
                    Spacer(modifier = Modifier.height(8.dp))
                    HorizontalDivider()
                    Spacer(modifier = Modifier.height(8.dp))
                }
            }

        }
    }
}


@Composable
fun ToolBar(scrollState: LazyListState, component: ExpertDetailsPageComponent) {
    val toolbarColor by animateColorAsState(
        if (scrollState.firstVisibleItemIndex > 0) {
            Color.LightGray

        } else {
            Color.Transparent
        }
    )

    Box(
        modifier = Modifier.fillMaxWidth().background(toolbarColor)
    ) {
        Row(
            modifier = Modifier.padding(vertical = 10.dp).padding(horizontal = 16.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Icon(imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                contentDescription = null,
                tint = Color.Black,
                modifier = Modifier.padding(start = 4.dp).clickable {
                    component.onBackPress()
                })
            Text(
                text = "Consultant Details",
                modifier = Modifier.weight(1f).padding(start = 16.dp),
                color = Color.Black,
                fontSize = 18.sp,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                fontWeight = FontWeight.Bold
            )
        }
    }
}


@Composable
fun CallChartActionButton(
    consultant: RequestConsultant,
    text: String,
    icon: ImageVector,
    //icon: Painter,
    onItemClick: (RequestConsultant) -> Unit,
    buttonColor: Color,
    consultantCallAvailability: Boolean
) {
    Button(onClick = { onItemClick(consultant) },
        modifier = Modifier.fillMaxWidth(),
        enabled = consultantCallAvailability,
        colors = ButtonDefaults.buttonColors(buttonColor),
        shape = RoundedCornerShape(4.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(3.dp),
        contentPadding = PaddingValues(horizontal = 8.dp),
        content = {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    imageVector = icon,
                    contentDescription = null,
                    modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE),
                    tint = Color.White
                )
                Spacer(modifier = Modifier.width(15.dp))
                Text(
                    text = text, color = Color.White, maxLines = 1, textAlign = TextAlign.Center
                )
            }
        })
}


@Composable
fun DescriptionTextView(description: String) {
    Text(
        text = description,
        modifier = Modifier.fillMaxWidth().padding(horizontal = 20.dp, vertical = 8.dp)
    )
}

@Composable
fun UserReview(review: Review) {
    Column(
        modifier = Modifier.fillMaxWidth().padding(horizontal = 12.dp, vertical = 6.dp)
            .background(color = Color.LightGray, shape = RoundedCornerShape(8.dp)).padding(8.dp)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = review.user?.full_name.toString(),
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold
//                style = MaterialTheme.typography.subtitle1,
                // modifier = Modifier.padding(start = 4.dp)
            )
//            Spacer(modifier = Modifier.weight(1f))
            Spacer(modifier = Modifier.width(4.dp))
            Box(
                modifier = Modifier.background(
                    color = Color(0xFF1069BC), shape = RoundedCornerShape(Scale.ELEVATION_MEDIUM)
                ).padding(horizontal = 4.dp, vertical = 2.dp)
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color.White,
                        modifier = Modifier.size(12.dp)
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = review.rating!!,
//                        style = MaterialTheme.typography.body2,
                        color = Color.White
                    )
                }
            }
        }
        Spacer(modifier = Modifier.height(4.dp))
        Text(
            text = review.remarks!!,
//            style = MaterialTheme.typography.body2,
            modifier = Modifier.padding(top = 2.dp)
        )
    }
}

