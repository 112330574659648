package com.winiwayuser.feature_courses.persentation.course_page.widget.sections

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.feature_courses.data.response.courses.CourseDetails

@Composable
fun FaqSection(
    modifier: Modifier = Modifier,
    list: List<CourseDetails>
) {

    Column(
        modifier = modifier
    ) {

        Spacer(
            modifier = Modifier.height(12.dp)
        )

        list.forEach {
            FaqItem(
                question = it.title.toString(),
                answer = it.content.toString()
            )
            Spacer(modifier = Modifier.padding(8.dp))
        }

        Spacer(
            modifier = Modifier.height(24.dp)
        )

    }

}

@Composable
fun FaqItem(
    question: String,
    answer: String
) {
    var isExpanded by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier.fillMaxWidth().background(
            color = Color.White,
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        )
    ) {
        // Question Row
        Row(
            modifier = Modifier.fillMaxWidth().padding(vertical = 4.dp, horizontal = 16.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                modifier = Modifier.weight(0.7f),
                text = question,
                fontSize = 16.sp,
                fontWeight = FontWeight.Bold
            )
            IconButton(onClick = { isExpanded = !isExpanded }) {
                Icon(
                    modifier = Modifier.weight(0.3f),
                    imageVector = if (isExpanded) Icons.Default.Remove else Icons.Default.Add,
                    contentDescription = if (isExpanded) "Collapse" else "Expand"
                )
            }
        }

        // Answer Section
        AnimatedVisibility(visible = isExpanded) {
            Column(modifier = Modifier.padding(horizontal = 16.dp)) {
                Text(text = answer, fontSize = 16.sp)
                Spacer(modifier = Modifier.height(16.dp))
            }
        }
    }
}