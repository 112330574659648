package com.winiwayuser.feature_courses.data.response.courses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class Pivot(

    @SerialName("course_id") var courseId: Int? = null,
    @SerialName("instructor_id") var instructorId: Int? = null,
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null

)