package com.winiwayuser.feature_expert_Search

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

@Composable
fun DisplaySearchExpertList(component: SearchScreenComponent){
    //SimpleDockedSearchBar(onBackClick = {})
    Column(modifier = Modifier.fillMaxSize()) {

        HomeScreen(
            modifier = Modifier,
            viewModel = component, // Ensure `component` is of type `SearchScreenComponent`
            navigateToTutorial = { tutorialId -> /* Handle navigation here */ },
            state = rememberSearchState() // Optional, as this is the default value
        )

       // SimpleDockedSearchBar(onBackClick = {component.event(SearchScreenEvent.OnBackKeyPress)},"5",component)
    }
}