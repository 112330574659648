package com.winiwayuser.feature_winyway_wallet.data.response


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TransationDetails(
    @SerialName("id")
    val id: Int,
    @SerialName("user_id")
    val userId: Int?,
    @SerialName("expert_id")
    val expertId: Int?,
    @SerialName("coupon_id")
    val couponId: Int?,
    @SerialName("coupon_code")
    val couponCode: String?,
    @SerialName("cashback_value")
    val cashbackValue: Double?,
    @SerialName("cashback_percent")
    val cashbackPercent: Double?,
    @SerialName("currency")
    val currency: String?,
    @SerialName("total_amount")
    val totalAmount: String?,
    @SerialName("type")
    val type: String?,
    @SerialName("payment_mode")
    val paymentMode: String?,
    @SerialName("payment_status")
    val paymentStatus: String?,
    @SerialName("plan_id")
    val planId: Int?,
    @SerialName("plan_name")
    val planName: String?,
    @SerialName("created_at")
    val createdAt: String?,
    @SerialName("updated_at")
    val updatedAt: String?,
    @SerialName("wallet_balance")
    val walletBalance: String?,
    @SerialName("txn_id")
    val txnId: String?,
    @SerialName("payment_method")
    val paymentMethod: String?,
    @SerialName("call_duration")
    val callDuration: String?,
    @SerialName("redeem_coins")
    val redeemCoins: Int?,
    @SerialName("remarks")
    val remarks: String?,
    @SerialName("last_wallet_balance")
    val lastWalletBalance: String?,
    @SerialName("event")
    val event: String?,
    @SerialName("refund_amount")
    val refundAmount: Double?,
    @SerialName("is_updated")
    val isUpdated: String?,
    @SerialName("chat_duration")
    val chatDuration: String?,
    @SerialName("is_free_refund")
    val isFreeRefund: Int?,
    @SerialName("deleted_at")
    val deletedAt: String?,
    @SerialName("send_refund_whatsapp")
    val sendRefundWhatsapp: Int?,
    @SerialName("custom_order_id")
    val customOrderId: Int?
)