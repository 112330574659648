package com.winiwayuser.core.persentation

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.VectorConverter
import androidx.compose.animation.core.animateValueAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicText
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Chat
import androidx.compose.material.icons.filled.Call
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.winiwayuser.core.local.UserInfoProvider.getIsLogin
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.initializeCurrency
import com.winiwayuser.core.theme.primaryColor
import com.winiwayuser.feature_common.data.request.RequestConsultant
import com.winiwayuser.resources.Res
import com.winiwayuser.resources.premium_background
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.painterResource

enum class ButtonStatus(
    val label: String,
    val buttonColor: Color,
    val iconColor: Color,
    val enabled: Boolean,
    val icon: ImageVector
) {
    ONLINE_CHAT(
        "CHAT",
        Color(0xFF6200EE),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),   // Purple for online
    ONLINE_CALL(
        "CALL",
        Color(0xFF28A745),
        Color.White,
        true,
        Icons.Filled.Call
    ),   // Purple for online
    BUSY_CHAT(
        "BUSY",
        Color(0xFFFF8A65),
        Color.White,
        true,
        Icons.AutoMirrored.Filled.Chat
    ),     // Orange for busy
    BUSY_CALL(
        "BUSY",
        Color(0xFFFF8A65),
        Color.White,
        true,
        Icons.Filled.Call
    ),     // Orange for busy
    OFFLINE_CHAT(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.AutoMirrored.Filled.Chat
    ),   // Gray for offline
    OFFLINE_CALL(
        "OFFLINE",
        Color.Gray.copy(alpha = 0.5f),
        Color.Gray,
        false,
        Icons.Filled.Call
    )   // Gray for offline
}


@Composable
fun ConsultationButton(
    modifier: Modifier,
    status: ButtonStatus,
    onClick: () -> Unit,
    isTextVisible: Boolean
) {
    Button(
        onClick = {
            if ((status.label == "BUSY").not()) onClick.invoke()
        },
        colors = ButtonDefaults.buttonColors(containerColor = status.buttonColor),
        enabled = status.enabled,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = ButtonDefaults.elevatedButtonElevation( // Correct elevation usage
            defaultElevation = Scale.ELEVATION_MEDIUM, // Customize elevation value
            pressedElevation = Scale.ELEVATION_LARGE, // Elevation when pressed
            disabledElevation = 0.dp // Elevation when disabled
        ),
        modifier = modifier
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Icon(
                imageVector = status.icon,
                contentDescription = status.label,
                tint = status.iconColor,
                modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE)
            )

            if (isTextVisible) {
                Spacer(modifier = Modifier.width(4.dp))
                AutoSizeText(
                    text = status.label,
                    color = status.iconColor,
                    maxLines = 1
                )
            }
        }
    }
}

@Composable
fun ChatConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        chatStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CHAT
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CHAT

        else -> ButtonStatus.ONLINE_CHAT
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}

@Composable
fun CallConsultationButton(
    modifier: Modifier = Modifier,
    chatStatus: String?,
    callStatus: String?,
    onClick: () -> Unit,
    isTextVisible: Boolean = true
) {
    val buttonStatus = when {
        callStatus.equals("Offline", ignoreCase = true) -> ButtonStatus.OFFLINE_CALL
        chatStatus.equals("Busy", ignoreCase = true) || callStatus.equals(
            "Busy",
            ignoreCase = true
        ) -> ButtonStatus.BUSY_CALL

        else -> ButtonStatus.ONLINE_CALL
    }

    ConsultationButton(
        modifier = modifier,
        status = buttonStatus,  // Using the dynamically set buttonStatus
        onClick = onClick,
        isTextVisible = isTextVisible
    )
}


@Composable
fun ExpertPriceRow(
    isFreeCallApplicable: Boolean,
    callPerMinutePrice: String?,
    expertPrice: Int,
    winywayPrice: Int,
    flashPrice: String?,
    horizontalArrangement: Arrangement.Horizontal
) {
    val totalCallPerMinutePrice = callPerMinutePrice ?: (expertPrice + winywayPrice).toString()

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth(),
        horizontalArrangement = horizontalArrangement
    ) {
        // New User Tag
        if (isFreeCallApplicable || getIsLogin().not()) {
//            Box(modifier = Modifier
//                .background(primaryColor)
//                .padding(horizontal = 4.dp, vertical = 2.dp), contentAlignment = Alignment.Center){
//
//            }
            Box(
                modifier = Modifier
                    .wrapContentHeight()
                    .background(primaryColor)
                    .padding(horizontal = 4.dp, vertical = 4.dp),
                contentAlignment = Alignment.Center,
                // Centers the text inside the box
            ) {
                Text(
                    text = "New User",
                    color = Color.White,
                    textAlign = TextAlign.Center,
                    fontSize = 10.sp
                )
            }

            val notLoginOrNewUserCase = flashPrice ?: callPerMinutePrice
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = "${initializeCurrency()}$notLoginOrNewUserCase/Min",
                color = primaryColor,
                fontSize = 14.sp,
                modifier = Modifier.alpha(0.6f),
                style = TextStyle(
                    fontStyle = FontStyle.Italic,
                    textDecoration = TextDecoration.LineThrough
                )
            )
        }else{
            // Price Text
            if (isFreeCallApplicable) {
                FlashPrice(flashPrice, totalCallPerMinutePrice)
            } else {
                if (!flashPrice.isNullOrEmpty() && flashPrice != "0") {
                    FlashPrice(flashPrice, totalCallPerMinutePrice)
                } else {
                    Text(
                        text = "${initializeCurrency()}$totalCallPerMinutePrice/Min",
                        color = primaryColor,
                        fontSize = 14.sp
                    )
                }
            }
        }


    }
}

@Composable
fun FlashPrice(flashPrice: String?, totalCallPerMinutePrice: String) {
    // Convert prices to floats
    val flashPriceValue = flashPrice?.toFloatOrNull() ?: 0f
    val totalCallPerMinutePriceValue = totalCallPerMinutePrice.toFloatOrNull() ?: 0f

    // Animate the total price reduction with a faster speed
    val animatedTotalPrice = animateValueAsState(
        targetValue = flashPriceValue,
        animationSpec = tween(durationMillis = 5000), // Reduced duration for price change
        typeConverter = Float.VectorConverter
    )

    // Animatable for shake effect
    val shakeAnimation = remember { Animatable(0f) }

    // Trigger shake animation when the flash price is set
    LaunchedEffect(animatedTotalPrice.value) {
        if (animatedTotalPrice.value < totalCallPerMinutePriceValue) {
            // Shake effect with faster timing
            shakeAnimation.animateTo(
                targetValue = 10f,
                animationSpec = tween(durationMillis = 50) // Faster shake to the right
            )
            shakeAnimation.animateTo(
                targetValue = -10f,
                animationSpec = tween(durationMillis = 50) // Faster shake to the left
            )
            shakeAnimation.animateTo(
                targetValue = 0f,
                animationSpec = tween(durationMillis = 50) // Return to center
            )
        }
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.wrapContentWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        var currentPrice by remember { mutableStateOf(totalCallPerMinutePriceValue) }
        LaunchedEffect(Unit) {
            while (currentPrice > flashPriceValue) {
                currentPrice--
                delay(200)
            }
        }

        Text(
            text = "${initializeCurrency()}$currentPrice/Min",
            color = primaryColor,
            fontSize = 14.sp,
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = "${initializeCurrency()}$totalCallPerMinutePrice/Min",
            color = Color.Red.copy(alpha = 0.8f),
            fontSize = 14.sp,
            style = TextStyle(
                fontStyle = FontStyle.Italic,
                textDecoration = TextDecoration.LineThrough
            )
        )
    }
}







/* Expert Profile Icon with Customize Ring according to value
* use modifier to modify
* This used in this project all place
* if you want to change in this than if will reflect all place*/
@Composable
fun ExpertProfileIcon(
    profileImageUrl: String?,
    modifier: Modifier = Modifier.size(30.dp),
    data: RequestConsultant? = null
) {

    val borderColor = when (data?.isAnyUniqueExpertApplicable()) {
        "Premium" -> Color(0xFFD19753)
        "Trending" -> Color(0xFF1B98F0) // You can use different colors for different experts
        "Celebrity" -> Color(0xFF171616)// Same here for Celebrity
        "New" -> Color(0xFFe7404a)
        else -> Color.White // Default color when no applicable expert
    }

    Box(
        modifier = modifier, contentAlignment = Alignment.Center
    ) {
        AsyncImage(
            model = profileImageUrl,
            modifier = Modifier.size(85.dp).border(
                width = 2.4.dp, color = borderColor, shape = CircleShape
            ).background(Color.White).padding(1.dp).clip(CircleShape),
            contentScale = ContentScale.Crop,
            contentDescription = null,
        )
    }
}

//@Composable
//fun HighlightingTag(
//    expert: RequestConsultant
//) {
//
//    val borderColor = when (expert.isAnyUniqueExpertApplicable()) {
//        "Premium" -> Pair(Color(0xFFD19753), Color.Black) // 0xFFFFCB8E
//        "Trending" -> Pair(
//            Color(0xFF1B98F0),
//            Color.White
//        ) // You can use different colors for different experts
//        "Celebrity" -> Pair(Color(0xFF171616), Color.White) // Same here for Celebrity
//        "New" -> Pair(Color(0xFFe7404a), Color.White)
//        else -> Pair(Color.White, Color.White) // Default color when no applicable expert
//    }
//
//    val borderIcon = when (expert.isAnyUniqueExpertApplicable()) {
//        "Premium" -> painterResource(Res.drawable.premium_background)
//        "Trending" -> painterResource(Res.drawable.trending_background)
//        "Celebrity" -> painterResource(Res.drawable.celebrity_background) // Add appropriate drawable
//        "New" -> painterResource(Res.drawable.new_expert_background)
//        else -> null // Add default drawable
//    }
//
//    Box(
//        contentAlignment = Alignment.Center,
//    ) {
//        if (borderIcon != null) {
//            Image(
//                painter = borderIcon,
//                contentDescription = null,
//                contentScale = ContentScale.FillBounds,
//                modifier = Modifier.size(90.dp, 20.dp)
//            )
//        }
//
//        expert.isAnyUniqueExpertApplicable()?.let {
//            Text(
//                text = it.uppercase(),
//                color = borderColor.second,
//                maxLines = 1,
//                style = TextStyle(
//                    fontWeight = FontWeight.Light,
//                    fontSize = 11.sp,
//                    letterSpacing = 1.sp,
//                ),
//                modifier = Modifier.align(Alignment.Center) // Center-align the text over the icon
//            )
//        }
//    }
//}