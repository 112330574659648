package com.winiwayuser.core.persentation


import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import com.winiwayuser.core.platform.PlatformType
import com.winiwayuser.core.remote.KtorApi
import com.winiwayuser.feature_chat_consultation.data.response.ChatMessage
import com.winiwayuser.feature_chat_consultation_new.data.request.SaveChatConversationRequest
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.Worker
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun clearCaptchaData() {
}

actual fun clearSessionOrCache() {
    // Clear localStorage
    js("localStorage.clear()")

    // Clear sessionStorage
    js("sessionStorage.clear()")

    // Clear cookies (if session or cache is stored in cookies)
    js("document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });")
}

actual fun refreshPage() {
    window.document.cookie = ""
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = window.location.href + "?refresh=" + js("Date.now()")
}

actual fun clearData() {
    console.log("Attempting to clear data...")
    if (js("typeof localStorage !== 'undefined'") as Boolean) {
        console.log("Clearing localStorage...")
        localStorage.clear()
    } else {
        console.log("localStorage is not available!")
    }

    if (js("typeof sessionStorage !== 'undefined'") as Boolean) {
        console.log("Clearing sessionStorage...")
        sessionStorage.clear()
    } else {
        console.log("sessionStorage is not available!")
    }
}

actual abstract class BasePagingSource<Value : Any> {
    actual abstract suspend fun fetchData(
        page: Int,
        limit: Int
    ): PaginationItems<Value>

    suspend fun load(page: Int, limit: Int): PaginationItems<Value> {
        return try {
            val response = fetchData(page, limit)
            response
        } catch (e: Exception) {
            throw e
        }
    }
}


actual fun downloadBrochurePdf(res: String) {
    val anchor = window.document.createElement("a") as HTMLAnchorElement
    anchor.href = res
    anchor.download = res.substringAfterLast("/")
    anchor.click()
}

private fun triggerFileDownload(url: String, fileName: String) {
    val anchor = window.document.createElement("a") as HTMLAnchorElement
    anchor.href = url
    anchor.download = fileName  // Makes it a downloadable file
    anchor.click()  // Programmatically clicks the anchor element to trigger the download
}

actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>
): String {
    // Create an instance of KtorApi
    val ktorApi = object : KtorApi() {}
    // Create the request object with the messages list and chatId
    val request = SaveChatConversationRequest(msg = list.toTypedArray(), chatId)

    // Debug log to check the serialized JSON
    val jsonBody = Json.encodeToString(SaveChatConversationRequest.serializer(), request)
    console.log("Serialized Request Body: $jsonBody")

    return try {
        // Perform POST request with JSON body using the KtorApi instance's client
        val response: String = ktorApi.client.post {
            url("${KtorApi.BASE_URL}$url") // Construct the full URL using BASE_URL
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()

        response
    } catch (e: Exception) {
        console.log("API call failed: ${e.message}")
        "Error: ${e.message}" // Return error message if something goes wrong
    }
}

actual fun startWorkerTask() {
    // Create a new worker instance
    val worker = js("new Worker('./worker.js')") as Worker

    // Send a message to the worker
    worker.postMessage("Start task")

    // Handle messages from the worker
    worker.onmessage = { event: MessageEvent ->
        console.log("Received from worker:", event.data)
        // Handle worker response here
    }
}

actual fun setupBeforeUnload(endChat: () -> Unit) {
    // Listen for when the tab or browser window is about to close (before unload)
    window.addEventListener("beforeunload", { event ->
        // Log to check if this is called
        console.log("before unload event triggered")

        // Call the endChat function when the event is triggered
        endChat()

        // Show a confirmation dialog before closing
        // Set the return value of the event, which triggers the browser's confirmation dialog
        event.asDynamic().returnValue = "" // Modern browsers typically ignore this message

        // Optionally log the event to console for debugging
        console.log("beforeunload event details:", event)
    })

    // Listen for when the page is actually unloading (after confirmation)
    window.addEventListener("unload", { event ->
        // Log to check if this is called
        console.log("unload event triggered", event)

        // Perform any cleanup or saving here if necessary
        // Example: Call an API to save the user's progress or data
        // myApiService.saveUserData()
    })
}


fun onEndChatBackground() {
    // Data payload for the end chat request
    val data = "Your data payload here" // Replace with your actual data payload

    // Create a Blob with the data
    val blob = Blob(arrayOf(data), BlobPropertyBag("text/plain"))

    // Use sendBeacon dynamically
    val url = "https://example.com/api/end-chat" // Replace with your actual endpoint
    val success = window.asDynamic().navigator.sendBeacon(url, blob)

    console.log("sendBeacon success:", success)
}

actual fun getPlatformType(): PlatformType  = PlatformType.Web
actual fun openWhatsApp(phoneNumber: String) {
    val url = "https://wa.me/$phoneNumber" // WhatsApp link format
    window.location.href = url // Open WhatsApp in the browser
}

actual fun openUrlInBrowser(url: String) {
    window.open(url, "_blank") // Opens in a new tab
}