package com.winiwayuser.feature_courses.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Data class representing a request to verify a course purchase made through Razorpay.
 *
 * @param orderId The unique identifier for the Razorpay order.
 * @param paymentId The unique identifier for the Razorpay payment.
 * @param paymentSignature The signature generated by Razorpay to verify the payment.
 *
 * @return An instance of [CourseBuyVerifyRequest] containing the provided order ID, payment ID, and signature.
 */
@Serializable
data class CourseBuyVerifyRequest(
    @SerialName("razorpay_order_id") val orderId: String,
    @SerialName("razorpay_payment_id") val paymentId: String,
    @SerialName("razorpay_signature") val paymentSignature: String
)
