package com.winiwayuser.feature_expert_consultation.presentation.expert_review_submit

import androidx.compose.runtime.Composable
import com.winiwayuser.core.local.UserInfoProvider
import com.winiwayuser.core.persentation.LoginDialog
import com.winiwayuser.core.persentation.showToast

@Composable
fun ExpertReviewSubmitHost(
    onLoginOnClick: () -> Unit,
    onDismiss: () -> Unit,
    onSubmitReview: @Composable () -> Unit
){
    val isUserLogin = UserInfoProvider.getIsLogin()

    if(isUserLogin.not()){
        LoginDialog(
            onDismiss = {onDismiss.invoke()},
            onLoginClicked = { onLoginOnClick.invoke() },
            onSignUpClicked = { onLoginOnClick.invoke() }
        )
    }else{
        onSubmitReview.invoke()
        onDismiss.invoke()
    }
}