package com.winiwayuser.feature_courses.common


import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json


//actual fun VideoPlayer(
//    url: String
//) {

//    val encodedUrl = js("encodeURIComponent(url)") as String
//    js("window.open('video.html?url=' + encodedUrl, '_blank')")

//    Iframe(
//        attrs = {
//            attr("src", url)
//            attr("width", "100%")
//            attr("height", "100%")
//            attr("allow", "autoplay; encrypted-media; fullscreen")
//            attr("frameborder", "0")
//            attr("allowfullscreen", "true")
//        }
//    )
//}

// Helper function to set attributes
actual fun VideoPlayer(
    url: String,
    title: String,
    timeStamps: String,
    instructorName: String,
    instructorDescription: String,
    instructorImage: String
) {
    val data = Json.encodeToString(
        mapOf(
            "videoUrl" to url,
            "title" to title,
            "timestamps" to timeStamps,
            "instructorName" to instructorName,
            "instructorDesc" to instructorDescription,
            "instructorImg" to instructorImage
        )
    )

    val encodedData = js("encodeURIComponent")(data) as String
    window.open("video.html?data=$encodedData", "_blank")
}