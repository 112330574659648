package com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.remote.TransactionHistoryApiService
import com.winiwayuser.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class TransactionInfoRepo(
    private val api: TransactionHistoryApiService
) {
    fun getTransactionHistoryInfo():Flow<Resources<List<TransactionOrderData>>>{
        return flow {
            emit(Resources.Loading())
            val response = api.getTransactionHistoryInfo()
            emit(Resources.Success(response.data))
            emit(Resources.Loading(false))
        }
    }
}