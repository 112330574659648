package com.winiwayuser.feature_call_consultation.data.response.start_voip_call

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StartCallConsultationData(
    @SerialName("amount") val amount: String?, // null
    @SerialName("booking_date_time") val bookingDateTime: String, // 2024-04-09T07:09:28.302121Z
    @SerialName("call_booking_date") val callBookingDate: String, // 09-04-2024
    @SerialName("call_booking_time") val callBookingTime: String, // 12:39:28
    @SerialName("call_duration") val callDuration: String?, // null
    @SerialName("call_minutes") val callMinutes: String?, // null
    @SerialName("call_place_again") val callPlaceAgain: Int, // 0
    @SerialName("created_at") val createdAt: String, // 2024-04-09 12:12:53
    @SerialName("currency") val currency: String, // INR
    @SerialName("exotel_call_duration") val exotelCallDuration: String?, // null
    @SerialName("exotel_call_status") val exotelCallStatus: String, // in-progress
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("expert_payment") val expertPayment: String?, // null
    @SerialName("expert_price") val expertPrice: Int, // 14
    @SerialName("flash_off") val flashOff: String?, // null
    @SerialName("id") val id: Int, // 15591
    @SerialName("international_rate") val internationalRate: String?, // null
    @SerialName("parent_id") val parentId: Int, // 0
    @SerialName("ramaining_balance") val ramainingBalance: String?, // null
    @SerialName("sid") val sid: String, // cdxhYu4aRnap4DeiDXNjg5VUCEI8jepy
    @SerialName("slot_for") val slotFor: String, // Voip Call
    @SerialName("updated_at") val updatedAt: String, // 2024-04-09 12:39:28
    @SerialName("user_id") val userId: Int, // 53618
    @SerialName("user_rating") val userRating: String?, // null
    @SerialName("user_review") val userReview: String?, // null
    @SerialName("winyway_payment") val winywayPayment: String?, // null
    @SerialName("winyway_price") val winywayPrice: Int // 1
)