package com.winiwayuser.feature_call_consultation.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CallWaitingResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val `data`: Data?,
    @SerialName("message") val message: String, // Please wait,you will get a call in 2 minutes
    @SerialName("status") val status: Boolean // true
)
