package com.winiwayuser.feature_courses.persentation.course_page.widget.dailog

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Cancel
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Discount
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import com.winiwayuser.core.persentation.CommonLoadingButton
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.theme.white
import com.winiwayuser.feature_courses.persentation.course_page.CourseComponent
import com.winiwayuser.feature_courses.persentation.course_page.widget.CouponDailogState

@Composable
fun CouponDialog(
    component: CourseComponent,
    onDismiss: () -> Unit,
    onApplyCoupon: (coupon: String) -> Unit
) {

    val state by component.couponState.collectAsState()

    var couponCode by remember { mutableStateOf("") }
    var isCouponError by remember { mutableStateOf<String?>(null) }

    if (
        state is CouponDailogState.Message
    ) {
        isCouponError = (state as CouponDailogState.Message).msg
    }

    if (
        state is CouponDailogState.Success
    ) {
        onDismiss.invoke()
    }


    Dialog(onDismissRequest = {
        onDismiss.invoke()
    }) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .wrapContentHeight()
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .padding(16.dp)
        ) {
            Column {

                Box(
                    modifier = Modifier.align(Alignment.End)
                ) {
                    Icon(
                        modifier = Modifier.clickable {
                            onDismiss.invoke()
                        },
                        imageVector = Icons.Default.Close,
                        contentDescription = "Close Icon"
                    )
                }

                Text(
                    text = "Have a coupon code? Redeem it here.",
                    style = MaterialTheme.typography.bodyLarge.copy(
                        color = Color.Gray,
                        fontWeight = FontWeight.Bold
                    ),
                    modifier = Modifier.padding(bottom = 4.dp)
                )

                // Name Field
                OutlinedTextField(
                    value = couponCode,
                    onValueChange = {
                        couponCode = it.uppercase()
                        isCouponError = null
                    },
                    label = { Text("Coupon Code", color = Color.Gray) },
                    placeholder = { Text("Enter Coupon Code") },
                    singleLine = true,
                    isError = isCouponError != null,
                    keyboardOptions = KeyboardOptions.Default.copy(
                        capitalization = KeyboardCapitalization.Characters // Forces uppercase keyboard
                    ),
                    leadingIcon = {
                        Icon(Icons.Default.Discount, contentDescription = "Name Icon")
                    },
                    trailingIcon = {
                        if (couponCode.trim().isNotEmpty()) {
                            IconButton(onClick = {
                                couponCode = ""
                            }) {
                                Icon(Icons.Filled.Cancel, contentDescription = "Check Icon")
                            }
                        }
                    },
                    supportingText = {
                        if (isCouponError != null) {
                            Text(
                                text = isCouponError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = Color(0xFF112B60),
                        unfocusedBorderColor = Color.Gray,
                    )
                )
                Spacer(modifier = Modifier.height(12.dp))
                CommonLoadingButton(
                    modifier = Modifier.fillMaxWidth(),
                    isLoading = state is CouponDailogState.LoadingState,
                    text = "Apply Coupon",
                    onClick = {
                        if (couponCode.trim().isEmpty() || couponCode.trim().length < 3) {
                            isCouponError = "Enter valid Coupon"
                        } else {
                            onApplyCoupon.invoke(couponCode)
                        }
                    },
                    buttonColors = ButtonDefaults.buttonColors(
                        containerColor = primaryDark,
                        contentColor = white,
                    ),
                )
            }
        }
    }

    DisposableEffect(Unit){

        onDispose {
            // Notify ViewModel to release resources
            println("Dialog closed, ViewModel cleaned up.")
        }
    }
}
