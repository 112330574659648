package com.winiwayuser.feature_common.di

import com.winiwayuser.feature_common.data.remote.HomeApiService
import com.winiwayuser.feature_common.persentation.home_tab.HomeScreenTabComponent
import org.koin.dsl.module

//val homeCommonModule = module {
//    scope<HomeScreenTabComponent> {
//        scoped{HomeApiService()}
//    }
//}

val homeCommonModule = module {
    factory { HomeApiService() }
}

