package com.winiwayuser.feature_call_consultation.persentation.voip_call_page

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.VolumeOff
import androidx.compose.material.icons.automirrored.filled.VolumeUp
import androidx.compose.material.icons.filled.CallEnd
import androidx.compose.material.icons.filled.Mic
import androidx.compose.material.icons.filled.MicOff
import androidx.compose.material.icons.filled.Timer
import androidx.compose.material.icons.filled.Wifi
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.IconButtonColors
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.winiwayuser.core.extenstion.getMaxInputAbleWidthModifier
import com.winiwayuser.core.persentation.ExpertProfileIcon
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.theme.primaryDark
import com.winiwayuser.core.timer.formatTime


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallConsultationPage(
    component: VoipCallComponent
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val isCompact = windowSizeClass == WindowWidthSizeClass.Compact
    val newtworkState by component.networkStatusState.collectAsState()
    val isExpertMute by component.isRemoteMicMuted.collectAsState()

    // Background and main layout
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(primaryDark),
        contentAlignment = Alignment.Center
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxSize()
        ) {
            // Top Section: Network Status, Call Status
            TopBar(
                networkStatus = newtworkState,
                isExpertMute = isExpertMute
            )

            Spacer(modifier = Modifier.height(16.dp))

            CallStatusBar(
                modifier = windowSizeClass.getMaxInputAbleWidthModifier(
                    medium = 0.34f, expand = 0.3f
                ),
                component = component
            )

            // Logo and Expert Information
            ExpertInfoSection(
                isCompact = isCompact,
                component = component
            )

            // Bottom Section: Controls (Mute, Speaker, End Call)
            CallControlSection(
                modifier = windowSizeClass.getMaxInputAbleWidthModifier(
                    medium = 0.34f, expand = 0.3f
                ),
                onMuteToggle = { component.onMuteToggle(it) },
                onEndCall = { component.onEndCallByUser() },
                onSpeakerToggle = { component.onSpeakerToggle(it) }
            )
        }
    }
}

@Composable
fun TopBar(
    networkStatus: String,
    isExpertMute: Boolean = false
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {

        TextWithDrawable(
            modifier = Modifier,
            drawableRes = Icons.Default.Wifi,
            text = networkStatus
        )

        Box {
            if (isExpertMute) {
                TextWithDrawable(
                    modifier = Modifier,
                    drawableRes = Icons.Default.MicOff,
                    text = "Expert Muted"
                )
            }
        }
    }
}

@Composable
fun CallStatusBar(
    modifier: Modifier,
    component: VoipCallComponent
) {

    val timerStatus by component.callState.collectAsState()
    val callStatus by component.callStatus.collectAsState()
    val formatedTime = formatTime(component.callDuration)

    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = callStatus,
            style = MaterialTheme.typography.headlineSmall.copy(color = Color.White)
        )
        Spacer(modifier = Modifier.height(4.dp))
        TextWithDrawable(
            modifier = Modifier,
            drawableRes = Icons.Default.Timer,
            text = "${timerStatus.first} / $formatedTime"
        )
    }
}


@Composable
fun TextWithDrawable(
    modifier: Modifier,
    drawableRes: ImageVector,
    text: String,
    contentDescription: String? = null
) {

    Box(
        modifier = modifier.background(
            shape = CircleShape, color = Color.Black.copy(0.2f)
        )
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(horizontal = 8.dp, vertical = 3.dp),
        ) {
            Icon(
                imageVector = drawableRes,
                contentDescription = contentDescription,
                modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_MIN),
                tint = Color.White
            )
            Spacer(modifier = Modifier.width(4.dp))
            Text(
                text = text, color = Color.White,
                style = MaterialTheme.typography.labelMedium
            )
        }
    }

}



@Composable
fun ExpertInfoSection(isCompact: Boolean, component: VoipCallComponent) {

    val status by component.callState.collectAsState()

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
//        Image(
//            painter = painterResource(Res.drawable.icon_logo), // Add your logo here
//            contentDescription = "Winyway Logo",
//            modifier = Modifier.size(50.dp)
//        )
        Spacer(modifier = Modifier.height(16.dp))
        ExpertProfileIcon(
            component.expertDetail.expertProfileImageUrl,
            modifier = Modifier.size(if (isCompact) 100.dp else 150.dp).background(
                shape = CircleShape, color = Color.Black.copy(0.3f)
            )
        )
        Spacer(modifier = Modifier.height(16.dp))
        Text(
            text = component.expertDetail.expertName,
            style = MaterialTheme.typography.titleMedium.copy(color = Color.White)
        )
        Text(
            text = status.second,
            style = MaterialTheme.typography.bodyMedium.copy(color = Color.White)
        )
    }
}

@Composable
fun CallControlSection(
    modifier: Modifier,
    onMuteToggle: (Boolean) -> Unit,
    onEndCall: () -> Unit,
    onSpeakerToggle: (Boolean) -> Unit
) {

    val isSpeakerOn = rememberSaveable { mutableStateOf(true) }
    val isMuted = rememberSaveable { mutableStateOf(false) }

    Box(
        modifier = modifier
            .padding(16.dp)
            .wrapContentHeight()
            .background(shape = RoundedCornerShape(40f), color = Color.Black.copy(alpha = 0.3f)),
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 12.dp)
                .wrapContentHeight(),
            horizontalArrangement = Arrangement.SpaceAround,
            verticalAlignment = Alignment.CenterVertically
        ) {
            IconButton(
                colors = IconButtonColors(
                    containerColor = Color.White.copy(0.2f),
                    contentColor = Color.White,
                    disabledContainerColor = Color.Transparent,
                    disabledContentColor = Color.White
                ),
                onClick = {
                    onMuteToggle.invoke(isMuted.value)
                    isMuted.value = !isMuted.value
                },
                modifier = Modifier.size(40.dp)
            ) {
                Icon(
                    imageVector = if (isMuted.value) Icons.Default.MicOff else Icons.Default.Mic,
                    contentDescription = if (isMuted.value) "Unmute" else "Mute",
                    tint = Color.White
                )
            }

            IconButton(
                colors = IconButtonColors(
                    containerColor = Color.Red,
                    contentColor = Color.White,
                    disabledContainerColor = Color.Transparent,
                    disabledContentColor = Color.White
                ),
                onClick = onEndCall,
                modifier = Modifier.size(45.dp)
            ) {
                Icon(
                    imageVector = Icons.Default.CallEnd,
                    contentDescription = "End Call",
                    tint = Color.White
                )
            }

            IconButton(
                colors = IconButtonColors(
                    containerColor = Color.White.copy(0.2f),
                    contentColor = Color.White,
                    disabledContainerColor = Color.Transparent,
                    disabledContentColor = Color.White
                ),
                onClick = {
                    onSpeakerToggle.invoke(isSpeakerOn.value)
                    isSpeakerOn.value = !isSpeakerOn.value
                },
                modifier = Modifier.size(40.dp)

            ) {
                Icon(
                    imageVector = if (isSpeakerOn.value) Icons.AutoMirrored.Filled.VolumeUp else Icons.AutoMirrored.Filled.VolumeOff,
                    contentDescription = if (isSpeakerOn
                            .value
                    ) "Speaker On" else "Speaker Off",
                    tint = Color.White
                )
            }
        }
    }
}
