package com.winiwayuser.feature_call_consultation.data.response.voip_agora_token

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CallConsultationDetails(
    @SerialName("created_at") val createdAt: String, // 2024-04-09 10:22:42
    @SerialName("expert_id") val expertId: String, // 200
    @SerialName("id") val id: Int, // 15587
    @SerialName("updated_at") val updatedAt: String, // 2024-04-09 10:22:42
    @SerialName("user_id") val userId: Int // 84
)