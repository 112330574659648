package com.winiwayuser.feature_expert_consultation.domain

import com.winiwayuser.core.remote.Resources
import com.winiwayuser.feature_expert_consultation.data.remote.ExpertDetailsApi
import com.winiwayuser.feature_expert_consultation.data.remote.request.Data
import com.winiwayuser.feature_expert_consultation.data.remote.request.RequestReviewSubmit
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class ExpertDetailsRepo(private val api: ExpertDetailsApi) {

    fun getExpertDetails(id:String):Flow<Resources<Data>>{
        return flow {
            emit(Resources.Loading(true))
            val response = api.getExpertDetails(id)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

    fun postExpertReview(request: RequestReviewSubmit):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading(true))
            val response = api.postSubmitExpertReview(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

//    fun getRelatedExpert(request: RelatedExpertRequest):Flow<Resources<Data>>{
//        return flow {
//            emit(Resources.Loading(true))
//            val response = api.getRelatedExpertList(request)
//            emit(response)
//            emit(Resources.Loading(false))
//        }
//    }
}