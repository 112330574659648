package com.winiwayuser.feature_courses.data.response.courses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class CourseDetails(
    @SerialName("id") var id: Int? = null,
    @SerialName("course_id") var courseId: Int? = null,
    @SerialName("section") var section: String? = null,
    @SerialName("title") var title: String? = null,
    @SerialName("content") var content: String? = null,
    @SerialName("created_at") var createdAt: String? = null,
    @SerialName("updated_at") var updatedAt: String? = null,
    @SerialName("deleted_at") var deletedAt: String? = null
)