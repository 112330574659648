package com.winiwayuser.feature_call_consultation.persentation.voip_call_page


import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.winiwayuser.core.persentation.showToast
import com.winiwayuser.core.theme.Scale
import com.winiwayuser.core.timer.VoiPCallState
import com.winiwayuser.feature_call_consultation.persentation.voip_end_page.ConsultationEndedPage

@Composable
fun VoIPCallConsultationScreen(
    componentContext: VoipCallComponent
) {


    val callEndState by componentContext.callEndedState.collectAsState()
    val state by componentContext.state.collectAsState()

    when (val currentState = state) {
        is VoiPCallState.OnError -> {
            showToast(currentState.msg)
        }

        is VoiPCallState.OnLoading -> {
            LoadingPage(modifier = Modifier.fillMaxSize())
        }

        is VoiPCallState.OnSuccess -> {
            CallConsultationPage(component = componentContext)
        }

        is VoiPCallState.OnMicPermissionChanged -> {
            if (currentState.isGranted.not()) {
                MicrophonePermissionDialog(
                    onRequestPermission = {
                        componentContext.getAgoraToken()
                    },
                    onExit = {
                        componentContext.onClosePage()
                    }
                )
            }
        }
    }

    if (callEndState) {
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                componentContext.onClosePage()
            },
            text = {
                ConsultationEndedPage(
                    expertInfo = componentContext.expertDetail,
                    duration = componentContext.callState.value.second,
                    onRatingSelected = {
                        componentContext.onClosePage()
                    },
                    onDoneClicked = {
                        componentContext.onClosePage()
                    }
                )
            },
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {},
            dismissButton = {
//                componentContext.onClosePage()
            }
        )

    }
}

@Composable
fun MicrophonePermissionDialog(
    onRequestPermission: () -> Unit,
    onExit: () -> Unit
) {
    var showDialog by remember { mutableStateOf(true) }

    if (showDialog) {
        AlertDialog(
            onDismissRequest = { /* Prevent dismiss by clicking outside */ },
            title = {
                Text(text = "Microphone Permission Required")
            },
            text = {
                Text(
                    text = "This app requires microphone access for VOIP calls. Please grant the permission to proceed."
                )
            },
            confirmButton = {
                Button(onClick = {
                    showDialog = false
                    onRequestPermission()
                }) {
                    Text(text = "Request Again")
                }
            },
            dismissButton = {
                Button(onClick = {
                    showDialog = false
                    onExit()
                }) {
                    Text(text = "Exit")
                }
            }
        )
    }
}
