package com.winiwayuser.feature_common.persentation.more_tab.privacy_policy

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.winiwayuser.core.persentation.BrowserType
import com.winiwayuser.core.persentation.NoTitleTopAppBar
import com.winiwayuser.core.persentation.getDeviceType
import com.winiwayuser.core.theme.background_color
import com.winiwayuser.core.theme.bodies
import com.winiwayuser.core.theme.getBrowserTypeScale
import com.winiwayuser.core.theme.subtitles
import com.winiwayuser.feature_common.persentation.home_tab.footer.Footer
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyComponent
import com.winiwayuser.feature_common.persentation.more_tab.component.PrivacyPolicyEvent
import com.winiwayuser.feature_common.persentation.more_tab.component.TermsAndConditionsEvent

@Composable
fun PrivacyPolicyPage(component: PrivacyPolicyComponent) {
    val deviceType = getDeviceType()
    val isBrowserType = deviceType.name in BrowserType.entries.map { it.name }

    if(isBrowserType){
        Scaffold(
            topBar = { NoTitleTopAppBar(navigationIcon = { component.event(PrivacyPolicyEvent.OnBackKeyPress) }) }
        ) { innerPadding ->
            Box(
                modifier = Modifier.fillMaxSize(),
               // modifier = Modifier.width(deviceType.getBrowserTypeScale(250).dp),
                contentAlignment = Alignment.Center
            ) {
                LazyColumn(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp)
                ) {
                    item {
                        Text(
                            text = "Privacy Policy",
                            fontSize = 20.sp,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            style = MaterialTheme.typography.headlineMedium,
                            modifier = Modifier.padding(bottom = 8.dp).fillMaxWidth()
                        )
                    }
                    subtitles.forEachIndexed { index, subtitle ->
                        item {
                            Text(
                                text = subtitle,
                                style = MaterialTheme.typography.titleMedium,
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                modifier = Modifier.padding(bottom = 8.dp).fillParentMaxWidth()
                            )
                        }
                        item {
                            Text(
                                text = bodies[index],
                                fontSize = 14.sp,
                                style = MaterialTheme.typography.bodyMedium,
                                modifier = Modifier.padding(bottom = 16.dp).fillParentMaxWidth().wrapContentHeight(),
                            )
                        }
                    }
                    item {
//                        Footer()
                    }
                }
            }
        }
    }else{
        Scaffold(
            topBar = { NoTitleTopAppBar(navigationIcon = { component.event(PrivacyPolicyEvent.OnBackKeyPress) }) }
        ) { innerPadding ->
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(innerPadding),
                contentAlignment = Alignment.Center
            ) {
                LazyColumn(
                    modifier = Modifier
                        .fillMaxWidth()
                ) {
                    item {
                        Text(
                            text = "Privacy Policy",
                            style = MaterialTheme.typography.headlineMedium,
                            modifier = Modifier.padding(bottom = 8.dp)
                        )
                    }
                    subtitles.forEachIndexed { index, subtitle ->
                        item {
                            Text(
                                text = subtitle,
                                fontSize = 18.sp,
                                fontWeight = FontWeight.Bold,
                                style = MaterialTheme.typography.titleMedium,
                                modifier = Modifier.padding(bottom = 8.dp).fillParentMaxWidth()
                            )
                        }
                        item {
                            Text(
                                text = bodies[index],
                                fontSize = 14.sp,
                                style = MaterialTheme.typography.bodyMedium,
                                modifier = Modifier.padding(bottom = 16.dp).fillParentMaxWidth().wrapContentHeight(),
                            )
                        }
                    }

                    item {
//                        Footer()
                    }
                }
            }
        }
    }

}



